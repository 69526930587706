import React, { useState, useEffect } from 'react';
import { Layout } from '../../../../allcomponents';
import axios from 'axios';
import { useRecoilState } from 'recoil';
import { Table, Button } from 'antd';
import { API_URL, IMAGE_URL } from '../../../../Store/Global';
import styles from '../../../../styles/XAI.module.css'

interface Image {
    key: number,
    name: string,
    url: string,
}

export default function AirportMetaLearningResultPage(props:any) {
    const projectType = localStorage.getItem('projectType');
    const curId = window.location.href.split('/')[6];
    let token : string | null = localStorage.getItem("token") 
    let projectId : string | null = localStorage.getItem("projectId")

    const [userid, setUserId] = useState<number>(-1);
    const [companyId, setcompanyId] = useState<any>(localStorage.getItem("companyId"));

    const { Column } = Table;

    const oriImgUrl : Image[] = [];
    const [oriImgList, setOriImgList] = useState<any[]>(oriImgUrl);
    const [oriList, setOriList] = useState<boolean>(false)

    const [oriSelet, setOriSelect] = useState(0)

    useEffect(() => {
        axios({
                method: 'get',
                url: API_URL+'/File/Files',
                headers: { "accept": `application/json`, "access-token": `${token}` },
                params : {path : `/project/${projectId}/stageMeta/original`}
        }).then((res) => {
            if (res.data.check === true) {
                // console.log(res.data.data.files)

                for (let i = 0; i < res.data.data.files.length; i++) {
                    oriImgUrl.push({
                        key: i + 1,
                        name: res.data.data.files[i],
                        url: `${IMAGE_URL}/image?path=/project/${projectId}/stageMeta/original/${res.data.data.files[i]}&width=360`,
                    })
                }

                setOriImgList(oriImgUrl)
                setOriList(true)

            } else {
                
            }
        })
        axios({
            method: 'get',
            url: API_URL+'/File/Files',
            headers: { "accept": `application/json`, "access-token": `${token}` },
            params : {path : `/project/${projectId}/stageMeta/result`}
        }).then((res) => {
            if (res.data.check === true) {

                //text 파일 읽고 싶어요
                // console.log(res.data.data.files)
                // // ${IMAGE_URL}/project/245/stageMeta/result/Result.txt

                // let file = "${IMAGE_URL}/image?path=/project/245/stageMeta/result/" + res.data.data.files

                // let fileReader = new FileReader();

                // fileReader.onload = () => {
                //     console.log(fileReader.result)
                // }

                // fileReader.readAsText(new Blob([file], {type: 'text/plain'}));
            } else {
                
            }
        })

        console.log(oriImgList)
        console.log(oriList)
    }, []);
    

    return (
        <Layout>
            <div style={{ paddingTop : '2.01rem', paddingLeft : '2rem', color: '#999', fontSize: '0.75rem', fontFamily: 'inter-pretendard', fontStyle: 'normal', fontWeight: 400, lineHeight: '100%'}}>검출 & 측정 &gt; 메타 러닝 결과</div>
            <div style={{ paddingTop : '0.3rem', paddingLeft : '2rem', color: '#3A4557', fontSize: '1.5rem', fontFamily: 'inter-pretendard', fontStyle: 'normal', fontWeight: 700, lineHeight: '100%'}}>메타 러닝 결과</div>

            <>
                <div className={styles.DivArea}>
                    {/* <div>
                        <p className={styles.subOrder}>{t.dmDetectorMeasure} &gt; {t.dmMetaLearningResult}</p>
                        <p className={styles.mainOrder}>{t.dmMetaLearningResult}</p>   
                    </div> */}

                    <div className={styles.xai_table}>
                        <Table dataSource={oriList === true? oriImgList:[]}>
                            <Column title="num" dataIndex="key" key="num" />
                            <Column title="이름" dataIndex="name" key="num" onCell={(record: any) => {
                                return {
                                    onClick: event => {
                                        // console.log(record)
                                        setOriSelect(record.key-1)
                                    }
                                }
                            }} />
                        </Table>
                    </div>
{/* 
                    <p className={styles.mainOrder}>{oriImgList[oriSelet].name}</p>
                    <div className={styles.originalImg}>
                        <img src={oriList === true ? oriImgList[oriSelet].url:""} />
                    </div> */}
                </div>
            </>
        </Layout>
    );
};
import React, { useState, useEffect } from 'react';
import { Layout } from '../../../../allcomponents';
import { useNavigate } from 'react-router-dom';
import axios from "axios";
import { API_URL, IMAGE_URL } from '../../../../Store/Global';
import { Image, Select } from 'antd';
import { Box, Table, TableBody, TableCell, tableCellClasses, TableContainer, TableHead, TableRow, TableFooter, Pagination, styled, Button } from '@mui/material';
  
const { Option } = Select;

interface ImgContents {
    no : number
    name : any
    sort : string
}

interface OriImage {
    no : any
    original_image : string
}

interface DefectResultImage {
  no : any
  result_image : string
  sort : string
}

export default function BridgeDefectDetectorPage(props:any) {
    const projectType = localStorage.getItem('projectType');
    const curId = window.location.href.split('/')[6];
    let token : string | null = localStorage.getItem("token") 
    let projectId : string | null = localStorage.getItem("projectId")
    const [userid, setUserId] = useState<number>(-1);
    const [companyid, setCompanyId] = useState<number>(-1);

    useEffect(() => {
        let getIdCompany = async () => {
            if(token !== null){ 
                console.log("여기 들어옴?")
                console.log("프로젝트ID"+projectId)
                const response = await axios({
                method : "get",
                url : `${API_URL}/account/auth/check/${token}`,                
                }).then(async(res)=>{
                if(res.data.check === true){
                    setUserId(res.data.data.id)
                    setCompanyId(res.data.data.companyId)
                    // localStorage.set("projectId", id);
                    console.log(`아이디는 다음과 같음 : ${res.data.data.id} / 회사는 다음과 같음 : ${res.data.data.companyId}`)
                    return {email : res.data.data.email, name : res.data.data.name, phone : res.data.data.phone, avatar : res.data.data.avatar, role : res.data.data.role, id : res.data.data.id, companyId : res.data.data.companyId}
                }else{
                    console.log("토큰 만료")
                    localStorage.removeItem('token')
                    alert("토큰이 만료었습니다 다시 로그인 해주세요")
                    window.location.replace("/Main")
                }
                }).catch((err)=>{
                console.log(err)
                })
            }
        }
        getIdCompany()
    },[])

    let job_id = 0;
    let resultCouple :any;

    const ImgContents : ImgContents[] = []; 
    const OriImage : OriImage[] = [];  
    const DefectResultImage : DefectResultImage[] = [];

    const [ImgList, setImgList] = useState<any | undefined>(ImgContents)
    const [ImgListOri, setImgListOri] = useState<any[]>(OriImage);
    const [ImgListDefect, setImgListDefect] = useState<any[]>(DefectResultImage);
    const [data, setData] = useState<any[]>(OriImage);

    const [resultOri, setResultOri] = useState<boolean>(false)
    const [resultDefect, setResultDefect] = useState<boolean>(false)

    const [setting, setSetting]=useState<any>([])
    const [typeArr, setType]=useState([])

    const [BridgeType, setBridgeType] = useState<string>("")
    const [GirderCount, setGirderCount] = useState<number>(0)

    const [num, setNum] = useState<number>(0);

    const navigate = useNavigate();
    const [page, setPage] = React.useState(1);

    useEffect(() => {
        setData(ImgList.slice((page-1)*10, page*10));
      }, [page]);

    const handlePage = (event:any) => {
      const nowPageInt = parseInt(event.target.outerText);
      console.log(event.target)
      
      if(event.target.outerText) {
        setPage(nowPageInt);
      }
      else {
        if(event.target.ariaLabel.includes("prev")) {
          setPage(page-1);
        }
        if(event.target.ariaLabel.includes("next")) {
          setPage(page+1);
        }
  
      }
      
    }

    const [resultImageList, setResultImageList] = useState<boolean>(false)
    const [resultTableList, setResultTableList] = useState<boolean>(false)

    const [isModalVisible, setIsModalVisible] = useState(false);
    const [choiceImg, setChoiceImg] = useState<string>("")
    const [choiceIndex, setChoiceIndex] = useState<number>()

    const { onSelectAllClick, order, orderBy, numSelected, rowCount, onRequestSort } = props;

    const [value, setValue] = React.useState(0);

    const handleChange = (event: React.SyntheticEvent, newValue: number) => {
        setValue(newValue);
    };

    
    useEffect(()=>{

      localStorage.setItem('bridge_type', BridgeType)

      setResultOri(false);
      setResultDefect(false);

      setImgList([])
      setImgListOri([])
      setImgListDefect([])

      //교량 구조물 종류 받아오기
      axios({
          method: 'get',
          url: API_URL + `/project/view/${projectId}`,
          headers : {"accept" : `application/json`, "access-token" : `${token}`}, 
      }).then((res) => {
          const settings: any = JSON.parse(res.data.data.settings)
              setSetting(settings)
              setType(settings.bridge_type)
              setGirderCount(settings.girder_count)

              if(BridgeType === "Girder" || BridgeType === "Slab" || BridgeType === "GirderSide"){   

                  for(let i = 0; i < settings.span_count; i++){
                      for(let k = 0; k < settings.span_length/settings.span_class_length; k++){

                      axios({
                          method: 'get',
                          url: API_URL+'/File/Files',
                          headers: { "accept": `application/json`, "access-token": `${token}` },
                          params: {
                              path: `/project/${projectId}/stage4/${BridgeType}/S${settings.span_number_list[i].num.length === 2 ? settings.span_number_list[i].num+"0"+(k+1) : "0"+settings.span_number_list[i].num+"0"+(k+1)}`
                          }
                          }).then((res)=>{
                              for(let y = 0; y < res.data.data.files.length; y++){
                                  const indexFront = res.data.data.files[y].indexOf("S") 
                                  const indexBack = res.data.data.files[y].indexOf(".png") 
                                  const SpanNum = res.data.data.files[y].substring(indexFront, indexBack)

                                  OriImage.push({
                                      original_image : `${IMAGE_URL}/image?path=/project/${projectId}/stage4/${BridgeType}/S${settings.span_number_list[i].num.length === 2 ? settings.span_number_list[i].num+"0"+(k+1) : "0"+settings.span_number_list[i].num+"0"+(k+1)}/${res.data.data.files[y]}&width=1440`,
                                      no : OriImage.length
                                  })
              
                                  ImgContents.push({
                                      no : ImgContents.length,
                                      name : <a style={{color:"black"}}>{res.data.data.files[y]}</a>,
                                      sort : SpanNum
                                  })                                     
                              } 

                              OriImage.sort((obj1, obj2) => {
                                      if (obj1.original_image > obj2.original_image) {
                                          return 1;
                                      }
                                      if (obj1.original_image < obj2.original_image) {
                                          return -1;
                                      }
                                      return 0;
                              })
                                  
                              ImgContents.sort((obj1, obj2) => {
                                      if (obj1.sort > obj2.sort) {
                                          return 1;
                                      }
                                      if (obj1.sort < obj2.sort) {
                                          return -1;
                                      }
                                      return 0;
                              })

                              let copyArrOriImage = [...OriImage];
                              let copyArrImgContents = [...ImgContents];    

                              console.log(OriImage)
                              if(BridgeType === "Girder"){
                                  if(OriImage.length === settings.girder_count * settings.span_length/settings.span_class_length * settings.span_count * settings.girder_camera_count){
                                      setResultOri(true);
  
                                      for(let j = 0; j < OriImage.length; j++){
                                          // console.log(j+1)
                                          copyArrOriImage[j] = { ...copyArrOriImage[j], no : j+1};
                                          copyArrImgContents[j] = { ...copyArrImgContents[j], no : j+1};
                                      }
                                  } 
                              }else if(BridgeType === "Slab"){
                                  if(OriImage.length > 0){
                                      setResultOri(true);
  
                                      for(let j = 0; j < OriImage.length; j++){
                                          // console.log(j+1)
                                          copyArrOriImage[j] = { ...copyArrOriImage[j], no : j+1};
                                          copyArrImgContents[j] = { ...copyArrImgContents[j], no : j+1};
                                      }
                                  }
                              }else if(BridgeType === "GirderSide"){
                                  if(OriImage.length === settings.span_count * settings.span_length/settings.span_class_length){
                                      setResultOri(true);

                                      for(let j = 0; j < OriImage.length; j++){
                                          // console.log(j+1)
                                          copyArrOriImage[j] = { ...copyArrOriImage[j], no : j+1};
                                          copyArrImgContents[j] = { ...copyArrImgContents[j], no : j+1};
                                      }
                                  }
                              }

                              setImgListOri(copyArrOriImage);
                              setData(copyArrImgContents.slice((page-1)*10, page*10));
                              setImgList(copyArrImgContents); 
                      })
                      }
                  }

                  //결함 검출 결과
                  axios({
                      method: 'get',
                      url: API_URL+'/File/Files',
                      headers: { "accept": `application/json`, "access-token": `${token}` },
                      params: {
                          path: `/project/${projectId}/stage6_defect/${BridgeType}/S0101`
                      }
                  }).then((res)=>{
                      for(let y = 0; y < res.data.data.files.length; y++){
                          if(res.data.data.files[y].includes(String(".png")) || res.data.data.files[y].includes(String(".jpg"))){
                              const indexFront = BridgeType === "Girder" ?  res.data.data.files[y].indexOf("S") : res.data.data.files[y].length
                              const indexBack = res.data.data.files[y].indexOf(".png") 
                              const SpanNum = BridgeType === "Girder" ? res.data.data.files[y].substring(indexFront, indexBack-7) : res.data.data.files[y].substring(indexFront-11, indexBack-12)
                            console.log(`${IMAGE_URL}/image?path=/project/${projectId}/stage6_defect/${BridgeType}/S0101/${res.data.data.files[y]}&width=1440`)
                              DefectResultImage.push({
                                  result_image : `${IMAGE_URL}/image?path=/project/${projectId}/stage6_defect/${BridgeType}/S0101/${res.data.data.files[y]}&width=1440`,
                                  no : DefectResultImage.length,
                                  sort : SpanNum
                              })

                              DefectResultImage.sort((obj1, obj2) => {
                                  if (obj1.sort > obj2.sort) {
                                      return 1;
                                  }
                                  if (obj1.sort < obj2.sort) {
                                      return -1;
                                  }
                                      return 0;
                                  })
                          }
                      }

                      console.log(DefectResultImage)
                      let copyArrResultImage = [...DefectResultImage]; 

                      if(BridgeType === "Girder"){
                          if(DefectResultImage.length === settings.girder_count * settings.span_length/settings.span_class_length * settings.span_count * settings.girder_camera_count){
                              setResultDefect(true);
  
                              for(let j = 0; j < DefectResultImage.length; j++){
                                  copyArrResultImage[j] = { ...copyArrResultImage[j], no : j+1};
                              }
                          }else{
                              alert("모든 결과가 나올 때까지 기다려주세요.")
                          }
                      }else if(BridgeType === "Slab"){
                          if(DefectResultImage.length > 0){
                              setResultDefect(true);
  
                              for(let j = 0; j < DefectResultImage.length; j++){
                                  copyArrResultImage[j] = { ...copyArrResultImage[j], no : j+1};
                              }
                          }else{
                            //   alert("모든 결과가 나올 때까지 기다려주세요.")
                          }
                      }else if(BridgeType === "GirderSide"){
                          if(DefectResultImage.length === settings.span_count * settings.span_length/settings.span_class_length){
                              setResultDefect(true);

                              for(let j = 0; j> DefectResultImage.length; j++){
                                  copyArrResultImage[j] = { ...copyArrResultImage[j], no : j+1};
                              }
                          }else{
                              alert("모든 결과가 나올 때까지 기다려주세요.")
                          }
                      }
                      setImgListDefect(copyArrResultImage)
                  })

              }else if(BridgeType === "Pier"){
                  //교각 파노라마 원본 이미지
                  console.log(settings.pier_count)
                  for(let i = 0; i < settings.pier_count; i++){
                      axios({
                          method: 'get',
                          url: API_URL+'/File/Files',
                          headers: { "accept": `application/json`, "access-token": `${token}` },
                          params: {
                              path: `/project/${projectId}/stage4/${BridgeType}/P${settings.pier_number_list[i]?.num.length === 2 ? settings.pier_number_list[i]?.num : "0"+settings.pier_number_list[i]?.num}`
                          }
                      }).then((res)=> {
                        console.log()
                              for(let j = 0; j < res.data.data.files.length; j++){
                                  const indexFront = res.data.data.files[j].indexOf("S") 
                                  const indexBack = res.data.data.files[j].indexOf(".png") 
                                  const SpanNum = res.data.data.files[j].substring(indexFront-4, indexBack)

                                  console.log(SpanNum)
                                  OriImage.push({
                                      original_image : `${IMAGE_URL}/image?path=/project/${projectId}/stage4/${BridgeType}/P${settings.pier_number_list[i].num.length === 2 ? settings.pier_number_list[i].num : "0"+settings.pier_number_list[i].num}/${res.data.data.files[j]}&width=1440`,
                                      no : OriImage.length
                                  })
                                  console.log()
  
                                  ImgContents.push({
                                      no : ImgContents.length + 1,
                                      name : <a style={{color:"black"}}>{res.data.data.files[j]}</a>,
                                      sort : SpanNum
                                  })
                              }
                              console.log(OriImage[0]?.original_image.split('/')[9] )
                              OriImage.sort((obj1, obj2) => {
                                  if (obj1.original_image.split('/')[9] > obj2.original_image.split('/')[9]) {
                                      return 1;
                                  }
                                  if (obj1.original_image.split('/')[9] < obj2.original_image.split('/')[9]) {
                                      return -1;
                                  }
                                  return 0;
                              })
                              console.log(OriImage)
                              ImgContents.sort((obj1, obj2) => {
                                  if (obj1.sort > obj2.sort) {
                                      return 1;
                                  }
                                  if (obj1.sort < obj2.sort) {
                                      return -1;
                                  }
                                  return 0;
                              })
                              let copyArrOriImage = [...OriImage];
                              let copyArrImgContents = [...ImgContents];    
                        
                              console.log(OriImage)
                              if(OriImage.length > 0){
                                  setResultOri(true);

                                  for(let j = 0; j < OriImage.length; j++){
                                      // console.log(j+1)
                                      copyArrOriImage[j] = { ...copyArrOriImage[j], no : j+1};
                                      copyArrImgContents[j] = { ...copyArrImgContents[j], no : j+1};
                                  }
                              }
                              setImgListOri(copyArrOriImage);
                              setData(copyArrImgContents.slice((page-1)*10, page*10));
                              setImgList(copyArrImgContents); 
                          })

                          // setNewDefectMeasureList(DefectMeasure);
                          // setNewCrackMeasureList(CrackMeasure);
                  }

                  //교각 결함 검출 이미지
                  axios({
                          method: 'get',
                          url: API_URL+'/File/Files',
                          headers: { "accept": `application/json`, "access-token": `${token}` },
                          params: {
                              path: `/project/${projectId}/stage6_defect/${BridgeType}/P09`
                          }
                  }).then((res)=> {
                    console.log(res)
                              for(let i = 0; i < res.data.data.files.length; i++){
                                  if(res.data.data.files[i].split(".")[1] === "png" || res.data.data.files[i].split(".")[1] === "jpg"){

                                      DefectResultImage.push({
                                      result_image : `${IMAGE_URL}/image?path=/project/${projectId}/stage6_defect/${BridgeType}/P09/${res.data.data.files[i]}&width=1440`,
                                      no : DefectResultImage.length,
                                      sort : ""
                                  })

                                  if(DefectResultImage.length > 0){
                                      setResultDefect(true);
                                  }
                                  }
                              }
                            //   setResultDefect(true);
                              setImgListDefect(DefectResultImage)
                  })

              }
      }).catch((err) => {
          console.log(err);
      });     
      
  }, [BridgeType])

    const option_render=()=>{
      const arr: any[] = [];
      typeArr.map((type:any)=>{
          let name=''
          if (type === 'Girder') {
              name = '거더 하면'
          } else if(type==='GirderSide'){
                  name='거더 측면'
          }else if(type==='Slab'){
              name='슬라브 하면'
          } else if (type === 'Slab_Side') {
              name = '슬라브 측면'
          } else if (type === 'Pier') {
              name ='교각'
          } else if (type === 'Abutment') {
              name = '교대'
          }
              arr.push(<Option value={type}> {name}</Option>)
          })
        return arr;
      }

      const onChangeType = (e:any) => {
        setIsSelect(true)
        if (e === 'Girder') {
            setBridgeType("Girder")
        } else if (e === 'GirderSide'){
            setBridgeType('GirderSide')
        } else if (e === 'Slab') {
            setBridgeType("Slab")
        } else if (e === 'Slab_Side') {
            setBridgeType("Slab_Side")
        } else if (e === 'Pier') {
            setBridgeType("Pier")
        } else if (e ==='Abutment'){
            setBridgeType("Abutment")
        }
      }

      const downloadCouple = () => {

        alert("30초 정도 기다리시면 다운로드 됩니다.")

        const indexFrontOri = ImgListOri[num].original_image.indexOf("/stage")
        const indexBackOri= ImgListOri[num].original_image.indexOf("&width")   
        const ImageUrlCutWidthOri = ImgListOri[num].original_image.substring(indexFrontOri+1, indexBackOri)

        // console.log("원본 경로 : ", ImageUrlCutWidthOri)

        const indexFrontDefectName = ImgListDefect[num].result_image.indexOf("/stage")
        const indexBackDefectName= ImgListDefect[num].result_image.indexOf("&width")   
        const ImageUrlCutWidthDefectName = ImgListDefect[num].result_image.substring(BridgeType === "Girder" ? indexFrontDefectName+22
                                                                                                            : BridgeType === "Pier" ? indexFrontDefectName+20
                                                                                                            : BridgeType === "Slab" ? indexFrontDefectName+20
                                                                                                            : indexFrontDefectName+26, indexBackDefectName)

        // console.log("결함 커플 이미지 이름 : ", ImageUrlCutWidthDefectName)

        const indexFrontDefect = ImgListDefect[num].result_image.indexOf("/stage")
        const indexBackDefect= ImgListDefect[num].result_image.indexOf("&width")   
        const ImageUrlCutWidthDefect = ImgListDefect[num].result_image.substring(indexFrontDefect+1, indexBackDefect)

        // console.log("결함 경로 : ", ImageUrlCutWidthDefect)

        axios({
            method: 'post',
            url: API_URL+'/Job/Start',
            headers: { "accept": `application/json`, "access-token": `${token}` },  
            data: {
                projectId: projectId,
                task_name: "image_join",
                interactive: false,
                tasks: [{
                    image1_path: ImageUrlCutWidthOri,
                    image2_path: ImageUrlCutWidthDefect,
                    align: 0, //수평 //수직으로 하려면 0
                    output_folder: 'stage6_Couple',
                    output_name: ImageUrlCutWidthDefectName
                }]
                }
        }).then((res) => {
            console.log(res.data.check)
            if (res.data.check === true) {
                job_id = res.data.data.job_id
                        /////20초마다 alert로 알려줌////////////
                resultCouple = setInterval(confirm, 10000)
            } else {
                console.log("실패")
            }
        })
    }

    async function confirm(){        
      axios({
          method: 'post',
          url: API_URL + '/Job/Query',
          headers: { "accept": `application/json`, "access-token": `${token}` },
          data: { job_id: job_id}
          }).then(async (res) => {
              console.log(res)
              if (res.data.check === true) {
                  if (res.data.data.status === "done") {
                      console.log("성공")
                      setTimeout(()=>
                          download()
                      ,10000)                    
                      clearInterval(resultCouple)
                  } else if (res.data.data.status === "progress") {
                  } else if(res.data.data.status === "error"){
                      alert("해당 파일이 없습니다.")
                  }
              } else {
                  console.log("실패")
              }
          })
      
  }

  async function download(){
      console.log("지금! 다운로드 해!")

      const indexFrontDefectName = ImgListDefect[num].result_image.indexOf("/stage")
      const indexBackDefectName= ImgListDefect[num].result_image.indexOf("&width")   
      const ImageUrlCutWidthDefectName = ImgListDefect[num].result_image.substring(BridgeType === "Girder" ? indexFrontDefectName+22
                                                                                                          : BridgeType === "Pier" ? indexFrontDefectName+20
                                                                                                          : BridgeType === "Slab" ? indexFrontDefectName+20
                                                                                                          : indexFrontDefectName+26, indexBackDefectName)

      // console.log("결함 커플 이미지 이름 : ", ImageUrlCutWidthDefectName)

      const linkDefect = document.createElement('a');
      let srcDefect = `${IMAGE_URL}/image?path=/project/${projectId}/stage6_Couple/${ImageUrlCutWidthDefectName}`;
      const imageBlobDefect = await (await fetch(srcDefect)).blob();
      srcDefect = URL.createObjectURL(imageBlobDefect);
      linkDefect.href = srcDefect;
      linkDefect.download = ImageUrlCutWidthDefectName
      linkDefect.click();
  }

  // const openWindowDefect = () => {
  //     window.open('../../ViewerDefect', "결함 결과 수정", "width=1650px, height=1200px, left=300, top=150");
  // }
  
      const rendering = (i:any) => {
          const result = [];
          result.push(
            BridgeType !== "Pier"? 
            <div>
                <div style={{marginTop : '1.25rem', marginRight: '3rem', flexShrink: 0}}><Image src={resultOri === true ? ImgListOri[num]?.original_image : ""} className={'imagetask'} style={{width: '60rem', height: '15rem', cursor: 'pointer', borderRadius: '0.625rem'}}/></div>
                <div style={{marginTop : '1.25rem', marginRight: '3rem', flexShrink: 0}}><Image src={resultDefect === true ? ImgListDefect[num]?.result_image : ""} className={'imagetask'} style={{width: '60rem', height: '15rem', cursor: 'pointer', borderRadius: '0.625rem'}}/></div>
            </div>
            :
            <div style={{display:'flex'}}>
                <div style={{marginTop : '1.25rem', marginRight: '3.5rem', flexShrink: 0}}><Image src={resultOri === true ? ImgListOri[num]?.original_image : ""} className={'imagetask'} style={{width: '20rem', height: '35rem', cursor: 'pointer', borderRadius: '0.625rem'}}/></div>
                <div style={{marginTop : '1.25rem', marginRight: '3.5rem', flexShrink: 0}}><Image src={resultDefect === true ? ImgListDefect[num]?.result_image : ""} className={'imagetask'} style={{width: '20rem', height: '35rem', cursor: 'pointer', borderRadius: '0.625rem'}}/></div>
            </div>
        );
          return result;
      };

      const onClickPanor = (i:any) => {
        setNum(i);
    };


    const showModal = (e:any) => {
        setIsModalVisible(true);
        setChoiceIndex(e.target.id);
        setChoiceImg(e.target.src);
    };

    const StyledTableCell = styled(TableCell)(({ theme }) => ({
        [`&.${tableCellClasses.head}`]: {
          fontSize: '1rem',
          fontWeight: 600,
          backgroundColor: '#F5F5F5',
          color: '#3A4557',
          width: '42.8125rem',
          height: '2.5rem',
          border: '1px solid #DDD',
          borderTop: '0.15rem solid #3A4557',
          padding : 0
        },
        [`&.${tableCellClasses.body}`]: {
          color: '#3A4557',
          textAlign: 'center',
          fontSize: '0.8125rem',
          width: '42.8125rem',
          height: '3rem',
          border: '1px solid #DDD',
          padding : 0,
        },
      }));
      
    const StyledTableRow = styled(TableRow)(({ theme }) => ({
        // '&:nth-of-type(odd)': {
        //   backgroundColor: theme.palette.action.hover,
        // },
        // hide last border
        '&:last-child td': {
        },

      }));

      const [isSelect, setIsSelect] = useState<boolean>(false)

      const  modelSelect :any[]= [];
      const selectModel = () => {
          modelSelect.push(
              <Select placeholder="선택해주세요" style={{ width : '8rem'}} onChange={onChangeType}>
                  {option_render()}    
              </Select>
          ) 
  
          return modelSelect;
      }

    
    return (
        <Layout>
            <div style={{ paddingTop : '2.01rem', paddingLeft : '2rem', color: '#999', fontSize: '0.75rem', fontFamily: 'inter-pretendard', fontStyle: 'normal', fontWeight: 400, lineHeight: '100%'}}>검출 & 측정 &gt; 결함 검출</div>
            <div style={{ paddingTop : '0.3rem', paddingLeft : '2rem', color: '#3A4557', fontSize: '1.5rem', fontFamily: 'inter-pretendard', fontStyle: 'normal', fontWeight: 700, lineHeight: '100%'}}>결함 검출</div>
            <div style={{ display : 'flex', marginLeft : '2rem'}}>
                <div style={{display:'flex'}}>
                    <div>
                      <div style={{ marginTop: '1rem'}}>
                          <Box sx={{
                              display: 'block',
                              width: '8rem',
                              height: '2rem',
                              borderRadius: '0.5rem',
                              background: '#FFF',
                              boxShadow: '2px 2px 20px 0px rgba(58, 69, 87, 0.05)',
                              padding : '1rem'
                          }}>
                              {selectModel()}
                          </Box>
                      </div>
                      <div>
                        {
                            resultDefect === true && ImgList.length !== 0 ?
                            <div style={{ display:'flex'}}>
                                <div> 
                                    <Box sx={{ width: '25rem', backgroundColor: 'white', borderRadius: '0.5rem', boxShadow: '2px 2px 20px 0px rgba(58, 69, 87, 0.05)', padding : '2rem', marginTop: '1rem'}}>
                                        <div style={{ display: 'flex', alignItems : 'center', justifyContent : 'space-between'}}>
                                            <div style={{ fontWeight : 700, borderLeft: '0.1875rem solid #0F6FB7', fontSize:'1rem', height:'1.2rem', paddingLeft: '0.62rem'}}>이미지 파일 리스트 </div>
                                        </div>
                                        <div>
                                            <TableContainer  sx={{ width: '23rem', marginLeft: '1rem', marginTop: '1rem', marginBottom: '1rem' }}>
                                                <Table sx={{ backgroundColor: '#fffff' }} aria-label="customized table">
                                                    <TableHead>
                                                    
                                                    <TableRow >
                                                        <StyledTableCell align="center" style={{width:'2rem', fontFamily:'inter-pretendard'}}>번호</StyledTableCell>
                                                        <StyledTableCell align="center" style={{width:'5rem', fontFamily:'inter-pretendard'}}>이름</StyledTableCell>
                                                    </TableRow>
                                                    </TableHead>
                                                    
                                                    <TableBody>
                                                    {data.length !== 0 ? data.map((row:any, idx:any) => (
                                                        <StyledTableRow 
                                                            key={row.original_image}
                                                            style={{ height: '2.5rem', cursor:'pointer'}}
                                                            onClick={() => onClickPanor(idx)}
                                                        >
                                                        {/* <StyledTableCell component="th" scope="row" align="center">
                                                            {row.num}
                                                        </StyledTableCell> */}
                                                        <StyledTableCell align="center" style={{width:'2em', height: '2.5rem', fontFamily:'inter-pretendard'}}>{(page-1)*10+(idx+1)}</StyledTableCell>
                                                        {/* <StyledTableCell align="center" style={{width:'5rem', height: '2.5rem', fontFamily:'inter-pretendard'}}>{data[idx]?.original_image.split('stage4/Overflow/')[1].split('&width')[0]}</StyledTableCell> */}
                                                        <StyledTableCell align="center" style={{width:'5rem', height: '2.5rem', fontFamily:'inter-pretendard'}}>{row.name}</StyledTableCell>
                                                        </StyledTableRow> 
                                                    )):'안됨'}
                                                    </TableBody>
                                                </Table>
                                                <TableFooter>
                                            <TableRow style={{ width: '23rem', display : 'flex', justifyContent: 'center', marginTop:'4rem'}}>
                                            <Pagination count={ImgList.length > 10 && ImgList.length % 10 === 0 ? Math.floor(ImgList.length/10) : Math.floor(ImgList.length/10)+1} defaultPage={1} onChange={(e) => handlePage(e)} color="primary" 
                                                sx={{
                                                '& svg': {
                                                    pointerEvents: 'none'
                                                }
                                                }}
                                            />
                                            </TableRow>
                                            </TableFooter>
                                            </TableContainer>
                                            
                                        </div>
                                    </Box> 
                                </div>
                                <div>
                                  <Box sx={BridgeType !== "Pier" ?{ width: '66rem', marginTop: '1rem', flexShrink: 0, borderRadius: '0.5rem', background: '#FFF', boxShadow: '2px 2px 20px 0px rgba(58, 69, 87, 0.05)', marginLeft : '2rem', marginBottom: '1rem'}:{ width: '49.5rem', marginTop: '1rem', flexShrink: 0, borderRadius: '0.5rem', background: '#FFF', boxShadow: '2px 2px 20px 0px rgba(58, 69, 87, 0.05)', marginLeft : '2rem', marginBottom: '1rem'}}>
                                      <div style={{padding : '3rem'}}>
                                      <div>
                                          <div style={{ display: 'flex', justifyContent : 'space-between'}}>
                                              <div style={{ fontWeight : 700, borderLeft: '0.1875rem solid #0F6FB7', fontSize:'1rem', height:'1.2rem', paddingLeft: '0.62rem', fontFamily:'inter-pretendard'}}>{ImgList[num]?.name}</div>
                                              <div>
                                                  <Button variant="outlined" sx={{  fontWeight : 600, fontFamily: 'inter-pretendard' }} onClick={downloadCouple}>커플 이미지 다운로드</Button>
                                                  {/* <Button variant="contained" sx={{ fontWeight : 600 }} onClick={openWindow}>균열 결과 수정</Button> */}
                                              </div>
                                          </div>
                                          <div>
                                              {rendering(num)}
                                          </div>
                                      </div>
                                      </div>
                                  </Box>
                              </div>
                            </div>
                            : isSelect ?
                            <Box sx={{
                                width: '11.5rem',
                                height: '1.5rem',
                                borderRadius: '0.5rem',
                                background: '#FFF',
                                boxShadow: '2px 2px 20px 0px rgba(58, 69, 87, 0.05)',
                                padding : '0.75rem', 
                                marginTop : '1rem'
                                
                            }}>
                                <div>결함 검출을 진행해주세요.</div>
                            </Box>
                            : <Box sx={{
                                width: '11.5rem',
                                height: '1.5rem',
                                borderRadius: '0.5rem',
                                background: '#FFF',
                                boxShadow: '2px 2px 20px 0px rgba(58, 69, 87, 0.05)',
                                padding : '0.75rem',
                                marginTop : '1rem'
                            }}>
                                <div>구역을 먼저 선택해주세요.</div>
                            </Box>
                        
                        }
                        </div>
                        
                    </div>
                    
                </div>
            
            </div>
        
        </Layout>
    );
};


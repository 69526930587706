import React, { useState, useEffect } from 'react';
import axios from "axios";
import { Layout } from '../../../../allcomponents';
import { useNavigate } from 'react-router-dom';
import { API_URL, IMAGE_URL } from '../../../../Store/Global';
import { Modal, Slider} from 'antd';
import { Box, Button, Table, TableBody, TableCell, tableCellClasses, TableContainer, TableHead, TableRow, TableFooter, Pagination, styled, Switch, Stack, Typography} from '@mui/material';

interface Image {
    num : Number;
    folderName : string;
    imageURL : Array<string>;
    lean: string;
}

interface Lean {
    input_folder : string;
    lean_mean : number;
}

interface Copy {
    input_folder : string;
    output_folder : string;
}

const CosSliderUp = styled(Slider)`
    
    .ant-slider-tooltip .ant-tooltip-inner {
        none
    }

    .ant-slider-handle {
        position: absolute;
        width: 0.5px;
        height: 720px;
        margin-top: -755px;       
        border-left: solid 0.5px #230;
        opacity: 1;
        border-radius: 10%;
        cursor: pointer;
    }
    `;

    const CosSliderDown = styled(Slider)`
    
    .ant-slider-tooltip .ant-tooltip-inner {
        none
    }

    .ant-slider-handle {
        position: absolute;
        width: 0.5px;
        height: 720px;
        margin-top: -810px;       
        border-left: solid 0.5px #230;
        opacity: 1;
        border-radius: 10%;
        cursor: pointer;
    }
    `;

  const AntSwitch = styled(Switch)(({ theme }) => ({
    width: '3rem',
    height: '1.5rem',
    padding: 0,
    display: 'flex',
    '&:active': {
      '& .MuiSwitch-thumb': {
        width: '1.5rem',
      },
      '& .MuiSwitch-switchBase.Mui-checked': {
        transform: 'translateX(1.5rem)',
      },
    },
    '& .MuiSwitch-switchBase': {
      padding: 4,
      '&.Mui-checked': {
        transform: 'translateX(1.5rem)',
        color: '#fff',
        '& + .MuiSwitch-track': {
          opacity: 1,
          backgroundColor: theme.palette.mode === 'dark' ? '#dddddd' : '#0F6FB7',
        },
      },
    },
    '& .MuiSwitch-thumb': {
      boxShadow: '0 2px 4px 0 rgb(0 35 11 / 20%)',
      width: '1rem',
      height: '1rem',
      borderRadius: '50%',
      transition: theme.transitions.create(['width'], {
        duration: 200,
      }),
    },
    '& .MuiSwitch-track': {
      borderRadius: 22 / 2,
      opacity: 1,
      backgroundColor:
        theme.palette.mode === 'dark' ? 'rgba(255,255,255,.35)' : 'rgba(0,0,0,.25)',
      boxSizing: 'border-box',
    },
  }));


export default function TunnelPreProcessImageTaskPage(props:any) {
    const projectType = localStorage.getItem('projectType');
    const curId = window.location.href.split('/')[6];
    let token : string | null = localStorage.getItem("token") 
    let projectId : string | null = localStorage.getItem("projectId")

    const [userid, setUserId] = useState<number>(-1);
    const [companyId, setcompanyId] = useState<any>(localStorage.getItem("companyId"));

    // 프로젝트 영문이름 + 상행,하행 (폴더, 이미지 만들 때 상관있음)
    const [ProjectNameEn, setProjectNameEn] = useState<string>("")

    const navigate = useNavigate();

    let Newarr:any[] = [];
    const images : Image [] = [];
    const lean : Lean [] = [];
    const folder_copy : Copy [] = [];

    folder_copy.push({
      input_folder: "stage1",
      output_folder: "stage1_2"
  },
  {
      input_folder: "stage1",
      output_folder: `stage2/${ProjectNameEn}`
  })

  const [arr, setArr] = useState<any>([])
  const [imgList, setImgList] = useState<any[]>(images);
  const [finalLean, setFinalLean] = useState<any[]>(lean);

    const [resultImageList, setResultImageList] = useState<boolean>(false)
    const [resultTableList, setResultTableList] = useState<boolean>(false)

    const [isModalVisible, setIsModalVisible] = useState(false);

    const [value1, setvalue1] = useState<any>(0)
    const [value2, setvalue2] = useState<any>(0)
    const [value3, setvalue3] = useState<number>(0)


    const [choiceImg, setChoiceImg] = useState<string>("")
    const [choiceIndex, setChoiceIndex] = useState<number>()

    const [page, setPage] = React.useState(1);
    const [data, setData] = useState<any[]>([]);

    useEffect(() => {
      setData(imgList.slice((page-1)*10, page*10));
    }, [page]);

    const handlePage = (event:any) => {
      const nowPageInt = parseInt(event.target.outerText);
      console.log(event.target)
      
      if(event.target.outerText) {
        setPage(nowPageInt);
      }
      else {
        if(event.target.ariaLabel.includes("prev")) {
          setPage(page-1);
        }
        if(event.target.ariaLabel.includes("next")) {
          setPage(page+1);
        }
  
      }
      
    }

    const rendering = (i:number) => {
        const resultImage:any[] = [];

        imgList[i].imageURL.map((imageURL:any)=>{      
            resultImage.push((<img src={imageURL} id={String(i)} key={imageURL} alt={imageURL} className={'imagetask'} onClick={showModal} style={{width: '6.625rem', height: '11.75rem', marginTop : '1.25rem', marginRight: '1rem', flexShrink: 0, cursor: 'pointer'}}/>))
        })
            
        if(resultImage.length < 1){
            setResultImageList(false);
        }

        return resultImage;
    }

    const showModal = (e:any) => {
        setIsModalVisible(true);
        setChoiceIndex(e.target.id);
        setChoiceImg(e.target.src);
    };

    /////////////////30초마다 status알려주는 alert//////////////////////////////////////////
    let job_id = 0;
    let result :any;
    const confirm = () => {
        console.log(job_id)
        axios({
            method: 'post',
            url: API_URL + '/scheduler/job/query',
            headers : {"accept" : `application/json`, "access-token" : `${token}`, "Content-Type" : `application/json`},
            data: { job_id: job_id}
            }).then((res) => {
                console.log(res)
                if (res.data.check == true) {
                    console.log("성공")
                    if (res.data.data.status === "done") {
                        alert("lean_mean 작업이 끝났습니다.")
                        // setTask([])
                        clearInterval(result)
                        window.location.href='../Preprocess/Jtag'
                    } else if (res.data.data.status === "progress") {
                        // alert("이미지 추출중입니다.")
                    } else if(res.data.data.status === "error"){
                        alert("해당 파일이 없습니다.")
                    }
                } else {
                    console.log("실패")
                }
            })
        
    }
    /////////////////////////////////////////////////////////////////////////////////////////////////

    let copyArrImgList = [...imgList];
    let copyArrleanList = [...finalLean];

    const handleOk = () => {
        setIsModalVisible(false);
        setvalue3(value1 - value2)
        
        arr[Number(choiceIndex)].push(Number(value3));

        console.log(arr);

        let sumlean : number = 0;

        for ( let i = 0; i < arr[Number(choiceIndex)].length; i++){
            sumlean += arr[Number(choiceIndex)][i]
        }
        
        //평균 값 계산 후 표에 값 추가
        // lean[Number(choiceIndex)].lean_mean = (sumlean / arr[Number(choiceIndex)].length);
        copyArrImgList[Number(choiceIndex)] = { ...copyArrImgList[Number(choiceIndex)], lean : String(sumlean / arr[Number(choiceIndex)].length)};
        copyArrleanList[Number(choiceIndex)] = { ...copyArrleanList[Number(choiceIndex)], lean_mean : sumlean / arr[Number(choiceIndex)].length * 4};

        console.log(copyArrleanList)
        setImgList(copyArrImgList);
        setData(copyArrImgList.slice((page-1)*10, page*10));
        setFinalLean(copyArrleanList);
    };

    const handleCancel = () => {
        setIsModalVisible(false);
    };

    const onChange1 = (value: number | any) => {
        setvalue1(value)
        setvalue3(value1 - value2)
    };

    const onChange2 = (value: number | any) => {
        setvalue2(value)
        setvalue3(value1 - value2)
    };

    const onClick = () => {
        console.log(finalLean);
        // alert("해당 이미지들을 작업에 반영합니다.")

        // console.log(filePrefix);
        // console.log(folder_copy);

        axios({
            method: 'post',
            url: API_URL +`/scheduler/job/start/${companyId}/${userid}`,
            headers: {
              "accept": `application/json`,
              "access-token": `${token}`,
              "Content-Type": `application/json`
            },
            data: {
                projectId: projectId,
                task_name: "copy_folder",
                interactive: false,
                tasks: folder_copy
                
            }
        }).then((res)=>{
            if (res.data.check === true) {
                console.log("복사 성공")

                axios({
                    method: 'post',
                    url: API_URL +`/scheduler/job/start/${companyId}/${userid}`,
                    headers: {
                      "accept": `application/json`,
                      "access-token": `${token}`,
                      "Content-Type": `application/json`
                    },
                              data: {
                        projectId: projectId,
                        task_name: "lean_mean",
                        interactive: false,
                        tasks: finalLean
                    }
                }).then((res)=>{
                    if (res.data.check === true) {
                        console.log("성공")
                        job_id = res.data.data.job_id
                        /////30초마다 alert로 알려줌////////////
                        result = setInterval(confirm, 30000)
                        alert("lean_mean 작업")
                    } else {
                        console.log("실패")
                    }
                }).catch((err) => {
                    console.log(err);
                });
            } else {
                console.log("복사 실패")
            }
        }).catch((err) => {
            console.log(err);
        });        
    }

    // const TableRendering = () => {
    //     const resultTable:any[] = [];        
    //     resultTableList === true ? 
    //     resultTable.push(<Table dataSource={ImgList}>
    //             <Column title={t.cnNumber} dataIndex="num" key="num" />
    //             <Column title={t.cnName} dataIndex="folderName" key="num" />
    //             <Column title={t.cnAvgLean} dataIndex="lean" key="num" />
    //     </Table>) 
    //     : resultTable.push(<div>????</div>)   

    //     return resultTable;
    // }




      useEffect(() => {
        let getIdCompany = async () => {
          if (token !== null) {
            console.log("프로젝트ID" + projectId);
            const response = await axios({
              method: "get",
              url: `${API_URL}/account/auth/check/${token}`,
            })
              .then(async (res) => {
                if (res.data.check === true) {
                  setUserId(res.data.data.id)
                  setcompanyId(res.data.data.companyId)
                  // localStorage.set("projectId", id);
                  console.log(
                    `아이디는 다음과 같음 : ${res.data.data.id} / 회사는 다음과 같음 : ${res.data.data.companyId}`
                  );
                  return {
                    email: res.data.data.email,
                    name: res.data.data.name,
                    phone: res.data.data.phone,
                    avatar: res.data.data.avatar,
                    role: res.data.data.role,
                    id: res.data.data.id,
                    companyId: res.data.data.companyId,
                  };
                } else {
                  console.log("토큰 만료");
                  localStorage.removeItem("token");
                  alert("토큰이 만료었습니다 다시 로그인 해주세요");
                  window.location.replace("/Main");
                }
              })
              .catch((err) => {
                console.log(err);
              });
          }
        };
        getIdCompany();
      }, []);


    const [isBind, setIsBind] = useState(false);

    const DataBind = () => {
        if (!isBind) {
            const response = axios({
                method: 'get',
                url: API_URL + `/project/view/${projectId}`,
                headers : {"accept" : `application/json`, "access-token" : `${token}`}, 
            }).then((res) => {
                    const settings: any = JSON.parse(res.data.data.settings)
                    console.log(res)
                    if (res.data.check === true) {
                        console.log(res)
                        setProjectNameEn(settings.tunnel_eng + '_' + settings.direction)
                        // console.log(file_prefix)
    
                        // if(settings.cameraCount > 0 ){
                            for(let i = 0; i < settings.cameraCount; i++){
                            images.push({
                                num:i+1, 
                                // folderName:"C0"+ Number(i + 1), 
                                folderName: "C" + String(i+1).padStart(2,"0"), 
                                imageURL: [],
                                lean: ""
                            })
    
                            lean.push({
                                // input_folder : "stage1_2/C0"+ Number(i + 1), 
                                input_folder : "stage1_2/C"+ String(i+1).padStart(2, "0"), 
                                lean_mean : 0})
                                setResultTableList(true);
                        }
    
                        // }
    
                    setImgList(images);
                    setData(images.slice((page-1)*10, page*10));

  
    
                    for(let i = 0; i < settings.cameraCount; i++){
                        
                            Newarr[i] = [];
                            axios({
                                method: 'get',
                                url: API_URL+'/file/files',
                                headers: { "accept": `application/json`, "access-token": `${token}` },
                                params: {
                                    path: `/project/${projectId}/stage1/${imgList[i].folderName}`
                                }
                            }).then((res)=>{
                                if (res.data.check === true) {
                                    console.log("성공")
                                    console.log(res.data)
                
                                    for(let j=0; j<res.data.data.files.length; j++){
                                      imgList[i].imageURL.push(`${IMAGE_URL}/image?path=/project/${projectId}/stage1/${images[i].folderName}/${res.data.data.files[j]}&width=360`)
                                    }
    
                                    setIsBind(true);
                                    setResultImageList(true);            
                                } else {
                                    console.log("실패 : "+res.data.value)
                                    console.log(res)
                                }
                            }).catch((err) => {
                                console.log(err);
                            });
                        }
                        setArr(Newarr)
    
                    } else {
                        console.log("실패");
                    }
                }).catch((err) => {
                    console.log(err);
                });
      };
    }

    useEffect(() => {
        DataBind();
    }, [isBind])


      const StyledTableCell = styled(TableCell)(({ theme }) => ({
        [`&.${tableCellClasses.head}`]: {
          fontSize: '1rem',
          fontWeight: 600,
          backgroundColor: '#F5F5F5',
          color: '#3A4557',
          width: '42.8125rem',
          height: '2.5rem',
          border: '1px solid #DDD',
          borderTop: '0.15rem solid #3A4557',
          padding : 0
        },
        [`&.${tableCellClasses.body}`]: {
          color: '#3A4557',
          textAlign: 'center',
          fontSize: '0.8125rem',
          width: '42.8125rem',
          height: '3rem',
          border: '1px solid #DDD',
          padding : 0,
        },
      }));
      
      const StyledTableRow = styled(TableRow)(({ theme }) => ({
        // '&:nth-of-type(odd)': {
        //   backgroundColor: theme.palette.action.hover,
        // },
        // hide last border
        '&:last-child td': {
        },

      }));

      const onSave = () => {
        alert("해당 이미지들을 작업에 반영합니다.")
        // setTimeout(function() {
        //   alert("반영되었습니다.")
        //   navigate(`/project/preprocess/jtag/${projectId}`)
        // }, 2000);

        axios({
          method: 'post',
          url: API_URL +`/scheduler/job/start/${companyId}/${userid}`,
          headers: {
            "accept": `application/json`,
            "access-token": `${token}`,
            "Content-Type": `application/json`
          },
          data: {
              project_id: projectId,
              task_name: "copy_folder",
              interactive: false,
              tasks: folder_copy
              
          }
      }).then((res)=>{
          if (res.data.check === true) {
              console.log("복사 성공")

              axios({
                  method: 'post',
                  url: API_URL +`/scheduler/job/start/${companyId}/${userid}`,
                  headers: {
                    "accept": `application/json`,
                    "access-token": `${token}`,
                    "Content-Type": `application/json`
                  },
                            data: {
                      project_id: projectId,
                      task_name: "lean_mean",
                      interactive: false,
                      tasks: finalLean
                  }
              }).then((res)=>{
                  if (res.data.check === true) {
                      console.log("성공")
                      job_id = res.data.data.job_id
                      /////30초마다 alert로 알려줌////////////
                      result = setInterval(confirm, 30000)
                      // alert("lean_mean 작업")
                      alert("lean_mean 작업이 반영되었습니다.")
                      navigate(`/project/preprocess/jtag/${projectId}`)
                  } else {
                      console.log("실패")
                  }
              }).catch((err) => {
                  console.log(err);
              });
          } else {
              console.log("복사 실패")
          }
      }).catch((err) => {
          console.log(err);
      });        
      }
      

    return (
        <Layout>
            <div style={{ paddingTop : '2.01rem', paddingLeft : '2rem', color: '#999', fontSize: '0.75rem', fontFamily: 'inter-pretendard', fontStyle: 'normal', fontWeight: 400, lineHeight: '100%'}}>전처리 &gt; 이미지 작업</div>
            <div style={{ paddingTop : '0.3rem', paddingLeft : '2rem', color: '#3A4557', fontSize: '1.5rem', fontFamily: 'inter-pretendard', fontStyle: 'normal', fontWeight: 700, lineHeight: '100%'}}>이미지 작업</div>
            
            <div style={{ display : 'flex'}}>
                <div>
                
                <Box sx={{ width: '20rem', backgroundColor: 'white', borderRadius: '0.5rem', boxShadow: '2px 2px 20px 0px rgba(58, 69, 87, 0.05)', padding : '2rem', marginLeft : '2rem', marginTop: '1rem', paddingBottom:'2.5rem'}}>
                <div style={{ display: 'flex', alignItems : 'center', justifyContent : 'space-between'}}>
                        <div style={{ fontWeight : 700, marginBottom : '2.13rem', borderLeft: '0.1875rem solid #0F6FB7', fontSize:'1rem', height:'1.2rem', paddingLeft: '0.62rem'}}>이미지 파일 리스트 </div>
                        <div>
                            <Button variant="contained" size="small" sx={{ float : 'right', marginBottom : '0.5rem', marginRight:'1rem', fontFamily: 'inter-pretendard', fontSize: '0.875rem', background: '#0F6FB7' }} onClick={()=>onSave()}>확인</Button>
                        </div>
                    </div>
                    <div>
                        <TableContainer  sx={{ width: '18rem', marginLeft: '1rem', marginTop: '1rem' }}>
                            <Table sx={{ backgroundColor: '#fffff' }} aria-label="customized table">
                                <TableHead>
                                <TableRow >
                                    <StyledTableCell align="center" style={{width:'5rem', fontFamily: 'inter-pretendard'}}>번호</StyledTableCell>
                                    <StyledTableCell align="center" style={{width:'7.5rem', fontFamily: 'inter-pretendard'}}>이름</StyledTableCell>
                                    <StyledTableCell align="center" style={{width:'7.5rem', fontFamily: 'inter-pretendard'}}>평균 기울기</StyledTableCell>
                                </TableRow>
                                </TableHead>
                                
                                <TableBody>
                                {data.map((img, idx) => (
                                    <StyledTableRow 
                                        key={idx}
                                        style={{ height: '2.5rem'}}
                                    >
                                    {/* <StyledTableCell component="th" scope="row" align="center">
                                        {row.num}
                                    </StyledTableCell> */}
                                    <StyledTableCell align="center" style={{width:'5rem', height: '2.5rem', fontFamily: 'inter-pretendard'}}>{(page-1)*10+(idx+1)}</StyledTableCell>
                                    <StyledTableCell align="center" style={{width:'7.5rem', height: '2.5rem', fontFamily: 'inter-pretendard'}}>{(page-1)*10+(idx+1) >= 10 ? `C${(page-1)*10+(idx+1)}` : `C0${(page-1)*10+(idx+1)}`}</StyledTableCell>
                                    <StyledTableCell align="center" style={{width:'7.5rem', height: '2.5rem', fontFamily: 'inter-pretendard'}}>{img.lean? img.lean : 0}</StyledTableCell>
                                    </StyledTableRow> 
                                ))}
                                </TableBody>
                            </Table>
                            <TableFooter>
                                <TableRow style={{ width: '18rem', display : 'flex', justifyContent: 'center', marginTop:'4rem'}}>
                                <Pagination count={imgList.length > 10 && imgList.length % 10 === 0 ? Math.floor(imgList.length/10) : Math.floor(imgList.length/10)+1} defaultPage={1} onChange={(e) => handlePage(e)} color="primary" 
                                    sx={{
                                      '& svg': {
                                        pointerEvents: 'none'
                                      }
                                    }}
                                  />
                                </TableRow>
                                </TableFooter>
                        </TableContainer>
                    </div>
                </Box> 
                </div>
                <div style={{ marginTop: '1rem', height : '50rem', overflowY : 'scroll'}}>
                    {
                      resultImageList === true ? 
                        imgList.map((_, idx) => (
                            <Box sx={{ width: '73.6875rem', height: '20rem', flexShrink: 0, borderRadius: '0.5rem', background: '#FFF', boxShadow: '2px 2px 20px 0px rgba(58, 69, 87, 0.05)', marginLeft : '2rem', marginBottom: '1rem'}}>
                                <div style={{padding : '2rem'}}>
                                    {/* 네임라인 */}
                                    <div style={{ display: 'flex', justifyContent: 'space-between'}}>
                                            <div style={{ display : 'flex'}}>
                                                <div style={{ backgroundColor : '#0F6FB7', borderRadius : '50%', color : '#fff', width : '1.25rem', height : '1.25rem', textAlign : 'center', fontSize : '0.75rem', fontWeight:400, lineHeight:'175%'}}>{idx+1}</div>
                                                <div style={{ marginLeft : '0.5rem', fontWeight : 600}}>{idx+1 >= 10 ? `C${idx+1}` : `C0${idx+1}`}</div>
                                            </div>
                                            <div style={{ color : '#3A4557', fontSize : '1rem'}}> 
                                            
                                            <Stack direction="row" spacing={1} alignItems="center">
                                                <Typography style={{ fontWeight : 600, fontFamily: 'inter-pretendard'}}>상하반전</Typography>
                                                <AntSwitch defaultChecked inputProps={{ 'aria-label': 'ant design' }} />
                                                <Typography style={{ marginLeft : '1rem', fontWeight : 600, fontFamily: 'inter-pretendard'}}>좌우반전</Typography>
                                                <AntSwitch defaultChecked inputProps={{ 'aria-label': 'ant design' }} />
                                            </Stack>
                                            </div>
                                    </div>
                                    {/* 이미지라인 */}
                                    <div style={{ width : '69.65rem', height : '15rem', marginTop : '0.25rem', whiteSpace : 'nowrap', overflow: 'auto'}}>
                                      { rendering(idx) }
                                        
                                    </div>
                                </div>
                            </Box>
                        ))
                        :   
                        <div>
                            이미지 로딩 중 입니다.
                        </div>
                    }
                      <Modal visible={isModalVisible} onOk={handleOk} onCancel={handleCancel}>
                          <div style={{textAlign:"center"}}>
                              {/* 원본크기는 가로 pixel이 1440,, 1/4배로 화면에 출력 */}
                              <img src={choiceImg} style={{width:"360px", textAlign:"center"}}/>
                          </div>
                          <div style={{paddingLeft:"11%"}}>
                              위 : <CosSliderUp style={{width:"360px"}} defaultValue={0} min={0} max={360} onChange={onChange1}/>
                              아래 : <CosSliderDown style={{width:"360px"}} defaultValue={0} min={0} max={360} onChange={onChange2}/>
                              {/* <CosSlider range defaultValue={[0,360]} min={0} max={360}  /> */}
                              {/* 아래 : <CosSlider style={{width:"360px"}} defaultValue={0} min={0} max={360} onChange={onChange2}/> */}
                          </div>
                      </Modal>
                </div>
            </div>
        </Layout>
    );
};


import React, { useState, useEffect } from 'react';
import { useRecoilValue } from 'recoil';
import axios from "axios";
import { API_URL } from '../../../../Store/Global';
import { Layout } from '../../../../allcomponents';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import CreateSettingTab from './CreateSettingTab'
import PanoramaSettingTab from './PanoramaSettingTab';
import DetectorSettingTab from './DetectorSettingTab';
import MeasureSettingTab from './MeasureSettingTab';
import DrawerSettingTab from './DrawerSettingTab';
import EstimatorSettingTab from './EstimatorSettingTab';
import styles from '../../../../styles/Preprocess.module.css'
import { useNavigate } from 'react-router-dom';
import { Button, Form, Radio,Select, Input, Table} from 'antd';
import { tunnel_project_name, tunnel_name_kor, tunnel_name_eng, tunnel_scanner_speed, tunnel_direction, tunnel_video_fps, tunnel_filming_set, tunnel_camera_num, tunnel_span_num, tunnel_span_length, tunnel_type, tunnel_width, tunnel_length, tunnel_crack_max_width, tunnel_overlap_rate } from "../../../../Store/State/projectAtom";

interface TabPanelProps {
    children?: React.ReactNode;
    index: number;
    value: number;
  }

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}
  
function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

export default function TunnelCreatePage(props:any) {
    const navigate = useNavigate();

    const projectType = localStorage.getItem('projectType');
    const curId = window.location.href.split('/')[6];
    let token: string | null = localStorage.getItem("token");

    const [userid, setUserId] = useState<number>(-1);
    const [companyId, setCompanyId] = useState<any>(localStorage.getItem("companyId"));

    useEffect(() => {
      let getIdCompany = async () => {
        if (token !== null) {
          const response = await axios({
            method: "get",
            url: `${API_URL}/account/auth/check/${token}`,
          })
            .then(async (res) => {
              if (res.data.check === true) {
                setUserId(res.data.data.id)
                setCompanyId(res.data.data.companyId)
                // localStorage.set("project_id", id);
                console.log(
                  `아이디는 다음과 같음 : ${res.data.data.id} / 회사는 다음과 같음 : ${res.data.data.companyId}`
                );
                return {
                  email: res.data.data.email,
                  name: res.data.data.name,
                  phone: res.data.data.phone,
                  avatar: res.data.data.avatar,
                  role: res.data.data.role,
                  id: res.data.data.id,
                  companyId: res.data.data.companyId,
                };
              } else {
                console.log("토큰 만료");
                localStorage.removeItem("token");
                alert("토큰이 만료었습니다 다시 로그인 해주세요");
                window.location.replace("/Main");
              }
            })
            .catch((err) => {
              console.log(err);
            });
        }
      };
      getIdCompany();
    }, []);

    
    const [value, setValue] = React.useState(0);

    const handleChange = (event: React.SyntheticEvent, newValue: number) => {
      setValue(newValue);
    };

      let settings = 
      {   "tunnel_kor" : useRecoilValue(tunnel_name_kor),
          "tunnel_eng" : useRecoilValue(tunnel_name_eng),
          "scanSpeed" : useRecoilValue(tunnel_scanner_speed),
          "fps": useRecoilValue(tunnel_video_fps),
          "direction": useRecoilValue(tunnel_direction),
          "filmSetCount":  String(JSON.stringify(useRecoilValue(tunnel_filming_set)).replace(/\"/gi, '"')),
          "cameraCount": useRecoilValue(tunnel_camera_num),
          "spanCount": useRecoilValue(tunnel_span_num),
          "spanLength": useRecoilValue(tunnel_span_length),
          "constructType": useRecoilValue(tunnel_type),
          "constructWidth": useRecoilValue(tunnel_width),
          "constructLength": useRecoilValue(tunnel_length),
          "crackMaxWidth": useRecoilValue(tunnel_crack_max_width),
          "offsetPersent": useRecoilValue(tunnel_overlap_rate),
          "ownerId": userid,
          "companyId": companyId
      };
      const title = useRecoilValue(tunnel_project_name)
    const submitClick1 = async () => {
      
      console.log(`아이디는 다음과 같음 : ${userid} / 회사는 다음과 같음 : ${companyId}`)
        const response = await axios({

            method: "post",
            url: API_URL + `/project/regist`,
            headers : {"accept" : `application/json`, "access-token" : `${token}`, "Content-Type": `application/json`},
            data: {"title": title,
            "projectType": 'Tunnel',
            "ownerId": userid,
            "companyId": companyId,
            "settings" : JSON.stringify(settings).replace(/\"/gi, '"'),
            "status" : false
        }  ,
            // `${API_URL}/Project/Set?projectId=-1&title=${title}&projectType=${project_Type}`,
            // { "settings" : JSON.stringify(settings).replace(/\"/gi, '"') },
            // {headers : {"Content-Type": `application/json`, access_token : `${token}` }}       
        }).then((res) => {
                console.log("들어오는 데이터는 다음과 같습니다 : ",res.data)
                console.log("시설물",projectType )
                if (res.data.check === true) {
                    console.log("받은 데이터는 다음과 같음 : ",res.data.check)

                    // console.log(res.data.code)

                    localStorage.setItem('projectId', res.data.code)
                    localStorage.setItem('settings',  JSON.stringify(settings).replace(/\"/gi, '"'))
                    
                    axios({
                        method: 'get',
                        url: API_URL + `/project/view/${res.data.code}`,
                        headers : {"accept" : `application/json`, "access-token" : `${token}`},

                    }).then((res) => {
                        if (res.data.check === true) {
                            // SetProjectInfo(res.data.data.settings)
                            localStorage.setItem('projectId', res.data.code)
                            // setTimeout(()=>window.location.replace("/Facility/Dashboard/ProjectDashboard"),500)
                            
                        } else {
                            console.log('프로젝트 정보 atom 저장 실패')
                        }
                        })
                        console.log(settings)
                    alert("프로젝트가 생성되었습니다.")
                    navigate(`/project/list`)
                    // router.push('./PreProcessor/Dashboard')
                } else {
                    console.log("이미 있음")
                    console.log(res.data.check)
                }   
            }).catch((err) => {
                console.log("받은 에러는 다음과 같음",err)
            })  
      // recoil로 저장한 값을 useRecoilValue로 불러와서 create api req body에 넣기
      
    }

    return (
        <Layout>
            <div >
            <div style={{ paddingTop : '2.01rem', paddingLeft : '2rem', color: '#999', fontSize: '0.75rem', fontFamily: 'inter-pretendard', fontStyle: 'normal', fontWeight: 400, lineHeight: '100%'}}></div>
            <div style={{ paddingTop : '0.3rem', paddingLeft : '2rem', color: '#3A4557', fontSize: '1.5rem', fontFamily: 'inter-pretendard', fontStyle: 'normal', fontWeight: 700, lineHeight: '100%'}}>프로젝트 생성</div>
                <Box sx={{ width: '80%', backgroundColor: 'white', borderRadius: '1rem', padding : '2rem', marginLeft : '2rem', marginTop: '2rem'}}>
                <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                    <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
                    <Tab label="프로젝트 설정" {...a11yProps(0)} />
                    <Tab label="파노라마" {...a11yProps(1)} />
                    <Tab label="검출 & 측정" {...a11yProps(2)} />
                    <Tab label="외관조사망도" {...a11yProps(3)} />
                    <Tab label="상태평가보고서" {...a11yProps(4)} />
                    </Tabs>
                </Box>
                <TabPanel value={value} index={0} >
                  <Box sx={{ height: '28rem', paddingTop: '3rem' ,overflowY:'scroll'}}>
                  <CreateSettingTab/>
                  </Box> 
                </TabPanel>
                <TabPanel value={value} index={1}>
                  <Box sx={{ height: '31rem', overflowY:'scroll'}}>
                    <PanoramaSettingTab/>
                  </Box>
                </TabPanel>
                <TabPanel value={value} index={2}>
                  <Box sx={{ height: '31rem', overflowY:'scroll'}}>
                    <div style={{ display: 'flex', padding: '2rem'}}>
                      <div>
                        <div style={{ fontWeight: 600, marginBottom: '1rem'}}>균열 검출</div>
                      <DetectorSettingTab/>
                      </div>
                      <div>
                      <div style={{ fontWeight: 600, marginBottom: '1rem'}}>균열 측정</div>
                        < MeasureSettingTab/>
                      </div>
                    </div>
                  </Box>
                </TabPanel>
                <TabPanel value={value} index={3}>
                  <Box sx={{ height: '31rem', overflowY:'scroll'}}>
                    <DrawerSettingTab/>
                  </Box>
                </TabPanel>
                <TabPanel value={value} index={4}>
                  <Box sx={{ height: '31rem', overflowY:'scroll'}}>
                    <EstimatorSettingTab/>
                  </Box>
                </TabPanel>
                </Box>
            </div>
            <div style={{ display: 'flex', width : '30rem', height: '3rem' }}>
              <Form.Item className={styles.createBtn} style={{ float:'right'}}>
                  <Button type="primary" htmlType='submit' onClick={submitClick1} style={{ width: '6rem', float : 'right', backgroundColor: '#0F6FB7'}} >확인</Button>
              </Form.Item>
            </div>
        </Layout>
    );
};


import styles from '../../../styles/CrackDetector_Measure.module.css'
import { Button, Form, Select, Radio } from 'antd';
import type { RadioChangeEvent } from 'antd';
import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { useRecoilState, atom } from 'recoil';
import { API_URL } from '../../../../../Store/Global';
import { Box, Table, TableBody, TableCell, tableCellClasses, TableContainer, TableHead, TableRow, TableFooter, Pagination, styled } from '@mui/material';

const { Option } = Select;

//Tabel에 보이는 이미지 목록 interface
interface ImgName {
    no : number
    name : string
  }
  
  //결함 검출 실행 tasks명
  interface DetectorList {
    input_folder : string
    output_folder : string
    weight_number : number
  }

  interface Detect {
    input_folder : string;
    output_folder : string;
    weight_number: number;
}

  
  interface Measure {
      input_folder : string;
      output_folder : string;
  }

export default function DetectorMeasureTab() {
    const navigate = useNavigate();

    let projectId : string | null = localStorage.getItem("projectId")
    let token : string | null = localStorage.getItem("token") 

    const [page, setPage] = React.useState(0);
    const [page2, setPage2] = React.useState(0);
    const handlePage = (event:any) => {
      const nowPageInt = parseInt(event.target.outerText);
      console.log(event.target)
      
      if(event.target.outerText) {
        setPage(nowPageInt);
      }
      else {
        if(event.target.ariaLabel.includes("prev")) {
          setPage(page-1);
        }
        if(event.target.ariaLabel.includes("next")) {
          setPage(page+1);
        }
      }
    }
    const handlePage2 = (event:any) => {
        const nowPageInt = parseInt(event.target.outerText);
        console.log(event.target)
        
        if(event.target.outerText) {
          setPage2(nowPageInt);
        }
        else {
          if(event.target.ariaLabel.includes("prev")) {
            setPage2(page2-1);
          }
          if(event.target.ariaLabel.includes("next")) {
            setPage2(page2+1);
          }
        }
      }

    const ImgName : ImgName[] = [];  
    const ImgName2 : ImgName[] = [];  
    const DetectorList : DetectorList[] = [];
    const Detect : Detect [] = [];
    const Measure : Measure [] = [];

    //useEffect에서 stage4의 파노라마 이미지를 불러와서 Table에 사용
    const [ImgList, setImgList] = useState<any | undefined>([]);    
    const [ImgList2, setImgList2] = useState<any | undefined>([]);   
    //ImgList에 불러온 목록이 카메라 개수 * 스팬 개수와 동일하면 true로 반환 후, true일 때 Table을 그림
    const [result, setResult] = useState<boolean>(false)
    const [result2, setResult2] = useState<boolean>(false)
    //균열 검출을 실행하기 위한 tasks를 Weight가 변경될 때 함께 복사하여 변경하고 새롭게 세팅하기 위함
    const [NewDetectorList, setNewDetectorList] = useState<any[]>(DetectorList);
    //결함 검출을 실행하기 위한 tasks를 세팅
    const [detectList, setDetectList] = useState<any[]>(Detect);
    //결함 측정을 실행하기 위한 tasks를 세팅
    // const [NewMeasureList, setNewMeasureList] = useState<any[]>(Measure);
    const [measureList, setMeasureList] = useState<any[]>(Measure);
    //새롭게 할당할 Weight 파일명
    const [DetectorModel, setDetectorModel] = useState<string>("crack_detector_tf1");
    //현재 체크되어 있는 체크박스(Weight => 현재 옵션은 버튼으로 설정되어 있음(checkbox X))
    const [CheckBox, setCheckBox] = useState(1);

    // const [setting, setSetting]=useState<any>([])

    const [typeArr, setType]=useState([])
    const [DamType, setDamType]=useState<string>();

    const [userid, setUserId] = useState<number>(-1);
    const [companyid, setCompanyId] = useState<number>(-1);
  
    useEffect(() => {
        let getIdCompany = async () => {
          if (token !== null) {
            console.log("프로젝트ID" + projectId);
            const response = await axios({
              method: "get",
              url: `${API_URL}/account/auth/check/${token}`,
            })
              .then(async (res) => {
                if (res.data.check === true) {
                  setUserId(res.data.data.id)
                  setCompanyId(res.data.data.companyId)
                  console.log(
                    `아이디는 다음과 같음 : ${res.data.data.id} / 회사는 다음과 같음 : ${res.data.data.companyId}`
                  );
                  return {
                    email: res.data.data.email,
                    name: res.data.data.name,
                    phone: res.data.data.phone,
                    avatar: res.data.data.avatar,
                    role: res.data.data.role,
                    id: res.data.data.id,
                    companyId: res.data.data.companyId,
                  };
                } else {
                  console.log("토큰 만료");
                  localStorage.removeItem("token");
                  alert("토큰이 만료었습니다 다시 로그인 해주세요");
                  window.location.replace("/Main");
                }
              })
              .catch((err) => {
                console.log(err);
              });
          }
        };
        getIdCompany();
      }, []);




    //체크박스 값 변경될 때 호출되는 함수
    const onChange = (e: RadioChangeEvent) => {
        setCheckBox(Number(e.target.value));
        // console.log(e.target.value, typeof e.target.value);

        let copyArrDetectorList = [...NewDetectorList];

        axios({
            method: 'get',
            url: API_URL + `/project/view/${projectId}`,
            headers : {"accept" : `application/json`, "access-token" : `${token}`}, 
        }).then((res) => {
            const settings: any = JSON.parse(res.data.data.settings)
            if(e.target.value===1){
                detectList.push({
                    input_folder : `stage4/${DamType}`,
                    output_folder : `stage6/${DamType}`,
                    weight_number: 1
                }) 

            }else if(e.target.value===2){
                
                detectList.push({
                    input_folder : `stage4/${DamType}`,
                    output_folder : `stage6/${DamType}`,
                    weight_number: 2
                }) 
            }
            else if(e.target.value===3){
                
                detectList.push({
                    input_folder : `stage4/${DamType}`,
                    output_folder : `stage6/${DamType}`,
                    weight_number: 3
                }) 
            }
                
        }).catch((err) => {
            console.log(err);
        })
    };

    type ModelList = string;

    useEffect(()=>{
        const response = axios({
            method: 'get',
                url: API_URL + `/project/view/${projectId}`,
                headers : {"accept" : `application/json`, "access-token" : `${token}`}, 
        }).then((res) => {
            console.log(res.data)
            const settings: any = JSON.parse(res.data.data.settings)
                // setSetting(settings)
                setType(settings.dam_type)
                console.log(settings.dam_type, settings.dam_type.length)
                
                Detect.push({
                    input_folder : `stage4/${DamType}`,
                    output_folder : `stage6/${DamType}`,
                    weight_number: 1
                }) 

                Measure.push({
                    input_folder: `stage6/${DamType}`,
                    output_folder: `stage7/${DamType}`
                }) 
        }).catch((err) => {
            console.log(err);
        });   


        axios({
            method: 'get',
            url: API_URL+'/File/Files',
            headers: { "accept": `application/json`, "access-token": `${token}` },
            params: {
                path: `/project/${projectId}/stage4/${DamType}`
            }
        }).then((res) => {    
            
        if(res.data.check) {
            setResult(true);
        }
        // console.log(res.data.data.files.length)
        for (let j = 0; j < res.data.data.files.length; j++) {
            ImgName.push({
                no : j+1,
                name : res.data.data.files[j]
            })            
    }
    
    ImgName.sort((obj1, obj2) => {
        if (obj1.no > obj2.no) {
            return 1;
        }
        if (obj1.no < obj2.no) {
            return -1;
        }
        return 0;
        })
    setImgList(ImgName)

    }).catch((err) => {
        console.log(err)
    })

        axios({
            method: 'get',
            url: API_URL+'/File/Files',
            headers: { "accept": `application/json`, "access-token": `${token}` },
            params: {
                path: `/project/${projectId}/stage6/${DamType}`
            }
        }).then((res) => {    
        console.log(res.data.check)
        if(res.data.check) {
            setResult2(true);
        }
        // console.log(res.data.data.files.length)
        for (let j = 0; j < res.data.data.files.length; j++) {
            ImgName2.push({
                no : j+1,
                name : res.data.data.files[j]
            })            
    }

    ImgName2.sort((obj1, obj2) => {
        if (obj1.no > obj2.no) {
            return 1;
        }
        if (obj1.no < obj2.no) {
            return -1;
        }
        return 0;
        })
    setImgList2(ImgName2)

    }).catch((err) => {
        console.log(err)
    })
        setDetectList(Detect);
        setMeasureList(Measure);
        console.log(result,result2)
    },[DamType])

     /////////////////30초마다 status알려주는 alert//////////////////////////////////////////
     let job_id = 0;
     let resultDefectDetector :any;
     let resultDefectDetector2 :any;
     const confirmDetect = () => {
         // console.log(job_id)
         axios({
             method: "post",
             url: API_URL + '/scheduler/job/query',
             headers: { "accept": `application/json`, "access-token": `${token}`, "Content-Type" : `application/json` },
                 data: {
                     "job_id": job_id ,
                     "company_id": companyid
                 }
                   }).then((res) => {
                 // console.log(res)
                 if (res.data.check === true) {
                     console.log("성공")
                     if (res.data.data.status === "done") {
                         alert("결함 검출 작업이 끝났습니다.")
                         navigate(`/dam/project/defect/${projectId}`)
                         clearInterval(resultDefectDetector)
                        //  window.location.href='./DefectDetector'
                     } else if (res.data.data.status === "progress") {
                         alert("이미지 추출중입니다.")
                     } else if(res.data.data.status === "error"){
                         alert("해당 파일이 없습니다.")
                         clearInterval(resultDefectDetector)
                     }
                 } else {
                     console.log("실패")
                 }
             })
         
     }
     const confirmMeasure = () => {
        // console.log(job_id)
        axios({
            method: "post",
            url: API_URL + '/scheduler/job/query',
            headers: { "accept": `application/json`, "access-token": `${token}`, "Content-Type" : `application/json` },
                data: {
                    "job_id": job_id ,
                    "company_id": companyid
                }
                  }).then((res) => {
                // console.log(res)
                if (res.data.check === true) {
                    console.log("성공")
                    if (res.data.data.status === "done") {
                        alert("결함 측정 작업이 끝났습니다.")
                        navigate(`/dam/project/defect/${projectId}`)
                        clearInterval(resultDefectDetector2)
                       //  window.location.href='./DefectDetector'
                    } else if (res.data.data.status === "progress") {
                        alert("이미지 추출중입니다.")
                    } else if(res.data.data.status === "error"){
                        alert("해당 파일이 없습니다.")
                        clearInterval(resultDefectDetector2)
                    }
                } else {
                    console.log("실패")
                }
            })
        
    }
     /////////////////////////////////////////////////////////////////////////////////////////////////

    const DetectStart = () => {
        console.log(DetectorModel);
        console.log(detectList);
        
        // setTimeout(function() {
        //     alert("균열 검출 작업이 완료되었습니다.")
        //     localStorage.setItem("isDetect", "true");
        //     window.location.reload()
        //   }, 5000);

         axios({
            method: "post",
            url: API_URL +`/scheduler/job/start/${companyid}/${userid}`,
            headers: {
              "accept": `application/json`,
              "access-token": `${token}`,
              "Content-Type": `application/json`
            },
                  data: {
                project_id: projectId,
                task_name: "defectDetect",
                interactive: false,
                tasks: detectList
            }
            }).then((res)=>{
                if (res.data.check === true) {
                    console.log("성공")  
                    alert(`${DamType} 결함 검출 작업을 시작합니다.`)  
                    job_id = res.data.data.job_id
                    /////30초마다 alert로 알려줌////////////
                    resultDefectDetector = setInterval(confirmDetect, 30000)
                } else {
                    console.log("실패")
                }
            }).catch((err) => {
                console.log(err);
            });      
    }

    const MeasureStart = () => {
        console.log(DetectorModel);
        console.log(NewDetectorList);
        // clearInterval(resultDefectDetector2)
        // alert("균열 측정 작업 중입니다.")
        // setTimeout(function() {
        //     alert("균열 측정 작업이 완료되었습니다.")
        //     localStorage.setItem("isMeasure", "true");
        //     window.location.reload()
        //   }, 5000);
       
           axios({
            method: "post",
            url: API_URL +`/scheduler/job/start/${companyid}/${userid}`,
            headers: {
            "accept": `application/json`,
            "access-token": `${token}`,
            "Content-Type": `application/json`
            },
            data: {
            project_id: projectId,
            task_name: "cv2_defectMeasure",
            interactive: false,
            tasks: measureList
        }
        }).then((res)=>{
            if (res.data.check === true) {
                console.log("성공")    
                alert(`${DamType} 결함 측정 작업을 시작합니다.`)  
                job_id = res.data.data.job_id
                /////30초마다 alert로 알려줌////////////
                resultDefectDetector2 = setInterval(confirmMeasure, 30000)
                // alert("lean_mean 작업")            
            } else {
                console.log("실패")
            }
        }).catch((err) => {
            console.log(err);
        });
    }

    const onChangeModel = (value: ModelList) => {
        if(value==="1"){
            setModelData(
                <Radio.Group optionType="button">
                    <Radio value={1} onChange={onChange}>bohyeon_weight_0109</Radio>
                    <Radio value={2} onChange={onChange}>Seomjin_weight_0311</Radio>
                </Radio.Group>
            )
            setDetectorModel("crack_detector_tf1")
        }
    }

    const [isSelect1, setIsSelect1] = useState<boolean>(false);
    const onChangeModel1 = (value: ModelList) => {
        setIsSelect1(true)
    }

    const [isSelect2, setIsSelect2] = useState<boolean>(false);
    const onChangeModel2 = (value: ModelList) => {
        setIsSelect2(true)
    }

    const [ModelData, setModelData] = useState<any | undefined>(
        <Radio.Group optionType="button">
            <Radio value={1} onChange={onChange}>dam88</Radio>
            <Radio value={2} onChange={onChange}>dam87</Radio>
            <Radio value={3} onChange={onChange}>dam84</Radio>
        </Radio.Group>
    ); 

    const [ModelData2, setModelData2] = useState<any | undefined>(
        <Radio.Group optionType="button">
            <Radio value={1} onChange={onChange}>new_drawing_classification.h5</Radio>
            <Radio value={2} onChange={onChange} disabled>준비중</Radio>
        </Radio.Group>
    ); 



    const [WeightData, setWeightData] = useState<any | undefined>(
        <div>
            <p style={{fontSize:"20px", fontWeight:"bold"}}>bohyeon_weight_0109</p>
            <p style={{fontSize:"25px"}}>Mask RCNN</p>
            <p>Epoch : 300</p>
            <p>LR : 0.0005</p>
            <p>batch_size : 1</p>
            <p>optimizer : Adam</p>
        </div>
    );

    const [isSelect, setIsSelect] = useState<boolean>(false)

    const option_render=()=>{
      const arr:any[]=[];
      
      typeArr.map((type:any)=>{
          let name=''
          if(type==='Overflow'){
              name='월류부'
          }else if(type==='DamFloor'){
              name='댐마루'
          }else if (type === 'UpStream') {
              name='상류면'
          } else if (type === 'DownStream') {
              name ='비월류부'
          } else if (type === 'Spillway') {
              name ='여수로'
          }
          arr.push(<Option value={type} > {name}</Option>)
      })
      return arr;
  }

    const  modelSelect :any[]= [];
      const selectModel = () => {
          modelSelect.push(
              <Select placeholder="선택해주세요"  style={{ width : '8rem'}} onChange={(e) => {setIsSelect(true); setDamType(e)}}>
                  {option_render()}    
              </Select>
          ) 
  
          return modelSelect;
      }

      const StyledTableCell = styled(TableCell)(({ theme }) => ({
        [`&.${tableCellClasses.head}`]: {
          fontSize: '1rem',
          fontWeight: 600,
          backgroundColor: '#F5F5F5',
          color: '#3A4557',
          width: '42.8125rem',
          height: '2.5rem',
          border: '1px solid #DDD',
        //   borderTop: '0.15rem solid #3A4557',
          padding : 0
        },
        [`&.${tableCellClasses.body}`]: {
          color: '#3A4557',
          textAlign: 'center',
          fontSize: '0.8125rem',
          width: '42.8125rem',
          height: '3rem',
          border: '1px solid #DDD',
          padding : 0,
        },
      }));


    return (
    <div>
        <div style={{ width: "80rem"}}>
        <div style={{ marginTop: '1rem'}}>
                <Box sx={{
                    display: 'block',
                    width: '13rem',
                    height: '2rem',
                    borderRadius: '0.5rem',
                    boxShadow: '2px 2px 20px 0px rgba(58, 69, 87, 0.05)',
                    // backgroundColor:'#f0fbff',
                    // border: '1px solid black',
                    padding:'1rem',
                    fontSize : '1rem',
                    fontFamily : 'inter-pretendard',
                    marginLeft:'-2rem'
                }}>
                    댐 타입 &nbsp; : &nbsp; {selectModel()}
                </Box>
                <br/>
                <br/>
        </div>
        {
            isSelect ?
            <div style={{ display:'flex', width: "80rem"}}>
            
            <div style={{ width: "40rem", marginRight: '5rem' }}>
                <div style={{ fontWeight: 600, marginBottom: '1rem', fontFamily: "inter-pretendard"}}>결함 검출</div>
                <Form labelCol={{ span: 0, }} wrapperCol={{ span: 10, }} layout="horizontal">
                <Form.Item label={<p style={{fontFamily: "inter-pretendard"}}>Detector Model </p>}>
                    <Select  placeholder="선택해주세요." onChange={onChangeModel1}>
                        <Select.Option value="1">defectDetector.exe</Select.Option>
                        <Select.Option value="2" disabled>Defect Detector TF1.x</Select.Option>
                        {/* <Select.Option value="3" disabled>Defect Detector TF2.x</Select.Option> */}
                    </Select>
                </Form.Item>
                </Form>
                {
                    isSelect1 ? 
                    <>
                        <div style={{ fontFamily: "inter-pretendard", fontSize: '0.875rem' }}>
                            weight : {""} {ModelData}
                        </div>
                        <br/>
                        <br/>
                        {/* <Table dataSource={result === true ? ImgList:""}>
                            <Column title="num" dataIndex="no" key="no" />
                            <Column title="name" dataIndex="name" key="name" />
                        </Table> */}
                        {
                            result ?
                            <TableContainer  sx={{ width: '20rem', marginLeft: '1rem', marginTop: '1rem' }}>
                            <Table sx={{ backgroundColor: '#fffff' }} aria-label="customized table">
                                <TableHead>
                                <TableRow>
                                    <StyledTableCell align="center" style={{width:'5rem', fontFamily:'inter-pretendard', fontSize: '1rem'}}>번호</StyledTableCell>
                                    <StyledTableCell align="center" style={{width:'15rem', fontFamily:'inter-pretendard', fontSize: '1rem'}}>이름</StyledTableCell>
                                </TableRow>
                                </TableHead>
                                
                                <TableBody>
                                {(result === true ? ImgList:[]).map((row:any, i:any) => (
                                    <TableRow 
                                        key={row.no}
                                        style={{ height: '2.5rem', cursor:'pointer'}}
                                    >
                                    <StyledTableCell align="center" style={{width:'3em', height: '2.5rem', fontFamily:'inter-pretendard', fontSize: '0.8rem'}}>{row.no}</StyledTableCell>
                                    <StyledTableCell align="center" style={{width:'5rem', height: '2.5rem', fontFamily:'inter-pretendard', fontSize: '0.8rem'}}>{row.name}</StyledTableCell>
                                    </TableRow> 
                                ))}
                                </TableBody>
                            </Table>
                            <TableFooter>
                                <TableRow style={{ width: '20rem', display : 'flex', justifyContent: 'center', marginTop:'4rem'}}>
                                <Pagination count={ImgList.length > 10 && ImgList.length % 10 === 0 ? Math.floor(ImgList.length/10) : Math.floor(ImgList.length/10)+1} defaultPage={1} onChange={(e) => handlePage(e)} color="primary" 
                                    sx={{
                                    '& svg': {
                                        pointerEvents: 'none'
                                    }
                                    }}
                                />
                                </TableRow>
                                </TableFooter>
                            </TableContainer>
                            : <div style={{fontFamily:'inter-pretendard', fontSize: '1rem'}}>결함 검출할 이미지가 없습니다.</div>

                        }
                        <br/>
                        <br/>
                        <div style={{ marginLeft: "400px" }}>
                            <Button style={{ width: "150px" }} onClick={DetectStart}>검출 시작</Button>
                        </div>
                        <br/>
                        <br/>
                    </> : <></>
                }
            </div>
            <div style={{ width: "40rem" }}>
                <div style={{ fontWeight: 600, marginBottom: '1rem', fontFamily: "inter-pretendard"}}>결함 측정</div>
                <Form labelCol={{ span: 0, }} wrapperCol={{ span: 10, }} layout="horizontal">
                <Form.Item label={<p style={{fontFamily: "inter-pretendard"}}>Measure Model </p>}>
                    <Select placeholder="선택해주세요." onChange={onChangeModel2}>
                        <Select.Option value="1">cv2_defectMeasure.exe</Select.Option>
                        <Select.Option value="2" disabled>Defect Measure TF1.x</Select.Option>
                        {/* <Select.Option value="3" disabled>Defect Detector TF2.x</Select.Option> */}
                    </Select>
                </Form.Item>
                </Form>
                {
                    isSelect2 ? 
                    <>
                        <div style={{ fontFamily: "inter-pretendard", fontSize: '0.875rem' }}>
                            weight : {""} {ModelData2}
                        </div>
                        <br/>
                        <br/>
                        {
                            result2 ? 
                            <TableContainer  sx={{ width: '20rem', marginLeft: '1rem', marginTop: '1rem' }}>
                            <Table sx={{ backgroundColor: '#fffff' }} aria-label="customized table">
                                <TableHead>
                                <TableRow>
                                    <StyledTableCell align="center" style={{width:'5rem', fontFamily:'inter-pretendard', fontSize: '1rem'}}>번호</StyledTableCell>
                                    <StyledTableCell align="center" style={{width:'15rem', fontFamily:'inter-pretendard', fontSize: '1rem'}}>이름</StyledTableCell>
                                </TableRow>
                                </TableHead>
                                
                                <TableBody>
                                {(result2 === true ? ImgList2:[]).map((row:any, i:any) => (
                                    <TableRow 
                                        key={row.no}
                                        style={{ height: '2.5rem', cursor:'pointer'}}
                                    >
                                    <StyledTableCell align="center" style={{width:'3em', height: '2.5rem', fontFamily:'inter-pretendard', fontSize: '0.8rem'}}>{row.no}</StyledTableCell>
                                    <StyledTableCell align="center" style={{width:'5rem', height: '2.5rem', fontFamily:'inter-pretendard', fontSize: '0.8rem'}}>{row.name}</StyledTableCell>
                                    </TableRow> 
                                ))}
                                </TableBody>
                            </Table>
                            <TableFooter>
                                <TableRow style={{ width: '20rem', display : 'flex', justifyContent: 'center', marginTop:'4rem'}}>
                                <Pagination count={ImgList2.length > 10 && ImgList2.length % 10 === 0 ? Math.floor(ImgList2.length/10) : Math.floor(ImgList2.length/10)+1} defaultPage={1} onChange={(e) => handlePage2(e)} color="primary" 
                                    sx={{
                                    '& svg': {
                                        pointerEvents: 'none'
                                    }
                                    }}
                                />
                                </TableRow>
                                </TableFooter>
                        </TableContainer>
                        : <div style={{fontFamily:'inter-pretendard', fontSize: '1rem'}}>결함 검출을 먼저 실행해주세요.</div>
                        }
                        <br/>
                        <br/>
                        <div style={{ marginLeft: "400px" }}>
                            <Button style={{ width: "150px" }} onClick={MeasureStart}>측정 시작</Button>
                        </div>
                        <br/>
                        <br/>
                    </> : <></>
                }
            </div>

            
            <div>
                {/* {WeightData} */}
            </div>
            </div> : <></>
        }
        </div>
    </div>
    )
}

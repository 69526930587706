import React, { useState, useEffect } from 'react';
import styles from '../../../../styles/Preprocess.module.css'
import { useNavigate } from 'react-router-dom';
import { Layout } from '../../../../allcomponents';
import axios from "axios";
import { API_URL, IMAGE_URL } from '../../../../Store/Global';
import { Select, Checkbox } from 'antd';
import { Box, Button } from '@mui/material';

const { Option } = Select;

interface Image_folder {
    typeFolderName : string;
    SpanFolderName : string;
    SubSpanFolderName : string;
}

export default function AirportPreProcessImageFilterPage(props:any) {
    const projectType = localStorage.getItem('projectType');
    const curId = window.location.href.split('/')[6];
    let token : string | null = localStorage.getItem("token") 
    let projectId : string | null = localStorage.getItem("projectId")

    const [userid, setUserId] = useState<number>(-1);
    const [companyId, setcompanyId] = useState<any>(localStorage.getItem("companyId"));

    const navigate = useNavigate();

    let job_id=0

    // 전체 이미지
    const [allNList, setAllNList] = useState<any>();
    const [confirmAll, setConfirmAll] = useState<boolean>(false)
    
    // 사용이미지
    const [clickImg, setClickImg] = useState<any>([])
    const [selectImg, setSelectImg]= useState<any>([])
    const [standardImg, setStandardImg] = useState<any>([])
    const [confirmSelect, setConfirmSelect] = useState<boolean>(false)

    //미사용 이미지
    const [unUsed, SetUnUsed] = useState<any>([])
    const [confirmUnused, setConfirmUnUsed] = useState<boolean>(false)

    //C01&C02나누기
    const[camClass,setCamClass] = useState<any>([])
    const cameraClass: any[] = []
    
    //conf값
    const [setting, setSetting]=useState<any>([])

    //dam
    const [typeArr, setType]=useState([])
    const [DamType, setDamType] = useState<string>("Overflow");
    const [dam_type_name, setDamTypeName] = useState<string>("")

    // 전체 선택 checked
    const [checkAll, setCheckAll] = useState<boolean>(false)

    //구역 또는 옵션 선택 하여 필터링이 진행되었는지 확인하는 변수 (아직 안됨.)
    const [checkFilter, setCheckFilter] =useState<boolean>(false)
   
    let allList: any = [];


    useEffect(() => {
        let getIdCompany = async () => {
            if(token !== null){ 
                console.log("여기 들어옴?")
                console.log("프로젝트ID"+projectId)
                const response = await axios({
                method : "get",
                url : `${API_URL}/account/auth/check/${token}`,                
                }).then(async(res)=>{
                if(res.data.check === true){
                    setUserId(res.data.data.id)
                    setcompanyId(res.data.data.companyId)
                    // localStorage.set("projectId", id);
                    console.log(`아이디는 다음과 같음 : ${res.data.data.id} / 회사는 다음과 같음 : ${res.data.data.companyId}`)
                    return {email : res.data.data.email, name : res.data.data.name, phone : res.data.data.phone, avatar : res.data.data.avatar, role : res.data.data.role, id : res.data.data.id, companyId : res.data.data.companyId}
                }else{
                    console.log("토큰 만료")
                    localStorage.removeItem('token')
                    alert("토큰이 만료었습니다 다시 로그인 해주세요")
                    window.location.replace("/Main")
                }
                }).catch((err)=>{
                console.log(err)
                })
            }
        }
        getIdCompany()
    },[])

    const [isSelect, setIsSelect] = useState<boolean>(false)
    // const setting :any = localStorage.getItem("settings")
    // const typeArr: any = JSON.parse(setting).dam_type

    useEffect(() => {
        let path: any = `/project/${projectId}/stage0_r`
        console.log(path)
        let all: any[] = [];
        // setTimeout(()=>{console.log(path)},3000)
        axios({
            method: 'get',
            url: API_URL + `/File/Files`,
            headers: { "accept": `application/json`, "access-token": `${token}` },
            params: {path : path}
        }).then((res) => { 
            // console.log(res.data.check)
            if (res.data.check === true) {
                let i = 0;
                for (const image of res.data.data.files) {
                    allList.push({ key: i + 1, name: image, imageURL: `${IMAGE_URL}/image?path=`+path+`/${image}&width=360` });
                    // setConfirmAll(true)
                    // setConfirmUnUsed(true)
                    all.push(image)
                    i=i+1
                }
                allList.sort((obj1: { name: String; }, obj2: { name: String; }) => {
                if (obj1.name > obj2.name) {
                    return 1;
                }
                if (obj1.name < obj2.name) {
                    return -1;
                }
                return 0;
            })
                setAllNList(allList)
                SetUnUsed(allList)
            } else {
                console.log("불러오기 실패")
                // alert("데이터가 없습니다.")
            }
        })

        axios({
            method: 'get',
            url: API_URL + `/project/view/${projectId}`,
            headers: { "accept": `application/json`, "access-token": `${token}` },
        }).then((res) => {
            if (res.data.check === true) {
                const settings: any = JSON.parse(res.data.data.settings)
                let countList :any= [];
                setSetting(settings)
            } else {
                console.log("셋팅값 가져오기 실패")
            }
        }).catch((err) => { console.log(err) })
        
    },[])
    
    const onClickimage = (e: any) => {
        let imgClick: any = [...clickImg]
        let imgSelect: any = [...selectImg]
        
        let unUsedimg :any =[...unUsed]
        setConfirmSelect(true)
        setConfirmUnUsed(true)

        
        let indexFront: any;
        let indexBack: any;
        let image_name: any;
        
        indexFront = e.target.src.indexOf("stage0_r") 
            indexBack = e.target.src.indexOf(".JPG")   
            image_name = e.target.src.substring(indexFront+9,indexBack+4)
        
        if (imgClick.includes(image_name) === true) {
            const find = imgClick.findIndex((e: any) => e === image_name)  
            imgClick.splice(find, 1)
            imgSelect.splice(find, 1)
        } else {
            imgClick.push(image_name)
            const image = allNList.filter((item: any) => item.name === image_name)
            imgSelect.push({ key: image[0]?.key, name: image_name, imageURL: e.target.src })
           
            imgSelect.sort((obj1: { name: String; }, obj2: { name: String; }) => {
                if (obj1.name > obj2.name) {
                    return 1;
                }
                if (obj1.name < obj2.name) {
                    return -1;
                }
                return 0;
            })

            
        }
        const unUsedIndex = unUsedimg.findIndex((e: any) => e.name === image_name)
        if (unUsedIndex !== -1) {
            unUsedimg.splice(unUsedIndex,1)
        } else {
            const image = allNList.filter((item: any) => item.name === image_name)
            // console.log(image)
            unUsedimg.push({ key: image[0]?.key, name: image_name, imageURL: e.target.src })
            unUsedimg.sort((obj1: { name: String; }, obj2: { name: String; }) => {
                if (obj1.name > obj2.name) {
                    return 1;
                }
                if (obj1.name < obj2.name) {
                    return -1;
                }
                return 0;
            })
            
        }
        

        setClickImg(imgClick)
        setSelectImg(imgSelect)
        SetUnUsed(unUsedimg)
    }
    const onClickSelectimage = (e: any) => {
        let imgStandard: any = [...standardImg]
        let indexFront: any = e.target.src.indexOf("stage0_r")
        let indexBack: any = e.target.src.indexOf(".JPG") || e.target.src.indexOf(".jpg");
        let image_name: any = e.target.src.substring(indexFront + 9, indexBack + 4);
        let confirm:boolean = true

        if (confirm === true) {
            if (imgStandard.includes(image_name) === true) {
                const find = imgStandard.findIndex((e: any) => e === image_name)
                imgStandard.splice(find, 1)
            } else {
                if (imgStandard.length === 0) {
                    imgStandard.push(image_name)
                } else {
                    alert("하나만 선택해주세요.")
                }
            }
        } else {
            //선택 하지 않아도됨.
        }
        
        setStandardImg(imgStandard)

        // 선택된 이미지 중 c01,c02 나눌 이미지가 stadardImg에 들어가있음.
        const index = allNList.findIndex((item: any) => item.name===image_name)
        let C01_image: any[] = [];
        let C02_image: any[] = [];
        for (let i = 0; i < selectImg.length; i++) {
            // console.log(selectImg[i].key)
            if (i <= index) {
                // console.log(i)
                C01_image.push(selectImg[i].name)
            } else {
                // console.log(i)
                C02_image.push(selectImg[i].name)
            }
        }
        cameraClass.push({ key: 1, image: C01_image })
        cameraClass.push({ key: 2, image: C02_image })
        setCamClass(cameraClass)
    }

    const AllImage = () => {
        let all: any[] = [];
        // console.log(allNList)
        for (let i = 0; i < allNList?.length; i++) {
            all.push(<img src={allNList[i].imageURL} id={String(i)} key={allNList[i].imageURL}  className={clickImg.includes(allNList[i].name) ? styles.GirderImageBorder : styles.GirderImage} onClick={onClickimage}/>)
        }
        console.log("all", all.length)
        return all;
    }

    const SelectImg = () => {
        let select: any[] = []
        // let j =0
        for (let i = 0; i < selectImg?.length; i++){
            const find_select = allNList.filter((item: any) => item.name.includes(selectImg[i].name))
            // console.log(find_select[0].key)
            select.push(<img src={find_select[0]?.imageURL}id={String(i)} key={find_select[0]?.key} className={standardImg.includes(clickImg[i]) ? styles.GirderImageBorder : styles.GirderImage} onClick={onClickSelectimage} />)
        }
        console.log("sel", select.length)
        return select;
    }

    const UnusedImg = () => {
        let unUsedimg: any[] = [...unUsed]
        let unImg: any[] =[]
        for (let i = 0; i < unUsedimg?.length; i++){
            unImg.push(<img src={unUsedimg[i].imageURL} id={String(i)} key={unUsedimg[i].key} className={styles.GirderImage} onClick={onClickSelectimage} />)
        }
        console.log("un", unImg.length)
        return unImg;
    }
    const allClick = (e: any) => {
        if (checkAll === true) {
            setCheckAll(false)
        } else {
            setCheckAll(true)
        }
        let clickAll: any[] = []
        let selectAll :any[] = []
        setConfirmSelect(true)
        if (e.target.checked === true) {
            for (const image of allNList) {
                // console.log(image.key)
                clickAll.push(image.name)
                selectAll.push({ key: image.key, name: image.name, imageURL: image.imageURL })
            }
            // console.log(selectAll)
            SetUnUsed([])
            setConfirmUnUsed(true)
        } else {
            SetUnUsed(allNList)
            clickAll = []
        }
        
        
        setClickImg(clickAll)
        setSelectImg(selectAll)
    }

    let result: any;
    let IsProc = false;

    const FilterStart = (e: any) => {
        e.preventDefault();
        if (!IsProc) {
            IsProc = true;
        }
        for (let i = 0; i < camClass.length; i++){
            axios({
                method: "post",
                url: API_URL +`/scheduler/job/start/${companyId}/${userid}`,
                headers: {
                  "accept": `application/json`,
                  "access-token": `${token}`,
                  "Content-Type": `application/json`
                },
                data: {
                    projectId: projectId,
                    task_name: "copy_folder",
                    interactive: false,
                    tasks: [{
                        input_folder: "stage0_r",
                        file_names: camClass[i].image, //복사할 이미지 이름 list
                        output_folder: "stage1/C0" + String(i + 1)
                    }
                    , {
                        input_folder: "stage0",
                        file_names: camClass[i].image, //복사할 이미지 이름 list
                        output_folder: `stage2/${setting.airport_eng}/C0` + String(i + 1)
                    }]
                }
            }).then((res) => {
                if (res.data.check === true) {
                    console.log("C0" + (i + 1) + " 성공이다")
                    IsProc = false;
                    setTimeout(() => {
                        axios({
                            method: "post",
                            url: API_URL +`/scheduler/job/start/${companyId}/${userid}`,
                            headers: {
                              "accept": `application/json`,
                              "access-token": `${token}`,
                              "Content-Type": `application/json`
                            },
                            data: {
                                projectId: projectId,
                                task_name: "gpstoxyz_airport",
                                interactive: false,
                                tasks: [{
                                    input_folder: `stage2/${setting.airport_eng}/C0` + String(i + 1),
                                    align: 1,
                                    meter: setting.spanLength,
                                    conf_name: "gps.conf",
                                    conf_folder: "stage2",
                                    conf_values: {
                                        sensor_width: Number(setting.cameraSensor),
                                        focal_length: Number(setting.cameraFocus),
                                        altitude: Number(setting.droneAltitude),
                                        image_width: Number(setting.imageWidth),
                                        image_height: Number(setting.imageHeight),
                                        photo_ratio: Number(setting.overlap)
                                    }
                                }]
                            }
                        }).then((res3) => {
                            if (res3.data.check === true) {
                                console.log("좌표변환 모듈 성공")
                                setStandardImg([])
                               
                                for (let j = 0; j < setting.spanCount; j++) {
                                    console.log("C0"+(i+1)+" S00"+(j+1)+" rotation 시작")
                                    axios({
                                        method: "post",
                                        url: API_URL +`/scheduler/job/start/${companyId}/${userid}`,
                                        headers: {
                                          "accept": `application/json`,
                                          "access-token": `${token}`,
                                          "Content-Type": `application/json`
                                        },                                        data: {
                                            projectId: projectId,
                                            task_name: "imageRotation_airport",
                                            interactive: false,
                                            tasks: [{
                                                input_folder: `stage2/${setting.airport_eng}/C0${i+1}/S00`+String(j + 1),
                                                output_folder: `stage2/${setting.airport_eng}/C0${i+1}/S00`+String(j + 1),
                                                rotation_angle: 90
                                            }]
                                        }
                                    }).then((res4) => {
                                        if (res4.data.check === true) {
                                            job_id = res4.data.data.job_id
                                            console.log("로테이션 시작")
                                            result = setInterval(confirm, 30000)
                                        } else {
                                            console.log("좌표변환후 rotate 실패")
                                        }
                                    })
                                    // console.log("끝!")
                                }
                            } else {
                                console.log("C0" + (i + 1) + "좌표변환 모듈 실패")
                            }
                        })
                    }, 20000)
                    
                } else {
                    console.log("C0" + (i+1) + " 실패다")
                    console.log(res)
                    
                }
            })
        }
        // alert("이미지 필터링 작업을 시작합니다.")
        // setTimeout(function() {
        //     alert("이미지 필터링 작업이 완료되었습니다.")
        //     navigate(`/airport/project/preprocess/folderdiv/${projectId}`)
        // }, 2000);
    }
    
   
    const gpsModule = (i: number) => {
        alert("좌표변환모듈이 시작되었습니다.")
        let input_folder: any;
        let output_folder: any;
        let config_list: any;
        let facility_type: any;

        
        axios({
            method: "post",
            url: API_URL +`/scheduler/job/start/${companyId}/${userid}`,
            headers: {
              "accept": `application/json`,
              "access-token": `${token}`,
              "Content-Type": `application/json`
            },
            data: {
                projectId: projectId,
                task_name: "gpstoxyz_total",
                interactive: false,
                tasks: [{
                    input_folder: input_folder,
                    output_folder: output_folder,
                    facility: projectType,
                    facility_type:facility_type,
                    conf_name: "totalconfig.conf",
                    conf_folder: 'stage1',
                    conf_values:config_list
                }]
            }
            }).then((res) => {
                if (res.data.check === true) {
                    console.log("좌표 변환모듈 성공")
                    job_id = res.data.data.job_id
                    result = setInterval(confirm, 30000)
                    
                } else {
                    console.log("좌표 변환모듈 실패")
                }    
            }).catch((err) => console.log(err))
    }

    const confirm = () => {
        axios({
            method: "post",
            url: API_URL + '/scheduler/job/query',
            headers: { "accept": `application/json`, "access-token": `${token}`, "Content-Type" : `application/json` },
                data: {
                    "job_id": job_id ,
                    "company_id": companyId
                }
        }).then((res) => {
            if (res.data.data.status === "done") {
                setCheckFilter(false)
                alert("이미지 필터링이 끝났습니다.")
                clearInterval(result)
                window.location.replace('./Filter')
                
            }
        })
        
    }


    return (
        <Layout>
            <div style={{ paddingTop : '2.01rem', paddingLeft : '2rem', color: '#999', fontSize: '0.75rem', fontFamily: 'inter-pretendard', fontStyle: 'normal', fontWeight: 400, lineHeight: '100%'}}>전처리 &gt; 이미지 필터</div>
            <div style={{ paddingTop : '0.3rem', paddingLeft : '2rem', color: '#3A4557', fontSize: '1.5rem', fontFamily: 'inter-pretendard', fontStyle: 'normal', fontWeight: 700, lineHeight: '100%'}}>이미지 필터</div>
            <div style={{ marginTop: '2rem', marginLeft: '2rem'}}>
                <div style={{ height: '43rem', overflowY : 'scroll'}}>
                    <Box sx={{ width: '99.75rem', height: '21.25rem', flexShrink: 0, borderRadius: '0.5rem', background: '#FFF', boxShadow: '2px 2px 20px 0px rgba(58, 69, 87, 0.05)', marginBottom: '1rem'}}>
                        <div style={{padding : '2rem'}}>
                            {/* 네임라인 */}
                            <div style={{ display: 'flex', justifyContent: 'space-between'}}>
                                    <div style={{ display : 'flex'}}>
                                        <div style={{ backgroundColor : '#0F6FB7', borderRadius : '50%', color : '#fff', width : '1.25rem', height : '1.25rem', textAlign : 'center', fontSize : '0.75rem', fontWeight:400, lineHeight:'175%'}}>1</div>
                                        <div style={{ marginLeft : '0.5rem', fontWeight : 600}}>전체 이미지</div>
                                    </div>
                                    <div>
                                        <Checkbox style={{ float: 'right' }} onClick={allClick} checked={checkAll}> 전체 선택 </Checkbox>   
                                    </div>
                            </div>
                            {/* 이미지라인 */}
                            <div style={{ width : '95.75rem', marginTop : '1.25rem', whiteSpace : 'nowrap', overflow: 'auto'}}>
                                { AllImage() }
                            </div>
                        </div>
                    </Box>
                    <Box sx={{ width: '99.75rem', height: '21.25rem', flexShrink: 0, borderRadius: '0.5rem', background: '#FFF', boxShadow: '2px 2px 20px 0px rgba(58, 69, 87, 0.05)', marginBottom: '1rem'}}>
                        <div style={{padding : '2rem'}}>
                            {/* 네임라인 */}
                            <div style={{ display: 'flex', justifyContent: 'space-between'}}>
                                    <div style={{ display : 'flex'}}>
                                        <div style={{ backgroundColor : '#0F6FB7', borderRadius : '50%', color : '#fff', width : '1.25rem', height : '1.25rem', textAlign : 'center', fontSize : '0.75rem', fontWeight:400, lineHeight:'175%'}}>2</div>
                                        <div style={{ marginLeft : '0.5rem', fontWeight : 600}}>사용 이미지</div>
                                    </div>
                                    <div>
                                        <Checkbox style={{ float: 'right' }} onClick={allClick} checked={checkAll}> 전체 선택 </Checkbox>   
                                    </div>
                            </div>
                            {/* 이미지라인 */}
                            <div style={{ width : '95.75rem', marginTop : '1.25rem', whiteSpace : 'nowrap', overflow: 'auto'}}>
                                { SelectImg() }
                            </div>
                        </div>
                    </Box>
                    <Box sx={{ width: '99.75rem', height: '21.25rem', flexShrink: 0, borderRadius: '0.5rem', background: '#FFF', boxShadow: '2px 2px 20px 0px rgba(58, 69, 87, 0.05)', marginBottom: '1rem'}}>
                        <div style={{padding : '2rem'}}>
                            {/* 네임라인 */}
                            <div style={{ display: 'flex', justifyContent: 'space-between'}}>
                                    <div style={{ display : 'flex'}}>
                                        <div style={{ backgroundColor : '#0F6FB7', borderRadius : '50%', color : '#fff', width : '1.25rem', height : '1.25rem', textAlign : 'center', fontSize : '0.75rem', fontWeight:400, lineHeight:'175%'}}>3</div>
                                        <div style={{ marginLeft : '0.5rem', fontWeight : 600}}>미사용 이미지</div>
                                    </div>
                                    <div>
                                        <Checkbox style={{ float: 'right' }} onClick={allClick} checked={checkAll}> 전체 선택 </Checkbox>   
                                    </div>
                            </div>
                            {/* 이미지라인 */}
                            <div style={{ width : '95.75rem', marginTop : '1.25rem', whiteSpace : 'nowrap', overflow: 'auto'}}>
                                { UnusedImg() }
                            </div>
                        </div>
                    </Box>
                </div>
                <div style={{ marginBottom: '1rem', display : 'flex'}}>
                    <div style={{ float : 'right'}}>
                        <Button variant="contained" sx={{ background: '#0F6FB7', padding: '0.35rem 2rem', marginLeft: '90.1rem', marginTop: '1rem', marginRight: '1rem', fontSize:'1rem', fontWeight:600, fontFamily: 'inter-pretendard'}} onClick={FilterStart}>
                            이미지 필터링
                        </Button>
                    </div>
                    
                </div>
            </div>
        </Layout>
    );
};
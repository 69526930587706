import React, { useState, useEffect } from 'react';
import { Layout } from '../../../../allcomponents';
import { useNavigate } from 'react-router-dom';
import { styled } from '@mui/material/styles';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import VideoUpload from './Component/VideoUpload';

function createData(
    num: string,
    name: string,
    startPoint: string,
    endPoint:string,
    status : string
  ) {
    return { num, name, startPoint, endPoint, status};
  }
  
  const rows = [
    createData('1', 'C01', '00:00', '00:00', '-'),
    createData('2', 'C02', '00:00', '00:00', '-'),
    createData('3', 'C03', '00:00', '00:00', '-'),
    createData('4', 'C04', '00:00', '00:00', '-'),
    createData('5', 'C05', '00:00', '00:00', '-'),
    createData('6', 'C06', '00:00', '00:00', '-'),
    createData('7', 'C07', '00:00', '00:00', '-'),
    createData('8', 'C08', '00:00', '00:00', '-'),
    createData('9', 'C09', '00:00', '00:00', '-'),
    createData('10', 'C10', '00:00', '00:00', '-'),
    createData('11', 'C11', '00:00', '00:00', '-'),
  ];

export default function TunnelPreProcessVideoEditPage(props:any) {
    const projectType = localStorage.getItem('projectType');
    const curId = window.location.href.split('dashboard/')[1];
    let projectId : string | null = localStorage.getItem("projectId")

    const navigate = useNavigate();
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);

        // Avoid a layout jump when reaching the last page with empty rows.
        const emptyRows =
        page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;

        const handleChangePage = (
        event: React.MouseEvent<HTMLButtonElement> | null,
        newPage: number,
        ) => {
        setPage(newPage);
        };

        // const handleChangeRowsPerPage = (
        //   event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
        // ) => {
        //   setRowsPerPage(parseInt(event.target.value, 10));
        //   setPage(0);
        // };

      const StyledTableCell = styled(TableCell)(({ theme }) => ({
        [`&.${tableCellClasses.head}`]: {
          fontSize: '1rem',
          fontWeight: 600,
          backgroundColor: '#F5F5F5',
          color: '#3A4557',
          width: '42.8125rem',
          height: '2.5rem',
          border: '1px solid #DDD',
          borderTop: '0.15rem solid #3A4557',
          padding : 0
        },
        [`&.${tableCellClasses.body}`]: {
          color: '#3A4557',
          textAlign: 'center',
          fontSize: '0.8125rem',
          width: '42.8125rem',
          height: '3rem',
          border: '1px solid #DDD',
          padding : 0,
        },
      }));
      
      const StyledTableRow = styled(TableRow)(({ theme }) => ({
        // '&:nth-of-type(odd)': {
        //   backgroundColor: theme.palette.action.hover,
        // },
        // hide last border
        '&:last-child td': {
        },

      }));


    return (
        <Layout>
            <div>
                <div style={{ paddingTop : '2.01rem', paddingLeft : '2rem', color: '#999', fontSize: '0.75rem', fontFamily: 'inter-pretendard', fontStyle: 'normal', fontWeight: 400, lineHeight: '100%'}}>전처리 &gt; 영상 편집</div>
                <div style={{ paddingTop : '0.3rem', paddingLeft : '2rem', color: '#3A4557', fontSize: '1.5rem', fontFamily: 'inter-pretendard', fontStyle: 'normal', fontWeight: 700, lineHeight: '100%'}}>영상 편집</div>
                <div style={{ marginTop: '1rem', marginLeft: '2rem'}}>
                    <div>
                        <VideoUpload />
                    </div> 
                </div>
            </div>
        </Layout>
    );
};


import React, { useState, useEffect } from 'react';
import { Layout } from '../../../../allcomponents';

export default function TunnelPanoramaSettingPage(props:any) {
    const projectType = localStorage.getItem('projectType');
    const curId = window.location.href.split('/')[6];
    const [projectId, setProjectId] = useState("");
    useEffect(() => {
        if(localStorage.getItem('projectId')) {
            let id: string | null = localStorage.getItem('projectId');
            if(id != curId) {
                localStorage.setItem('projectId', curId)
                
            }
            setProjectId(String(curId));
        }
        else {
            localStorage.setItem('projectId', curId)
        }
      }, []);

    return (
        <Layout>
            <div>
                {projectType}-{projectId} 파노라마 설정
            </div>
        </Layout>
    );
};

import styles from '../../../../../styles/Preprocess.module.css'
import styled from "styled-components";
import { useNavigate } from 'react-router-dom';
import { Button, Form, Radio,Select, Input, Table, Spin} from 'antd';
import { useRecoilState, atom } from 'recoil';
import { useState } from 'react'
import axios from 'axios';
import { useEffect } from 'react';
import { API_URL } from '../../../../../Store/Global';

interface TableSet {
    key: any;
    no: any;
    firstCam:any;
    lastCam:any;
}

export default function Setting() {
    const navigate = useNavigate();
    const [projectName, setprojectNamee] = useState('공항점검');
    const [airportNameKor, setAirportNameKor] = useState('고양드론');
    const [airportNameEng, setAirportNameEng] = useState('GoYung');
    const [cameraCount, setCameraCount] = useState('2');
    const [airstripWidth, setAirstripWidth] = useState('20');
    const [airstripLength, setAirstripLength] = useState('200');
    const [spanLength, setSpanLength] = useState('70');
    const [spanCount, setSpanCount] = useState('3');
    const [crackMaxWidth, setCrackMaxWidth] = useState('');
    const [cameraSensor, setCameraSensor] = useState('');
    const [cameraFocus, setCameraFocus] = useState('9');
    const [droneAltitude, setDroneAltitude] = useState('');
    const [imageWidth, setImageWidth] = useState('');
    const [imageHeight, setImageHeight] = useState('');
    const [overlapRate, setOverlapRate] = useState('');

    const [isStart, setIsStart] = useState<boolean>(false)
    const [isFOD, setIsFOD] = useState<boolean>(false)
    const [text, setText] = useState<string>("loading")

    const list: any[] = [projectName, airportNameKor, airportNameEng, cameraCount, airstripWidth, airstripLength, spanLength, spanCount, crackMaxWidth,
        cameraSensor, cameraFocus, droneAltitude, imageWidth, imageHeight, overlapRate]

        const plus = (event:any) => {
    
            console.log(list);
            alert(`설정값을 변경하였습니다`);
            window.location.replace("/Facility/Panorama/Dashboard")
        }
        let token : string | null = localStorage.getItem("token") 
        let projectId: string | null = localStorage.getItem("projectId");


        // const formItemLayoutWithOutLabel = {
        //     wrapperCol: {
        //     xs: { span: 24, offset: 0 },
        //     sm: { span: 24, offset: 0 },
        //     },
        // };

        // const [projectName, setProjectName] =useState(0); 
        // const [korName, setKorName] =useState(0);
        // const [cameraNo, setCamraNo] =useState(0); 
        // const [spanNo, setSpanNo] =useState(0); 
        // const [enName, setEnName] =useState(0);
        // const [speedd, setSpeedd] =useState(0); 
        // const [fpss, setFpss] =useState(0); 
        // const [spanLenn, setSpanLenn] =useState(0); 
        // const [direction, setDirection] = useState('');
        // const[dirvlaue, setDirvlaue] = useState(direction)

        let TableSet : TableSet[] = [];

        const [filmSetResult, setfilmSetResult] = useState<boolean>(false);
        const [TableSetStauts, setTableSetStauts] = useState(TableSet);
        // let TableSet:any[] = [];



        const { Column } = Table;
        const [leng, SetLeng] = useState('');

        const spanNoInput = (e:any) => {setSpanCount(e.target.value);}


        useEffect(() => {
            const response = axios({
                method: 'get',
                url: API_URL + `/project/view/${projectId}`,
                headers : {"accept" : `application/json`, "access-token" : `${token}`}, 
            }).then((res) => {
                    // console.log(res.data.check) 
                    const settings: any = JSON.parse(res.data.data.settings)
                    // console.log("dlfmaaaaaaaaaaaaaaaa"+res.data.data.title)
                    // if (res.data.check === true) {
                    //     for (let i = 0; i < 1; i++) {
                    //         console.log('세트갯'+JSON.parse(settings.filmSetCount)[i].FirstCamera)
                    //         console.log(`카메라갯수${settings.tunnel_kor}` )
                    //         console.log(`스팬갯수${settings.direction}` )
                    //         setCamraNo(settings.cameraCount)
                    //         setSpanNo(settings.spanCount)
                    //         setProjectName(res.data.data.title)
                    //         setKorName(settings.tunnel_kor)
                    //         setEnName(settings.tunnel_eng)
                    //         setSpeedd(settings.scanSpeed)
                    //         setFpss(settings.scanSpeed.fps)
                    //         setSpanLenn(settings.spanLength)
                    //         setDirection(settings.direction)

                    //         if(settings.direction === 'P01'){
                    //             setDirvlaue('상행')
                    //         } else {
                    //             setDirvlaue('하행')
                    //             }
                    //         }

                    //     for(let i = 0; i < JSON.parse(settings.filmSetCount).length; i++){
                    //         TableSet.push({
                    //             key:i,
                    //             no: i+1,
                    //             firstCam: JSON.parse(settings.filmSetCount)[i].FirstCamera,
                    //             lastCam: JSON.parse(settings.filmSetCount)[i].LastCamera
                    //         })
                    //             // console.log(JSON.parse(settings.filmSetCount).length)

                    //         // SetLeng(JSON.parse(settings.filmSetCount).length);

                    //     }
                    // } else {
                    //     console.log("실패");
                    // }
                
                    setTableSetStauts(TableSet);
                    if(TableSetStauts.length > 0){
                        setfilmSetResult(true)
                }

                }).catch((err) => {
                    console.log(err);
                });
        }, []);

        useEffect (() => {
            if(isFOD) {
                setText("FOD 검출 작업 중 ...")
            } 
            
        }, [isFOD])

        const submitClick1 = () => {
            alert("공항 시설물에 대한 AI 안전점검이 시작되었습니다.")
            setIsStart(true)
            setTimeout(function() {
                alert("파노라마 생성이 완료되었습니다. FOD 검출을 시작합니다.")
                setIsFOD(true)
                setTimeout(function() {
                    alert("FOD 검출이 완료되었습니다. 안전점검 진단이 모두 완료되었습니다.")
                    setIsStart(false)
                    navigate(`../airport/project/fod/${projectId}`)
                }, 10000); // 검출 완료
            }, 2000); // 검출 시작
        }
        
    return (
            <>
            <Form labelCol={{ span: 8, }} wrapperCol={{ span: 8 }} layout="horizontal">
                            <Form.Item className={styles.FormItem}  label={<p style={{fontFamily: "inter-pretendard", fontSize:'2rem'}}>프로젝트 명</p>}>
                                <Input value={projectName} />
                            </Form.Item>
                            <Form.Item className={styles.FormItem} label={<p style={{fontFamily: "inter-pretendard", fontSize:'2rem'}}>공항 이름(한)</p>}>
                                <Input value={airportNameKor} />
                            </Form.Item>
                            <Form.Item className={styles.FormItem} label={<p style={{fontFamily: "inter-pretendard", fontSize:'2rem'}}>공항 이름(영)</p>}>
                                <Input value={airportNameEng} />
                            </Form.Item>
                            <Form.Item className={styles.FormItem} label={<p style={{fontFamily: "inter-pretendard", fontSize:'2rem'}}>카메라 수</p>}>
                                <Input value={cameraCount} suffix="개"  />
                            </Form.Item>
                            <Form.Item className={styles.FormItem} label={<p style={{fontFamily: "inter-pretendard", fontSize:'2rem'}}>활주로 너비</p>}>
                                <Input value={airstripWidth} suffix="m"  />
                            </Form.Item>
                            <Form.Item className={styles.FormItem} label={<p style={{fontFamily: "inter-pretendard", fontSize:'2rem'}}>활주로 길이</p>}>
                                <Input value={airstripLength} suffix="m"  />
                            </Form.Item>
                            <Form.Item className={styles.FormItem} label={<p style={{fontFamily: "inter-pretendard", fontSize:'2rem'}}>스팬 길이</p>}>
                                <Input value={spanLength} suffix="m"  />
                            </Form.Item>
                            <Form.Item className={styles.FormItem} label={<p style={{fontFamily: "inter-pretendard", fontSize:'2rem'}}>스팬 수</p>}>
                                <Input value={spanCount} suffix="개"  />
                            </Form.Item>
                            <Form.Item className={styles.FormItem} label={<p style={{fontFamily: "inter-pretendard", fontSize:'2rem'}}>실제 균열 최대 폭</p>}>
                                <Input value={crackMaxWidth} suffix="m"  />                         
                            </Form.Item>
                            <Form.Item className={styles.FormItem} label={<p style={{fontFamily: "inter-pretendard", fontSize:'2rem'}}>카메라 센서 크기</p>}>
                                <Input value={cameraSensor} suffix="m"  />  
                            </Form.Item>
                            <Form.Item className={styles.FormItem} label={<p style={{fontFamily: "inter-pretendard", fontSize:'2rem'}}>카메라 초점 거리</p>}>
                               <Input value={cameraFocus} suffix="m"  />  
                            </Form.Item>
                            <Form.Item className={styles.FormItem} label={<p style={{fontFamily: "inter-pretendard", fontSize:'2rem'}}>드론 촬영 고도</p>}>
                               <Input value={droneAltitude} suffix="m"  />  
                            </Form.Item>
                            <Form.Item className={styles.FormItem} label={<p style={{fontFamily: "inter-pretendard", fontSize:'2rem'}}>이미지 너비</p>}>
                               <Input value={imageWidth} suffix="m"  />  
                            </Form.Item>
                            <Form.Item className={styles.FormItem} label={<p style={{fontFamily: "inter-pretendard", fontSize:'2rem'}}>이미지 높이</p>}>
                               <Input value={imageHeight} suffix="m"  />  
                            </Form.Item>
                            <Form.Item className={styles.FormItem} label={<p style={{fontFamily: "inter-pretendard", fontSize:'2rem'}}>촬영시 겹침률</p>}>
                               <Input value={overlapRate} suffix="m"  />  
                            </Form.Item>

                            <Form.Item className={styles.submitBtn}>
                            {
                                    !isStart ? 
                                    <Button type="primary" htmlType='submit' onClick={submitClick1} style={{ width: '200px'}} >AI 안전점검 시작</Button>
                                    : 
                                    <>
                                    <Spin spinning={true} size={"large"}/>
                                    <p>{text}</p>
                                    </>

                                    
                                }
                        </Form.Item>
            </Form>
            </>


    )
}

import React, { useState, useEffect } from 'react';
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";
import { Layout } from '../../allcomponents';
import { User } from '../../alltypes';
import { useNavigate } from 'react-router-dom';
import { ReturnValues } from '../../allmodels';
import { apiHelper } from '../../allutils';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import FormControl from '@mui/material/FormControl';
import OutlinedInput from '@mui/material/OutlinedInput'
import axios from "axios";
import { API_URL, IMAGE_URL } from '../../Store/Global';
import { Progress, Space } from 'antd';

function UserDetailPage(props:any) {
  const token = "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6IjMiLCJpYXQiOjE2OTM0NTMxNDAsImV4cCI6MTY5MzQ2MjE0MH0.h3i1nTon-aFXf5DkljeQGue9BJSBoFoDkjHXtQm0xHY"
  const userid = document.location.href.split("user/detail/")[1];
  const [percent, setPercent] = useState<number>(10);
  const navigate = useNavigate();

  const [isBind, setIsBind] = useState(false);
  const [data, setData] = useState<User>();
  const [companyName, setCompanyName] = useState<any>();

  
  const DataBind = () => {
    if (!isBind) {
      apiHelper.Get(`/account/member/view/${userid}`, {}, (rst: ReturnValues<User>) => {
        if (rst.check && rst.data !== null && rst.data !== undefined) {
            setData(rst.data);
            console.log(rst);
            setIsBind(true);
        }
    });
    
    }
  };

  const circularProgress = document.querySelectorAll<HTMLElement>(".circular-progress");

  
  useEffect(() => {
    Array.from(circularProgress).forEach((progressBar) => {
      const progressValue = progressBar.querySelector<HTMLElement>(".percentage");
      const innerCircle = progressBar.querySelector<HTMLElement>(".inner-circle");
      let startValue = 0,
        endValue = Number(progressBar.getAttribute("data-percentage")),
        speed = 50,
        progressColor = progressBar.getAttribute("data-progress-color");
  
      const progress = setInterval(() => {
        startValue++;
        if (progressValue?.textContent !== undefined) {
          progressValue.textContent = `${startValue}%`;
          progressValue.style.color = `${progressColor}`;
        }
        
        if (!innerCircle) return;
        
        innerCircle.style.backgroundColor = `${progressBar.getAttribute(
          "data-inner-circle-color"
        )}`;
  
        progressBar.style.background = `conic-gradient(${progressColor} ${
          startValue * 3.6
        }deg,${progressBar.getAttribute("data-bg-color")} 0deg)`;
        if (startValue === endValue) {
          clearInterval(progress);
        }
      }, speed);
      return () => {
        clearInterval(progress);
      };
    });

  });

  useEffect (function () {
      DataBind();      
  }, [isBind]);
  
  useEffect (function () {
    let getCompanyName = async () => {
      await axios({
        method: "get",
        headers: { 
          "accept": `application/json`,
          "access-token": `${token}`,
          "Content-Type": `application/json`
      },  
        url: `${API_URL}/company/view/${data?.companyId}`,
      })
        .then(async (res) => {
          if (res.data.check === true) {
            // console.log(res.data.data.name)
            setCompanyName(res.data.data.name)
            
            
          }
        })
    }
    getCompanyName()
    
}, [data]);

  



    return (
      <Layout>
        <div>
            <div style={{ paddingTop : '2.5rem', paddingLeft: '2rem'}}>
                <a style={{ fontSize : '1.5rem', fontWeight: 700, color: '#3A4557'}}>내 정보</a>
            </div>
            <div style={{ display: 'flex'}}>
            <Box sx={{ 
                        width: '29.5rem',
                        height: '38rem', 
                        background : '#ffffff', 
                        borderRadius: '0.5rem',
                        marginTop : '1rem',
                        marginLeft : '2rem',
                        padding : '2rem',
                        boxShadow: '2px 2px 20px 0px rgba(58, 69, 87, 0.05)'
                    }}
            >
              <div style={{ display :'block'}}>
                  <div style={{ color: '#3A4557', fontSize : '1rem', fontWeight : 600 }}>내 정보</div>
                  <div>
                      {/* <Button variant="outlined"  sx={{ float: 'right', fontSize: '0.75rem', fontWeight:600}} >정보 수정</Button> */}
                  </div>
                  <div>
                    <div style={{ textAlign: 'center', marginTop: '3rem'}}>
                      <svg xmlns="http://www.w3.org/2000/svg" width="128" height="128" viewBox="0 0 32 32" fill="none">
                        <g clip-path="url(#clip0_1082_2532)">
                        <path d="M15.9993 2.66699C8.63935 2.66699 2.66602 8.64033 2.66602 16.0003C2.66602 23.3603 8.63935 29.3337 15.9993 29.3337C23.3593 29.3337 29.3327 23.3603 29.3327 16.0003C29.3327 8.64033 23.3593 2.66699 15.9993 2.66699ZM15.9993 6.66699C18.2127 6.66699 19.9993 8.45366 19.9993 10.667C19.9993 12.8803 18.2127 14.667 15.9993 14.667C13.786 14.667 11.9993 12.8803 11.9993 10.667C11.9993 8.45366 13.786 6.66699 15.9993 6.66699ZM15.9993 25.6003C12.666 25.6003 9.71935 23.8937 7.99935 21.307C8.03935 18.6537 13.3327 17.2003 15.9993 17.2003C18.6527 17.2003 23.9593 18.6537 23.9993 21.307C22.2793 23.8937 19.3327 25.6003 15.9993 25.6003Z" fill="#909090"/>
                        </g>
                        <defs>
                          <clipPath id="clip0_1082_2532">
                            <rect width="128" height="128" fill="white"/>
                          </clipPath>
                        </defs>
                      </svg>
                    </div>
                    <div style={{ textAlign: 'center', color: 'var(--new-maincolor, #0F6FB7)', fontSize:'1.125rem', fontWeight: 600 }}>{data?.name}</div>
                    {/* <div style={{ textAlign: 'center', fontSize: '0.875rem', fontWeight: 300, color : '#3A4557', marginTop: '0.5rem'}}><span style={{ fontWeight: 600 }}>소속</span> 개발지원팀</div> */}
                  </div>
                  <div style={{ marginTop : '3rem'}}>
                    <div style={{ marginTop : '1rem'}}>
                      <div style={{ color: 'var(--new-maincolor, #0F6FB7)', fontSize: '1rem', fontWeight: 600, marginLeft: '2rem', marginBottom: '0.5rem'}}>이메일</div>
                      <div style={{ display : 'flex' }}>
                        <FormControl sx={{ margin : 'auto', width: '25.5rem', height: '2.5rem', borderRadius : '0.25rem', border: '1px solid #DDD', background : '#fff' }} variant="outlined">
                        <OutlinedInput
                            id="email"
                            aria-describedby="outlined-weight-helper-text"
                            value={data?.email}
                            inputProps={{
                              'aria-label': 'email',
                              
                            }}
                            sx={{
                              width: '25.5rem', 
                              height: '2.5rem', 
                              border: 'none'
                            }}
                          />
                        </FormControl>
                      </div>
                    </div>
                    <div style={{ marginTop : '1rem'}}>
                      <div style={{ color: 'var(--new-maincolor, #0F6FB7)', fontSize: '1rem', fontWeight: 600, marginLeft: '2rem', marginBottom: '0.5rem'}}>이름</div>
                      <div style={{ display : 'flex' }}>
                        <FormControl sx={{ margin : 'auto', width: '25.5rem', height: '2.5rem', borderRadius : '0.25rem', border: '1px solid #DDD', background : '#fff' }} variant="outlined">
                        <OutlinedInput
                            id="name"
                            aria-describedby="outlined-weight-helper-text"
                            value={data?.name}
                            inputProps={{
                              'aria-label': 'name',
                              
                            }}
                            sx={{
                              width: '25.5rem', 
                              height: '2.5rem', 
                              border: 'none'
                            }}
                          />
                        </FormControl>
                      </div>
                    </div>
                    <div style={{ marginTop : '1rem'}}>
                      <div style={{ color: 'var(--new-maincolor, #0F6FB7)', fontSize: '1rem', fontWeight: 600, marginLeft: '2rem', marginBottom: '0.5rem'}}>전화번호</div>
                      <div style={{ display : 'flex' }}>
                        <FormControl sx={{ margin : 'auto', width: '25.5rem', height: '2.5rem', borderRadius : '0.25rem', border: '1px solid #DDD', background : '#fff' }} variant="outlined">
                        <OutlinedInput
                            id="phone"
                            aria-describedby="outlined-weight-helper-text"
                            value={data?.phone}
                            inputProps={{
                              'aria-label': 'phone',
                              
                            }}
                            sx={{
                              width: '25.5rem', 
                              height: '2.5rem', 
                              border: 'none'
                            }}
                          />
                        </FormControl>
                      </div>
                    </div>
                    {/* <div style={{ marginTop : '1rem'}}>
                      <div style={{ color: 'var(--new-maincolor, #0F6FB7)', fontSize: '1rem', fontWeight: 600, marginLeft: '2rem', marginBottom: '0.5rem'}}>직책</div>
                      <div style={{ display : 'flex' }}>
                        <FormControl sx={{ margin : 'auto', width: '25.5rem', height: '2.5rem', borderRadius : '0.25rem', border: '1px solid #DDD', background : '#fff' }} variant="outlined">
                        <OutlinedInput
                            id="cameraAmount"
                            aria-describedby="outlined-weight-helper-text"
                            value={'연구원'}
                            inputProps={{
                              'aria-label': 'cameraAmount',
                              
                            }}
                            sx={{
                              width: '25.5rem', 
                              height: '2.5rem', 
                              border: 'none'
                            }}
                          />
                        </FormControl>
                      </div>
                    </div> */}
                    <div style={{ marginTop : '1rem'}}>
                      <div style={{ color: 'var(--new-maincolor, #0F6FB7)', fontSize: '1rem', fontWeight: 600, marginLeft: '2rem', marginBottom: '0.5rem'}}>소속</div>
                      <div style={{ display : 'flex' }}>
                        <FormControl sx={{ margin : 'auto', width: '25.5rem', height: '2.5rem', borderRadius : '0.25rem', border: '1px solid #DDD', background : '#fff' }} variant="outlined">
                        <OutlinedInput
                            id="companyName"
                            aria-describedby="outlined-weight-helper-text"
                            value={companyName}
                            inputProps={{
                              'aria-label': 'companyName',
                              
                            }}
                            sx={{
                              width: '25.5rem', 
                              height: '2.5rem', 
                              border: 'none'
                            }}
                          />
                        </FormControl>
                      </div>
                    </div>
                  </div>
              </div>
            </Box>
            <Box sx={{ 
                        width: '45.5rem',
                        height: '25.5rem',
                        background : '#ffffff', 
                        borderRadius: '0.5rem',
                        marginTop : '1rem',
                        marginLeft : '2rem',
                        padding : '2rem',
                        boxShadow: '2px 2px 20px 0px rgba(58, 69, 87, 0.05)'
                    }}
            >
              <div style={{ color: '#3A4557', fontSize : '1rem', fontWeight : 600 }}>데이터 사용량 및 부과 요금</div>
              <div style={{ display: 'flex', marginLeft : '2rem', margin:'3rem'}}>
                <div style={{marginRight:'5rem'}}>
                  <Progress type="dashboard" size={150} percent={percent} />
                  <div style={{marginLeft:'2rem'}}>CPU 사용량</div>
                </div>
                <div style={{marginRight:'5rem'}}>
                  <Progress type="dashboard" size={150} percent={percent} />
                  <div style={{marginLeft:'2rem'}}>GPU 사용량</div>
                </div>
                <div style={{marginRight:'5rem'}}>
                  <Progress type="dashboard" size={150} percent={percent} />
                  <div style={{marginLeft:'1.5rem'}}>Storage 사용량</div>
                </div>
              </div>
              <div style={{ display: 'flex', width : '45.5rem', height: '7.5rem', borderRadius : '0.25rem', background : 'rgba(15, 111, 183, 0.05)', fontSize: '1.25rem', fontWeight : 600, marginTop: '1.5rem'}}>
                <div style={{ margin : 'auto 17rem auto 4rem', color: '#0F6FB7'}}>청구 예정 금액</div>
                <div style={{ margin : 'auto 4rem', color : '#3A4557', }}> 0 원</div>
              </div>
            </Box>
            </div>
        </div>
      </Layout>
    );
};

export default UserDetailPage;
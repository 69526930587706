import React, { useState, useEffect } from 'react';
import { Layout } from '../../../../allcomponents';
import { useNavigate } from 'react-router-dom';
import axios from "axios";
import { API_URL, IMAGE_URL } from '../../../../Store/Global';
import { Select } from 'antd';
import { Box, Table, TableBody, TableCell, tableCellClasses, TableContainer, TableHead, TableRow, TableFooter, Pagination, styled, Button } from '@mui/material';
  
const { Option } = Select;

interface ImgContents {
  no : number
  name : any
  sort : string
}

interface DetectorImage{
  no: any
  detector_image : string
  sort : string
}

interface CrackMeasureResultImage {
  no : any
  result_image : string
  sort : string
}

export default function BridgeCrackMeasurePage(props:any) {
    const projectType = localStorage.getItem('projectType');
    const curId = window.location.href.split('/')[6];
    let token : string | null = localStorage.getItem("token") 
    let projectId : string | null = localStorage.getItem("projectId")
    const [userid, setUserId] = useState<number>(-1);
    const [companyid, setCompanyId] = useState<number>(-1);

    useEffect(() => {
        let getIdCompany = async () => {
            if(token !== null){ 
                console.log("여기 들어옴?")
                console.log("프로젝트ID"+projectId)
                const response = await axios({
                method : "get",
                url : `${API_URL}/account/auth/check/${token}`,                
                }).then(async(res)=>{
                if(res.data.check === true){
                    setUserId(res.data.data.id)
                    setCompanyId(res.data.data.companyId)
                    // localStorage.set("projectId", id);
                    console.log(`아이디는 다음과 같음 : ${res.data.data.id} / 회사는 다음과 같음 : ${res.data.data.companyId}`)
                    return {email : res.data.data.email, name : res.data.data.name, phone : res.data.data.phone, avatar : res.data.data.avatar, role : res.data.data.role, id : res.data.data.id, companyId : res.data.data.companyId}
                }else{
                    console.log("토큰 만료")
                    localStorage.removeItem('token')
                    alert("토큰이 만료었습니다 다시 로그인 해주세요")
                    window.location.replace("/Main")
                }
                }).catch((err)=>{
                console.log(err)
                })
            }
        }
        getIdCompany()
    },[])

    let job_id = 0;
    let resultCouple :any;

    const ImgContents : ImgContents[] = []; 
    const DetectorImage : DetectorImage[] = [];  
    const CrackMeasureResultImage : CrackMeasureResultImage[] = [];

    const [ImgList, setImgList] = useState<any | undefined>(ImgContents)
    const [data, setData] = useState<any[]>(ImgContents);
    const [ImgListDetector, setImgListDetector] = useState<any[]>(DetectorImage);
    const [ImgListCrackMeasure, setImgListCrackMeasure] = useState<any[]>(CrackMeasureResultImage);

    const [resultDetector, setResultDetector] = useState<boolean>(false)
    const [resultCrack, setResultCrack] = useState<boolean>(false)

    const [setting, setSetting]=useState<any>([])
    const [typeArr, setType]=useState([])

    const [BridgeType, setBridgeType] = useState<string>("")
    const [GirderCount, setGirderCount] = useState<number>(0)

    const [num, setNum] = useState<number>(0);

    const navigate = useNavigate();
    const [page, setPage] = React.useState(1);

    useEffect(() => {
        setData(ImgList.slice((page-1)*10, page*10));
      }, [page]);

    const handlePage = (event:any) => {
      const nowPageInt = parseInt(event.target.outerText);
      console.log(event.target)
      
      if(event.target.outerText) {
        setPage(nowPageInt);
      }
      else {
        if(event.target.ariaLabel.includes("prev")) {
          setPage(page-1);
        }
        if(event.target.ariaLabel.includes("next")) {
          setPage(page+1);
        }
  
      }
      
    }

    const [resultImageList, setResultImageList] = useState<boolean>(false)
    const [resultTableList, setResultTableList] = useState<boolean>(false)

    const [isModalVisible, setIsModalVisible] = useState(false);
    const [choiceImg, setChoiceImg] = useState<string>("")
    const [choiceIndex, setChoiceIndex] = useState<number>()

    const { onSelectAllClick, order, orderBy, numSelected, rowCount, onRequestSort } = props;

    const [value, setValue] = React.useState(0);

    const handleChange = (event: React.SyntheticEvent, newValue: number) => {
        setValue(newValue);
    };

    
    useEffect(()=> {
      localStorage.setItem('bridge_type', BridgeType)

      setResultDetector(false);
      setResultCrack(false);

      setImgList([])
      setImgListDetector([])
      setImgListCrackMeasure([])

      axios({
          method: 'get',
          url: API_URL + `/project/view/${projectId}`,
          headers : {"accept" : `application/json`, "access-token" : `${token}`}, 
      }).then((res) => {
          const settings: any = JSON.parse(res.data.data.settings)
          setSetting(settings)
          setType(settings.bridge_type)

          if(BridgeType === "Girder" || BridgeType === "Slab" || BridgeType === "GirderSide"){
              for(let i = 0; i < settings.span_count; i++){
                  for(let k = 0; k < settings.span_length/settings.span_class_length; k++){
                      axios({
                          method:'get',
                          url: API_URL+'/File/Files',
                          headers: {"accept":`application/json`, "access-token": `${token}`},
                          params: {
                              path: `/project/${projectId}/stage6_crack/${BridgeType}/S${settings.span_number_list[i].num.length === 2 ? settings.span_number_list[i].num+"0"+(k+1) : "0"+settings.span_number_list[i].num+"0"+(k+1)}`
                          }
                      }).then((res)=>{
                          for(let y = 0; y < res.data.data.files.length; y++){
                              const indexFront = res.data.data.files[y].indexOf("S") 
                              const indexBack = res.data.data.files[y].indexOf(".png") 
                              const SpanNum = res.data.data.files[y].substring(BridgeType==="Girder" ? indexFront
                                                                                                      : BridgeType === "GirderSide" ? indexFront+10 
                                                                                                      : indexFront+12, indexBack-6)

                              DetectorImage.push({
                              no : DetectorImage.length,
                              detector_image : `${IMAGE_URL}/image?path=/project/${projectId}/stage6_crack/${BridgeType}/S${settings.span_number_list[i].num.length === 2 ? settings.span_number_list[i].num+"0"+(k+1) : "0"+settings.span_number_list[i].num+"0"+(k+1)}/${res.data.data.files[y]}&width=1440`,
                              sort : SpanNum
                              })

                              ImgContents.push({
                                  no : ImgContents.length,
                                  name : <a style ={{color:"black"}}>{res.data.data.files[y]}</a>,
                                  sort : SpanNum
                              })
                          }

                              DetectorImage.sort((obj1, obj2) => {
                                  if(obj1.sort > obj2.sort){
                                      return 1;
                                  }
                                  if(obj1.sort < obj2.sort){
                                      return -1;
                                  }
                                  return 0;
                              })

                              ImgContents.sort((obj1, obj2) => {
                                  if (obj1.sort > obj2.sort) {
                                      return 1;
                                  }
                                  if (obj1.sort < obj2.sort) {
                                      return -1;
                                  }
                                  return 0;
                              })

                              let copyArrDetectorImage = [...DetectorImage];
                              let copyArrImgContents = [...ImgContents];

                              if(BridgeType === "Girder"){
                                  if(DetectorImage.length === settings.girder_count * settings.span_length/settings.span_class_length * settings.span_count * settings.girder_camera_count){
                                      setResultDetector(true);

                                      for(let j = 0; j < DetectorImage.length; j++){
                                          copyArrDetectorImage[j] = {...copyArrDetectorImage[j], no : j+1};
                                          copyArrImgContents[j] = { ...copyArrImgContents[j], no : j+1};
                                      }
                                  }

                              }else if(BridgeType === "Slab"){
                                  if(DetectorImage.length > 0){
                                      setResultDetector(true);

                                      for(let j = 0; j < DetectorImage.length; j++){
                                          // console.log(j+1)
                                          copyArrDetectorImage[j] = { ...copyArrDetectorImage[j], no : j+1};
                                          copyArrImgContents[j] = { ...copyArrImgContents[j], no : j+1};
                                      }
                                  }
                              }else if(BridgeType === "GirderSide"){
                                  if(DetectorImage.length === settings.span_count * settings.span_length/settings.span_class_length){
                                      setResultDetector(true);

                                      for(let j = 0; j < DetectorImage.length; j++){
                                      // console.log(j+1)
                                          copyArrDetectorImage[j] = { ...copyArrDetectorImage[j], no : j+1};
                                          copyArrImgContents[j] = { ...copyArrImgContents[j], no : j+1};
                                      }
                                  }
                              }else if(BridgeType === "Pier"){
                                  if(DetectorImage.length > 0){
                                      setResultDetector(true);

                                      for(let j = 0; j < DetectorImage.length; j++){
                                          copyArrDetectorImage[j] = { ...copyArrDetectorImage[j], no : j+1};
                                          copyArrImgContents[j] = { ...copyArrImgContents[j], no : j+1};
                                      }
                                  }
                              }
                              setImgListDetector(copyArrDetectorImage);
                              setData(copyArrImgContents.slice((page-1)*10, page*10));
                              setImgList(copyArrImgContents);
                      })
                  }
              }
          }

          if(BridgeType === "Pier"){
              for(let i = 0; i < settings.pier_count; i++){
                  axios({
                      method: 'get',
                      url: API_URL+'/File/Files',
                      headers: {"accept":`application/json`, "access-token": `${token}`},
                      params: {
                          path: `/project/${projectId}/stage6_crack/${BridgeType}/P${settings.pier_number_list[i].num.length === 2 ? settings.pier_number_list[i].num : "0"+settings.pier_number_list[i].num}`
                      }
                  }).then((res) => {
                    console.log(res)
                      for(let y = 0; y < res.data.data.files.length; y++){
                          if(res.data.data.files[y].includes(String(".png"))||res.data.data.files[y].includes(String(".jpg"))){
                              // console.log(res.data.data.files[y])
                            //   const indexFront = res.data.data.files[y].indexOf("_C")
                            //   const indexBack = res.data.data.files[y].indexOf(".png")
                            //   const SpanNum = res.data.data.files[y].substring(indexFront-8, indexBack)
                            const indexFront = res.data.data.files[y].indexOf("S") 
                                  const indexBack = res.data.data.files[y].indexOf(".png") 
                                  const SpanNum = res.data.data.files[y].substring(indexFront-4, indexBack)

                              // console.log(SpanNum)
                    
                              DetectorImage.push({
                                  no : DetectorImage.length,
                                  detector_image : `${IMAGE_URL}/image?path=/project/${projectId}/stage6_crack/${BridgeType}/P${settings.pier_number_list[i].num.length === 2 ? settings.pier_number_list[i].num : "0"+settings.pier_number_list[i].num}/${res.data.data.files[y]}&width=1440`,
                                  sort : SpanNum
                              })

                              ImgContents.push({
                                  no : ImgContents.length,
                                  name : <a style ={{color:"black"}}>{res.data.data.files[y]}</a>,
                                  sort : SpanNum
                              })
                          }

                          DetectorImage.sort((obj1, obj2) => {
                              if(obj1.sort > obj2.sort){
                                  return 1;
                              }
                              if(obj1.sort < obj2.sort){
                                  return -1;
                              }
                              return 0;
                          })

                          ImgContents.sort((obj1, obj2) => {
                              if (obj1.sort > obj2.sort) {
                                  return 1;
                              }
                              if (obj1.sort < obj2.sort) {
                                  return -1;
                              }
                              return 0;
                          })

                          let copyArrDetectorImage = [...DetectorImage];
                          let copyArrImgContents = [...ImgContents];

                           if(DetectorImage.length > 0){
                              setResultDetector(true);

                              for(let j = 0; j < DetectorImage.length; j++){
                                  copyArrDetectorImage[j] = {...copyArrDetectorImage[j], no : j+1};
                                  copyArrImgContents[j] = { ...copyArrImgContents[j], no : j+1};
                              }
                          }
                          setImgListDetector(copyArrDetectorImage);
                          setData(copyArrImgContents.slice((page-1)*10, page*10));
                          setImgList(copyArrImgContents);
                      }
                  })
              }
          }

              axios({
                  method: 'get',
                  url : API_URL+'/File/Files',
                  headers : { "Contents-Type" : "application/json", "access-token": `${token}`},
                  params: {
                      path : `/project/${projectId}/stage7_crack/${BridgeType}`
                  }
              }).then((res)=>{
                  for(let y = 0; y < res.data.data.files.length; y++){
                      if(res.data.data.files[y].includes(String(".png"))){
                          const indexFront = res.data.data.files[y].indexOf("_M")
                          const indexBack = res.data.data.files[y].indexOf(".png")
                          // const SpanNum = res.data.data.files[y].substring(indexFront-8, indexBack-7)
                          const SpanNum = res.data.data.files[y].substring(BridgeType === "Pier" ? indexFront-8 
                                                                                                  : indexFront-5, indexBack-7)

                          // console.log(SpanNum)

                          CrackMeasureResultImage.push({
                              result_image : `${IMAGE_URL}/image?path=/project/${projectId}/stage7_crack/${BridgeType}/${res.data.data.files[y]}&width=1440`,
                              no : CrackMeasureResultImage.length,
                              sort : SpanNum
                          })

                          CrackMeasureResultImage.sort((obj1, obj2) => {
                              if(obj1.sort > obj2.sort){
                                  return 1;
                              }
                              if(obj1.sort < obj2.sort){
                                  return -1;
                              }
                              return 0;
                          })
                      }
                  }
                  console.log(CrackMeasureResultImage)

                  let copyArrCrackResultImage = [...CrackMeasureResultImage];
                  if(BridgeType === "Girder"){
                      if(CrackMeasureResultImage.length === settings.girder_count * settings.span_length/settings.span_class_length * settings.span_count * settings.girder_camera_count){
                          setResultCrack(true);
                          for(let j = 0; j < CrackMeasureResultImage.length; j++){
                              copyArrCrackResultImage[j] = { ...copyArrCrackResultImage[j], no : j+1};
                          }
                      }
                  }else if(BridgeType === "Slab"){
                    console.log(CrackMeasureResultImage)
                      if(CrackMeasureResultImage.length > 0){
                          setResultCrack(true);
                          for(let j = 0; j < CrackMeasureResultImage.length; j++){
                              copyArrCrackResultImage[j] = { ...copyArrCrackResultImage[j], no : j+1};
                          }
                      }
                  }else if(BridgeType === "GirderSide"){
                    console.log(CrackMeasureResultImage.length, settings.span_count * settings.span_length/settings.span_class_length)
                      if(CrackMeasureResultImage.length === settings.span_count * settings.span_length/settings.span_class_length){
                          setResultCrack(true);
                          for(let j = 0; j < CrackMeasureResultImage.length; j++){
                              copyArrCrackResultImage[j] = { ...copyArrCrackResultImage[j], no : j+1};
                          }
                      }
                  }else if(BridgeType === "Pier"){
                    console.log(CrackMeasureResultImage.length)
                      if(CrackMeasureResultImage.length > 0){
                          setResultCrack(true);
                          for(let j = 0; j < CrackMeasureResultImage.length; j++){
                              copyArrCrackResultImage[j] = { ...copyArrCrackResultImage[j], no : j+1};
                          }
                      }
                  }
                  setImgListCrackMeasure(copyArrCrackResultImage)
              })
      }).catch((err) => {
          console.log(err);
      })

  }, [BridgeType])

    const option_render=()=>{
      const arr: any[] = [];
      typeArr.map((type:any)=>{
          let name=''
          if (type === 'Girder') {
              name = '거더 하면'
          } else if(type==='GirderSide'){
                  name='거더 측면'
          }else if(type==='Slab'){
              name='슬라브 하면'
          } else if (type === 'Slab_Side') {
              name = '슬라브 측면'
          } else if (type === 'Pier') {
              name ='교각'
          } else if (type === 'Abutment') {
              name = '교대'
          }
              arr.push(<Option value={type}> {name}</Option>)
          })
        return arr;
      }

      const onChangeType = (e:any) => {
        setIsSelect(true)
        if (e === 'Girder') {
            setBridgeType("Girder")
        } else if (e === 'GirderSide'){
            setBridgeType('GirderSide')
        } else if (e === 'Slab') {
            setBridgeType("Slab")
        } else if (e === 'Slab_Side') {
            setBridgeType("Slab_Side")
        } else if (e === 'Pier') {
            setBridgeType("Pier")
        } else if (e ==='Abutment'){
            setBridgeType("Abutment")
        }
      }

      const downloadCouple = () => {

        alert("30초 정도 기다리시면 다운로드 됩니다.")

        const indexFrontOri = ImgListDetector[num].detector_image.indexOf("/stage")
        const indexBackOri= ImgListDetector[num].detector_image.indexOf("&width")   
        const ImageUrlCutWidthOri = ImgListDetector[num].detector_image.substring(indexFrontOri+1, indexBackOri)

        console.log("원본 경로 : ", ImageUrlCutWidthOri)

        const indexFrontCrackName = ImgListCrackMeasure[num].result_image.indexOf("/stage")
        const indexBackCrackName= ImgListCrackMeasure[num].result_image.indexOf("&width")   
        const ImageUrlCutWidthCrackName = ImgListCrackMeasure[num].result_image.substring(BridgeType === "Girder" ? indexFrontCrackName+21
                                                                                                            : BridgeType === "Pier" ? indexFrontCrackName+19
                                                                                                            : BridgeType === "Slab" ? indexFrontCrackName+19
                                                                                                            : indexFrontCrackName+25, indexBackCrackName)

        console.log("균열 커플 이미지 이름 : ", ImageUrlCutWidthCrackName)

        const indexFrontCrack = ImgListCrackMeasure[num].result_image.indexOf("/stage")
        const indexBackCrack= ImgListCrackMeasure[num].result_image.indexOf("&width")   
        const ImageUrlCutWidthCrack = ImgListCrackMeasure[num].result_image.substring(indexFrontCrack+1, indexBackCrack)

        console.log("균열 경로 : ", ImageUrlCutWidthCrack)

        axios({
            method: "post",
            url: API_URL +`/scheduler/job/start/${companyid}/${userid}`,
            headers: {
              "accept": `application/json`,
              "access-token": `${token}`,
              "Content-Type": `application/json`
            },
                  data: {
                projectId: projectId,
                task_name: "image_join",
                interactive: false,
                tasks: [{
                    image1_path: ImageUrlCutWidthOri,
                    image2_path: ImageUrlCutWidthCrack,
                    align: 0, //수평 //수직으로 하려면 0
                    output_folder: 'stage7_Couple',
                    output_name: ImageUrlCutWidthCrackName
                }]
                }
        }).then((res) => {
            console.log(res.data.check)
            if (res.data.check === true) {
                job_id = res.data.data.job_id
                        /////20초마다 alert로 알려줌////////////
                resultCouple = setInterval(confirm, 10000)
            } else {
                console.log("실패")
            }
        })
    }
    async function confirm(){        
      axios({
          method: "post",
          url: API_URL + '/scheduler/job/query',
          headers: { "accept": `application/json`, "access-token": `${token}`, "Content-Type" : `application/json` },
              data: {
                  "job_id": job_id ,
                  "company_id": companyid
              }
                }).then(async (res) => {
              console.log(res)
              if (res.data.check === true) {
                  if (res.data.data.status === "done") {
                      console.log("성공")
                      setTimeout(()=>
                          download()
                      ,10000)                    
                      clearInterval(resultCouple)
                  } else if (res.data.data.status === "progress") {
                  } else if(res.data.data.status === "error"){
                      alert("해당 파일이 없습니다.")
                  }
              } else {
                  console.log("실패")
              }
          })
      
  }

  async function download(){
      console.log("지금! 다운로드 해!")

      const indexFrontCrackName = ImgListCrackMeasure[num].result_image.indexOf("/stage")
      const indexBackCrackName= ImgListCrackMeasure[num].result_image.indexOf("&width")   
      const ImageUrlCutWidthCrackName = ImgListCrackMeasure[num].result_image.substring(BridgeType === "Girder" ? indexFrontCrackName+21
                                                                                                          : BridgeType === "Pier" ? indexFrontCrackName+19
                                                                                                          : BridgeType === "Slab" ? indexFrontCrackName+19
                                                                                                          : indexFrontCrackName+25, indexBackCrackName)

      console.log("균열 커플 이미지 이름 : ", ImageUrlCutWidthCrackName)

      const linkCrack = document.createElement('a');
      let srcCrack = `${IMAGE_URL}/image?path=/project/${projectId}/stage7_Couple/${ImageUrlCutWidthCrackName}`;
      const imageBlobCrack = await (await fetch(srcCrack)).blob();
      srcCrack = URL.createObjectURL(imageBlobCrack);
      linkCrack.href = srcCrack;
      linkCrack.download = ImageUrlCutWidthCrackName
      linkCrack.click();
  }
  
      const rendering = (i:any) => {
          const result = [];
          result.push(
            BridgeType !== "Pier" && BridgeType !== "Slab"? 
            <div>
                <div><img src={resultDetector === true ? ImgListDetector[num]?.detector_image : ""} className={'imagetask'} style={{width: '60rem', height: '15rem', marginTop : '1.25rem', marginRight: '3rem', flexShrink: 0, cursor: 'pointer', borderRadius: '0.625rem'}}/></div>
                <div><img src={resultCrack === true ? ImgListCrackMeasure[num]?.result_image:""} className={'imagetask'} style={{width: '60rem', height: '15rem', marginTop : '1.25rem', marginRight: '2rem', flexShrink: 0, cursor: 'pointer', borderRadius: '0.625rem'}}/></div>
            </div>
            :
            <div style={{display:'flex'}}>
                <div><img src={resultDetector === true ? ImgListDetector[num]?.detector_image : ""} className={'imagetask'} style={{width: '20rem', height: '35rem', marginTop : '1.25rem', marginRight: '3rem', flexShrink: 0, cursor: 'pointer', borderRadius: '0.625rem'}}/></div>
                <div><img src={resultCrack === true ? ImgListCrackMeasure[num]?.result_image : ""} className={'imagetask'} style={{width: '20rem', height: '35rem', marginTop : '1.25rem', marginRight: '2rem', flexShrink: 0, cursor: 'pointer', borderRadius: '0.625rem'}}/></div>
            </div>
        );
          return result;
      };

      const onClickPanor = (i:any) => {
        setNum(i);
    };


    const showModal = (e:any) => {
        setIsModalVisible(true);
        setChoiceIndex(e.target.id);
        setChoiceImg(e.target.src);
    };

    const StyledTableCell = styled(TableCell)(({ theme }) => ({
        [`&.${tableCellClasses.head}`]: {
          fontSize: '1rem',
          fontWeight: 600,
          backgroundColor: '#F5F5F5',
          color: '#3A4557',
          width: '42.8125rem',
          height: '2.5rem',
          border: '1px solid #DDD',
          borderTop: '0.15rem solid #3A4557',
          padding : 0
        },
        [`&.${tableCellClasses.body}`]: {
          color: '#3A4557',
          textAlign: 'center',
          fontSize: '0.8125rem',
          width: '42.8125rem',
          height: '3rem',
          border: '1px solid #DDD',
          padding : 0,
        },
      }));
      
    const StyledTableRow = styled(TableRow)(({ theme }) => ({
        // '&:nth-of-type(odd)': {
        //   backgroundColor: theme.palette.action.hover,
        // },
        // hide last border
        '&:last-child td': {
        },

      }));

      const [isSelect, setIsSelect] = useState<boolean>(false)

      const  modelSelect :any[]= [];
      const selectModel = () => {
          modelSelect.push(
              <Select placeholder="선택해주세요" style={{ width : '8rem'}} onChange={onChangeType}>
                  {option_render()}    
              </Select>
          ) 
  
          return modelSelect;
      }

    
    return (
        <Layout>
            <div style={{ paddingTop : '2.01rem', paddingLeft : '2rem', color: '#999', fontSize: '0.75rem', fontFamily: 'inter-pretendard', fontStyle: 'normal', fontWeight: 400, lineHeight: '100%'}}>검출 & 측정 &gt; 균열 측정</div>
            <div style={{ paddingTop : '0.3rem', paddingLeft : '2rem', color: '#3A4557', fontSize: '1.5rem', fontFamily: 'inter-pretendard', fontStyle: 'normal', fontWeight: 700, lineHeight: '100%'}}>균열 측정</div>
            <div style={{ display : 'flex', marginLeft : '2rem'}}>
                <div style={{display:'flex'}}>
                    <div>
                      <div style={{ marginTop: '1rem'}}>
                          <Box sx={{
                              display: 'block',
                              width: '8rem',
                              height: '2rem',
                              borderRadius: '0.5rem',
                              background: '#FFF',
                              boxShadow: '2px 2px 20px 0px rgba(58, 69, 87, 0.05)',
                              padding : '1rem'
                          }}>
                              {selectModel()}
                          </Box>
                      </div>
                      <div>
                        {
                            resultCrack === true && ImgList.length !== 0 ?
                            <div style={{ display:'flex'}}>
                                <div> 
                                    <Box sx={{ width: '25rem', backgroundColor: 'white', borderRadius: '0.5rem', boxShadow: '2px 2px 20px 0px rgba(58, 69, 87, 0.05)', padding : '2rem', marginTop: '1rem'}}>
                                        <div style={{ display: 'flex', alignItems : 'center', justifyContent : 'space-between'}}>
                                            <div style={{ fontWeight : 700, borderLeft: '0.1875rem solid #0F6FB7', fontSize:'1rem', height:'1.2rem', paddingLeft: '0.62rem'}}>이미지 파일 리스트 </div>
                                        </div>
                                        <div>
                                            <TableContainer  sx={{ width: '23rem', marginLeft: '1rem', marginTop: '1rem', marginBottom: '1rem' }}>
                                                <Table sx={{ backgroundColor: '#fffff' }} aria-label="customized table">
                                                    <TableHead>
                                                    
                                                    <TableRow >
                                                        <StyledTableCell align="center" style={{width:'2rem', fontFamily:'inter-pretendard'}}>번호</StyledTableCell>
                                                        <StyledTableCell align="center" style={{width:'5rem', fontFamily:'inter-pretendard'}}>이름</StyledTableCell>
                                                    </TableRow>
                                                    </TableHead>
                                                    
                                                    <TableBody>
                                                    {data.length !== 0 ? data.map((row:any, idx:any) => (
                                                        <StyledTableRow 
                                                            key={row.original_image}
                                                            style={{ height: '2.5rem', cursor:'pointer'}}
                                                            onClick={() => onClickPanor(idx)}
                                                        >
                                                        {/* <StyledTableCell component="th" scope="row" align="center">
                                                            {row.num}
                                                        </StyledTableCell> */}
                                                        <StyledTableCell align="center" style={{width:'2em', height: '2.5rem', fontFamily:'inter-pretendard'}}>{(page-1)*10+(idx+1)}</StyledTableCell>
                                                        {/* <StyledTableCell align="center" style={{width:'5rem', height: '2.5rem', fontFamily:'inter-pretendard'}}>{data[idx]?.original_image.split('stage4/Overflow/')[1].split('&width')[0]}</StyledTableCell> */}
                                                        <StyledTableCell align="center" style={{width:'5rem', height: '2.5rem', fontFamily:'inter-pretendard'}}>{row.name}</StyledTableCell>
                                                        </StyledTableRow> 
                                                    )):'안됨'}
                                                    </TableBody>
                                                </Table>
                                                <TableFooter>
                                            <TableRow style={{ width: '23rem', display : 'flex', justifyContent: 'center', marginTop:'4rem'}}>
                                            <Pagination count={ImgList.length > 10 && ImgList.length % 10 === 0 ? Math.floor(ImgList.length/10) : Math.floor(ImgList.length/10)+1} defaultPage={1} onChange={(e) => handlePage(e)} color="primary" 
                                                sx={{
                                                '& svg': {
                                                    pointerEvents: 'none'
                                                }
                                                }}
                                            />
                                            </TableRow>
                                            </TableFooter>
                                            </TableContainer>
                                            
                                        </div>
                                    </Box> 
                                </div>
                                <div>
                                  <Box sx={BridgeType !== "Pier" && BridgeType !== "Slab" ?{ width: '66rem', marginTop: '1rem', flexShrink: 0, borderRadius: '0.5rem', background: '#FFF', boxShadow: '2px 2px 20px 0px rgba(58, 69, 87, 0.05)', marginLeft : '2rem', marginBottom: '1rem'}:{ width: '49.5rem', marginTop: '1rem', flexShrink: 0, borderRadius: '0.5rem', background: '#FFF', boxShadow: '2px 2px 20px 0px rgba(58, 69, 87, 0.05)', marginLeft : '2rem', marginBottom: '1rem'}}>
                                      <div style={{padding : '3rem'}}>
                                      <div>
                                          <div style={{ display: 'flex', justifyContent : 'space-between'}}>
                                              <div style={{ fontWeight : 700, borderLeft: '0.1875rem solid #0F6FB7', fontSize:'1rem', height:'1.2rem', paddingLeft: '0.62rem', fontFamily:'inter-pretendard'}}>{ImgList[num]?.name}</div>
                                              <div>
                                                  <Button variant="outlined" sx={{ marginRight : '0.5rem', fontWeight : 600, fontFamily: 'inter-pretendard' }} onClick={downloadCouple}>커플 이미지 다운로드</Button>
                                                  {/* <Button variant="contained" sx={{ fontWeight : 600 }} onClick={openWindow}>균열 결과 수정</Button> */}
                                              </div>
                                          </div>
                                          <div>
                                              {rendering(num)}
                                          </div>
                                      </div>
                                      </div>
                                  </Box>
                              </div>
                            </div>
                            : isSelect ?
                            <Box sx={{
                                width: '11.5rem',
                                height: '1.5rem',
                                borderRadius: '0.5rem',
                                background: '#FFF',
                                boxShadow: '2px 2px 20px 0px rgba(58, 69, 87, 0.05)',
                                padding : '0.75rem', 
                                marginTop : '1rem'
                                
                            }}>
                                <div>균열 측정을 진행해주세요.</div>
                            </Box>
                            : <Box sx={{
                                width: '11.5rem',
                                height: '1.5rem',
                                borderRadius: '0.5rem',
                                background: '#FFF',
                                boxShadow: '2px 2px 20px 0px rgba(58, 69, 87, 0.05)',
                                padding : '0.75rem',
                                marginTop : '1rem'
                            }}>
                                <div>구역을 먼저 선택해주세요.</div>
                            </Box>
                        
                        }
                        </div>
                        
                    </div>
                    <div>
                        {/* {
                           typeArr.length !== 0 && ImgList.length !== 0 ?
                            
                            : isSelect ? 
                            <></>
                            : <></>
                        
                        } */}
                    </div>
                    
                </div>
            
            </div>
        
        </Layout>
    );
};


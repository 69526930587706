import styles from '../../../../styles/Preprocess.module.css'
import styled from "styled-components";
import { Button, Form, Radio,Select, Input, Table} from 'antd';
import { useRecoilState, atom } from 'recoil';
import { useState } from 'react'
import axios from 'axios';
import { useEffect } from 'react';
import { API_URL } from '../../../../Store/Global';
// import { project_name, airport_name_kor, airport_name_eng, camera_count, overlap_rate } from "../../../../Store/State/projectAtom";

export default function AirportCreateSettingTab() {
    let token : string | null = localStorage.getItem("token") 
    const [projectName, setprojectNamee] = useState('');
    const [airportNameKor, setAirportNameKor] = useState('');
    const [airportNameEng, setAirportNameEng] = useState('');
    const [cameraCount, setCameraCount] = useState('');
    const [airstripWidth, setAirstripWidth] = useState('');
    const [airstripLength, setAirstripLength] = useState('');
    const [spanLength, setSpanLength] = useState('');
    const [spanCount, setSpanCount] = useState('');
    const [crackMaxWidth, setCrackMaxWidth] = useState('');
    const [cameraSensor, setCameraSensor] = useState('');
    const [cameraFocus, setCameraFocus] = useState('');
    const [droneAltitude, setDroneAltitude] = useState('');
    const [imageWidth, setImageWidth] = useState('');
    const [imageHeight, setImageHeight] = useState('');
    const [overlapRate, setOverlapRate] = useState('');

    const list: any[] = [projectName, airportNameKor, airportNameEng, cameraCount, overlapRate]


    return (
            <>
            <Form labelCol={{ span: 8, }} wrapperCol={{ span: 8 }} layout="horizontal">
            <Form.Item className={styles.FormItem}  label={<p style={{fontFamily: "inter-pretendard", fontSize:'2rem'}}>프로젝트 명</p>}>
                                <Input value={projectName} />
                            </Form.Item>
                            <Form.Item className={styles.FormItem} label={<p style={{fontFamily: "inter-pretendard", fontSize:'2rem'}}>공항 이름(한)</p>}>
                                <Input value={airportNameKor} />
                            </Form.Item>
                            <Form.Item className={styles.FormItem} label={<p style={{fontFamily: "inter-pretendard", fontSize:'2rem'}}>공항 이름(영)</p>}>
                                <Input value={airportNameEng} />
                            </Form.Item>
                            <Form.Item className={styles.FormItem} label={<p style={{fontFamily: "inter-pretendard", fontSize:'2rem'}}>카메라 수</p>}>
                                <Input value={cameraCount} suffix="개"  />
                            </Form.Item>
                            <Form.Item className={styles.FormItem} label={<p style={{fontFamily: "inter-pretendard", fontSize:'2rem'}}>활주로 너비</p>}>
                                <Input value={airstripWidth} suffix="m"  />
                            </Form.Item>
                            <Form.Item className={styles.FormItem} label={<p style={{fontFamily: "inter-pretendard", fontSize:'2rem'}}>활주로 길이</p>}>
                                <Input value={airstripLength} suffix="m"  />
                            </Form.Item>
                            <Form.Item className={styles.FormItem} label={<p style={{fontFamily: "inter-pretendard", fontSize:'2rem'}}>스팬 길이</p>}>
                                <Input value={spanLength} suffix="m"  />
                            </Form.Item>
                            <Form.Item className={styles.FormItem} label={<p style={{fontFamily: "inter-pretendard", fontSize:'2rem'}}>스팬 수</p>}>
                                <Input value={spanCount} suffix="개"  />
                            </Form.Item>
                            <Form.Item className={styles.FormItem} label={<p style={{fontFamily: "inter-pretendard", fontSize:'2rem'}}>실제 균열 최대 폭</p>}>
                                <Input value={crackMaxWidth} suffix="m"  />                         
                            </Form.Item>
                            <Form.Item className={styles.FormItem} label={<p style={{fontFamily: "inter-pretendard", fontSize:'2rem'}}>카메라 센서 크기</p>}>
                                <Input value={cameraSensor} suffix="m"  />  
                            </Form.Item>
                            <Form.Item className={styles.FormItem} label={<p style={{fontFamily: "inter-pretendard", fontSize:'2rem'}}>카메라 초점 거리</p>}>
                               <Input value={cameraFocus} suffix="m"  />  
                            </Form.Item>
                            <Form.Item className={styles.FormItem} label={<p style={{fontFamily: "inter-pretendard", fontSize:'2rem'}}>드론 촬영 고도</p>}>
                               <Input value={droneAltitude} suffix="m"  />  
                            </Form.Item>
                            <Form.Item className={styles.FormItem} label={<p style={{fontFamily: "inter-pretendard", fontSize:'2rem'}}>이미지 너비</p>}>
                               <Input value={imageWidth} suffix="m"  />  
                            </Form.Item>
                            <Form.Item className={styles.FormItem} label={<p style={{fontFamily: "inter-pretendard", fontSize:'2rem'}}>이미지 높이</p>}>
                               <Input value={imageHeight} suffix="m"  />  
                            </Form.Item>
                            <Form.Item className={styles.FormItem} label={<p style={{fontFamily: "inter-pretendard", fontSize:'2rem'}}>촬영시 겹침률</p>}>
                               <Input value={overlapRate} suffix="m"  />  
                            </Form.Item>

                            
                            
            </Form>
            </>


    )
}

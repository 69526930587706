import React, { useState, useEffect, useCallback } from 'react';
import styles from "../../../styles/Panorama.module.css";
import { Layout } from '../../../../allcomponents';
import { useNavigate } from 'react-router-dom';
import axios from "axios";
import { API_URL, IMAGE_URL } from '../../../../Store/Global';

import Box from '@mui/material/Box';
// import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableFooter from '@mui/material/TableFooter';
// import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import Typography from '@mui/material/Typography';
import Pagination from '@mui/material/Pagination';
import Button from '@mui/material/Button';
import {Image, Slider } from "antd";

const CosSlider = styled(Slider)`
    
    .ant-slider-tooltip .ant-tooltip-inner {
        none
    }

    .ant-slider-handle {
        position: absolute;
        width: 0.5px;
        height: 250px;
        margin-top: -250px;       
        background-color: #fff;
        border-left: solid 0.5px #230;
        opacity: 1;
        border-radius: 10%;
        box-shadow: 0;
        cursor: pointer;
        transition: border-color 0.3s, box-shadow 0.6s, transform 0.3s cubic-bezier(0.18, 0.89, 0.32, 1.28);
    }
    `;

const TopCosSlider = styled(Slider)`
    
    .ant-slider-tooltip .ant-tooltip-inner {
        none
    }

    .ant-slider-handle {
        position: absolute;
        width: 0.5px;
        height: 250px;
        margin-top: -250px;       
        background-color: #fff;
        border-left: solid 0.5px #230;
        opacity: 1;
        border-radius: 10%;
        box-shadow: 0;
        cursor: pointer;
        transition: border-color 0.3s, box-shadow 0.6s, transform 0.3s cubic-bezier(0.18, 0.89, 0.32, 1.28);
    }
    `;

const BotCosSlider = styled(Slider)`
    
    .ant-slider-tooltip .ant-tooltip-inner {
        none
    }

    .ant-slider-handle {
        position: absolute;
        width: 0.5px;
        height: 310px;
        margin-top: -310px;       
        background-color: #fff;
        border-left: solid 0.5px #230;
        opacity: 1;
        border-radius: 10%;
        box-shadow: 0;
        cursor: pointer;
        transition: border-color 0.3s, box-shadow 0.6s, transform 0.3s cubic-bezier(0.18, 0.89, 0.32, 1.28);
    }
    `;

type Order = 'asc' | 'desc';

interface ImageList {
  result_image: any;
  cam: any;
  span: any;
  name: any;
  lean: any;
  left: any;
  right: any;
  alllist: any;
}

interface Result {
  key: any;
  name: any;
  lean: any;
  left: any;
  right: any;
}

interface CutList {
  name: any;
  lean: any;
  left: any;
  right: any;
  resize: any;
}

interface PanoramaImage {
  no: any;
  result_image: string;
}

  interface TabPanelProps {
    children?: React.ReactNode;
    index: number;
    value: number;
  }
  
  function TabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props;
  
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box sx={{ p: 3 }}>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    );
  }
  
  function a11yProps(index: number) {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
    };
  }


  
  let spanCount = 1;
export default function TunnelPanoramaImageEditPage(props:any) {
    const projectType = localStorage.getItem('projectType');
    const curId = window.location.href.split('/')[6];
    let token: string | null = localStorage.getItem("token");
    let projectId: string | null = localStorage.getItem("projectId");

    const navigate = useNavigate();
    const [page, setPage] = React.useState(1);
    const [data, setData] = useState<any[][]>([]);

    

    const [cam, setCam] = useState("1");
    const [span, setSpan] = useState("1");
    const [alllist, setAlllist] = useState("1");
    const [arr, setArr] = useState<any>([]);

    const [num, setNum] = useState(0);
    const [list, setList] = useState(0);
    const ImageList: ImageList[] = [];
    const PanoramaImage: PanoramaImage[] = [];

    const CutList: CutList[] = [];
    const Result: Result[] = [];

    const [imgList, setImgList] = useState<any | undefined>(ImageList);
    const [CutImgList, setCutImgList] = useState<any | undefined>(CutList);
    const [resultImage, setResultImage] = useState<any | undefined>(Result);

    const [panormamImgList, setPanormamImgList] = useState<any[]>(PanoramaImage);

    const [panorama, setPanorama] = useState<boolean>(false);
    const [listName, setListName] = useState("");
    const [allList, setAlllest] = useState("");
    const [veiwList, setViewList] = useState<Number>(1);

    const [tab, satTab] = useState(0);
    const [lean, setLean] = useState(0);
    const [urll, setUrll] = useState([]);
    const [urlll, setUrlll] = useState("0");
    const [direction, setDirection] = useState("");
    const [directionn, setDirectionn] = useState(direction);
    // const[dirvlaue, setDirvlaue] = useState(direction);
    const [cNameEn, setEnName] = useState("");
    const [panoramaa, setPanoramaa] = useState<boolean>(false);
    const [cutClick, setCutClick] = useState<boolean>(false);
    const [sendData, setSendData] = useState<any>([])
    const [cameraNo, setCamraNo] = useState(1);

    const { onSelectAllClick, order, orderBy, numSelected, rowCount, onRequestSort } = props;

    const [value, setValue] = React.useState(0);
    const [value2, setValue2] = React.useState(0);

    const handleChange = (event: React.SyntheticEvent, newValue: number) => {
        setValue(newValue);
    };

    const handleChange2 = (event: React.SyntheticEvent, newValue: number) => {
      setValue2(newValue);
  };

    const [num1, setNum1] = useState<number>(0);
    const [num2, setNum2] = useState<number>(0);

    useEffect(() => {
      // console.log(imgList)
      let list = imgList.filter((Img: { span: any }) => Img.span === num1+1);
      console.log(list)
      setData(list.slice((page-1)*10, page*10))
    }, [imgList, page, num1]);
    
    const handlePage = (event:any) => {
      const nowPageInt = parseInt(event.target.outerText);
      console.log(event.target)
      
      if(event.target.outerText) {
        setPage(nowPageInt);
      }
      else {
        if(event.target.ariaLabel.includes("prev")) {
          setPage(page-1);
        }
        if(event.target.ariaLabel.includes("next")) {
          setPage(page+1);
        }
  
      }
      
    }

    const [userid, setUserId] = useState<number>(-1);
    const [companyId, setcompanyId] = useState<any>(localStorage.getItem("companyId"));

    useEffect(() => {
      let getIdCompany = async () => {
        if (token !== null) {
            console.log("프로젝트ID" + projectId);
          const response = await axios({
            method: "get",
            url: `${API_URL}/account/auth/check/${token}`,
          })
            .then(async (res) => {
              if (res.data.check === true) {
                setUserId(res.data.data.id)
                setcompanyId(res.data.data.companyId)
                // localStorage.set("projectId", id);
                console.log(
                  `아이디는 다음과 같음 : ${res.data.data.id} / 회사는 다음과 같음 : ${res.data.data.companyId}`
                );
                return {
                  email: res.data.data.email,
                  name: res.data.data.name,
                  phone: res.data.data.phone,
                  avatar: res.data.data.avatar,
                  role: res.data.data.role,
                  id: res.data.data.id,
                  companyId: res.data.data.companyId,
                };
              } else {
                console.log("토큰 만료");
                localStorage.removeItem("token");
                alert("토큰이 만료었습니다 다시 로그인 해주세요");
                window.location.replace("/Main");
              }
            })
            .catch((err) => {
              console.log(err);
            });
        }
      };
      getIdCompany();
    }, []);

    let Spandist: any[] = [];

    useEffect(() => {
      const response = axios({
        method: "get",
        url: API_URL + `/project/view/${projectId}`,
        headers: { "accept": `application/json`, "access-token": `${token}` },
      })
        .then((res) => {
          console.log(res)
          const settings: any = JSON.parse(res.data.data.settings);
          if (res.data.check === true) {
            for (let i = 0; i < 1; i++) {
              console.log(`터널이름${settings.tunnel_eng}`);
              setEnName(settings.tunnel_eng);
              setDirection(settings.direction);
              setCamraNo(settings.cameraCount);
            }
          }
        })
        .catch((err) => {});
    }, []);

    const [rrr, setRrr] = useState("");
    // useEffect(() => {
    //   axios({
    //     method: "get",
    //     url: API_URL + "/File/Files",                                                                                                                                                                                                                                                                                                                                                                   
    //     headers: { "accept": `application/json`, "access-token": `${token}` },
    //     params: {
    //       path: `/project/${projectId}/stage4/S003`,
    //     },
    //   })
    //     .then((res) => {
    //       if (res.data.check === true) {
    //         setRrr(
    //           `${IMAGE_URL}/image?path=/project/${projectId}/stage4/S001/${res.data.data.files[0]}&width=1240`
    //         );
    //       } else {
    //       }
    //     })
    //     .catch((err) => {
    //       console.log(err);
    //     });
      
    // }, []);

    useEffect(() => {
      // i = 0 => 데모 시연 i = 2
      for (let j = 1; j < spanCount + 1; j++) {
        axios({
          method: "get",
          url: API_URL + "/File/Files",
          headers: {
            "accept": `application/json`,
            "access-token": `${token}`,
          },
          params: {
            path: `/project/${projectId}/stage4/S00${j}`,
          },
        })
          .then((res) => {
            if (res.data.check === true) {
              for (let i = 0; i < res.data.data.files.length; i++) {
                // console.log("adsfasdfasdfasdfasdf", res.data.data.files.len);
                setPanoramaa(true)
                PanoramaImage.push({
                  result_image: `${IMAGE_URL}?path=/project/${projectId}/stage4/S00${j}/${res.data.data.files[i]}&width=1240`,
                  no: i,
                });
  
                setUrll(res.data.data.files[0]);
  
                // console.log(PanoramaImage[0].result_image)
                ImageList.push({
                  result_image: `${IMAGE_URL}?path=/project/${projectId}/stage4/S00${j}/${res.data.data.files[i]}&width=1240`,
                  cam: i + 1,
                  span: j,
                  name: res.data.data.files[i],
                  lean: "0",
                  left: "0",
                  right: "0",
                  alllist: (j - 1) * 4 + (i + 1),
                });
                CutList.push({
                  name: res.data.data.files[i],
                  lean: "0",
                  left: "0",
                  right: "0",
                  resize: "10",
                });
                Result.push({
                  key: j,
                  name: res.data.data.files[i],
                  lean: "",
                  left: "",
                  right: "",
                });
              }
              setListName(res.data.data.files[0]);
              setAlllest(res.data.data.files.length);
              if (PanoramaImage.length > 0) {
                setPanorama(true);
                // console.log("큰거야큰거",res.data.data.files[0])
              } else {
                alert("파노라마 작업이 진행 중입니다.");
              }
            } else {
            }
  
            ImageList.sort((obj1, obj2) => {
              if (obj1.span > obj2.span) {
                return 1;
              }
              if (obj1.span < obj2.span) {
                return -1;
              }
              return 0;
            });
            CutList.sort((obj1, obj2) => {
              if (obj1.name > obj2.name) {
                return 1;
              }
              if (obj1.name < obj2.name) {
                return -1;
              }
              return 0;
            });
            let Url = [...panormamImgList];
            Url.sort((obj1, obj2) => {
              if (obj1.no > obj2.no) {
                return 1;
              }
              if (obj1.no < obj2.no) {
                return -1;
              }
              return 0;
            });
            Url.sort((obj1, obj2) => {
              if (obj1.no > obj2.no) {
                return 1;
              }
              if (obj1.no < obj2.no) {
                return -1;
              }
              return 0;
            });
            Url.sort((obj1, obj2) => {
              if (obj1.result_image > obj2.result_image) {
                return 1;
              }
              if (obj1.result_imageo < obj2.result_image) {
                return -1;
              }
              return 0;
            });
  
            setUrlll(Url[0]?.result_image);
  
  
            let copyArrImgList = [...imgList];
            for (let i = 0; i < 2; i++) {
              copyArrImgList[i] = { ...copyArrImgList[i] };
              // console.log(ImgList[i].lean, "ImgListttttt")
            }
            setImgList(copyArrImgList);
  
            let cutArrImgList = [...CutImgList];
            cutArrImgList.sort((obj1, obj2) => {
              if (obj1.key > obj2.key) {
                return 1;
              }
              if (obj1.key < obj2.key) {
                return -1;
              }
              return 0;
            });
            for (let i = 0; i < 2; i++) {
              cutArrImgList[i] = { ...cutArrImgList[i] };
              // console.log(cutArrImgList[i].lean, "cutArrImgListitttttt");
            }
            setCutImgList(cutArrImgList);
  
            setPanormamImgList(PanoramaImage);
          })
          .catch((err) => {
            console.log(err);
          });
      }
    }, []);
    let tempList: any[] = [];
    let result_list: any[][] = [];
  
    // i = 0, i = 2
    for (let i = 2; i < spanCount; i++) {
      tempList = imgList.filter((Img: { span: any }) => Img.span === i + 1);
      result_list.push(tempList);
    }
   
  
    for (let i = 1; i < cameraNo; i++) {
      Spandist.push({
        input_folder: "stage4_edit",
      });
    }
  
    let copyArrImgList = [...imgList];
    let cutArrImgList = [...CutImgList];

    const ok = () => {
      alert("저장되었습니다.")
      console.log("기울기 :" + cutArrImgList[0].lean);
  
      copyArrImgList[Number(num - 1)] = {
        ...copyArrImgList[Number(num - 1)],
        lean: Number(leanval3),
      };
      setImgList(copyArrImgList);
  
      cutArrImgList[Number(num - 1)] = {
        ...cutArrImgList[Number(num - 1)],
        lean: Number(leanval3),
      };
      setCutImgList(cutArrImgList);
      console.log(Number(num));
    };
  
    const ok2 = () => {
      alert("저장되었습니다.")
        copyArrImgList[Number(num - 1)] = {
          ...copyArrImgList[Number(num - 1)],
          left: Number(cutval1),
          right: Number(cutval2),
        };
        setImgList(copyArrImgList);
        // console.log(copyArrImgList)
        // console.log(ImgList)
        cutArrImgList[Number(num - 1)] = {
          ...cutArrImgList[Number(num - 1)],
          left: Number(cutval1),
          right: Number(cutval2),
        };
        if(cutArrImgList.length > 0){
          setCutImgList(cutArrImgList);
  
        }
        console.log(cutClick)
  
  
    };
  
    useCallback(()=>{
      let sData : any[] = cutArrImgList.map((val1 : any, val2: any)=> [String(val1.name), String(val1.lean), String(val1.left), String(val1.right), String(val1.resize)])
      setSendData(sData);
    
      const cutData = [...sData]
      // console.log(sendData)
    },[sendData])
  
    
  
    const onClickPonirama = (e: any) => {
      
      alert("파노라마 이미지를 작업에 반영합니다.")
      // setTimeout(function() {
      //   navigate(`/project/panorama/imageconfirm/${projectId}`)
      // }, 1000);

      // console.log(arr);
      // console.log(Verstitch);
      axios({
        method: "post",
        url: API_URL +`/scheduler/job/start/${companyId}/${userid}`,
        headers: {
          "accept": `application/json`,
          "access-token": `${token}`,
          "Content-Type": `application/json`
        },
        data: {
          projectId: projectId,
          task_name: "create_csv",
          interactive: false,
          tasks: [
            {
              input_folder: "stage4/S003",
              output_folder: "stage4_csv",
              csv_name: "Template.csv",
              csv_header: ["ImageName", "Lean", "Left", "Right", "Resize"],
              csv_values:  sendData
      ,
            },
          ],
        },
      })
        .then((res) => {
          if (res.data.check === true) {
            console.log("파노라마 성공");
            axios({
              method: "post",
              url: API_URL +`/scheduler/job/start/${companyId}/${userid}`,
              headers: {
                "accept": `application/json`,
                "access-token": `${token}`,
                "Content-Type": `application/json`
              },
              data: {
                projectId: projectId,
                task_name: "cut_image",
                interactive: false,
                tasks: [
                  {
                    input_folder: "stage4_copy",
                    template_path: "stage4_csv/Template.csv",
                    output_folder: "stage4_edit"
            ,
                  },
                ],
              },
            })
              .then((res) => {
                if (res.data.check === true) {
                  console.log("cut이미지 성공");
                  
                  axios({
                    method: "post",
                    url: API_URL +`/scheduler/job/start/${companyId}/${userid}`,
                    headers: {
                      "accept": `application/json`,
                      "access-token": `${token}`,
                      "Content-Type": `application/json`
                    },
                    data: {
                      projectId: projectId,
                      task_name: "span_dist",
                      interactive: false,
                      tasks: [{
                        input_folder: "stage4_edit"
                      }],
                    },
                  })
                    .then((res) => {
                      if (res.data.check === true) {
                        console.log("스팬분배 성공");
                        alert("파노라마 작업이 모두 완료되었습니다.")
                        navigate(`/project/panorama/imageconfirm/${projectId}`)
                      } else {
                        console.log("스팬분배 실패");
                      }
                    })
        
        
        
                } else {
                  console.log("파노라마 실패");
                }
              })
  
  
  
          } else {
            console.log("파노라마 실패");
          }
        })
        .catch((err) => {
          console.log(err);
        });
    };

    const onChange = (key: any) => {
      console.log(key);
    };

    
  
    const [leanval1, setLeanVal1] = useState(0);
    const [leanval2, setLeanVal2] = useState(0);
    const [leanval3, setLeanVal3] = useState(0);
    const [cutval1, setCutVal1] = useState(0);
    const [cutval2, setCutVal2] = useState(0);
  
    const onChangeLean1 = (value: any) => {
      setLeanVal1(value);
      setLeanVal3(leanval1 - leanval2 - 1);
      // console.log("기울기"+leanval3)
    };
    const onChangeLean2 = (value: any) => {
      setLeanVal2(value);
      setLeanVal3(leanval1 - leanval2 - 1);
      // console.log("기울기"+leanval3)
    };
    const onChangeCut = (value: any) => {
      setCutVal1(Math.round((value[0])/14.4));
      setCutVal2(Math.round((1140-value[1])/14.4));
    };
  
  
  
    const [prevdisable, setPrevdisisable] = useState(true);
    const [nextdisable, setNextdisable] = useState(false);
  
    const nextClick = () => {
      if (num < Number(allList) + 1) {
        setNum(num + 1);
        satTab(tab + 1);
        console.log("김잉ㅇㅇㅇㅇㅇㅇㅇㅇ" + Number(allList), num);
        setNextdisable(false);
        setPrevdisisable(false);
      }
      if (num >= Number(allList) - 2) {
        setNextdisable(true);
        console.log(Number(allList) - 1, num);
        setPrevdisisable(false);
      }
    };
  
    const prevClick = () => {
      if (num <= Number(allList) - 1) {
        setNum(num - 1);
        satTab(tab - 1);
        console.log("감소" + num);
        setNextdisable(false);
        if (num <= 1) {
          setPrevdisisable(true);
          setNextdisable(false);
        }
      }
    };


  const [url, setUrl] = useState("0");

  const tabsPrint = () => {
    const spanResult:any[] = [];
    for (let i = 0; i < spanCount; i++) {
      spanResult.push((<StyledTab onClick={() => onClickTab(i)}
      // 데모
        // label={`Span ${i+1}`} {...a11yProps(i)} />))
        label={`Span 3`} {...a11yProps(i)} />))
    }
    
    return spanResult;
  }

  const onClickTab = (i:any) => {
    console.log(i)
    setPage(1);
    setNum1(i);
    setNum2(0);
    setPanoramaa(true);
    console.log("tab", i , "panor", num2)
    console.log(`${IMAGE_URL}/image?path=/project/${projectId}/stage4/S00${i+1}/${imgList[num2]}&width=1240`)
};

const onClickPanor = (row:any, i:any) => {
  console.log(i)
  console.log((page-1)*10+i)
  setNum2((page-1)*10+i);
  setCam(row.cam);
  setUrl(row.name);
  setSpan(row.span);
  setLean(leanval3);
  setPanoramaa(true);
  console.log("tab", num1 , "panor", (page-1)*10+i)
  console.log(imgList.filter((img: any) => {
    return img.span === 1;
  }))
  console.log(`${IMAGE_URL}/image?path=/project/${projectId}/stage4/S00${num1+1}/${imgList[num2]}&width=1240`)
};

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    fontSize: '1rem',
    fontWeight: 600,
    backgroundColor: '#F5F5F5',
    color: '#3A4557',
    width: '42.8125rem',
    height: '2.5rem',
    border: '1px solid #DDD',
    borderTop: '0.15rem solid #3A4557',
    padding : 0
  },
  [`&.${tableCellClasses.body}`]: {
    color: '#3A4557',
    textAlign: 'center',
    fontSize: '0.8125rem',
    width: '42.8125rem',
    height: '3rem',
    border: '1px solid #DDD',
    padding : 0,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  // '&:nth-of-type(odd)': {
  //   backgroundColor: theme.palette.action.hover,
  // },
  // hide last border
  '&:last-child td': {
  },

}));

const StyledTab = styled(Tab)({
  "&.Mui-selected": {
    color: "#0F6FB7",
    fontWeight : 700,
    background: 'rgba(15, 111, 183, 0.10)',
    borderRadius: '0.25rem',
    marginBottom : '0.25rem',
  },
  fontFamily: 'inter-pretendard',
  background: 'white',
  marginRight : '1rem',
  color : '#3A4557',
  fontSize: '0.875rem', 
  fontWeight:500,
});

const tabPrint = () => {
  const tabResult:any[] = [];
  for (let i = 0; i < spanCount; i++) {
    tabResult.push((
      <TabPanel value={value} index={i}>
                <div style={{ display:'flex'}}>
                    <div >
                        <Box sx={{ width: '34rem', backgroundColor: 'white', borderRadius: '0.5rem', boxShadow: '2px 2px 20px 0px rgba(58, 69, 87, 0.05)', padding : '2rem'}}>
                            <div style={{ display: 'flex', alignItems : 'center', justifyContent : 'space-between'}}>
                            <div style={{ fontWeight : 700, borderLeft: '0.1875rem solid #0F6FB7', paddingLeft: '0.62rem', fontSize:'1rem', height:'1.4rem', fontFamily : 'inter-pretendard'}}>이미지 파일 리스트 </div>
                            </div>
                            <div>
                                <TableContainer  sx={{ width: '34rem',  marginTop: '1rem' }}>
                                    <Table sx={{ backgroundColor: '#fffff' }} aria-label="customized table">
                                        <TableHead>
                                        
                                        <TableRow >
                                            <StyledTableCell align="center" style={{width:'2.5rem', fontFamily:'inter-pretendard'}}>번호</StyledTableCell>
                                            <StyledTableCell align="center" style={{width:'12rem', fontFamily:'inter-pretendard'}}>이름</StyledTableCell>
                                            <StyledTableCell align="center" style={{width:'2.5rem', fontFamily:'inter-pretendard'}}>Lean</StyledTableCell>
                                            <StyledTableCell align="center" style={{width:'2.5rem', fontFamily:'inter-pretendard'}}>Left</StyledTableCell>
                                            <StyledTableCell align="center" style={{width:'2.5rem', fontFamily:'inter-pretendard'}}>Right</StyledTableCell>
                                        </TableRow>
                                        </TableHead>
                                        
                                        <TableBody>
                                        {data?.map((row:any, idx:any) => (
                                            <StyledTableRow 
                                                key={row[idx]}
                                                style={{ height: '2.5rem', cursor:'pointer'}}
                                                onClick={() => onClickPanor(row, idx)}
                                            >
                                            <StyledTableCell align="center" style={{width:'2.5rem', height: '2.5rem', fontFamily:'inter-pretendard'}}>{row.cam}</StyledTableCell>
                                            <StyledTableCell align="center" style={{width:'12rem', height: '2.5rem', fontFamily:'inter-pretendard'}}>{row.name}</StyledTableCell>
                                            <StyledTableCell align="center" style={{width:'2.5rem', height: '2.5rem', fontFamily:'inter-pretendard'}}>{row.lean}</StyledTableCell>
                                            <StyledTableCell align="center" style={{width:'2.5rem', height: '2.5rem', fontFamily:'inter-pretendard'}}>{row.left}</StyledTableCell>
                                            <StyledTableCell align="center" style={{width:'2.5rem', height: '2.5rem', fontFamily:'inter-pretendard'}}>{row.right}</StyledTableCell>
                                            </StyledTableRow> 
                                        ))}
                                        </TableBody>
                                    </Table>
                                    <TableFooter>
                                    <TableRow style={{ width: '34rem', display : 'flex', justifyContent: 'center', marginTop:'4rem'}}>
                                    <Pagination count={result_list[i]?.length > 10 && result_list[i]?.length % 10 === 0 ? Math.floor(result_list[i]?.length/10) : Math.floor(result_list[i]?.length/10)+1} defaultPage={1} onChange={(e) => handlePage(e)} color="primary" 
                                        sx={{
                                          '& svg': {
                                            pointerEvents: 'none'
                                          }
                                        }}
                                      />
                                    </TableRow>
                                  </TableFooter>
                                </TableContainer>
                                
                            </div>
                        </Box> 
                        </div>
                        <div style={{ height : '45rem'}}>
                            <Box sx={{ width: '57rem', flexShrink: 0, borderRadius: '0.5rem', background: '#FFF', boxShadow: '2px 2px 20px 0px rgba(58, 69, 87, 0.05)', marginLeft : '2rem', marginBottom: '1rem'}}>
                                <div style={{padding : '2rem', paddingBottom :'1rem'}}>
                                <div style={{ display: 'flex', alignItems : 'center', justifyContent : 'space-between'}}>
                                  <div style={{ fontWeight : 700, borderLeft: '0.1875rem solid #0F6FB7', paddingLeft: '0.62rem', fontSize:'1rem', height:'1.4rem', fontFamily : 'inter-pretendard'}}>이미지 뷰어 </div>
                                  </div>
                                  <div>
                                  <div
                                      style={{ width: "1170px",marginLeft: "10px", marginTop:'1rem' }}
                                    >
                                      <Tabs value={value2} onChange={handleChange2}>
                                      <Tab style={{ fontFamily:'inter-pretendard'}} label="Lean" {...a11yProps(0)} />
                                      <Tab style={{ fontFamily:'inter-pretendard'}} label="Cut" {...a11yProps(1)} />
                                      </Tabs>
                                        <TabPanel value={value2} index={0}>
                                          <div
                                          >
                                            {/* `/project/${projectId}/stage4/S00${j}` */}
                                            <img
                                              src={
                                                panoramaa === true
                                                  ? `${IMAGE_URL}/image?path=/project/${projectId}/stage4/S00${num1+1}/${imgList.filter((img: any) => {
                                                    return img.span === num1+1;
                                                  })[num2]?.name}&width=1240`
                                                  : rrr
                                              }
                                              alt=""
                                              width={780}
                                              height={200}
                                              style={{ width:"780px", height:"200px",marginLeft:'0.3rem' }}
                                            ></img>
                                            <div>
                                              {/* <CosSlider range defaultValue={[0,1340]} disabled={disabled} min={0} max={1340}  onChange={onChange1}/> */}
                                              <a
                                                style={{
                                                  paddingLeft: "15px",
                                                  color: "black",
                                                  cursor: "default",
                                                  fontFamily:'inter-pretendard',
                                                  fontSize:'1rem'
                                                }}
                                              >
                                                위 :
                                              </a>{" "}
                                              <TopCosSlider
                                                style={{ width: "780px", marginTop: "10px" }}
                                                defaultValue={780}
                                                min={0}
                                                max={780}
                                                onChange={onChangeLean1}
                                              />{" "}
                                            </div>
                                            <div>
                                              <a
                                                style={{
                                                  paddingLeft: "15px",
                                                  color: "black",
                                                  cursor: "default",
                                                  fontFamily:'inter-pretendard',
                                                  fontSize:'1rem'
                                                }}
                                              >
                                                아래 :
                                              </a>{" "}
                                              <BotCosSlider
                                                style={{ width: "780px" }}
                                                defaultValue={0}
                                                min={0}
                                                max={780}
                                                onChange={onChangeLean2}
                                              />{" "}
                                            </div>

                                            <div className="sds"></div>
                                            <Button
                                              disabled={prevdisable}
                                              style={{
                                                width: "130px",
                                                height: "30px",
                                                marginLeft: "-45px",
                                                marginRight: "620px",
                                                fontFamily:'inter-pretendard'
                                              }}
                                              onClick={prevClick}
                                            >
                                              이전
                                            </Button>
                                            <Button
                                              disabled={nextdisable}
                                              style={{
                                                width: "130px",
                                                height: "30px",
                                                fontFamily:'inter-pretendard'
                                              }}
                                              onClick={nextClick}
                                            >
                                              다음
                                            </Button>
                                            <div style={{display:'flex'}}>
                                            <div>
                                            <Button
                                              style={{
                                                width: "150px",
                                                marginLeft: "465px",
                                                marginTop: "30px",
                                                fontFamily:'inter-pretendard'
                                              }}
                                              onClick={ok}
                                            >
                                              변경 사항 저장
                                            </Button>
                                            </div>
                                            <div>
                                            <Button
                                              style={{
                                                width: "150px",
                                                marginLeft: "20px",
                                                marginTop: "30px",
                                                backgroundColor : "rgba(15, 111, 183, 1)",
                                                color : '#fff',
                                                fontWeight: 600,
                                                fontFamily:'inter-pretendard'
                                              }}
                                              onClick={onClickPonirama}
                                            >
                                              실행
                                            </Button>
                                            </div>
                                            </div>
                                          </div>
                                        </TabPanel>
                                        <TabPanel value={value2} index={1}>
                                          <div
                                          >
                                            <img
                                              src={
                                                panoramaa === true
                                                ? `${IMAGE_URL}/image?path=/project/${projectId}/stage4/S00${num1+1}/${imgList.filter((img: any) => {
                                                  return img.span === num1+1;
                                                })[num2]?.name}&width=1240`
                                                  : rrr
                                              }
                                              alt=""
                                              width={780}
                                              height={200}
                                              style={{ width:"780px", height:"200px",marginLeft:'0.3rem' }}
                                            ></img>
                                            <div>
                                              <CosSlider
                                                style={{ width: "780px", marginTop: "30px" }}
                                                range
                                                defaultValue={[0, 1140]}
                                                min={0}
                                                max={780}
                                                onChange={onChangeCut}
                                              />{" "}
                                            </div>
                                            <Button
                                              disabled={prevdisable}
                                              style={{
                                                width: "130px",
                                                height: "30px",
                                                marginLeft: "-45px",
                                                marginRight: "620px",
                                                fontFamily:'inter-pretendard'
                                              }}
                                              onClick={prevClick}
                                            >
                                              이전
                                            </Button>
                                            <Button
                                              disabled={nextdisable}
                                              style={{
                                                width: "130px",
                                                height: "30px",
                                                fontFamily:'inter-pretendard'
                                              }}
                                              onClick={nextClick}
                                            >
                                              다음
                                            </Button>

                                            <Button
                                              style={{
                                                width: "150px",
                                                marginLeft: "635px",
                                                marginTop: "30px",
                                                fontFamily:'inter-pretendard',
                                                backgroundColor : "rgba(15, 111, 183, 1)",
                                                color : '#fff',
                                                fontWeight: 600,
                                              }}
                                              onClick={ok2}
                                            >
                                              변경 사항 저장
                                            </Button>
                                          </div>
                                        </TabPanel>
                                    </div>
              
                                  </div>
                                </div>
                                 
                            </Box>
                    </div>
                    

                    </div>
                        
                </TabPanel>
    
    ))
  }
  
  return tabResult;
}


    
    return (
        <Layout>
            <div style={{ paddingTop : '2.01rem', paddingLeft : '2rem', color: '#999', fontSize: '0.75rem', fontFamily: 'inter-pretendard', fontStyle: 'normal', fontWeight: 400, lineHeight: '100%'}}>파노라마 &gt; 이미지 편집</div>
            <div style={{ paddingTop : '0.3rem', paddingLeft : '2rem', color: '#3A4557', fontSize: '1.5rem', fontFamily: 'inter-pretendard', fontStyle: 'normal', fontWeight: 700, lineHeight: '100%'}}>이미지 편집</div>
            <div style={{ display : 'flex', marginLeft : '2rem'}}>
                <div>
                <Box sx={{ borderBottom: 1, borderColor: 'divider', marginTop: '1rem', marginLeft : '1.5rem', width: '97rem', height : '3rem' }}>
                    <Tabs value={value} onChange={handleChange} aria-label="basic tabs example" variant="scrollable" style={{ height: '2rem'}}>
                            {tabsPrint()}
                    </Tabs>
                </Box>
                {
                  tabPrint()
                }
                    

                </div>
            </div>
        </Layout>
    );
};


export default function FooterPrivacyPage() {

  return (
    <div>
      <div>
        <div>
            <p>개인정보 처리방침</p> <br/>
            <p>Deep Inspector은(는) 이용자들의 개인정보보호를 매우 중요시하며, 이용자가 회사의 서비스를
이용함과 동시에 온라인상에서 회사에 제공한 개인정보가 보호 받을 수 있도록 최선을 다하고
있습니다. 이에 Deep Inspector(주)는 통신비밀보호법, 전기통신사업법, 정보통신망 이용촉진 및 정보보호 등에
관한 법률 등 정보통신서비스제공자가 준수하여야 할 관련 법규상의 개인정보보호 규정 및
정보통신부가 제정한 개인정보보호지침을 준수하고 있습니다. Deep Inspector(주)는 개인정보 처리방침을 통하여
이용자들이 제공하는 개인정보가 어떠한 용도와 방식으로 이용되고 있으며 개인정보보호를 위해
어떠한 조치가 취해지고 있는지 알려 드립니다

Deep Inspector(주)는 개인정보 처리방침을 홈페이지 첫 화면에 공개함으로써 이용자들이 언제나 용이하게 보실
수 있도록 조치하고 있습니다.

회사의 개인정보 처리방침은 정부의 법률 및 지침 변경이나 회사의 내부 방침 변경 등으로 인하여
수시로 변경될 수 있고, 이에 따른 개인정보 처리방침의 지속적인 개선을 위하여 필요한 절차를
정하고 있습니다. 그리고 개인정보 처리방침을 개정하는 경우나 개인정보 처리방침 변경될 경우
쇼핑몰의 첫페이지의 개인정보처리방침을 통해 고지하고 있습니다. 이용자들께서는 사이트 방문 시
수시로 확인하시기 바랍니다.

Deep Inspector(주)의 개인정보 처리방침은 다음과 같은 내용을 담고 있습니다.

1. 개인정보 수집에 대한 동의
2. 개인정보의 수집목적 및 이용목적
3. 수집하는 개인정보 항목 및 수집방법
4. 수집하는 개인정보의 보유 및 이용기간
5. 수집한 개인정보의 공유 및 제공
6. 이용자 자신의 개인정보 관리(열람,정정,삭제 등)에 관한 사항
7. 쿠키(Cookie)의 운용 및 거부
8. 비회원 고객의 개인정보 관리
9. 개인정보의 위탁처리
10. 개인정보관련 의견수렴 및 불만처리에 관한 사항
11. 개인정보 관리책임자 및 담당자의 소속-성명 및 연락처
12. 아동의 개인정보보호
13. 고지의 의무


귀하의 개인정보는 다음과 같이 개인정보의 수집목적 또는 제공받은 목적이 달성되면 파기하는 것을
원칙으로 합니다. 그리고 상법, 전자상거래등에서의 소비자보호에 관한 법률 등 관계법령의 규정에 의하여
보존할 필요가 있는 경우 Deep Inspector(주)는 관계법령에서 정한 일정한 기간 동안 회원정보를 보관합니다.
이 경우 Deep Inspector(주)는 Deep Inspector(주)는 보관하는 정보를 그 보관의 목적으로만 이용하며 보존기간은 아래와 같습니다.

- 계약 또는 청약철회 등에 관한 기록 : 5년
- 대금결제 및 재화 등의 공급에 관한 기록 : 5년
- 소비자의 불만 또는 분쟁처리에 관한 기록 : 3년

Deep Inspector(주)는 귀중한 회원의 개인정보를 안전하게 처리하며, 유출의 방지를 위하여 다음과 같은 방법을
통하여 개인정보를 파기합니다.

- 종이에 출력된 개인정보는 분쇄기로 분쇄하거나 소각을 통하여 파기합니다.
- 전자적 파일 형태로 저장된 개인정보는 기록을 재생할 수 없는 기술적 방법을 사용하여 삭제합니다

- 다만, 아래의 경우에는 예외로 합니다.
= 이용자들이 사전에 동의한 경우
= 법령의 규정에 의거하거나, 수사 목적으로 법령에 정해진 절차와 방법에 따라 수사기관의
요구가 있는 경우

- 개인정보의 처리를 위탁하거나 제공하는 경우에는 수탁자, 수탁범위, 공유 정보의 범위 등에
관한 사항을 서면, 전자우편, 전화 또는 홈페이지를 통해 미리 귀하에게 고지합니다.

이용자 개인정보와 관련한 아이디(ID)의 비밀번호에 대한 보안유지책임은 해당 이용자 자신에게
있습니다. Deep Inspector(주)는 비밀번호에 대해 어떠한 방법으로도 이용자에게 직접적으로 질문하는 경우는
없으므로 타인에게 비밀번호가 유출되지 않도록 각별히 주의하시기 바랍니다.
특히 공공장소에서 온라인상에서 접속해 있을 경우에는 더욱 유의하셔야 합니다.

현 개인정보처리방침의 내용은 정부의 정책 또는 보안기술의 변경에 따라 내용의 추가 삭제 및
수정이 있을 시에는 홈페이지의 '공지사항'을 통해 고지할 것입니다.

개인정보처리방침 시행일자: 2023-12-29
</p> <br/>
            {/* 개인정보처리방침 변경일자: 0000-00-00 */}
          </div>
      </div>
    </div>
  )
}

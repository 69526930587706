import { Button, Form, Input, Radio, Select, Progress, } from "antd";
import styles from '../../../../styles/CrackDrawer_Estimator.module.css';
import { API_URL } from '../../../../Store/Global';
import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { useRecoilState, atom } from 'recoil';
import { dam_name_kor, dam_type } from "../../../../Store/State/projectAtom";
export default function DrawerSettingTab() {
    let token: string | null = localStorage.getItem("token");
    let job_id = 0;
    const projectId = localStorage.getItem("projectId");
    const [title, setTitle] = useRecoilState<any>(dam_name_kor);
    const [type, setType] = useRecoilState<any>(dam_type);
   
    const [userid, setUserId] = useState<number>(-1);
    const [companyid, setCompanyId] = useState<number>(-1);
  
    useEffect(() => {
        let getIdCompany = async () => {
          if (token !== null) {
            console.log("프로젝트ID" + projectId);
            const response = await axios({
              method: "get",
              url: `${API_URL}/account/auth/check/${token}`,
            })
              .then(async (res) => {
                if (res.data.check === true) {
                  setUserId(res.data.data.id)
                  setCompanyId(res.data.data.companyId)
                  // localStorage.set("projectId", id);
                  console.log(
                    `아이디는 다음과 같음 : ${res.data.data.id} / 회사는 다음과 같음 : ${res.data.data.companyId}`
                  );
                  return {
                    email: res.data.data.email,
                    name: res.data.data.name,
                    phone: res.data.data.phone,
                    avatar: res.data.data.avatar,
                    role: res.data.data.role,
                    id: res.data.data.id,
                    companyId: res.data.data.companyId,
                  };
                } else {
                  console.log("토큰 만료");
                  localStorage.removeItem("token");
                  alert("토큰이 만료었습니다 다시 로그인 해주세요");
                  window.location.replace("/Main");
                }
              })
              .catch((err) => {
                console.log(err);
              });
          }
        };
        getIdCompany();
      }, []);
    
    // form 설정 가져오기

    const handleChangeType = (e: any) => {
        setType(e.target.value)
    }
    const handleChangeName = (e: any) => {
      setTitle(e.target.value)
  }

    
    let result :any;

    
    return (
        
    <div>
      <Form labelCol={{ span: 8, }}  wrapperCol={{ span: 8, }} layout="horizontal" >
              <Form.Item label={<p style={{fontFamily: "inter-pretendard"}}>댐 타입</p>} className={styles.csFormItem} >
              <Select value={type}>
                {
                  type?.map((row:any, i:any) => (
                    <Select.Option value={i+1}>{row}</Select.Option>
                  ))
                }
                  </Select>
                  </Form.Item>
                  <Form.Item label={<p style={{fontFamily: "inter-pretendard"}}>댐 명</p>} className={styles.csFormItem}>
                    <Input value={title} className={styles.csFormInput}></Input>
                  </Form.Item>
                
              
            <Form.Item className={styles.progress}>
                {/* <Progress strokeColor={{ '0%': '#108ee9', '100%': '#87d068', }} percent={50} /> */}
                
            </Form.Item>
        </Form></div>
        //  style={{float: "right"}}
  )
}

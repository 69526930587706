import React from 'react';
import { Layout } from '../allcomponents';

function MainPage(props:any) {
    return (
        <Layout>
            <div className="main bgHighLight">
                메인 화면
            </div>
        </Layout>
    );
};

export default MainPage;
import React, { useState, useEffect } from 'react';
import { confirmAlert } from "react-confirm-alert";
import { useNavigate } from 'react-router-dom';
import "react-confirm-alert/src/react-confirm-alert.css";
import { Layout } from '../../allcomponents';
import { User } from '../../alltypes';
import { ReturnValue, ReturnValues } from '../../allmodels';
import { apiHelper } from '../../allutils';


function UserDetailPage(props:any) {
  const userid = document.location.href.split("user/update/")[1];
  const navigate = useNavigate();
  const [selectValue, setSelectValue] = useState("");

  const [isBind, setIsBind] = useState(false);
  const [data, setData] = useState<User>();

  const DataBind = () => {
    if (!isBind) {
      apiHelper.Get(`/account/member/view/${userid}`, {}, (rst: ReturnValues<User>) => {
        if (rst.check && rst.data !== null && rst.data !== undefined) {
            setData(rst.data);
            console.log(rst);
            setIsBind(true);
            if(selectValue == "") {
              setSelectValue(rst.data.role);
            }
            setConfirm(rst.data.isConfirm);
            // setEnabled(rst.data.isEnabled);
        }
    });
    }
  };

  useEffect(function() {
      DataBind();
  }, [isBind]);

  const project_data = [
    {
      "id" : 1,
      "name" : "OO 터널",
      "date" : "2023-00-00",
      "status" : "완료"
    },
    {
      "id" : 2,
      "name" : "OO 터널2",
      "date" : "2023-00-00",
      "status" : "완료"
    },
    {
      "id" : 3,
      "name" : "OO 터널3",
      "date" : "2023-00-00",
      "status" : "완료"
    }
  ]

  const ask_data = [
    {
      "id" : 1,
      "title" : "사용 관련 문의 사항이 있습니다.",
      "date" : "2023-00-00 00:00",
      "status" : "회신완료"
    },
    {
      "id" : 2,
      "title" : "사용 관련 문의 사항이 있습니다.22",
      "date" : "2023-00-00 00:00",
      "status" : "회신필요"
    }
  ]

  const [confirm, setConfirm] = useState<Boolean>(false);
  // const [enabled, setEnabled] = useState<Boolean>(false);

  const clickConfirm = () => {
    if(confirm) {
      setConfirm(false)
      // setEnabled(false)
    } else {
      setConfirm(true)
    }
  }

  // const clickEnabled = () => {
  //   if(confirm) {
  //     if(enabled) {
  //       setEnabled(false)
  //     } else {
  //       setEnabled(true)
  //     }
  //   }
  // }


  const OPTIONS = [
    { value: "user", name: "user" },
    { value: "admin", name: "admin" },
    { value: "manager", name: "manager" },
  ];

  const SelectBox = (props:any) => {
    return (
      <select className='grade-select'
        value={selectValue}
        onChange={(e) => { 
          setSelectValue(e.target.value);
        }}
      >
        {props.options.map((option:any) => (
          <option className='grade-option'
            value={option.value}
          >
          {option.name}
        </option>
          
        ))}
      </select>
    );
  };

  const [isModalOpen, setIsModalOpen] = useState(true);

  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleOk = () => {
    setIsModalOpen(false);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  // isConfirm === true 일 때 api 요청 보내고 요청 보낸 후 setIsConfirm(false) 해주기
  const [isConfirm, setIsConfirm] = useState(false); 

  const onCancelBtnClick = () => {
    // reload 안되면 window.history.go(0); 사용하기
    // span tag로 감싸면 red, bold 적용
    let contentData : JSX.Element = (
      <p>사용자 정보 수정을 <span>취소</span>하시겠습니까?</p>
    )
  }

  const onUpdateBtnClick = () => {
    // reload 안되면 window.history.go(0); 사용하기
    // span tag로 감싸면 red, bold 적용
    let contentData : JSX.Element = (
      <p>해당 사용자 정보를 <span>수정</span>하시겠습니까?</p>
    )

  }

  const onNoneChangeAlart = () => {
    let contentData : JSX.Element = (
      <p>변경된 내용이 없습니다.</p>
    )
    
  }

  const onChangeAlart = () => {
    let contentData : JSX.Element = (
      <p>사용자 정보 수정이 완료되었습니다.</p>
    )
    
  }

  const onErrorAlart = () => {
    let contentData : JSX.Element = (
      <p><span>오류로</span> 수정이 완료되지 않았습니다.</p>
    )
   
  }

  useEffect(function() {
    if(isConfirm) {
      update();
    }

}, [isConfirm]);


  const update = () => {
    console.log(isConfirm);
    let contentData2 : JSX.Element = (
      <p>사용자 정보 수정이 완료되었습니다.</p>
    )
    let contentData4 : JSX.Element = (
      <p><span>오류로</span> 수정이 완료되지 않았습니다.</p>
    )

    if (isConfirm) {
      if (data?.role !== selectValue || data.isConfirm !== confirm) {
        const postBody = {
          name : data?.name,
          phone: data?.phone,
          role: selectValue
        }
        if (data?.role !== selectValue && data?.isConfirm !== confirm) {
          apiHelper.Post(`/account/member/edit/${userid}`, postBody, (rst:ReturnValue) => {
            if (rst.check) {
                //수정에 성공하면 
                console.log(rst);
                console.log(postBody);
                apiHelper.Get(`/account/member/confirm/${userid}?confirm=${confirm ? 1 : 0}`, {}, (rst2: ReturnValues<User>) => {
                  if (rst2.check) {
                       //수정에 성공하면 
                       console.log(rst2);
                       console.log(rst2.data);
                       onChangeAlart();
                      navigate("/user/list");
                  } else {
                    console.log(rst2.message);
                    onErrorAlart();
                  }
              });
                
            } else {
              console.log(rst.message);
              onErrorAlart();
            }
        });
        }

        else if (data?.isConfirm !== confirm) {
          apiHelper.Get(`/account/member/confirm/${userid}?confirm=${confirm ? 1 : 0}`, {}, (rst: ReturnValues<User>) => {
            if (rst.check) {
                 //수정에 성공하면 
                 console.log(rst);
                 console.log(rst.data);
                 onChangeAlart();
                navigate("/user/list");
            } else {
              console.log(rst.message);
              onErrorAlart();
            }
        });
        }

        else if (data?.role !== selectValue) {
          apiHelper.Post(`/account/member/edit/${userid}`, postBody, (rst:ReturnValue) => {
            if (rst.check) {
                //수정에 성공하면 
                console.log(rst);
                console.log(postBody);
                onChangeAlart();
                window.history.go(-1);
                
            } else {
              console.log(rst.message);
              onErrorAlart();
            }
        });
      }
        
    
      }
      else onNoneChangeAlart();
    }
      
  }

    return (
        <Layout tabOff={false}>
            <div className="user-detail">
                {/* 사용자 상세 조회 화면 */}
                <div className="data-grid">
                  <div className="row">
                    <div className="col-12">
                        <div className="card">
                          <div className="card-body">
                              <div className = "detail-title">
                                사용자 정보 수정
                              </div>
                              <div className="back-btn">
                                <a onClick={onCancelBtnClick}>
                                    <span className="material-symbols-outlined">
                                        arrow_back
                                    </span>
                                    <span className="back-btn-text">
                                        이전으로
                                    </span>
                                </a>
                              </div>
                              <div className = "user-update-view">
                                <div className="profile-title opacity">
                                  <div className="account">
                                    <span className="material-symbols-outlined">
                                      account_circle
                                    </span>
                                    <div className="account-text">
                                      {data?.email}
                                    </div>
                                  </div>
                                  <div className="approval">
                                      <span className="approval-name">
                                        Approval Date
                                      </span>
                                      <span className="approval-data">
                                        {data?.createdAt.split("T")[0]}
                                      </span>
                                  </div>
                                </div>
                                <div className="section-line opacity"/>
                                <div className="profile-data">
                                  <div className="name-data opacity">
                                    Name <span>{data?.name}</span>
                                  </div>
                                  <div className="company-data opacity">
                                    Company <span>{data?.companyId ? data?.companyId:'없음'}</span>
                                  </div>
                                  <div className="phone-data opacity">
                                    Phone Number <span>{data?.phone}</span>
                                  </div>
                                  <div className="grade-data margin-up">
                                    Grade <SelectBox options={OPTIONS} defaultValue={data?.role}></SelectBox>
                                  </div>
                                  <div className="status-data margin-up">
                                    <div className="status-data-title">Status</div>
                                    <div className='btn-inline'>
                                      <div className={`confirm ${confirm ? 'active' : ''}`}  onClick={clickConfirm}>{confirm ? "승인" : "미승인"}</div>
                                      {/* <div className={`enabled ${enabled ? 'active' : ''}`} onClick={clickEnabled}>{enabled ? "허용" : "제한"}</div> */}
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className="used-data-box opacity">
                                  <div className="used-project">
                                    <div className="used-title">
                                      Project
                                    </div>
                                    <div className="used-data">
                                      {project_data.map((project, index) => {
                                        return (
                                          <div className="data-row">
                                            <div>{index}</div>
                                            <div className="row-large">{project.name}</div>
                                            <div>{project.date}</div>
                                            <div>{project.status}</div>
                                          </div>
                                        );
                                      }) }
                                    </div>
                                  </div>
                                  <div className="used-ask">
                                    <div className="used-title">
                                      Ask
                                    </div>
                                    <div className="used-data">
                                    {ask_data.map((ask, index) => {
                                        return (
                                          <div className="data-row">
                                            <div className="row-large">{ask.title}</div>
                                            <div>{ask.date}</div>
                                            <div>{ask.status}</div>
                                          </div>
                                        );
                                      }) }
                                    </div>
                                  </div>
                              </div>
                              <div className="btn-skin btn-skin-location">
                                <div className="delete-btn" onClick={onCancelBtnClick}>
                                    취소
                                </div>
                                <div className="update-btn" onClick={onUpdateBtnClick}>
                                    수정 완료
                                </div>
                              </div>
                          </div>
                        </div>
                    </div>
                  </div>
                </div>
            </div>
        </Layout>
    );
};

export default UserDetailPage;
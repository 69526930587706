import React, { useState, useEffect } from 'react';
import axios from "axios";
import styles from '../../../../styles/XAI.module.css'
import { Layout } from '../../../../allcomponents';
import { useNavigate } from 'react-router-dom';
import { API_URL, IMAGE_URL } from '../../../../Store/Global';
import { Box, Button, Table, TableBody, TableCell, tableCellClasses, TableContainer, TableHead, TableRow, TableFooter, Pagination, MenuItem, styled } from '@mui/material';
import { Select } from 'antd';

const { Option } = Select;

interface Image_folder {
    typeFolderName : string;
    SubFolderName: string;
}

interface Image {
    num: string;
    folderName: string;
    imageURL: Array<string>;
    cnt:Number
}

interface CNT {
    camera_number : string;
    cnt : number;
}

interface Heatmap {
    input_folder : string;
    output_folder: string;
    choice_weight: string;
}

export default function BridgeXAIHeatmapPage(props:any) {
    const projectType = localStorage.getItem('projectType');
    const curId = window.location.href.split('/')[6];
    let token : string | null = localStorage.getItem("token") 
    let projectId : string | null = localStorage.getItem("projectId")

    let Newarr:any[] = [];
    const images : Image [] = [];
    const cnt : CNT [] = [];
    const xai_heatmap: Heatmap[] = [];

    const [isSelect, setIsSelect] = useState<boolean>(false)
    const [typeArr, setType]=useState<string[]>([])
    const [BridgeType, setBridgeType] = useState("")
    const [bridge_type_name, setBridgeTypeName] = useState<string>("")
    const [folderName, setFolderName]= useState<Image_folder>({typeFolderName:"",SubFolderName:""})
    const [confirmOption, setConfirmOption] = useState<boolean>(false);
    const [reConfirm, setReConfirm] = useState<number>(0); // dropbox 새로고침
    const [spanNumber,setSpanNumber] =useState([])
    const [pierNumber, setPierNumber] = useState([])
    const [selectType, SetSelectType] = useState('')
    const [selectOption, setSelectOption] = useState()
    const [optionCount, setOptionCount] = useState([])
    
    const [imgList, setImgList] = useState<any[]>(images);
    const [girderImgUrl, setGirderImgUrl] = useState<any>([]);
    const [finalCNT, setFinalCNT] = useState<any[]>(cnt);
    const [result, setResult] = useState<boolean>(false)
    let [fileArr,setFileArr] = useState<any>([])
    const [arr, setArr] = useState<any>([])
    let [URLarr, setURLarr] = useState<any>([])

    const [userid, setUserId] = useState<number>(-1);
    const [companyId, setCompanyId] = useState<any>(localStorage.getItem("companyId"));

    const navigate = useNavigate();
    const [page, setPage] = React.useState(0);

    const setting :any = localStorage.getItem("settings")
    const count = JSON.parse(setting).cameraCount

    useEffect(()=>{
        axios({
            method: 'get',
            url: API_URL + `/project/view/${projectId}`,
            headers : {"accept" : `application/json`, "access-token" : `${token}`}, 
        }).then((res) => {
            let settings = JSON.parse(res.data.data.settings)
            let countList :any= [];
                if (res.data.check === true) {
                    setType(settings.bridge_type)
                    countList.push(settings.girder_count)
                    countList.push(settings.pier_count)
                    countList.push(settings.slab_count)
                    setOptionCount(countList)
                    setSpanNumber(settings.span_number_list)
                    setPierNumber(settings.pier_number_list)
                }
        })
    },[])

    const onChangeBridgeType = (e: any) => {
        let copy = [...imgList]
        setIsSelect(false)
        if (e === "Girder") {
            setConfirmOption(true)
            setBridgeType("Girder")
            localStorage.setItem("heat_type","Girder")
            setFolderName({typeFolderName:"Girder", SubFolderName: ''})
            setBridgeTypeName("거더")
            setReConfirm(0)
        } else if (e === 'GirderSide') {
            setBridgeType("GirderSide")
            localStorage.setItem("heat_type","GirderSide")
            setBridgeTypeName("거더 측면")
            setFolderName({typeFolderName:"GirderSide", SubFolderName: ''})
        } else if (e === "Slab") {
            setBridgeType("Slab")
            localStorage.setItem("heat_type","Slab")
            setBridgeTypeName("슬라브 하면")
            setFolderName({typeFolderName:"Slab", SubFolderName: ''})
        }else if(e === "SlabSide"){
            setBridgeType("SlabSide")
            localStorage.setItem("heat_type","SlabSide")
            setBridgeTypeName("슬라브 측면")
            setFolderName({typeFolderName:"SlabSide", SubFolderName: ''})
        } else if (e === "Pier") {
            setConfirmOption(false)
            localStorage.setItem("heat_type","Pier")
            setBridgeType("Pier")
            setBridgeTypeName("교각")
            setFolderName({typeFolderName:"Pier", SubFolderName: ''})
        }else if(e === "Abutment"){
            setBridgeType("Abutment")
            localStorage.setItem("heat_type","Abutment")
            setBridgeTypeName("교대")
            setFolderName({typeFolderName:"Abutment", SubFolderName: ''})
        }
        setURLarr([])
        setModel("Bridge")
    }
   
    const onChangeBridgeOption = (e: any) => {
        setFolderName({ ...folderName, SubFolderName: e })
        setIsSelect(true)
    }

    const option_render=()=>{
        const arr:any[]=[];
        typeArr?.map((type:any)=>{
            let name=''
            if(type==='Girder'){
                name='거더 하면'
            }else if(type==='GirderSide'){
                name='거더 측면'
            }else if (type === 'Slab') {
                name='슬라브 하면'
            } else if (type === 'Pier') {
                name ='교각'
            } else if(type==='Abutment') {
                name = '교대'
            }
            arr.push(<Option value={type}> {name}</Option>)
        })
        return arr;
    }

    const selectModel = () => {

        return <div style={{ display:'flex'}}>
                <Select placeholder="선택해주세요" value={folderName.typeFolderName === "" ? null : folderName.typeFolderName} style={{ width : '8rem'}} onChange={onChangeBridgeType}>
                    {option_render()}    
                </Select>
                <Select placeholder="선택해주세요" value={folderName.SubFolderName === "" ? null : folderName.SubFolderName} style={{ width : '8rem', marginLeft: '1rem'}} onChange={onChangeBridgeOption} >
                    {(() => {
                            let optionArr: any = [];
                            if (BridgeType === 'Girder' || BridgeType === 'Slab' || BridgeType === 'GirderSide') {
                                spanNumber.map((span: any) => {
                                    let name: string = 'S' + String(span.num).padStart(3, '0')
                                    optionArr.push(<Option value={name}>Span {span.num}</Option>)
                                })
                            }  else if (BridgeType === 'Pier') {
                                pierNumber.map((pier: any) => {
                                    let name: string = 'P' + String(pier.num).padStart(2, '0')
                                    optionArr.push(<Option value={name}>Pier {pier.num}</Option>)
                                })
                            }    
                            return optionArr;
                    })()}
                </Select>
            </div>;
    }


    const GirderCount: any = JSON.parse(setting).girder_count
    typeArr.map((type: string, index: number) => {
        if (type === 'Girder') {
            for (let i = 0; i < GirderCount; i++){
                images.push({
                    // num: 'G'+String(i+1).padStart(2,'0'),
                    num: '선택해주세요',
                    folderName: '구역을 선택해주세요',
                    imageURL: [],
                    cnt:0
                })
                cnt.push({
                    camera_number :  'G'+String(i+1).padStart(2,'0'),  
                    cnt: 0
                })
            }
        } 
        // console.log(images)
    })
    const [weight, setWeight]=useState('myresnet.pt')

    xai_heatmap.push({
        input_folder: `stageXAI/original/heatmap/${BridgeType}`,
        output_folder: `stageXAI/Heatmap/${BridgeType}`,
        choice_weight : "model/"+weight
    },
    )



    const handlePage = (event:any) => {
      const nowPageInt = parseInt(event.target.outerText);
      console.log(event.target)
      
      if(event.target.outerText) {
        setPage(nowPageInt);
      }
      else {
        if(event.target.ariaLabel.includes("prev")) {
          setPage(page-1);
        }
        if(event.target.ariaLabel.includes("next")) {
          setPage(page+1);
        }
  
      }
      
    }
    const StyledTableCell = styled(TableCell)(({ theme }) => ({
        [`&.${tableCellClasses.head}`]: {
          fontSize: '1rem',
          fontWeight: 600,
          backgroundColor: '#F5F5F5',
          color: '#3A4557',
          width: '42.8125rem',
          height: '2.5rem',
          border: '1px solid #DDD',
          borderTop: '0.15rem solid #3A4557',
          padding : 0
        },
        [`&.${tableCellClasses.body}`]: {
          color: '#3A4557',
          textAlign: 'center',
          fontSize: '0.8125rem',
          width: '42.8125rem',
          height: '3rem',
          border: '1px solid #DDD',
          padding : 0,
        },
      }));
      


    const [model, setModel] = React.useState('');

    let job_id = 0;
    let heatmap :any;
    const onClick = () => {
        xai_heatmap[0] = { ...xai_heatmap[0], choice_weight: 'model/' + weight  }

        console.log(imgList)
        console.log(imgList.length)
        var cnt_tmp = 0;
        if(fileArr.length === 0) {
            alert("이미지를 선택해주세요.")
        } else {
            alert("heatmap 생성 작업이 시작되었습니다. 기다려주세요.")
            arr.map((ob: any, i: number) => {
                if (imgList[i].cnt !== 0) {
                    cnt_tmp = cnt_tmp + 1;
                    console.log(cnt_tmp)
                    console.log(companyId, userid)
                    axios({
                        method: "post",
                        url: API_URL +`/scheduler/job/start/${companyId}/${userid}`,
                        headers: {
                        "accept": `application/json`,
                        "access-token": `${token}`,
                        "Content-Type": `application/json`
                        },
                        data: {
                            project_id: projectId,
                            task_name: "copy_folder",
                            interactive: false,
                            tasks: [{
                                input_folder: `stage2/` + ob.folder,
                                file_names: ob.filename,
                                output_folder: `stageXAI/original/heatmap/${BridgeType}`
                            }]
                        }
                        }).then((res) => {
                            console.log(xai_heatmap)
                            if (res.data.check === true) {
                                axios({
                                    method: "post",
                                    url: API_URL +`/scheduler/job/start/${companyId}/${userid}`,
                                    headers: {
                                    "accept": `application/json`,
                                    "access-token": `${token}`,
                                    "Content-Type": `application/json`
                                    },
                                    data: {
                                        project_id: projectId,
                                        task_name: "Bridge_xai_heatmap",
                                        interactive: false,
                                        tasks: xai_heatmap
                                    }
                                }).then((res2) => {
                                    console.log(res2)
                                    job_id = res2.data.data.job_id
                                    heatmap = setInterval(confirm, 30000)
                                }).catch((err) => {
                                    console.log(err);
                                    
                                });
                            }
                            else {
                                alert("에러 : " + res.data.message)
                            }
                            
                        
                        }).catch((err) => {
                            console.log(err)
                        })
                }
                else {
                    cnt_tmp = cnt_tmp - 1;
                    
                } 
                
                               
            })
        }    
        
        
    }

    const confirm = () => {
        axios({
            method: "post",
            url: API_URL + '/scheduler/job/query',
            headers: { "accept": `application/json`, "access-token": `${token}`, "Content-Type" : `application/json` },
                data: {
                    "job_id": job_id ,
                    "company_id": companyId
                }
            }).then((res) => {
                console.log(res)
                if (res.data.check == true) {
                    console.log("성공",res.data.data.status)
                    if (res.data.data.status === "done") {
                        alert("Heatmap이 끝났습니다.")
                        clearInterval(heatmap)
                        navigate(`/bridge/project/xai/heatmapresult/${projectId}`)
                    } else if(res.data.data.status === "error"){
                        alert("오류가 발생했습니다. 다시 시도해주세요.")
                        clearInterval(heatmap)
                    }
                 } else {
                    console.log("실패")
                 }
            })

    }



    useEffect(() => {
        let getIdCompany = async () => {
          if (token !== null) {
            console.log("프로젝트ID" + projectId);
            const response = await axios({
              method: "get",
              url: `${API_URL}/account/auth/check/${token}`,
            })
              .then(async (res) => {
                if (res.data.check === true) {
                  setUserId(res.data.data.id)
                  // localStorage.set("projectId", id);
                  console.log(
                    `아이디는 다음과 같음 : ${res.data.data.id} / 회사는 다음과 같음 : ${res.data.data.companyId}`
                  );
                  return {
                    email: res.data.data.email,
                    name: res.data.data.name,
                    phone: res.data.data.phone,
                    avatar: res.data.data.avatar,
                    role: res.data.data.role,
                    id: res.data.data.id,
                    companyId: companyId,
                  };
                } else {
                  console.log("토큰 만료");
                  localStorage.removeItem("token");
                  alert("토큰이 만료었습니다 다시 로그인 해주세요");
                  window.location.replace("/Main");
                }
              })
              .catch((err) => {
                console.log(err);
              });
          }
        };
        getIdCompany();
      }, []);


      let imgResult: any[];
      const rendering = (i: number) => {
        imgResult = [];
        imgList[i].imageURL.map((imageURL: any) => {
            let index_png = ""
            const index_c = imageURL.indexOf(String(BridgeType))
            const len = String(BridgeType).length
            let ImageUrlCutWidth = ""
            if (projectType === 'bridge') {
                if (BridgeType === 'Girder') {
                    if(imageURL.indexOf(".png") !== -1) {
                        index_png=imageURL.indexOf(".png")
                    }
                    else {
                        index_png=imageURL.indexOf(".jpg")
                    }
                    ImageUrlCutWidth = imageURL.substring(index_c + len + 10, index_png + 4)
                    imgResult.push(<img src={imageURL} id={String(i)} alt={imageURL} key={imageURL} className={URLarr.includes(ImageUrlCutWidth) ? styles.GirderImageBorder : styles.GirderImage} onClick={onClickImage} />)
                } else if (BridgeType === 'Pier') {
                    if(imageURL.indexOf(".png") !== -1) {
                        index_png=imageURL.indexOf(".png")
                    }
                    else {
                        index_png=imageURL.indexOf(".jpg")
                    }
                    ImageUrlCutWidth = imageURL.substring(index_c + len + 10, index_png + 4)
                    imgResult.push(<img src={imageURL} id={String(i)} alt={imageURL} key={imageURL} className={URLarr.includes(ImageUrlCutWidth) ? styles.GirderImageBorder : styles.GirderImage} onClick={onClickImage} />)
                } else if (BridgeType === 'Slab') {
                    if(imageURL.indexOf(".png") !== -1) {
                        index_png=imageURL.indexOf(".png")
                    }
                    else {
                        index_png=imageURL.indexOf(".jpg")
                    }
                    ImageUrlCutWidth = imageURL.substring(index_c + len + 13, index_png + 4)
                    imgResult.push(<img src={imageURL} id={String(i)} alt={imageURL} key={imageURL} className={URLarr.includes(ImageUrlCutWidth) ? styles.DamImageBorder : styles.DamImage} onClick={onClickImage} />)
                } else if (BridgeType === 'GirderSide') {
                    if(imageURL.indexOf(".png") !== -1) {
                        index_png=imageURL.indexOf(".png")
                    }
                    else {
                        index_png=imageURL.indexOf(".jpg")
                    }
                    ImageUrlCutWidth = imageURL.substring(index_c + len + 12, index_png + 4)
                    imgResult.push(<img src={imageURL} id={String(i)} alt={imageURL} key={imageURL} className={URLarr.includes(ImageUrlCutWidth) ? styles.DamImageBorder : styles.DamImage} onClick={onClickImage} />)
                }
            }
            
            
        })
        if(imgResult.length < 1){
            setResult(false);
        }
        
        
        // console.log(result)
        return imgResult;
    }

    //프로젝트 정보 가져오기
    let img_path: any;
    useEffect(() => {
        let countList: any = [];
        let settings: any;
        axios({
            method: 'get',
            url: API_URL + `/project/view/${projectId}`,
            headers: { "accept": `application/json`, "access-token": `${token}` },
        }).then((res) => {
            if (res.data.check === true) {
                settings= JSON.parse(res.data.data.settings)
                countList.push(settings.girder_count)
                countList.push(settings.pier_film_count)
                countList.push(settings.slab_count)
                countList.push(settings.span_length/settings.span_class_length)
                setOptionCount(countList)
                setSpanNumber(settings.span_number_list)
                setPierNumber(settings.pier_number_list)
            } else {
                console.log("실패")
            }
        })

        let copy = [...images]
        let copyCNT = [...cnt]
        if (BridgeType === 'Girder') {
            for (let i = 0; i < optionCount[0]; i++) {
                // copy[i] = { ...copy[i], folderName: BridgeType + '/' + e }
                copy[i] = { ...copy[i], num: folderName.SubFolderName, folderName: 'G' + String(i + 1).padStart(2, '0') }
                Newarr[i] = { folder: folderName.typeFolderName + '/' + folderName.SubFolderName + '/G' + String(i + 1).padStart(2, '0'), filename: [] };
                axios({
                    method: 'get',
                    url: API_URL + '/File/Files',
                    headers: { "accept": `application/json`, "access-token": `${token}` },
                    params: {
                        path: `/project/${projectId}/stage0/${folderName.typeFolderName + '/' + folderName.SubFolderName}/${'G' + String(i + 1).padStart(2, '0')}`
                    }
                }).then((res) => {
                    if (res.data.check === true) {
                        console.log("성공")
                        let imgURL = [];
                        for (let j = 0; j < res.data.data.files.length; j++) {
                            imgURL.push(`${IMAGE_URL}/image?path=/project/${projectId}/stage0/${folderName.typeFolderName + '/' + folderName.SubFolderName}/${'G' + String(i + 1).padStart(2, '0')}/${res.data.data.files[j]}&width=360`)
                        }

                        copy[i] = { ...copy[i], imageURL: imgURL }
                        setImgList(copy)
                        setResult(true)
                    } else {
                        console.log("실패")
                    }
                }).catch((err) => {
                    console.log(err);
                });
                console.log(copy[i])
            }
            setFinalCNT(copyCNT)
        } else if (BridgeType === 'GirderSide') {
            const f_name: string = 'S' + folderName.SubFolderName.substring(2, 4)
            copy = []
            copyCNT = []
            for (let i = 0; i < optionCount[3]; i++) {
                copy.push({ num: folderName.SubFolderName, folderName: f_name + String(i + 1).padStart(2, '0'), cnt: 0, imageURL: [] })
                Newarr[i] = { folder: folderName.typeFolderName + '/' + folderName.SubFolderName + '/' + f_name + String(i + 1).padStart(2, '0'), filename: [] };
                copyCNT.push({ camera_number:f_name+String(i + 1).padStart(2, '0'), cnt:0})
                axios({
                    method: 'get',
                    url: API_URL + '/File/Files',
                    headers: { "accept": `application/json`, "access-token": `${token}` },
                    params: {
                        path: `/project/${projectId}/stage2/${folderName.typeFolderName + '/' + folderName.SubFolderName}/${f_name+ String(i + 1).padStart(2, '0')}`
                    }
                }).then((res) => {
                    if (res.data.check === true) {
                        console.log("성공")
                        let imgURL = [];
                        for (let j = 0; j < res.data.data.files.length; j++) {
                            imgURL.push(`${IMAGE_URL}/image?path=/project/${projectId}/stage2/${folderName.typeFolderName + '/' + folderName.SubFolderName}/${f_name + String(i + 1).padStart(2, '0')}/${res.data.data.files[j]}&width=360`)
                        }
                        
                        copy[i] = { ...copy[i], imageURL: imgURL }
                        setImgList(copy)
                        setResult(true)
                    } else {
                        console.log("실패")
                    }
                }).catch((err) => {
                    console.log(err);
                });
            }
            setFinalCNT(copyCNT)
        } else if (BridgeType === 'Slab') {
            // console.log(`/project/${projectId}/stage0/${BridgeType + '/' + e}/${'Slab' + String(1).padStart(2, '0')}`)
            copy = []
            copyCNT = []
            
            for (let i = 0; i < optionCount[2]; i++){
                copy.push({num: folderName.typeFolderName, folderName: 'Slab' + String(i + 1).padStart(2, '0'),cnt:0, imageURL:[]})
                Newarr[i] = { folder: folderName.typeFolderName + '/' + folderName.SubFolderName + '/Slab' + String(i + 1).padStart(2, '0'), filename: [] };
                copyCNT.push({camera_number:folderName.typeFolderName, cnt:0})
                axios({
                    method: 'get',
                    url: API_URL + '/File/Files',
                    headers: { "accept": `application/json`, "access-token": `${token}` },
                    params: {
                        path: `/project/${projectId}/stage0/${folderName.typeFolderName + '/' + folderName.SubFolderName}/${'Slab' + String(i + 1).padStart(2, '0')}`
                    }
                }).then((res) => {
                    console.log(res)
                    if (res.data.check === true) {
                        console.log("성공")
                        let imgURL = [];
                        console.log(res.data)
                        for (let j = 0; j < res.data.data.files.length; j++) {
                            imgURL.push(`${IMAGE_URL}/image?path=/project/${projectId}/stage0/${folderName.typeFolderName + '/' + folderName.SubFolderName}/${'Slab' + String(i + 1).padStart(2, '0')}/${res.data.data.files[j]}&width=360`)
                        }
                        copy[i] = { ...copy[i], imageURL: imgURL }
                        setImgList(copy)
                        setResult(true)
                    } else {
                        console.log("실패")
                    }
                }).catch((err) => {
                    console.log(err);
                });
            }
            setFinalCNT(copyCNT)

        } else if (BridgeType === 'Pier') {
            copy = []
            copyCNT = []
            for (let i = 0; i < optionCount[3]; i++) {
                copy.push({num: folderName.SubFolderName, folderName: 'S' + String(i + 1).padStart(3, '0'),cnt:0, imageURL:[]})
                Newarr[i] = { folder: folderName.typeFolderName + '/' + folderName.SubFolderName + '/S' + String(i + 1).padStart(3, '0'), filename: [] };
                copyCNT.push({camera_number:folderName.typeFolderName, cnt:0})
                
                axios({
                    method: 'get',
                    url: API_URL + '/File/Files',
                    headers: { "accept": `application/json`, "access-token": `${token}` },
                    params: {
                    path: `/project/${projectId}/stage2/${folderName.typeFolderName+'/'+folderName.SubFolderName + '/S' + String(i + 1).padStart(3, '0')}`
                    }
                }).then((res) => {
                    if (res.data.check === true) {
                        console.log("성공")
                        let imgURL = [];
                        for (let j = 0; j < res.data.data.files.length; j++) {
                            imgURL.push(`${IMAGE_URL}/image?path=/project/${projectId}/stage2/${folderName.typeFolderName+'/'+folderName.SubFolderName+ '/S' + String(i + 1).padStart(3, '0')}/${res.data.data.files[j]}&width=360`)
                        }
                        copy[i] = { ...copy[i], imageURL: imgURL }
                        setImgList(copy)
                        setResult(true)
                    } else {
                        console.log("실패")
                    }
                }).catch((err) => {
                    console.log(err);
                });
                    
                     
            }
            setReConfirm(1)
            setFinalCNT(copyCNT)
        }
        setArr(Newarr)

    }, [selectOption,folderName])

    let copyArrImgList = [...imgList];
    let copyArr = [...URLarr];
    let copyfilename = [...arr];
    let filename = [...fileArr];
    let copyArrCNT = [...finalCNT];
    const onClickImage = (e: any) => {
        const id = Number(e.target.id)
        console.log(id)
        const len = BridgeType.length
        filename = [...copyfilename[id].filename]
        const str = e.target.src
        let index1 = ""
        if (projectType === "bridge") {
            if(str.indexOf(".png") !== -1) {
                index1=str.indexOf(".png")
            }
            else {
                index1=str.indexOf(".jpg")
            }
        }
        const index2 = str.indexOf(BridgeType)
        const num = (BridgeType === 'Girder' ? 'G' + String(id + 1).padStart(2, '0')
        : BridgeType === 'Pier' ? 'S' + String(id + 1).padStart(3, '0')
        : BridgeType === 'Slab' ? 'Slab' + String(id + 1).padStart(2, '0')
        : imgList[id].folderName)
        
        console.log(copyArrCNT)
        if (num === imgList[id].folderName) {
            let name = (BridgeType === 'Girder' ? str.substring(index2 + len + 10, index1 + 4)
                        : BridgeType === 'Pier' ? str.substring(index2 + len + 10, index1 + 4)
                        : BridgeType === 'Slab' ? str.substring(index2 + len + 13, index1 + 4)
                        : str.substring(index2 + len + 12, index1 + 4))
            console.log(index2, len, index1)
            console.log(name)
            console.log(copyArr)
            if (copyArr.includes(name) === false) {
                //border
                copyArr.push(name)
                //선택 개수 계산
                copyArrCNT[id] = { ...copyArrCNT[id], cnt: copyArrCNT[id]?.cnt + 1}
                copyArrImgList[id] = { ...copyArrImgList[id], cnt: copyArrCNT[id]?.cnt, }
                //filename
                filename.push(name)
                setFileArr(filename)
                 
            } else {//선택이미지 중복일때
                console.log("여기2")
                //  border없앰
                const i = copyArr.indexOf(name)
                copyArr.splice(i, 1)
                // 선택 개수 계산
                copyArrCNT[id] = { ...copyArrCNT[id], cnt: copyArrCNT[id]?.cnt - 1}
                copyArrImgList[id] = { ...copyArrImgList[id], cnt: copyArrCNT[id]?.cnt, }
                //filename 
                const j = filename.indexOf(name)
                filename.splice(j, 1)
            }
        }
        setFinalCNT(copyArrCNT)
        
        setImgList(copyArrImgList)
        localStorage.setItem("heatmapArr", JSON.stringify(copyArr))
        setURLarr(copyArr)
        copyfilename[id] = { ...copyfilename[id], filename: filename }
        setArr(copyfilename)
            
    }
    
    

    return (
        <Layout>
            <div style={{ display : 'flex', justifyContent: 'space-between', width:'85%'}}>
                <div>
                    <div style={{ paddingTop : '2.01rem', paddingLeft : '2rem', color: '#999', fontSize: '0.75rem', fontFamily: 'inter-pretendard', fontStyle: 'normal', fontWeight: 400, lineHeight: '100%'}}>XAI &gt; Heatmap</div>
                    <div style={{ paddingTop : '0.3rem', paddingLeft : '2rem', color: '#3A4557', fontSize: '1.5rem', fontFamily: 'inter-pretendard', fontStyle: 'normal', fontWeight: 700, lineHeight: '100%'}}>Heatmap</div>
                </div>
                
            </div>
            <div style={{ marginLeft : '2rem', marginTop: '1rem'}}>
                <Box sx={{
                    display: 'block',
                    width: '17rem',
                    height: '2rem',         
                    borderRadius: '0.5rem',
                    background: '#FFF',
                    boxShadow: '2px 2px 20px 0px rgba(58, 69, 87, 0.05)',
                    padding : '1rem'
                }}>
                    {selectModel()}
                </Box>
                
            </div>
            {
                isSelect ? 
                <div style={{ display : 'flex'}}>
            
                    <div>
                        <Box sx={{ width: '20rem',  backgroundColor: 'white', borderRadius: '0.5rem', boxShadow: '2px 2px 20px 0px rgba(58, 69, 87, 0.05)', padding : '2rem', marginLeft : '2rem', marginTop: '1rem'}}>
                            <div style={{ display: 'flex', alignItems : 'center', justifyContent : 'space-between'}}>
                                <div style={{ fontWeight : 700, marginBottom : '2.13rem', borderLeft: '0.1875rem solid #0F6FB7', fontSize:'1rem', height:'1.2rem', paddingLeft: '0.62rem'}}>구역별 이미지 리스트 </div>
                                <div>
                                    <Button variant="contained" size="small" sx={{ float : 'right', marginBottom : '0.5rem', marginRight:'1rem', fontFamily: 'inter-pretendard', fontSize: '0.875rem', background: '#0F6FB7' }} onClick={onClick}>확인</Button>
                                </div>
                            </div>
                            <div>
                                <TableContainer  sx={{ width: '18rem', marginLeft: '1rem', marginTop: '1rem' }}>
                                    <Table sx={{ backgroundColor: '#fffff' }} aria-label="customized table">
                                        <TableHead>
                                        <TableRow style={{ height: '2.5rem'}}>
                                            <StyledTableCell align="center" style={{width:'5rem', fontFamily: 'inter-pretendard'}}>번호</StyledTableCell>
                                            <StyledTableCell align="center" style={{width:'7.5rem', fontFamily: 'inter-pretendard'}}>이름</StyledTableCell>
                                            <StyledTableCell align="center" style={{width:'7.5rem', fontFamily: 'inter-pretendard'}}>개수</StyledTableCell>
                                        </TableRow>
                                        </TableHead>
                                        
                                        <TableBody>
                                        {   
                                            imgList.map((row, i) => (
                                            <TableRow 
                                                key={i}
                                                style={{ height: '2.5rem'}}
                                            >
                                            <StyledTableCell align="center" style={{width:'5rem', height: '2.5rem', fontFamily: 'inter-pretendard'}}>{row.num+1}</StyledTableCell>
                                            <StyledTableCell align="center" style={{width:'7.5rem', height: '2.5rem', fontFamily: 'inter-pretendard'}}>{row.folderName}</StyledTableCell>
                                            <StyledTableCell align="center" style={{width:'7.5rem', height: '2.5rem', fontFamily: 'inter-pretendard'}}>{row.cnt}</StyledTableCell>
                                            </TableRow> 
                                        ))}
                                        </TableBody>
                                        
                                    </Table>
                                    <TableFooter>
                                        <TableRow style={{ width: '18rem', display : 'flex', justifyContent: 'center', marginTop:'4rem'}}>
                                        <Pagination count={imgList.length > 10 && imgList.length % 10 === 0 ? Math.floor(imgList.length/10) : Math.floor(imgList.length/10)+1} defaultPage={1} onChange={(e) => handlePage(e)} color="primary" 
                                            sx={{
                                            '& svg': {
                                                pointerEvents: 'none'
                                            }
                                            }}
                                        />
                                        </TableRow>
                                        </TableFooter>
                                </TableContainer>
                            </div>
                        </Box> 
                    </div>
                    <div style={imgList.length < 3?{ marginTop: '1rem', height : '44rem'} : { marginTop: '1rem', overflowY : 'scroll', height : '44rem'}}>
                        {
                            result ? 
                            imgList.map((_, i) => (
                                <Box sx={BridgeType === 'Girder'||  BridgeType === 'Pier' ? { width: '73.6875rem', height: '21rem', flexShrink: 0, borderRadius: '0.5rem', background: '#FFF', boxShadow: '2px 2px 20px 0px rgba(58, 69, 87, 0.05)', marginLeft : '2rem', marginBottom: '1rem'} : { width: '73.6875rem', height: '12.5rem', flexShrink: 0, borderRadius: '0.5rem', background: '#FFF', boxShadow: '2px 2px 20px 0px rgba(58, 69, 87, 0.05)', marginLeft : '2rem', marginBottom: '1rem'}}>
                                    <div style={{padding : '2rem'}}>
                                        {/* 네임라인 */}
                                        <div style={{ display: 'flex', justifyContent: 'space-between'}}>
                                                <div style={{ display : 'flex'}}>
                                                    <div style={{ backgroundColor : '#0F6FB7', borderRadius : '50%', color : '#fff', width : '1.25rem', height : '1.25rem', textAlign : 'center', fontSize : '0.75rem', fontWeight:400, lineHeight:'175%'}}>{i+1}</div>
                                                    <div style={{ marginLeft : '0.5rem', fontWeight : 600}}>{imgList[i].folderName}</div>
                                                </div>

                                        </div>
                                        {/* 이미지라인 */}
                                        <div style={BridgeType === 'Girder'||  BridgeType === 'Pier' ? { width : '69.65rem', height : '15rem', marginTop : '1.25rem', whiteSpace : 'nowrap', overflow: 'auto'}:{ width : '69.65rem', height : '6.5rem', marginTop : '1.25rem', whiteSpace : 'nowrap', overflow: 'auto'}}>
                                        { rendering(i) }
                                            
                                        </div>
                                    </div>
                                </Box>
                            )) 
                            :
                            <div>
                                이미지 로딩 중 입니다.
                            </div>
                        }
                        
                    </div>
                </div> 
                : <Box sx={{
                    width: '11.5rem',
                    height: '1.5rem',
                    borderRadius: '0.5rem',
                    background: '#FFF',
                    boxShadow: '2px 2px 20px 0px rgba(58, 69, 87, 0.05)',
                    padding : '0.75rem',
                    marginLeft : '2rem', 
                    marginTop : '1rem'
                }}>
                    <div>구역을 먼저 선택해주세요.</div>
                </Box>
            

            }
                
        </Layout>
    );
};
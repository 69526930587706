import styles from '../../../../styles/Preprocess.module.css'
import styled from "styled-components";
import { Button, Form, Radio,Select, Input, Table} from 'antd';
import { useRecoilState, atom } from 'recoil';
import { useState } from 'react'
import axios from 'axios';
import { useEffect } from 'react';
import { API_URL } from '../../../../Store/Global';
import { tunnel_project_name, tunnel_name_kor, tunnel_name_eng, tunnel_scanner_speed, tunnel_direction, tunnel_video_fps, tunnel_filming_set, tunnel_camera_num, tunnel_span_num, tunnel_span_length, tunnel_type, tunnel_width, tunnel_length, tunnel_crack_max_width, tunnel_overlap_rate } from "../../../../Store/State/projectAtom";

export default function CreateSettingTab() {
    let token : string | null = localStorage.getItem("token") 

    const [projectName, setProjectName] = useRecoilState<any>(tunnel_project_name); 
    const [tunnelNameKor, setTunnelNameKor] = useRecoilState<any>(tunnel_name_kor); 
    const [tunnelNameEng, setTunnelNameEng] = useRecoilState<any>(tunnel_name_eng); 
    const [scannerSpeed, setScannerSpeed] = useRecoilState<any>(tunnel_scanner_speed); 
    const [tunnelDirection, setTunnelDirection] = useRecoilState<any>(tunnel_direction); 
    const [videoFps, setVideoFps] = useRecoilState<any>(tunnel_video_fps); 
    const [filmingSet, setFilmingSet] = useRecoilState<any[]>(tunnel_filming_set); 
    const [cameraNum, setCameraNum] = useRecoilState<any>(tunnel_camera_num); 
    const [spanNum, setSpanNum] = useRecoilState<any>(tunnel_span_num); 
    const [spanLength, setSpanLength] = useRecoilState<any>(tunnel_span_length); 
    const [tunnelType, setTunnelType] = useRecoilState<any>(tunnel_type); 
    const [tunnelWidth, setTunnelWidth] = useRecoilState<any>(tunnel_width); 
    const [tunnelLength, setTunnelLength] = useRecoilState<any>(tunnel_length); 
    const [crackMaxWidth, setCrackMaxWidth] = useRecoilState<any>(tunnel_crack_max_width); 
    const [overlapRate, setOverlapRate] = useRecoilState<any>(tunnel_overlap_rate); 

    const list: any[] = [projectName, tunnelNameKor, tunnelNameEng, scannerSpeed, tunnelDirection, videoFps, filmingSet, cameraNum, spanNum, spanLength, tunnelType, tunnelWidth, tunnelLength, crackMaxWidth, overlapRate]

    const handleProjectName = (event:any) => {
        setProjectName(event.target.value);
    }    

    const handleTunnelNameKor = (event:any) => {
        setTunnelNameKor(event.target.value);
    }

    const handleTunnelNameEng = (event:any) => {
        setTunnelNameEng(event.target.value);
    }
        
    const handleScannerSpeed = (event:any) => {
        setScannerSpeed(event.target.value);
    }  

    const handleTunnelDirection = (event:any) => {
        setTunnelDirection(event.target.value);
    }  

    const handleVideoFps = (event:any) => {
        setVideoFps(event.target.value);
    }  

    const handleFilmingSet = (event:any) => {
        setFilmingSet(event.target.value);
    }  
    
    const handleCameraNum = (event:any) => {
        setCameraNum(event.target.value);
    }  
        
    const handleSpanNum = (event:any) => {
        setSpanNum(event.target.value);
    } 

    const handleSpanLength = (event:any) => {
        setSpanLength(event.target.value);
    } 

    const handleTunnelType = (event:any) => {
        setTunnelType(event.target.value);
    } 

    const handleTunnelWidth = (event:any) => {
        setTunnelWidth(event.target.value);
    } 

    const handleTunnelLength = (event:any) => {
        setTunnelLength(event.target.value);
    } 

    const handleCrackMaxWidth = (event:any) => {
        setCrackMaxWidth(event.target.value);
    } 

    const handleOverlapRate = (event:any) => {
        setOverlapRate(event.target.value);
    } 


    return (
            <>
            <Form labelCol={{ span: 8, }} wrapperCol={{ span: 8 }} layout="horizontal">
                            <Form.Item className={styles.FormItem}  label="프로젝트 명">
                            <Input value={projectName} onChange={handleProjectName} type={"string"}/>
                            </Form.Item>
                            <Form.Item className={styles.FormItem} label="터널 이름(한)">
                            <Input value={tunnelNameKor} onChange={handleTunnelNameKor} type={"string"}/>
                            </Form.Item>
                            <Form.Item className={styles.FormItem} label="터널 이름(영)">
                            <Input value={tunnelNameEng} onChange={handleTunnelNameEng} type={"string"}/>
                            </Form.Item>
                            <Form.Item className={styles.FormItem} label="터널 스캐너 이동 속도">
                            <Input value={scannerSpeed} onChange={handleScannerSpeed} type={"string"} suffix="km/h"/>
                            </Form.Item>
                            <Form.Item className={styles.FormItem} name="radio-button" label="진행 방향">
                            <Input value={tunnelDirection} onChange={handleTunnelDirection} type={"string"}/>
                            </Form.Item>
                            <Form.Item className={styles.FormItem} label="영상 FPS">
                            <Input value={videoFps} onChange={handleVideoFps} type={"number"} suffix="FPS"/>
                            </Form.Item>
                            
                            {/* <Form.Item className={styles.FormItem} label="촬영 set 수" style={{marginBottom:'0px'}}>
                            {
                                filmSetResult === true ? 
                                <Table dataSource={TableSetStauts} pagination={false} size="small">
                                  
                                    <Column title="set 번호" dataIndex="no" key="no"/>
                                    <Column title="첫번째 카메라 번호" dataIndex="firstCam" key="firstCam"/>
                                    <Column title="마지막 카메라 번호" dataIndex="lastCam" key="lastCam"/>
                                </Table>
                            :
                            <div>데이터를 불러오지 못했습니다</div>                        
                            }

                            </Form.Item> */}
                            <Form.Item className={styles.FormItem} label="카메라 수">
                            <Input value={cameraNum} onChange={handleCameraNum} type={"number"} suffix="개"/>                              
                            </Form.Item>
                            <Form.Item className={styles.FormItem} label="스팬 수">
                            <Input value={spanNum} onChange={handleSpanNum} type={"number"} suffix="개"/>          
                            </Form.Item>
                            <Form.Item className={styles.FormItem} label="스팬 길이">
                            <Input value={spanLength} onChange={handleSpanLength} type={"number"} suffix="m"/>     
                            </Form.Item>
                            <Form.Item className={styles.FormItem} label="터널 종류">
                            <Input value={tunnelType} onChange={handleTunnelType} type={"string"}/>
                            </Form.Item>
                            <Form.Item className={styles.FormItem} label="터널 너비">
                            <Input value={tunnelWidth} onChange={handleTunnelWidth} type={"number"} suffix="m"/> 
                            </Form.Item>
                            <Form.Item className={styles.FormItem} label="터널 길이">
                            <Input value={tunnelLength} onChange={handleTunnelLength} type={"number"} suffix="m"/>   
                            </Form.Item>
                            <Form.Item className={styles.FormItem} label="실제 균열 최대 폭">
                            <Input value={crackMaxWidth} onChange={handleCrackMaxWidth} type={"number"} suffix="m"/>      
                            </Form.Item>
                            <Form.Item className={styles.FormItem} label="겹침률">
                            <Input value={overlapRate} onChange={handleOverlapRate} type={"number"} suffix="%"/>        
                            </Form.Item>
                            
            </Form>
            </>


    )
}

import styles from '../../../styles/CrackDetector_Measure.module.css'
import { Button, Form, Select, Table, Radio } from 'antd';
import { useNavigate } from 'react-router-dom';
import type { RadioChangeEvent } from 'antd';
import { useEffect, useState } from 'react';
import axios from 'axios';
import { useRecoilState, atom } from 'recoil';
import { API_URL } from '../../../../../Store/Global';
import {IProps} from '../BridgePreProcessSettingPage'

interface ImgName {
    no : Number
    name : string
}

interface MeasureListTF1 {
    input_folder : string
    crack_image_folder : string
    output_folder : string
}

interface MeasureListTF2 {
    input_folder : string
    crack_image_folder : string
    output_folder : string
    choice_weight : string
}

interface Measure {
    input_folder : string;
    output_folder : string;
}

type TypeProps = {
    props : IProps;
}

export default function MeasureSetting({props}:TypeProps) {
    const { bridgeType } = props;

    const navigate = useNavigate();
    let projectId : string | null = localStorage.getItem("projectId")
    let token : string | null = localStorage.getItem("token") 

    // const [detectorTask, setDetectorTask] = useState<any>([])

    const MeasureListTF1 : MeasureListTF1[] = [];
    const MeasureListTF2 : MeasureListTF2[] = [];
    const SpanDist :any[] = [];

    const [NewMeasureListTF1, setNewMeasureListTF1] = useState<any[]>(MeasureListTF1);
    const [NewMeasureListTF2, setNewMeasureListTF2] = useState<any[]>(MeasureListTF2);

    SpanDist.push({
        input_folder : "stage6_2"
    })

    const Measure : Measure [] = [];
    //결함 측정을 실행하기 위한 tasks를 세팅
    // const [NewMeasureList, setNewMeasureList] = useState<any[]>(Measure);
    const [measureList, setMeasureList] = useState<any[]>(Measure);

    const ImgName : ImgName[] = [];                                          
    const [ImgList, setImgList] = useState<any | undefined>([]);    

    const [result, setResult] = useState<boolean>(false)
    const [choiceModel, setchoiceModel] = useState<string>("TF1")

    const [CheckBox, setCheckBox] = useState(1);

    const [userid, setUserId] = useState<number>(-1);
    const [companyid, setCompanyId] = useState<number>(-1);
  
    useEffect(() => {
        let getIdCompany = async () => {
          if (token !== null) {
            console.log("프로젝트ID" + projectId);
            const response = await axios({
              method: "get",
              url: `${API_URL}/account/auth/check/${token}`,
            })
              .then(async (res) => {
                if (res.data.check === true) {
                  setUserId(res.data.data.id)
                  setCompanyId(res.data.data.companyId)
                  // localStorage.set("projectId", id);
                  console.log(
                    `아이디는 다음과 같음 : ${res.data.data.id} / 회사는 다음과 같음 : ${res.data.data.companyId}`
                  );
                  return {
                    email: res.data.data.email,
                    name: res.data.data.name,
                    phone: res.data.data.phone,
                    avatar: res.data.data.avatar,
                    role: res.data.data.role,
                    id: res.data.data.id,
                    companyId: res.data.data.companyId,
                  };
                } else {
                  console.log("토큰 만료");
                  localStorage.removeItem("token");
                  alert("토큰이 만료었습니다 다시 로그인 해주세요");
                  window.location.replace("/Main");
                }
              })
              .catch((err) => {
                console.log(err);
              });
          }
        };
        getIdCompany();
        if(bridgeType === 'Slab') {
            Measure.push({
                input_folder : `stage6_defect/${bridgeType}/S0101`,
                output_folder : `stage7_defect/${bridgeType}/S0101`,
            }) 
        }
        else if(bridgeType === 'Pier') {
            Measure.push({
                input_folder : `stage6_defect/${bridgeType}/P09`,
                output_folder : `stage7_defect/${bridgeType}/P09`,
            }) 
        }
        
        setMeasureList(Measure);
        console.log(bridgeType)
      }, [bridgeType]);


    
    const onChange = (e: RadioChangeEvent) => {
        // console.log(e.target.value);
        setCheckBox(Number(e.target.value));

        let copyArrMeasureListTF1 = [...NewMeasureListTF1];
        let copyArrMeasureListTF2 = [...NewMeasureListTF2];

        axios({
            method: 'get',
            url: API_URL + `/project/view/${projectId}`,
            headers : {"accept" : `application/json`, "access-token" : `${token}`}, 
        }).then((res) => {
            const settings: any = JSON.parse(res.data.data.settings)
            if(e.target.value===1){
                setWeightData(
                    <div>
                        <p style={{fontSize:"20px", fontWeight:"bold"}}>교량 1</p>
                        <p style={{fontSize:"25px"}}>Mask RCNN</p>
                        <p>Epoch : 1000</p>
                        <p>LR : 0.0001</p>
                        <p>.....</p>
                    </div>
                )
                for(let i = 0; i < settings.spanCount; i++){
                    copyArrMeasureListTF1[i] = { ...copyArrMeasureListTF1[i]};
        
                    setNewMeasureListTF1(copyArrMeasureListTF1)
                }
            }else if(e.target.value===2){
                setWeightData(
                    <div>
                        <p style={{fontSize:"20px", fontWeight:"bold"}}>교량 1</p>
                        <p style={{fontSize:"25px"}}>Mask RCNN</p>
                        <p>Epoch : 1000</p>
                        <p>LR : 0.0001</p>
                        <p>.....</p>
                    </div>
                )
                for(let i = 0; i < settings.spanCount; i++){
                    copyArrMeasureListTF1[i] = { ...copyArrMeasureListTF1[i]};
        
                    setNewMeasureListTF1(copyArrMeasureListTF1)
                }
            }else if(e.target.value===5){
                setWeightData(
                    <div>
                        <p style={{fontSize:"20px", fontWeight:"bold"}}>교량 1</p>
                        <p style={{fontSize:"25px"}}>Mask RCNN</p>
                        <p>Epoch : 1000</p>
                        <p>LR : 0.0001</p>
                        <p>.....</p>
                    </div>
                )
                for(let i = 0; i < settings.spanCount; i++){
                    copyArrMeasureListTF2[i] = { ...copyArrMeasureListTF2[i], choice_weight : "measure_model"};
        
                    setNewMeasureListTF2(copyArrMeasureListTF2)
                }
            }else if(e.target.value===6){
                setWeightData(
                    <div>
                        <p style={{fontSize:"20px", fontWeight:"bold"}}>교량 1</p>
                        <p style={{fontSize:"25px"}}>Mask RCNN</p>
                        <p>Epoch : 1000</p>
                        <p>LR : 0.0001</p>
                        <p>.....</p>
                    </div>
                )
                for(let i = 0; i < settings.spanCount; i++){
                    copyArrMeasureListTF2[i] = { ...copyArrMeasureListTF2[i], choice_weight : "test_measure_model"};
        
                    setNewMeasureListTF2(copyArrMeasureListTF2)
                }
            }     
        }).catch((err) => {
            console.log(err);
        })



    };

    const [ModelData, setModelData] = useState<any | undefined>(
        <Radio.Group optionType="button">
            <Radio value={1} onChange={onChange}>교량 1</Radio>
            <Radio value={2} onChange={onChange} disabled>교량 2</Radio>
        </Radio.Group>
    ); 

    const [WeightData, setWeightData] = useState<any | undefined>(
        <div>
            <p style={{fontSize:"20px", fontWeight:"bold"}}>교량 1</p>
            <p style={{fontSize:"25px"}}>Mask RCNN</p>
            <p>Epoch : 1000</p>
            <p>LR : 0.0001</p>
            <p>.....</p>
        </div>
    ); 



    type ModelList = string;

    const { Column } = Table;

    useEffect(()=>{
        const response = axios({
            method: 'get',
            url: API_URL + `/project/view/${projectId}`,
            headers : {"accept" : `application/json`, "access-token" : `${token}`}, 
        }).then((res) => {
            const settings: any = JSON.parse(res.data.data.settings)
            // console.log(settings.spanCount)

            for(let i = 1; i < settings.spanCount + 1; i++){
                MeasureListTF1.push({
                    // input_folder : `stage4/S00${i}`,
                    // crack_image_folder : `stage6_2/S00${i}`,
                    input_folder : `stage4/S${String(i).padStart(3,"0")}`,
                    crack_image_folder : `stage6_2/S${String(i).padStart(3,"0")}`,
                    output_folder : "stage7"
                })

                MeasureListTF2.push({
                    // input_folder : `stage4/S00${i}`,
                    // crack_image_folder : `stage6_2/S00${i}`,
                    input_folder : `stage4/S${String(i).padStart(3,"0")}`,
                    crack_image_folder : `stage6_2/S${String(i).padStart(3,"0")}`,
                    output_folder : "stage7",
                    choice_weight : "measure_model"
                })
            }

            setNewMeasureListTF1(MeasureListTF1)
            setNewMeasureListTF2(MeasureListTF2)

            axios({
                method: 'get',
                url: API_URL+'/File/Files',
                headers: { "accept": `application/json`, "access-token": `${token}` },
                params: {
                        path: `/project/${projectId}/stage6`
                    }
                }).then((res) => {    
                    
                    for (let j = 0; j < res.data.data.files.length; j++) {
                        ImgName.push({
                            no : j + 1,
                            name : res.data.data.files[j]
                        })            
                }
                setImgList(ImgName)
                if(ImgName.length > 0){
                    setResult(true);
                }

                }).catch((err) => {
                    console.log(err)
                })

        }).catch((err) => {
            console.log(err);
        });   
    },[])

    

    

    const onChangeModel = (value: ModelList) => {
            if(value==="1"){
                setModelData(
                    <Radio.Group optionType="button">
                        <Radio value={1} onChange={onChange}>교량 1</Radio>
                        <Radio value={2} onChange={onChange} disabled>교량 2</Radio>
                    </Radio.Group>
                )
                setchoiceModel("TF1")
            }else if(value==="2"){
                setModelData(
                    <Radio.Group optionType="button">
                        <Radio value={5} onChange={onChange}>교량 1</Radio>
                        <Radio value={6} onChange={onChange} disabled>교량 2</Radio>
                    </Radio.Group>
                )
                setchoiceModel("TF2")
            }
    }

     /////////////////30초마다 status알려주는 alert//////////////////////////////////////////
     let job_id = 0;
     let resultDefectDetector2 :any;

     const confirmMeasure = () => {
        // console.log(job_id)
        axios({
            method: "post",
            url: API_URL + '/scheduler/job/query',
            headers: { "accept": `application/json`, "access-token": `${token}`, "Content-Type" : `application/json` },
                data: {
                    "job_id": job_id ,
                    "company_id": companyid
                }
                  }).then((res) => {
                // console.log(res)
                if (res.data.check === true) {
                    console.log("성공")
                    if (res.data.data.status === "done") {
                        alert("결함 측정 작업이 끝났습니다.")
                        navigate(`/bridge/project/defect/measure/${projectId}`)
                        clearInterval(resultDefectDetector2)
                    } else if (res.data.data.status === "progress") {
                        alert("이미지 추출중입니다.")
                    } else if(res.data.data.status === "error"){
                        alert("해당 파일이 없습니다.")
                        clearInterval(resultDefectDetector2)
                    }
                } else {
                    console.log("실패")
                }
            })
        
    }
     /////////////////////////////////////////////////////////////////////////////////////////////////
     const MeasureStart = () => {
       
           axios({
            method: "post",
            url: API_URL +`/scheduler/job/start/${companyid}/${userid}`,
            headers: {
            "accept": `application/json`,
            "access-token": `${token}`,
            "Content-Type": `application/json`
            },
            data: {
            project_id: projectId,
            task_name: "Bridge_defectMeasure",
            interactive: false,
            tasks: measureList
        }
        }).then((res)=>{
            if (res.data.check === true) {
                console.log("성공")    
                alert(`${bridgeType} 결함 측정 작업을 시작합니다.`)  
                job_id = res.data.data.job_id
                /////30초마다 alert로 알려줌////////////
                resultDefectDetector2 = setInterval(confirmMeasure, 30000)
                // alert("lean_mean 작업")            
            } else {
                console.log("실패")
            }
        }).catch((err) => {
            console.log(err);
        });
    }

    return (
    <div>
        <div>
        <div style={{ width: "45rem"}}>
            <Form labelCol={{ span: 0, }} wrapperCol={{ span: 10, }} layout="horizontal">
            <Form.Item label='Model 선택'>
                <Select defaultValue="1" onChange={onChangeModel}>
                    <Select.Option value="1">Measure TF1.x</Select.Option>
                    <Select.Option value="2" disabled>Measure TF2.x</Select.Option>
                </Select>
            </Form.Item>
            </Form>
            <div>
                Weight 선택 : {""} {ModelData}
            </div>
        </div>
        <br/>
            <div style={{ marginRight: "10px" }}>
                <Button style={{ width: "150px" }} onClick={MeasureStart}>결함 측정 시작</Button>
            </div>
        </div>
    </div>
    )
}

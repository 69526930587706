import styles from '../../../../styles/Preprocess.module.css'
import styled from "styled-components";
import { Button, Form, Checkbox, Select, Input, Table} from 'antd';
import { useRecoilState, atom } from 'recoil';
import { useState } from 'react'
import axios from 'axios';
import { useEffect } from 'react';
import { API_URL } from '../../../../Store/Global';
import { dam_project_name, dam_name_kor, dam_name_eng, dam_type, dam_start_latitude, dam_end_latitude, dam_start_longitude, dam_end_longitude, dam_start_height, dam_end_height, dam_width_area_count, dam_height_area_count } from "../../../../Store/State/projectAtom";

export default function CreateSettingTab() {
    let token : string | null = localStorage.getItem("token")  

    const [projectName, setProjectName] = useRecoilState<any>(dam_project_name);
    const [korName, setKorName] = useRecoilState<any>(dam_name_kor);
    const [engName, setEngName] = useRecoilState<any>(dam_name_eng);
    const [type, setType] = useRecoilState<any[]>(dam_type);
    const [startLatitude, setStartLatitude] = useRecoilState<any>(dam_start_latitude); // 시작 위도
    const [endLatitude, setEndLatitude] = useRecoilState<any>(dam_end_latitude); // 끝 위도
    const [startLongitude, setStartLongitude] = useRecoilState<any>(dam_start_longitude); // 시작 경도
    const [endLongitude, setEndLongitude] = useRecoilState<any>(dam_end_longitude); // 끝 경도
    const [startHeight, setStartHeight] = useRecoilState<any>(dam_start_height); // 높이 시작 지점
    const [endHeight, setEndHeight] = useRecoilState<any>(dam_end_height); // 높이 끝 지점
    const [widthAreaCount, setWidthAreaCount] = useRecoilState<any>(dam_width_area_count); // 가로 영역 개수
    const [heightAreaCount, setHeightAreaCount] = useRecoilState<any>(dam_height_area_count); // 세로 영역 개수

    const list: any[] = [projectName, korName, engName, type, startLatitude, endLatitude, startLongitude, endLongitude, startHeight, endHeight, widthAreaCount, heightAreaCount]

    const handleProjectName = (event:any) => {
        setProjectName(event.target.value);
    }    

    const handleKorName = (event:any) => {
        setKorName(event.target.value);
    }

    const handleEngName = (event:any) => {
        setEngName(event.target.value);
    }

    const handleType = (e: any) => {
        let arr: any = [...type];
        console.log(e.target.value, e)
        if (e.target.checked === true) {
            if (arr.includes(e.target.value) === false) {
                arr.push(e.target.value)
            } 
        } else {
            arr = arr.filter((a:string) => a !== e.target.value)
        }
        setType(arr)
        console.log(arr)
    }

    const handleStartLatitude = (event:any) => {
        setStartLatitude(event.target.value);
    }  

    const handleEndLatitude = (event:any) => {
        setEndLatitude(event.target.value);
    }  

    const handleStartLongitude = (event:any) => {
        setStartLongitude(event.target.value);
    }  
    
    const handleEndLongitude = (event:any) => {
        setEndLongitude(event.target.value);
    }  
        
    const handleStartHeight = (event:any) => {
        setStartHeight(event.target.value);
    } 

    const handleEndHeight = (event:any) => {
        setEndHeight(event.target.value);
    } 

    const handleWidthAreaCount = (event:any) => {
        setWidthAreaCount(event.target.value);
    } 

    const handleHeightAreaCount = (event:any) => {
        setHeightAreaCount(event.target.value);
    } 

    

    return (
            <>
            <Form labelCol={{ span: 8, }} wrapperCol={{ span: 8 }} layout="horizontal">
                <Form.Item className={styles.FormItem}  label={<p style={{fontFamily: "inter-pretendard", fontSize:'2rem'}}>프로젝트 명</p>}>
                    <Input value={projectName} onChange={handleProjectName} type={"string"} />
                </Form.Item>
                <Form.Item className={styles.FormItem} label={<p style={{fontFamily: "inter-pretendard", fontSize:'2rem'}}>댐 이름(한)</p>}>
                    <Input value={korName} onChange={handleKorName} type={"string"}/>
                </Form.Item>
                <Form.Item className={styles.FormItem} label={<p style={{fontFamily: "inter-pretendard", fontSize:'2rem'}}>댐 이름(영)</p>}>
                    <Input value={engName} onChange={handleEngName} type={"string"}/>
                </Form.Item>
                <Form.Item className={styles.FormItem} label={<p style={{fontFamily: "inter-pretendard", fontSize:'2rem'}}>댐 구역</p>}>
                <Checkbox value="UpStream" checked={type.includes("UpStream") ? true : false} onClick={handleType}>상류</Checkbox>
                <Checkbox value="DamFloor" checked={type.includes("DamFloor") ? true : false} onClick={handleType}>댐마루</Checkbox>
                <Checkbox value="Overflow" checked={type.includes("Overflow") ? true : false} onClick={handleType}>하류(월류부)</Checkbox>
                <Checkbox value="DownStream" checked={type.includes("DownStream") ? true : false} onClick={handleType}>하류(비월류부)</Checkbox>
                <Checkbox value="Spillway" checked={type.includes("Spillway") ? true : false} onClick={handleType}>여수로</Checkbox>
                </Form.Item>
                <Form.Item className={styles.FormItem} label={<p style={{fontFamily: "inter-pretendard", fontSize:'2rem'}}>댐 시작 위도</p>}>
                    <Input value={startLatitude} suffix="°" onChange={handleStartLatitude} type={"number"} />
                </Form.Item>
                <Form.Item className={styles.FormItem} label={<p style={{fontFamily: "inter-pretendard", fontSize:'2rem'}}>댐 끝 위도</p>}>
                    <Input value={endLatitude} suffix="°" onChange={handleEndLatitude} type={"number"} />                         
                </Form.Item>
                <Form.Item className={styles.FormItem} label={<p style={{fontFamily: "inter-pretendard", fontSize:'2rem'}}>댐 시작 경도</p>}>
                    <Input value={startLongitude} suffix="°" onChange={handleStartLongitude} type={"number"} />  
                </Form.Item>
                <Form.Item className={styles.FormItem} label={<p style={{fontFamily: "inter-pretendard", fontSize:'2rem'}}>댐 끝 경도</p>}>
                    <Input value={endLongitude} suffix="°" onChange={handleEndLongitude} type={"number"} />  
                </Form.Item>
                <Form.Item className={styles.FormItem} label={<p style={{fontFamily: "inter-pretendard", fontSize:'2rem'}}>댐 높이 시작 지점</p>}>
                    <Input value={startHeight} suffix="m" onChange={handleStartHeight} type={"number"} />  
                </Form.Item>
                <Form.Item className={styles.FormItem} label={<p style={{fontFamily: "inter-pretendard", fontSize:'2rem'}}>댐 높이 끝 지점</p>}>
                    <Input value={endHeight} suffix="m" onChange={handleEndHeight} type={"number"} />  
                </Form.Item>
                <Form.Item className={styles.FormItem} label={<p style={{fontFamily: "inter-pretendard", fontSize:'2rem'}}>댐 가로 영역 갯수</p>}>
                    <Input value={widthAreaCount} suffix="개" onChange={handleWidthAreaCount} type={"number"} />  
                </Form.Item>
                <Form.Item className={styles.FormItem} label={<p style={{fontFamily: "inter-pretendard", fontSize:'2rem'}}>댐 높이 영역 갯수</p>}>
                    <Input value={heightAreaCount} suffix="개" onChange={handleHeightAreaCount} type={"number"} />  
                </Form.Item>      
            </Form>
            </>


    )
}

import React, { useState, useEffect } from 'react';
import { Layout } from '../../../../allcomponents';
import styles from '../../../../styles/Preprocess.module.css'
import axios from "axios";
import { API_URL, IMAGE_URL } from '../../../../Store/Global';
import { Select } from 'antd';
import { Box, Button } from '@mui/material';

const { Option } = Select;

interface ImgLoad {
    load : string;
    imageURL : Array<string>;    
}

export default function DamPreProcessFolderDivPage(props:any) {
    const projectType = localStorage.getItem('projectType');
    const curId = window.location.href.split('/')[6];
    let token : string | null = localStorage.getItem("token") 
    let projectId : string | null = localStorage.getItem("projectId")

    const [userid, setUserId] = useState<number>(-1);
    const [companyId, setcompanyId] = useState<any>(localStorage.getItem("companyId"));

    useEffect(() => {
        let getIdCompany = async () => {
            if(token !== null){ 
                console.log("여기 들어옴?")
                console.log("프로젝트ID"+projectId)
                const response = await axios({
                method : "get",
                url : `${API_URL}/account/auth/check/${token}`,                
                }).then(async(res)=>{
                if(res.data.check === true){
                    setUserId(res.data.data.id)
                    setcompanyId(res.data.data.companyId)
                    // localStorage.set("projectId", id);
                    console.log(`아이디는 다음과 같음 : ${res.data.data.id} / 회사는 다음과 같음 : ${res.data.data.companyId}`)
                    return {email : res.data.data.email, name : res.data.data.name, phone : res.data.data.phone, avatar : res.data.data.avatar, role : res.data.data.role, id : res.data.data.id, companyId : res.data.data.companyId}
                }else{
                    console.log("토큰 만료")
                    localStorage.removeItem('token')
                    alert("토큰이 만료었습니다 다시 로그인 해주세요")
                    window.location.replace("/Main")
                }
                }).catch((err)=>{
                console.log(err)
                })
            }
        }
        getIdCompany()
    },[])

    const [isSelect, setIsSelect] = useState<boolean>(false)

    const [typeArr, setType]=useState([])
    const [setting, setSetting]=useState<any>([])
    // const typeArr: any = JSON.parse(setting).dam_type

    const ImgLoad : ImgLoad[] = [];
    
    const [ImgLoadList, setImgLoadList]=useState<any[]>(ImgLoad)
    const [resultImageList, setresultImageList] = useState<boolean>(false)

    useEffect(()=>{
        axios({
            method: 'get',
            url: API_URL + `/project/view/${projectId}`,
            headers : {"accept" : `application/json`, "access-token" : `${token}`}, 
        }).then((res) => {
            const settings: any = JSON?.parse(res.data.data.settings)
                setSetting(settings)
                setType(settings.dam_type)     
        }).catch((err) => {
            console.log(err);
        });
        axios({
            method: 'get',
            url: API_URL + `/project/view/${projectId}`,
            headers : {"accept" : `application/json`, "access-token" : `${token}`}, 
        }).then((res) => {
            if (res.data.check === true) {
                const getSettings: any = JSON.parse(res.data.data.settings)
                    //폴더 내부 폴더 목록 가져오기///////////////////////////////////////
                    for(let i=0; i<getSettings.dam_type.length; i++){
                        axios({
                            method: 'get',
                            url: API_URL + "/file/folders",
                            headers : {"accept" : `application/json`, "access-token" : `${token}`}, 
                            params: {
                                path: `/project/${projectId}/stage2/${getSettings.dam_type[i]}/${getSettings.dam_eng}`
                            }
                        }).then((res1) => {
                            if (res1.data.check === true) {
                                for(let j=0; j<res1.data.data.folders.length; j++){
                                    axios({
                                        method: 'get',
                                        url: API_URL + "/file/folders",
                                        headers : {"accept" : `application/json`, "access-token" : `${token}`}, 
                                        params: {
                                            path: `/project/${projectId}/stage2/${getSettings.dam_type[i]}/${getSettings.dam_eng}/${res1.data.data.folders[j]}`
                                        }
                                    }).then((res2) => {
                                        if (res2.data.check === true) {
                                            console.log("성ㅇㅇㅇ공ㅇㅇㅇ")
                                            console.log(`/project/${projectId}/stage2/${getSettings.dam_type[i]}/${getSettings.dam_eng}`)
                                            // console.log(res2.data.data.folders)
                                            // console.log(res1.data.data.folders[j] + "/" + res2.data.data.folders[0] + "/S001")
                                            ImgLoad.push({
                                                // load : `${res1.data.data.folders[j]}/${res2.data.data.folders[0]}/S001`,
                                                load : `${res1.data.data.folders[j]}/${res2.data.data.folders[0]}/S001`,
                                                imageURL : []
                                            })

                                            axios({
                                                method: 'get',
                                                url: API_URL+'/file/files',
                                                headers: { "accept": `application/json`, "access-token": `${token}` },
                                                params: {
                                                    path: `/project/${projectId}/stage2/${getSettings.dam_type[i]}/${getSettings.dam_eng}/${res1.data.data.folders[j]}/${res2.data.data.folders[0]}/S001`
                                                }
                                            }).then((res3)=>{
                                                if (res3.data.check === true) {
                                                    console.log(res3.data)
                                                    console.log(getSettings.dam_type[i])
                                                    console.log(res1.data.data.folders[j])
                                                    console.log(res2.data.data.folders[0])
                                                    console.log(`/project/${projectId}/stage2/${getSettings.dam_type[i]}/${getSettings.dam_eng}/${res1.data.data.folders[j]}/${res2.data.data.folders[0]}/S001`)
                                                    for(let k = 0; k < res3.data.data.files.length; k++){
                                                        ImgLoad[j].imageURL.push(`${IMAGE_URL}/image?path=/project/${projectId}/stage2/${getSettings.dam_type[i]}/${getSettings.dam_eng}/${res1.data.data.folders[j]}/${res2.data.data.folders[0]}/S001/${res3.data.data.files[k]}&width=360`)
                                                    } 

                                                    setresultImageList(true);
                                                    setImgLoadList(ImgLoad)
                                                    console.log(ImgLoad)

                                                } else {
                                                    console.log("실패")
                                                }
                                            }).catch((err) => {
                                                console.log(err);
                                            });
                                        }
                                    }).catch((err) => {
                                        console.log(err);
                                    });
                                }                                
                            }
                        }).catch((err) => {
                            console.log(err);
                        });
                    }                    
            }
        }).catch((err) => {
            console.log(err);
        });
    },[])

    const rendering = (j:number) => {
        const resultImage:any[] = [];        
        ImgLoadList[j].imageURL.map((imageURL:any)=>{
            resultImage.push(<img src={imageURL} id={String(j)} alt={imageURL} key={imageURL} className={styles.DamImage}/>)
        }) 
        if(resultImage.length < 1){
            setresultImageList(false);
        }
        return resultImage;
    }

    const option_render=()=>{
        const arr:any[]=[];
        
        typeArr.map((type:any)=>{
            let name=''
            if(type==='Overflow'){
                name='월류부'
            }else if(type==='DamFloor'){
                name='댐마루'
            }else if (type === 'UpStream') {
                name='상류면'
            } else if (type === 'DownStream') {
                name ='비월류부'
            } else if (type === 'Spillway') {
                name ='여수로'
            }
            arr.push(<Option value={type}> {name}</Option>)
        })
        return arr;
    }
        
        const  modelSelect :any[]= [];
        const selectModel = () => {
            modelSelect.push(
                <Select placeholder="선택해주세요" style={{ width : '8rem'}} onChange={() => setIsSelect(true)}>
                    {option_render()}    
                </Select>
            ) 
    
            return modelSelect;
        }

    return (
        <Layout>
            <div style={{ paddingTop : '2.01rem', paddingLeft : '2rem', color: '#999', fontSize: '0.75rem', fontFamily: 'inter-pretendard', fontStyle: 'normal', fontWeight: 400, lineHeight: '100%'}}>전처리 &gt; 폴더 구분</div>
            <div style={{ paddingTop : '0.3rem', paddingLeft : '2rem', color: '#3A4557', fontSize: '1.5rem', fontFamily: 'inter-pretendard', fontStyle: 'normal', fontWeight: 700, lineHeight: '100%'}}>폴더 구분</div>
            <div style={{ marginTop: '1rem', marginLeft: '2rem'}}>
                <div style={{ marginBottom: '1rem', display : 'flex'}}>
                    <div>
                    <Box sx={{
                        display: 'block',
                        width: '8rem',
                        height: '2rem',
                        borderRadius: '0.5rem',
                        background: '#FFF',
                        boxShadow: '2px 2px 20px 0px rgba(58, 69, 87, 0.05)',
                        padding : '1rem'
                    }}>
                        {selectModel()}
                    </Box>
                    </div>
                </div>
                <div>
                    {
                        isSelect ? 
                        <div style={{ height: '43rem', overflowY:'scroll'}}>
                        {
                            ImgLoadList?.map((row:any, i:any)=>(
                                <Box sx={ImgLoadList[i].length > 12 ? { width: '99.75rem', height: '12.75rem', flexShrink: 0, borderRadius: '0.5rem', background: '#FFF', boxShadow: '2px 2px 20px 0px rgba(58, 69, 87, 0.05)', marginBottom: '1rem'} : { width: '99.75rem', height: '11.75rem', flexShrink: 0, borderRadius: '0.5rem', background: '#FFF', boxShadow: '2px 2px 20px 0px rgba(58, 69, 87, 0.05)', marginBottom: '1rem'}}>
                                <div style={{padding : '2rem'}}>
                                    {/* 네임라인 */}
                                    <div style={{ display : 'flex'}}>
                                        <div style={{ backgroundColor : '#0F6FB7', borderRadius : '50%', color : '#fff', width : '1.25rem', height : '1.25rem', textAlign : 'center', fontSize : '0.75rem', fontWeight:400, lineHeight:'175%'}}>{i+1}</div>
                                        <div style={{ marginLeft : '0.5rem', fontWeight : 600}}>{row.load}</div>
                                    </div>
                                    {/* 이미지라인 */}
                                    <div style={{ width : '95.75rem', marginTop : '1.25rem', whiteSpace : 'nowrap', overflow: 'auto'}}>
                                        { rendering(i) }
                                    </div>
                                </div>
                            </Box>
                            ))
                        }
                        </div>
                        
                        
                        
                        : <Box sx={{
                            width: '11.5rem',
                            height: '1.5rem',
                            borderRadius: '0.5rem',
                            background: '#FFF',
                            boxShadow: '2px 2px 20px 0px rgba(58, 69, 87, 0.05)',
                            padding : '0.75rem'
                        }}>
                            <div>구역을 먼저 선택해주세요.</div>
                        </Box>
                    }
                    
                </div>
            </div>
        </Layout>
    );
};
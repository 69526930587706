import { atom } from 'recoil';
import {v4 as uuidv4} from "uuid"

// 터널
// 프로젝트 명
let tunnel_project_name = atom<any>({
  key: 'tunnel_project_name',
  default : ''
})

// 터널 이름 한글
let tunnel_name_kor = atom<any>({
    key: 'tunnel_name_kor',
    default : ''
})

// 터널 이름 영어
let tunnel_name_eng = atom<any>({
    key: 'tunnel_name_eng',
    default : ''
})

// 터널 스캐너 이동 속도
let tunnel_scanner_speed = atom<any>({
    key: 'tunnel_scanner_speed',
    default : 0
})

// 진행 방향
let tunnel_direction = atom<any>({
    key: 'tunnel_direction',
    default : '상행'
})

// 영상 FPS
let tunnel_video_fps = atom<any>({
    key: 'tunnel_video_fps',
    default : 0
})

// 촬영 set 수
let tunnel_filming_set = atom<any[]>({
    key: 'tunnel_filming_set',
    default : [{ setNum : 1, firstCamera : 1, lastCamera : 1}]
})

// 카메라 수
let tunnel_camera_num = atom<any>({
    key: 'tunnel_camera_num',
    default : 0
})

// 스팬 수
let tunnel_span_num = atom<any>({
    key: 'tunnel_span_num',
    default : 0
})

// 스팬 길이
let tunnel_span_length = atom<any>({
    key: 'tunnel_span_length',
    default : 0
})

// 터널 종류
let tunnel_type = atom<any>({
    key: 'tunnel_type',
    default : ''
})

// 터널 너비
let tunnel_width = atom<any>({
    key: 'tunnel_width',
    default : 0
})

// 터널 길이
let tunnel_length = atom<any>({
    key: 'tunnel_length',
    default : 0
})

// 실제 균열 최대 폭
let tunnel_crack_max_width = atom<any>({
    key: 'tunnel_crack_max_width',
    default : 0
})

// 겹침률
let tunnel_overlap_rate = atom<any>({
    key: 'tunnel_overlap_rate',
    default : 0
})

// 댐
// 프로젝트 명
let dam_project_name = atom<any>({
    key: 'dam_project_name',
    default : ''
})

// 댐 이름 한글
let dam_name_kor = atom<any>({
    key: 'dam_name_kor',
    default : ''
})

// 댐 이름 영어
let dam_name_eng = atom<any>({
    key: 'dam_name_eng',
    default : ''
})

// 댐 구역
let dam_type = atom<any[]>({
    key: 'dam_type',
    default : []
})

// 시작 위도
let dam_start_latitude = atom<any>({
    key: 'dam_start_latitude',
    default : 36.12693
})

// 끝 위도
let dam_end_latitude = atom<any>({
    key: 'dam_end_latitude',
    default : 36.12537
})

// 시작 경도
let dam_start_longitude = atom<any>({
    key: 'dam_start_longitude',
    default : 128.9484
})

// 끝 경도
let dam_end_longitude = atom<any>({
    key: 'dam_end_longitude',
    default : 128.9461
})

// 높이 시작 지점
let dam_start_height = atom<any>({
    key: 'dam_start_height',
    default : 220
})

// 높이 끝 지점
let dam_end_height = atom<any>({
    key: 'dam_end_height',
    default : 280
})

// 가로 영역 개수
let dam_width_area_count = atom<any>({
    key: 'dam_width_area_count',
    default : 9
})

// 세로 영역 개수
let dam_height_area_count = atom<any>({
    key: 'dam_height_area_count',
    default : 4
})

// 교량
// 프로젝트 명
let bridge_project_name = atom<any>({
    key: 'bridge_project_name',
    default : ''
})

// 교량 종류
let bridge_type = atom<any>({
    key: 'bridge_type',
    default : ''
})

// 교량 이름 한글
let bridge_name_kor = atom<any>({
    key: 'bridge_name_kor',
    default : ''
})

// 교량 이름 영어
let bridge_name_eng = atom<any>({
    key: 'bridge_name_eng',
    default : ''
})

// 교량 길이
let bridge_length = atom<any>({
    key: 'bridge_length',
    default : 0
})

// 교량 부재
let bridge_sub_type = atom<any[]>({
    key: 'bridge_sub_type',
    default : []
})

// 경간의 개수
let bridge_span_count = atom<any>({
    key: 'bridge_span_count',
    default : 1
})

// 경간의 번호
let bridge_span_num = atom<any>({
    key: 'bridge_span_num',
    default : 0
})

// 경간 길이
let bridge_span_length = atom<any>({
    key: 'bridge_span_length',
    default : 10
})

// 경간 분류 길이
let bridge_span_class_length = atom<any>({
    key: 'bridge_span_class_length',
    default : 10
})

// 거더 개수
let girder_count = atom<any>({
    key: 'girder_count',
    default : 0
})

// 거더 촬영 횟수
let girder_camera_count = atom<any>({
    key: 'girder_camera_count',
    default : 0
})

// 거더 하면 폭
let girder_width = atom<any>({
    key: 'girder_width',
    default : 0
})

// 거더 측면 높이
let girderside_height = atom<any>({
    key: 'girderside_height',
    default : 0
})

// 교각 개수
let pier_count = atom<any>({
    key: 'pier_count',
    default : 1
})

// 교각 촬영 횟수
let pier_film_count = atom<any>({
    key: 'pier_film_count',
    default : 0
})

// 교각 높이
let pier_height = atom<any>({
    key: 'pier_height',
    default : 0
})

// 교각 반지름
let pier_radius = atom<any>({
    key: 'pier_radius',
    default : 0
})

// 슬라브 개수
let slab_count = atom<any>({
    key: 'slab_count',
    default : 1
})

// 슬라브 폭
let slab_width = atom<any>({
    key: 'slab_width',
    default : 10
})

// 교각 리스트
let pier_number_list = atom<any[]>({
    key: 'pier_number_list',
    default : [{key: 1, num : "9"}]
})

// 스팬 리스트
let span_number_list = atom<any[]>({
    key: 'span_number_list',
    default : [{key : 1, num : "1"}]
})


export {tunnel_project_name, tunnel_name_kor, tunnel_name_eng, tunnel_scanner_speed, tunnel_direction, tunnel_video_fps, tunnel_filming_set, tunnel_camera_num, tunnel_span_num, tunnel_span_length, tunnel_type, tunnel_width, tunnel_length, tunnel_crack_max_width, tunnel_overlap_rate,
    dam_project_name, dam_name_kor, dam_name_eng, dam_type, dam_start_latitude, dam_end_latitude, dam_start_longitude, dam_end_longitude, dam_start_height, dam_end_height, dam_width_area_count, dam_height_area_count,
    bridge_project_name, bridge_type, bridge_name_kor, bridge_name_eng, bridge_length, bridge_sub_type, bridge_span_count, bridge_span_num, bridge_span_length, bridge_span_class_length,
    girder_count, girder_camera_count, girder_width, girderside_height, pier_count, pier_film_count, pier_height, pier_radius, slab_count, slab_width, pier_number_list, span_number_list
}


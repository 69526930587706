import React, { useState, useEffect } from 'react';
import styles from '../../../../styles/Preprocess.module.css'
import axios from "axios";
import { Layout } from '../../../../allcomponents';
import { useNavigate } from 'react-router-dom';
import { API_URL, IMAGE_URL } from '../../../../Store/Global';
import { Box, Button, Table, TableBody, TableCell, tableCellClasses, TableContainer, TableHead, TableRow, TableFooter, Pagination, styled } from '@mui/material';

interface Image {
  num : Number;
  folderName : string;
  imageURL : Array<string>;
  joint: Number;
}

interface JointEdit {
  input_folder : string;
  add : Array<string>;
  remove : Array<string>;
}

export default function TunnelPreProcessJtagConfirmPage() {
    const projectType = localStorage.getItem('projectType');
    const curId = window.location.href.split('/')[6];
    let token : string | null = localStorage.getItem("token") 
    let projectId : string | null = localStorage.getItem("projectId")

    const [userid, setUserId] = useState<number>(-1);
    const [companyId, setcompanyId] = useState<any>(localStorage.getItem("companyId"));

    // 프로젝트 영문이름 + 상행,하행 (폴더, 이미지 만들 때 상관있음)
    const [ProjectNameEn, setProjectNameEn] = useState<string>("")

    const navigate = useNavigate();
    const [page, setPage] = React.useState(1);
    const [data, setData] = useState<any[]>([]);

    useEffect(() => {
      setData(ImgListAll.slice((page-1)*10, page*10));
    }, [page]);
    const handlePage = (event:any) => {
      const nowPageInt = parseInt(event.target.outerText);
      console.log(event.target)
      
      if(event.target.outerText) {
        setPage(nowPageInt);
      }
      else {
        if(event.target.ariaLabel.includes("prev")) {
          setPage(page-1);
        }
        if(event.target.ariaLabel.includes("next")) {
          setPage(page+1);
        }
  
      }
      
    }

    const imagesAll : Image [] = [];
    const imagesJoint : Image [] = [];
    const JointEdit : JointEdit [] = [];
    const JointCopy:any[] = [];
    const SpanMove :any[] = [];

    let UpdateArr:any[] = [];

    let [nowImage, setNowImage] = useState<any[]>(imagesJoint)

    const [ImgListAll, setImgListAll] = useState<any[]>(imagesAll);
    const [ImgListJoint, setImgListJoint] = useState<any[]>(imagesJoint);

    const [ChangeJointList, setChangeJointList] = useState<any[]>(JointEdit);

    const [spanMoveTask, setspanMoveTask] = useState<any[]>([]);
    const [JointCopyTask, setJointCopyTask] = useState<any[]>([]);


    const [result, setResult] = useState<boolean>(false)
    const [all, setAll] = useState<boolean>(true)

    const rendering = (i:number) => {
      const result:any[] = [];
      // console.log(nowImage, "::::::::::::::::::::::::::::::::::::::");
;
      if(all === true){
          ImgListAll[i].imageURL.map((imageURL:any)=>{
              // console.log(ImgListJoint[i].imageURL.includes(imageURL))
              result.push((<img src={imageURL} id={String(i)} key={imageURL} alt={imageURL} style={{width: '6.625rem', height: '11.75rem', marginTop : '1.25rem', marginRight: '1rem', flexShrink: 0, cursor: 'pointer'}} className={ImgListJoint[i].imageURL.includes(imageURL) ? styles.JtagImageBorder : styles.JtagImage} onClick={onClickimage}/>))
          })
      }else{
          ImgListJoint[i].imageURL.map((imageURL:any)=>{
              result.push((<img src={imageURL} id={String(i)} key={imageURL} alt={imageURL} style={{width: '6.625rem', height: '11.75rem', marginTop : '1.25rem', marginRight: '1rem', flexShrink: 0, cursor: 'pointer'}} className={ImgListJoint[i].imageURL.includes(imageURL) ? styles.JtagImageBorder : styles.JtagImage} onClick={onClickimage}/>))
          })
      }
          
      if(result.length < 1){
          setResult(false);
      }

      return result;
  }

  useEffect(() => {
    const response = axios({
        method: 'get',
        url: API_URL + `/project/view/${projectId}`,
        headers : {"accept" : `application/json`, "access-token" : `${token}`}, 
    }).then((res) => {
            const settings: any = JSON.parse(res.data.data.settings)
            if (res.data.check === true) {
                // console.log(settings.cameraCount)

                setProjectNameEn(settings.tunnel_eng + '_' + settings.direction)

                for(let i = 1; i < settings.cameraCount+1; i++){
                    imagesAll.push({ 
                        num:i, 
                        folderName:"C"+ String(i).padStart(2,"0"), 
                        imageURL: [],
                        joint: 0})
            
                    imagesJoint.push({ 
                        num:i, 
                        folderName:"C"+ String(i).padStart(2,"0"), 
                        imageURL: [],
                        joint: 0})
            
                    JointEdit.push({
                        // input_folder : "stage1_2/C0" + i,
                        input_folder : "stage1_2/C" + String(i).padStart(2,"0"),
                        add : [],
                        remove : []
                    })
            
                    JointCopy.push({
                        input_folder : "stage1_2/C" + String(i).padStart(2,"0"),
                        output_folder : `stage2/${settings.tunnel_eng + '_' + settings.direction}/C` + String(i).padStart(2,"0")
                    })
            
                    SpanMove.push({
                        input_folder : `stage2/${settings.tunnel_eng + '_' + settings.direction}/C` + String(i).padStart(2,"0"),
                        start_no :  0
                    })
                }

                let copyArrImgListAll = [...ImgListAll];
                console.log(ImgListAll)

    for(let i = 0; i < settings.cameraCount; i++){
        axios({
            method: 'get',
            url: API_URL+'/File/Files',
            headers: { "accept": `application/json`, "access-token": `${token}` },
            params: {
                path: `/project/${projectId}/stage1_2/${imagesAll[i].folderName}`
            }
        }).then((res)=>{
            if (res.data.check === true) {
                console.log("성공")

                // setImgList123(images);
                console.log(res.data.data.files.length)
                for(let j=0; j<res.data.data.files.length; j++){

                    imagesAll[i].imageURL.push(`${IMAGE_URL}/image?path=/project/${projectId}/stage1_2/${imagesAll[i].folderName}/${res.data.data.files[j]}&width=360`)

                    if(imagesAll[i].imageURL[j].includes("_J")){
                        imagesJoint[i].imageURL.push(`${IMAGE_URL}/image?path=/project/${projectId}/stage1_2/${imagesAll[i].folderName}/${res.data.data.files[j]}&width=360`)
                    }
                }
            // console.log(imagesJoint[i].imageURL.length)
            copyArrImgListAll[i] = { ...copyArrImgListAll[i], joint : imagesJoint[i].imageURL.length};
            console.log(copyArrImgListAll)
            setImgListAll(copyArrImgListAll)
            setData(copyArrImgListAll.slice((page-1)*10, page*10));
            setResult(true);

            } else {
                console.log("실패")
            }
        }).catch((err) => {
            console.log(err);
        });
        
    }

    console.log(ImgListAll)
    console.log(imagesJoint)

            }

    }).catch((err) => {
        console.log(err);
    });


    setJointCopyTask(JointCopy)
    setspanMoveTask(SpanMove)

}, [])

    useEffect(() => {
      let getIdCompany = async () => {
        if (token !== null) {
            console.log("프로젝트ID" + projectId);
          const response = await axios({
            method: "get",
            url: `${API_URL}/account/auth/check/${token}`,
          })
            .then(async (res) => {
              if (res.data.check === true) {
                setUserId(res.data.data.id)
                setcompanyId(res.data.data.companyId)
                // localStorage.set("projectId", id);
                console.log(
                  `아이디는 다음과 같음 : ${res.data.data.id} / 회사는 다음과 같음 : ${res.data.data.companyId}`
                );
                return {
                  email: res.data.data.email,
                  name: res.data.data.name,
                  phone: res.data.data.phone,
                  avatar: res.data.data.avatar,
                  role: res.data.data.role,
                  id: res.data.data.id,
                  companyId: res.data.data.companyId,
                };
              } else {
                console.log("토큰 만료");
                localStorage.removeItem("token");
                alert("토큰이 만료었습니다 다시 로그인 해주세요");
                window.location.replace("/Main");
              }
            })
            .catch((err) => {
              console.log(err);
            });
        }
      };
      getIdCompany();
    }, []);


    

  const onClickAll = () => {
      setAll(true);
  }

  const onClickJtag = () => {
      setAll(false);
  }

  let copyArrJointEdit = [...ChangeJointList];

  const onClickimage = (e:any) => {     
      console.log(ImgListJoint,"ImgListJoint")
      
      const indexFront = e.target.src.indexOf("C") 
      const indexBack= e.target.src.indexOf(".png")   
      const ImageUrlCutWidth = e.target.src.substring(indexFront+4, indexBack+4)

      if(ImgListJoint[e.target.id].imageURL.includes(e.target.src) === false){
          ImgListJoint[e.target.id].imageURL.push(e.target.src)


          if(e.target.src.includes("_J") === false){
              console.log("ADD")
              copyArrJointEdit[e.target.id].add.push(ImageUrlCutWidth);
          }else{
              console.log("OUTREMOVE")
              for(let i = 0; i < copyArrJointEdit[e.target.id].remove.length; i++) {
                  console.log(copyArrJointEdit[e.target.id].remove.length)
                  if(copyArrJointEdit[e.target.id].remove[i] === e.target.src)  {
                      copyArrJointEdit[e.target.id].remove.splice(i, 1);
                  }
              }
          }
      }else{
          for(let i = 0; i < ImgListJoint[e.target.id].imageURL.length; i++) {
              if(ImgListJoint[e.target.id].imageURL[i] === e.target.src)  {
                  ImgListJoint[e.target.id].imageURL.splice(i, 1);
              }
          }
          if(e.target.src.includes("_J") === false){
              console.log("OUTADD")
              for(let i = 0; i < copyArrJointEdit[e.target.id].add.length; i++) {
                  console.log(copyArrJointEdit[e.target.id].add.length)
                  if(copyArrJointEdit[e.target.id].add[i] === e.target.src)  {
                      copyArrJointEdit[e.target.id].add.splice(i, 1);
                  }
              }
          }else{
              console.log("REMOVE")
              copyArrJointEdit[e.target.id].remove.push(ImageUrlCutWidth);
          }
      }

      ImgListAll.map((_,i) => {
          ImgListJoint[i].imageURL.map((nowUrl:String)=>{
              // console.log(nowUrl)
              UpdateArr.push(nowUrl)
          })
      })
      
      setNowImage(UpdateArr)
      setChangeJointList(copyArrJointEdit);

      console.log(ChangeJointList);
  }

  /////////////////30초마다 status알려주는 alert//////////////////////////////////////////
  let job_id = 0;
  let resultstatus :any;
  const confirm = () => {
      console.log(job_id)
      axios({
          method: "post",
          url: API_URL + '/scheduler/job/query',
          headers: { "accept": `application/json`, "access-token": `${token}`, "Content-Type" : `application/json` },
              data: {
                  "job_id": job_id ,
                  "company_id": companyId
              }
          }).then((res) => {
              console.log(res)
              if (res.data.check == true) {
                  console.log("성공")
                  // console.log(res.data.data.status)
                  if (res.data.data.status === "done") {
                      alert("J-tag Confirm 작업이 끝났습니다.")
                      // setTask([])
                      clearInterval(resultstatus)
                      window.location.href='../Panorama/Setting'
                  } else if (res.data.data.status === "progress") {
                      // alert("이미지 추출중입니다.")
                  } else if(res.data.data.status === "error"){
                      alert("해당 파일이 없습니다.")
                  }
              } else {
                  console.log("실패")
              }
          })
      
  }
  /////////////////////////////////////////////////////////////////////////////////////////////////

  const onClickJtagEdit = () => {
      // console.log(JointCopyTask)
      // console.log(ChangeJointList)
      // console.log(spanMoveTask)
    //   alert("J-tag 편집이 완료되었습니다. 해당 내용으로 파노라마를 생성합니다.")
    //   setTimeout(function() {
    //     alert("파노라마 생성이 완료되었습니다.")
    //     navigate(`/project/panorama/imageedit/${projectId}`)
    //   }, 4000);

      // Joint 편집 실행
      axios({
          method: "post",
          url: API_URL +`/scheduler/job/start/${companyId}/${userid}`,
          headers: {
            "accept": `application/json`,
            "access-token": `${token}`,
            "Content-Type": `application/json`
          },
          data: {
              projectId: projectId,
              task_name: "joint_edit",
              interactive: false,
              tasks: ChangeJointList
          }
      }).then((res)=>{
          if (res.data.check === true) {
              console.log(" 성공")
              alert("J-tag 편집")

              //Joint 복사 (stage1_2 -> stage2)
              axios({
                  method: "post",
                  url: API_URL +`/scheduler/job/start/${companyId}/${userid}`,
                  headers: {
                    "accept": `application/json`,
                    "access-token": `${token}`,
                    "Content-Type": `application/json`
                  },
                  data: {
                      projectId: projectId,
                      task_name: "joint_copy",
                      interactive: false,
                      tasks: JointCopyTask
                  }
              }).then((res)=>{
                  if (res.data.check === true) {
                      console.log(" 성공")

                      //SpanMove 실행
                      axios({
                          method: "post",
                          url: API_URL +`/scheduler/job/start/${companyId}/${userid}`,
                          headers: {
                            "accept": `application/json`,
                            "access-token": `${token}`,
                            "Content-Type": `application/json`
                          },
                          data: {
                              projectId: projectId,
                              task_name: "span_move",
                              interactive: false,
                              tasks: spanMoveTask
                          }
                      }).then((res)=>{
                          if (res.data.check === true) {
                              console.log(" 성공")
                              
                              job_id = res.data.data.job_id
                              /////30초마다 alert로 알려줌////////////
                              resultstatus = setInterval(confirm, 30000)
                              alert("J-tag 편집이 완료되었습니다. 탭에서 파노라마 생성 버튼을 누르세요.")
                              navigate(`/project/preprocess/setting/${projectId}`)

                            //   루틴 프로세스 경우
                            //   alert("J-tag 편집이 완료되었습니다. 해당 내용으로 파노라마를 생성합니다.")
                            //   alert("파노라마 생성이 완료되었습니다.")
                            // navigate(`/project/panorama/imageedit/${projectId}`)
                          } else {
                              console.log(" 실패")
                          }
                      }).catch((err) => {
                          console.log(err);
                      });

                  } else {
                      console.log(" 실패")
                  }
              }).catch((err) => {
                  console.log(err);
              });

          } else {
              console.log(" 실패")
          }
      }).catch((err) => {
          console.log(err);
      });
  }


      const StyledTableCell = styled(TableCell)(({ theme }) => ({
        [`&.${tableCellClasses.head}`]: {
          fontSize: '1rem',
          fontWeight: 600,
          backgroundColor: '#F5F5F5',
          color: '#3A4557',
          width: '42.8125rem',
          height: '2.5rem',
          border: '1px solid #DDD',
          borderTop: '0.15rem solid #3A4557',
          padding : 0
        },
        [`&.${tableCellClasses.body}`]: {
          color: '#3A4557',
          textAlign: 'center',
          fontSize: '0.8125rem',
          width: '42.8125rem',
          height: '3rem',
          border: '1px solid #DDD',
          padding : 0,
        },
      }));
      
      const StyledTableRow = styled(TableRow)(({ theme }) => ({
        // '&:nth-of-type(odd)': {
        //   backgroundColor: theme.palette.action.hover,
        // },
        // hide last border
        '&:last-child td': {
        },

      }));

    return (
        <Layout>
            <div style={{ display: 'flex', alignItems : 'center'}}>
                <div>
                    <div style={{ paddingTop : '2.01rem', paddingLeft : '2rem', color: '#999', fontSize: '0.75rem', fontFamily: 'inter-pretendard', fontStyle: 'normal', fontWeight: 400, lineHeight: '100%'}}>전처리 &gt; J태그 검증</div>
                    <div style={{ paddingTop : '0.3rem', paddingLeft : '2rem', color: '#3A4557', fontSize: '1.5rem', fontFamily: 'inter-pretendard', fontStyle: 'normal', fontWeight: 700, lineHeight: '100%'}}>J태그 검증</div>
                </div>
                <div style={{ marginLeft : '81rem', float : 'right', marginBottom : '-2.5rem' }}>
                    <Button variant="outlined" sx={{ backgroundColor : '#fff', fontWeight:700, marginRight: '0.5rem', width: '5.9375rem', fontFamily: 'inter-pretendard' }} onClick={()=>onClickAll()}>전체</Button>
                    <Button variant="contained" sx={{ fontWeight:700, width: '5.9375rem', fontFamily: 'inter-pretendard' }} onClick={()=>onClickJtag()}>J태그</Button>
                </div>
            </div>
            <div style={{ display : 'flex'}}>
                <div>
                
                <Box sx={{ width: '20rem', backgroundColor: 'white', borderRadius: '0.5rem', boxShadow: '2px 2px 20px 0px rgba(58, 69, 87, 0.05)', padding : '2rem', marginLeft : '2rem', marginTop: '1rem', paddingBottom:'2.5rem'}}>
                    <div style={{ display: 'flex', alignItems : 'center', justifyContent : 'space-between'}}>
                        <div style={{ fontWeight : 700, marginBottom : '2.13rem', borderLeft: '0.1875rem solid #0F6FB7', fontSize:'1rem', height:'1.2rem', paddingLeft: '0.62rem'}}>이미지 파일 리스트 </div>
                        <div>
                            <Button variant="contained" size="small" sx={{ float : 'right', marginBottom : '0.5rem', marginRight:'1rem', fontFamily: 'inter-pretendard', fontSize: '0.875rem', background: '#0F6FB7' }} onClick={()=>onClickJtagEdit()}>J태그 편집</Button>
                        </div>
                    </div>
                    <div>
                        <TableContainer  sx={{ width: '18rem', marginLeft: '1rem', marginTop: '1rem' }}>
                            <Table sx={{ backgroundColor: '#fffff' }} aria-label="customized table">
                                <TableHead>
                                <TableRow >
                                    <StyledTableCell align="center" style={{width:'5rem', fontFamily: 'inter-pretendard'}}>번호</StyledTableCell>
                                    <StyledTableCell align="center" style={{width:'7.5rem', fontFamily: 'inter-pretendard'}}>이름</StyledTableCell>
                                    <StyledTableCell align="center" style={{width:'7.5rem', fontFamily: 'inter-pretendard'}}>조인트 개수</StyledTableCell>
                                </TableRow>
                                </TableHead>
                                
                                <TableBody>
                                {data.map((img, idx) => (
                                    <StyledTableRow 
                                        key={idx}
                                        style={{ height: '2.5rem'}}
                                    >
                                    {/* <StyledTableCell component="th" scope="row" align="center">
                                        {row.num}
                                    </StyledTableCell> */}
                                    <StyledTableCell align="center" style={{width:'5rem', height: '2.5rem', fontFamily: 'inter-pretendard'}}  key={idx}>{(page-1)*10+(idx+1)}</StyledTableCell>
                                    <StyledTableCell align="center" style={{width:'7.5rem', height: '2.5rem', fontFamily: 'inter-pretendard'}}  key={idx+1000}>{(page-1)*10+(idx+1) >= 10 ? `C${(page-1)*10+(idx+1)}` : `C0${(page-1)*10+(idx+1)}`}</StyledTableCell>
                                    <StyledTableCell align="center" style={{width:'7.5rem', height: '2.5rem', fontFamily: 'inter-pretendard'}}  key={idx+2000}>{img.joint}</StyledTableCell>
                                    </StyledTableRow> 
                                ))}
                                </TableBody>
                            </Table>
                            <TableFooter>
                                <TableRow style={{ width: '18rem', display : 'flex', justifyContent: 'center', marginTop:'4rem'}}>
                                <Pagination count={ImgListAll.length > 10 && ImgListAll.length % 10 === 0 ? Math.floor(ImgListAll.length/10) : Math.floor(ImgListAll.length/10)+1} defaultPage={1} onChange={(e) => handlePage(e)} color="primary" 
                                    sx={{
                                      '& svg': {
                                        pointerEvents: 'none'
                                      }
                                    }}
                                  />
                                </TableRow>
                                </TableFooter>
                        </TableContainer>
                    </div>
                </Box> 
                </div>
                <div style={{ marginTop: '1rem', height : '50rem', overflowY : 'scroll'}}>
                    {
                      result === true ? 
                      ImgListAll.map((_, i) => (
                            <div key={i}>
                              <Box sx={{ width: '73.6875rem', height: '20rem', flexShrink: 0, borderRadius: '0.5rem', background: '#FFF', boxShadow: '2px 2px 20px 0px rgba(58, 69, 87, 0.05)', marginLeft : '2rem', marginBottom: '1rem'}}>
                                <div style={{padding : '2rem'}}>
                                    {/* 네임라인 */}
                                    <div style={{ display: 'flex', justifyContent: 'space-between'}}>
                                            <div style={{ display : 'flex'}}>
                                                <div style={{ backgroundColor : '#0F6FB7', borderRadius : '50%', color : '#fff', width : '1.25rem', height : '1.25rem', textAlign : 'center', fontSize : '0.75rem', fontWeight:400, lineHeight:'175%'}}>{i+1}</div>
                                                <div style={{ marginLeft : '0.5rem', fontWeight : 600}}>{i+1 >= 10 ? `C${i+1}` : `C0${i+1}`}</div>
                                            </div>
                                    </div>
                                    {/* 이미지라인 */}
                                    <div style={{ width : '69.65rem', height : '15rem', marginTop : '0.25rem', whiteSpace : 'nowrap', overflow: 'auto'}}>
                                      { rendering(i) }
                                        
                                    </div>
                                </div>
                            </Box>
                              </div>
                        ))
                        :   
                        <div>
                            이미지 로딩 중 입니다.
                        </div>
                    }
                </div>
            </div>
        </Layout>
    );
};


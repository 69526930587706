import React, { useState, useEffect } from 'react';
import { Layout } from '../../../allcomponents';
import { useNavigate } from 'react-router-dom';
import { styled } from '@mui/material/styles';
import axios from "axios";
import { API_URL, IMAGE_URL } from '../../../Store/Global';
import { Select} from 'antd';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import DownloadIcon from '@mui/icons-material/Download';

const { Option } = Select;

function createData(
    num: string,
    name: string,
  ) {
    return { num, name };
  }
  
  const rows = [
    createData('1', '외관조사망도.dwg'),
    createData('2', '상태평가보고서.docx'),
  ];

export default function TunnelDownloadPage(props:any) {
    const projectType = localStorage.getItem('projectType');
    const curId = window.location.href.split('/')[6];
    let token : string | null = localStorage.getItem("token") 
    let projectId : string | null = localStorage.getItem("projectId")
    const [typeName, setTypeName] = useState<string>();

      const navigate = useNavigate();
      const [page, setPage] = React.useState(0);
      const [rowsPerPage, setRowsPerPage] = React.useState(10);

      // Avoid a layout jump when reaching the last page with empty rows.
        const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;

        const handleChangePage = (
            event: React.MouseEvent<HTMLButtonElement> | null,
            newPage: number,
            ) => {
            setPage(newPage);
        };

    const StyledTableCell = styled(TableCell)(({ theme }) => ({
      [`&.${tableCellClasses.head}`]: {
        fontSize: '1rem',
        fontWeight: 600,
        backgroundColor: '#F5F5F5',
        color: '#3A4557',
        width: '42.8125rem',
        height: '2.5rem',
        border: '1px solid #DDD',
        borderTop: '0.15rem solid #3A4557',
        padding : 0
      },
      [`&.${tableCellClasses.body}`]: {
        color: '#3A4557',
        textAlign: 'center',
        fontSize: '0.8125rem',
        width: '42.8125rem',
        height: '3rem',
        border: '1px solid #DDD',
        padding : 0,
      },
    }));
    
    const StyledTableRow = styled(TableRow)(({ theme }) => ({
      // '&:nth-of-type(odd)': {
      //   backgroundColor: theme.palette.action.hover,
      // },
      // hide last border
      '&:last-child td': {
      },

    }));

    const [isDxf, setIsDxf] = useState<boolean>(false)
    const [isDwg, setIsDwg] = useState<boolean>(false)
    const [isDocx, setIsDocx] = useState<boolean>(false)
    const [dxfName, setDxfName] = useState<string>()
    const [docxName, setDocxName] = useState<string>()

    useEffect(() => {
      if (projectType === 'dam') {
        // 외관조사망도 파일 생성되었는지
        let drawer_path: any = `/project/${projectId}/stage8/${typeName}/`
        axios({
          method: "get",
          url: API_URL + '/File/Files',
          headers: { "accept": `application/json`, "access-token": `${token}` },
          params: { path: drawer_path }
        }).then((res) => {
            if (res.data.check === true) {
                let file = ""
                let down_file =""
                for (file of res.data.data.files) {
                    if (file.includes('.dxf')) {
                        down_file = file
                        setDxfName(down_file)
                        setIsDxf(true);
                    }
                }
                
                
            } else {
                console.log("실패")
            }
        })
        // 상태평가보고서 파일 생성되었는지
        let estimator_path: any = `/project/${projectId}/stage9/${typeName}/`
        axios({
          method: "get",
          url: API_URL + '/File/Files',
          headers: { "accept": `application/json`, "access-token": `${token}` },
          params: { path: estimator_path }
        }).then((res) => {
            if (res.data.check === true) {
                let file = ""
                let down_file = ""
                for (file of res.data.data.files) {
                    if (file.includes('docx')) {
                        down_file=file
                        setDocxName(down_file)
                    }
                }
                setIsDocx(true);
            } else {
                console.log("실패")
            }
            
        })
      }
      else if (projectType === 'tunnel') {
        // 외관조사망도 파일 생성되었는지
        let drawer_path: any = `/project/${projectId}/stage8/`
        axios({
          method: "get",
          url: API_URL + '/File/Files',
          headers: { "accept": `application/json`, "access-token": `${token}` },
          params: { path: drawer_path }
        }).then((res) => {
            if (res.data.check === true) {
                let file = ""
                let down_file =""
                for (file of res.data.data.files) {
                    if (file.includes('.dxf')) {
                        down_file = file
                        setDxfName(down_file)
                    }
                }
                setIsDxf(true);
                
            } else {
                console.log("실패")
            }
        })
        // 상태평가보고서 파일 생성되었는지
        let estimator_path: any = `/project/${projectId}/stage9/`
        axios({
          method: "get",
          url: API_URL + '/File/Files',
          headers: { "accept": `application/json`, "access-token": `${token}` },
          params: { path: estimator_path }
        }).then((res) => {
            if (res.data.check === true) {
                let file = ""
                let down_file = ""
                for (file of res.data.data.files) {
                    if (file.includes('docx')) {
                        down_file=file
                        setDocxName(down_file)
                    }
                }
                setIsDocx(true);
            } else {
                console.log("실패")
            }
            
        })
      }
      else if (projectType === 'bridge') {
        // 외관조사망도 파일 생성되었는지
        let drawer_path: any = `/project/${projectId}/stage8/${typeName}/`
        // let drawer_path: any = `/project/${projectId}/stage8/${typeName}/`
        axios({
          method: "get",
          url: API_URL + '/File/Files',
          headers: { "accept": `application/json`, "access-token": `${token}` },
          params: { path: drawer_path }
        }).then((res) => {
          
            if (res.data.check === true) {
                let file = ""
                let down_file =""
                for (file of res.data.data.files) {
                  console.log(file)
                    if (file.includes('.dwg') || file.includes('.dxf')) {
                      down_file = file
                      setDxfName(down_file)
                      setIsDxf(true)
                  }
                }
                
                
            } else {
                console.log("외관조사망도 없음")
            }
        })
        // 상태평가보고서 파일 생성되었는지
        let estimator_path: any = `/project/${projectId}/stage9/${typeName}/`
        axios({
          method: "get",
          url: API_URL + '/File/Files',
          headers: { "accept": `application/json`, "access-token": `${token}` },
          params: { path: estimator_path }
        }).then((res) => {
            if (res.data.check === true) {
                let file = ""
                let down_file = ""
                for (file of res.data.data.files) {
                    if (file.includes('docx')) {
                        down_file=file
                        setDocxName(down_file)
                    }
                }
                setIsDocx(true);
            } else {
                console.log("상태평가보고서 없음")
            }
            
        })
      }
  }, [typeName])

    const download = (type:any) => {
      if (projectType === 'dam') {
        if(type.includes("dxf")) {
          let drawer_path: any = `/project/${projectId}/stage8/${typeName}/`
          axios({
            method: "get",
            url: API_URL + '/File/Files',
            headers: { "accept": `application/json`, "access-token": `${token}` },
            params: { path: drawer_path }
        }).then((res) => {
            if (res.data.check === true) {
                let file = ""
                let down_file =""
                for (file of res.data.data.files) {
                    if (file.includes('.dxf')) {
                        down_file = file
                    }
                }
                console.log(down_file)
                const link = document.createElement('a');
                link.href = `${IMAGE_URL}/image?path=` + drawer_path + `${down_file}`;
                link.setAttribute('download', 'file.dwg'); //or any other extension
                document.body.appendChild(link);
                link.click();
                
            } else {
                console.log("실패")
            }
        })
        }
        if(type.includes("docx")) {
          let estimator_path: any = `/project/${projectId}/stage9/${typeName}/`
          axios({
            method: "get",
            url: API_URL + '/File/Files',
            headers: { "accept": `application/json`, "access-token": `${token}` },
            params: { path: estimator_path }
        }).then((res) => {
            if (res.data.check === true) {
                let file = ""
                let down_file = ""
                for (file of res.data.data.files) {
                    if (file.includes('docx')) {
                        down_file=file
                    }
                }
                
                const link = document.createElement('a');
                link.href = `${IMAGE_URL}/image?path=`+estimator_path+`${down_file}`;
                link.setAttribute('download', 'file.docx'); //or any other extension
                document.body.appendChild(link);
                link.click();
                
            } else {
                console.log("실패")
            }
            
        })
        }
      } else if (projectType === 'tunnel') {
        if(type.includes("dxf")) {
          let drawer_path: any = `/project/${projectId}/stage8/`
          axios({
            method: "get",
            url: API_URL + '/File/Files',
            headers: { "accept": `application/json`, "access-token": `${token}` },
            params: { path: drawer_path }
        }).then((res) => {
            if (res.data.check === true) {
                let file = ""
                let down_file =""
                for (file of res.data.data.files) {
                    if (file.includes('.dxf')) {
                        down_file = file
                    }
                }
                console.log(down_file)
                const link = document.createElement('a');
                link.href = `${IMAGE_URL}/image?path=` + drawer_path + `${down_file}`;
                link.setAttribute('download', 'file.dwg'); //or any other extension
                document.body.appendChild(link);
                link.click();
                
            } else {
                console.log("실패")
            }
        })
        }
        if(type.includes("docx")) {
          let estimator_path: any = `/project/${projectId}/stage9/`
          axios({
            method: "get",
            url: API_URL + '/File/Files',
            headers: { "accept": `application/json`, "access-token": `${token}` },
            params: { path: estimator_path }
        }).then((res) => {
            if (res.data.check === true) {
                let file = ""
                let down_file = ""
                for (file of res.data.data.files) {
                    if (file.includes('docx')) {
                        down_file=file
                    }
                }
                
                const link = document.createElement('a');
                link.href = `${IMAGE_URL}/image?path=`+estimator_path+`${down_file}`;
                link.setAttribute('download', 'file.docx'); //or any other extension
                document.body.appendChild(link);
                link.click();
                
            } else {
                console.log("실패")
            }
            
        })
        }
      } else if (projectType === 'bridge') {
        console.log(type)
        if(type.includes("dxf")) {
          console.log("download 누름")
          let drawer_path: any = `/project/${projectId}/stage8/${typeName}/`
          axios({
            method: "get",
            url: API_URL + '/File/Files',
            headers: { "accept": `application/json`, "access-token": `${token}` },
            params: { path: drawer_path }
        }).then((res) => {
            if (res.data.check === true) {
                let file = ""
                let down_file =""
                for (file of res.data.data.files) {
                    if (file.includes('.dxf')) {
                        down_file = file
                    }
                }
                console.log(down_file)
                const link = document.createElement('a');
                link.href = `${IMAGE_URL}/image?path=` + drawer_path + `${down_file}`;
                link.setAttribute('download', "file.dxf"); //or any other extension
                document.body.appendChild(link);
                link.click();
                
            } else {
                console.log("실패")
            }
        })
        }
        if(type.includes("docx")) {
          let estimator_path: any = `/project/${projectId}/stage9/${typeName}/`
          axios({
            method: "get",
            url: API_URL + '/File/Files',
            headers: { "accept": `application/json`, "access-token": `${token}` },
            params: { path: estimator_path }
        }).then((res) => {
            if (res.data.check === true) {
                let file = ""
                let down_file = ""
                for (file of res.data.data.files) {
                    if (file.includes('docx')) {
                        down_file=file
                    }
                }
                
                const link = document.createElement('a');
                link.href = `${IMAGE_URL}/image?path=`+estimator_path+`${down_file}`;
                link.setAttribute('download', 'file.docx'); //or any other extension
                document.body.appendChild(link);
                link.click();
                
            } else {
                console.log("실패")
            }
            
        })
        }
      }
      
    }

    const [isSelect, setIsSelect] = useState<boolean>(false)
      const setting :any = localStorage.getItem("settings")
      

    const option_render=()=>{
      const arr:any[]=[];
      if(projectType === 'dam') {
        const typeArr: any = JSON.parse(setting).dam_type
          typeArr?.map((type:any)=>{
            let name=''
            if(type==='Overflow'){
                name='월류부'
            }else if(type==='DamFloor'){
                name='댐마루'
            }else if (type === 'UpStream') {
                name='상류면'
            } else if (type === 'DownStream') {
                name ='비월류부'
            } else if (type === 'Spillway') {
                name ='여수로'
            }
            arr.push(<Option value={type} > {name}</Option>)
        })
        return arr;
      }
      else if(projectType === 'bridge') {
        const typeArr: any = JSON.parse(setting).bridge_type
        typeArr?.map((type:any)=>{
          let name=''
          if (type === 'Girder') {
              name = '거더 하면'
          } else if(type==='GirderSide'){
                  name='거더 측면'
          }else if(type==='Slab'){
              name='슬라브 하면'
          } else if (type === 'Slab_Side') {
              name = '슬라브 측면'
          } else if (type === 'Pier') {
              name ='교각'
          } else if (type === 'Abutment') {
              name = '교대'
          }
              arr.push(<Option value={type}> {name}</Option>)
          })
        return arr;
      }
      
  }

    const  modelSelect :any[]= [];
      const selectModel = () => {
          modelSelect.push(
              <Select placeholder="선택해주세요"  style={{ width : '8rem'}} onChange={(e) => {setIsSelect(true); setTypeName(e)}}>
                  {option_render()}    
              </Select>
          ) 
  
          return modelSelect;
      }

    return (
        <Layout>
            <div>
                <div>
                    <div style={{ paddingTop : '2.01rem', paddingLeft : '2rem', color: '#999', fontSize: '0.75rem', fontFamily: 'inter-pretendard', fontStyle: 'normal', fontWeight: 400, lineHeight: '100%'}}>외관조사망도 & 보고서 &gt; 보고서 다운로드</div>
                    <div style={{ paddingTop : '0.3rem', paddingLeft : '2rem', color: '#3A4557', fontSize: '1.5rem', fontFamily: 'inter-pretendard', fontStyle: 'normal', fontWeight: 700, lineHeight: '100%'}}>보고서 다운로드</div>
                </div>
                {
                  projectType === 'dam' || projectType === 'bridge'?
                    <div  style={{ marginTop: '1rem'}}>
                      <Box sx={{
                          display: 'block',
                          width: '8rem',
                          height: '2rem',
                          borderRadius: '0.5rem',
                          background: '#FFF',
                          boxShadow: '2px 2px 20px 0px rgba(58, 69, 87, 0.05)',
                          padding : '1rem',
                          marginLeft : '2rem'
                      }}>
                          {selectModel()}
                      </Box>
                  </div> : <></>
                }
                { projectType === 'dam' ? typeName ? isDxf || isDocx ?
                    <Box sx={{ width: '90rem', backgroundColor: 'white', borderRadius: '1rem', padding : '2rem', marginLeft : '2rem', marginTop: '1rem', boxShadow: '2px 2px 20px 0px rgba(58, 69, 87, 0.05)'}}>
                    <TableContainer  sx={{ width: '98%', marginLeft: '1rem'}}>
                            <Table sx={{ backgroundColor: '#fffff' }} aria-label="customized table">                               
                                <TableBody>
                                  {
                                    isDxf ?
                                    <StyledTableRow>
                                    <StyledTableCell align="center" style={{width:'5rem', height: '4rem', backgroundColor : '#F5F5F5', color:'#3A4557', fontWeight : 600, fontSize: '1rem', fontFamily:'inter-pretendard' }}>다운로드</StyledTableCell>
                                    <StyledTableCell style={{height: '2.5rem'}}>
                                      <div style={{ display : 'flex', justifyContent : 'space-between', alignItems:'center', padding: '1rem', fontSize: '0.875rem'}}>
                                        <div style={{ fontFamily:'inter-pretendard' }}>외관조사망도</div>
                                        <div><Button variant="contained" sx={{ fontWeight:700, width: '7rem', fontFamily:'inter-pretendard', background : '#0F6FB7', paddingRight : '0.7rem' }} onClick={()=> download(dxfName)}>다운로드 <DownloadIcon/></Button></div>
                                      </div>
                                    </StyledTableCell>
                                  </StyledTableRow> : <></>
                                  }

                                  {
                                    isDocx ?
                                    <StyledTableRow>
                                    <StyledTableCell align="center" style={{width:'5rem', height: '4rem', backgroundColor : '#F5F5F5', color:'#3A4557', fontWeight : 600, fontSize: '1rem', fontFamily:'inter-pretendard' }}>다운로드</StyledTableCell>
                                    <StyledTableCell style={{height: '2.5rem'}}>
                                      <div style={{ display : 'flex', justifyContent : 'space-between', alignItems:'center', padding: '1rem', fontSize: '0.875rem'}}>
                                        <div style={{ fontFamily:'inter-pretendard' }}>상태평가보고서</div>
                                        <div><Button variant="contained" sx={{ fontWeight:700, width: '7rem', fontFamily:'inter-pretendard', background : '#0F6FB7', paddingRight : '0.7rem' }} onClick={()=> download(docxName)}>다운로드 <DownloadIcon/></Button></div>
                                      </div>
                                    </StyledTableCell>
                                  </StyledTableRow> : <></>
                                  }
                                </TableBody>
                            </Table>
                        </TableContainer>
                </Box>
                :
                <Box sx={{
                  width: '23rem',
                  height: '1.5rem',
                  borderRadius: '0.5rem',
                  background: '#FFF',
                  boxShadow: '2px 2px 20px 0px rgba(58, 69, 87, 0.05)',
                  padding : '0.75rem',
                  marginTop : '1rem', 
                  marginLeft : '2rem'
              }}>
                  <div>외관조사망도나 상태평가보고서 작업을 진행해주세요.</div>
              </Box> 
              :
              <Box sx={{
                width: '11.5rem',
                height: '1.5rem',
                borderRadius: '0.5rem',
                background: '#FFF',
                boxShadow: '2px 2px 20px 0px rgba(58, 69, 87, 0.05)',
                padding : '0.75rem',
                marginTop : '1rem', 
                marginLeft : '2rem'
            }}>
                <div>구역을 먼저 선택해주세요.</div>
            </Box> : <></>
            }
            { projectType === 'tunnel' ? (isDxf || isDocx) ?
                    <Box sx={{ width: '90rem', backgroundColor: 'white', borderRadius: '1rem', padding : '2rem', marginLeft : '2rem', marginTop: '1rem', boxShadow: '2px 2px 20px 0px rgba(58, 69, 87, 0.05)'}}>
                    <TableContainer  sx={{ width: '98%', marginLeft: '1rem'}}>
                            <Table sx={{ backgroundColor: '#fffff' }} aria-label="customized table">                               
                                <TableBody>
                                  {
                                    isDxf ?
                                    <StyledTableRow>
                                    <StyledTableCell align="center" style={{width:'5rem', height: '4rem', backgroundColor : '#F5F5F5', color:'#3A4557', fontWeight : 600, fontSize: '1rem', fontFamily:'inter-pretendard' }}>다운로드</StyledTableCell>
                                    <StyledTableCell style={{height: '2.5rem'}}>
                                      <div style={{ display : 'flex', justifyContent : 'space-between', alignItems:'center', padding: '1rem', fontSize: '0.875rem'}}>
                                        <div style={{ fontFamily:'inter-pretendard' }}>외관조사망도</div>
                                        <div><Button variant="contained" sx={{ fontWeight:700, width: '7rem', fontFamily:'inter-pretendard', background : '#0F6FB7', paddingRight : '0.7rem' }} onClick={()=> download('dxf')}>다운로드 <DownloadIcon/></Button></div>
                                      </div>
                                    </StyledTableCell>
                                  </StyledTableRow> : <></>
                                  }

                                  {
                                    isDocx ?
                                    <StyledTableRow>
                                    <StyledTableCell align="center" style={{width:'5rem', height: '4rem', backgroundColor : '#F5F5F5', color:'#3A4557', fontWeight : 600, fontSize: '1rem', fontFamily:'inter-pretendard' }}>다운로드</StyledTableCell>
                                    <StyledTableCell style={{height: '2.5rem'}}>
                                      <div style={{ display : 'flex', justifyContent : 'space-between', alignItems:'center', padding: '1rem', fontSize: '0.875rem'}}>
                                        <div style={{ fontFamily:'inter-pretendard' }}>상태평가보고서</div>
                                        <div><Button variant="contained" sx={{ fontWeight:700, width: '7rem', fontFamily:'inter-pretendard', background : '#0F6FB7', paddingRight : '0.7rem' }} onClick={()=> download('docx')}>다운로드 <DownloadIcon/></Button></div>
                                      </div>
                                    </StyledTableCell>
                                  </StyledTableRow> : <></>
                                  }
                                </TableBody>
                            </Table>
                        </TableContainer>
                </Box>
                :
                <Box sx={{
                  width: '23rem',
                  height: '1.5rem',
                  borderRadius: '0.5rem',
                  background: '#FFF',
                  boxShadow: '2px 2px 20px 0px rgba(58, 69, 87, 0.05)',
                  padding : '0.75rem',
                  marginTop : '1rem', 
                  marginLeft : '2rem'
              }}>
                  <div>외관조사망도나 상태평가보고서 작업을 진행해주세요.</div>
              </Box> : <></>
            }
            { projectType === 'bridge' ? typeName ? (isDxf || isDocx) ?
                    <Box sx={{ width: '90rem', backgroundColor: 'white', borderRadius: '1rem', padding : '2rem', marginLeft : '2rem', marginTop: '1rem', boxShadow: '2px 2px 20px 0px rgba(58, 69, 87, 0.05)'}}>
                    <TableContainer  sx={{ width: '98%', marginLeft: '1rem'}}>
                            <Table sx={{ backgroundColor: '#fffff' }} aria-label="customized table">                               
                                <TableBody>
                                  {
                                    isDxf ?
                                    <StyledTableRow>
                                    <StyledTableCell align="center" style={{width:'5rem', height: '4rem', backgroundColor : '#F5F5F5', color:'#3A4557', fontWeight : 600, fontSize: '1rem', fontFamily:'inter-pretendard' }}>다운로드</StyledTableCell>
                                    <StyledTableCell style={{height: '2.5rem'}}>
                                      <div style={{ display : 'flex', justifyContent : 'space-between', alignItems:'center', padding: '1rem', fontSize: '0.875rem'}}>
                                        <div style={{ fontFamily:'inter-pretendard' }}>외관조사망도</div>
                                        <div><Button variant="contained" sx={{ fontWeight:700, width: '7rem', fontFamily:'inter-pretendard', background : '#0F6FB7', paddingRight : '0.7rem' }} onClick={()=> download('dxf')}>다운로드 <DownloadIcon/></Button></div>
                                      </div>
                                    </StyledTableCell>
                                  </StyledTableRow> : <></>
                                  }

                                  {
                                    isDocx ?
                                    <StyledTableRow>
                                    <StyledTableCell align="center" style={{width:'5rem', height: '4rem', backgroundColor : '#F5F5F5', color:'#3A4557', fontWeight : 600, fontSize: '1rem', fontFamily:'inter-pretendard' }}>다운로드</StyledTableCell>
                                    <StyledTableCell style={{height: '2.5rem'}}>
                                      <div style={{ display : 'flex', justifyContent : 'space-between', alignItems:'center', padding: '1rem', fontSize: '0.875rem'}}>
                                        <div style={{ fontFamily:'inter-pretendard' }}>상태평가보고서</div>
                                        <div><Button variant="contained" sx={{ fontWeight:700, width: '7rem', fontFamily:'inter-pretendard', background : '#0F6FB7', paddingRight : '0.7rem' }} onClick={()=> download('docx')}>다운로드 <DownloadIcon/></Button></div>
                                      </div>
                                    </StyledTableCell>
                                  </StyledTableRow> : <></>
                                  }
                                </TableBody>
                            </Table>
                        </TableContainer>
                </Box>
                :
                <Box sx={{
                  width: '23rem',
                  height: '1.5rem',
                  borderRadius: '0.5rem',
                  background: '#FFF',
                  boxShadow: '2px 2px 20px 0px rgba(58, 69, 87, 0.05)',
                  padding : '0.75rem',
                  marginTop : '1rem', 
                  marginLeft : '2rem'
              }}>
                  <div>외관조사망도나 상태평가보고서 작업을 진행해주세요.</div>
              </Box> :
              <Box sx={{
                width: '11.5rem',
                height: '1.5rem',
                borderRadius: '0.5rem',
                background: '#FFF',
                boxShadow: '2px 2px 20px 0px rgba(58, 69, 87, 0.05)',
                padding : '0.75rem',
                marginTop : '1rem', 
                marginLeft : '2rem'
            }}>
                <div>구역을 먼저 선택해주세요.</div>
            </Box> : <></>
            }
            </div>
        </Layout>
    );
};

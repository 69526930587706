import React, { useState, useEffect } from 'react';
import { Layout } from '../../../../allcomponents';
import { useRecoilState, atom } from 'recoil';
import { API_URL, IMAGE_URL } from '../../../../Store/Global';
import axios from 'axios';
import styles from '../../../../styles/CrackDetector_Measure.module.css'
// import { Button, Select, Slider, Table } from 'antd';
import { Box, Button, Table, TableBody, TableCell, tableCellClasses, TableContainer, TableHead, TableRow, TableFooter, Pagination, styled, Tabs, Tab, Typography } from '@mui/material';


interface Image {
    num: Number;
    folderName: string;
    imageURL: Array<string>;
    cnt:Number
}

interface CNT {
    camera_number : number;
    cnt : number;
}

interface TabPanelProps {
    children?: React.ReactNode;
    index: number;
    value: number;
  }

function createData(
    num: string,
    name: string,
    cnt: number
  ) {
    return { num, name, cnt };
  }

const rows = [
    createData('1', 'C01', 0),
    createData('2', 'C02', 0)
    // createData('S002', 'GoYung_C01_S002_Crack.png'),
    // createData('S002', 'GoYung_C02_S002_Crack.png'),
    // createData('S003', 'GoYung_C01_S003_Crack.png'),
    // createData('S003', 'GoYung_C02_S003_Crack.png')
  ];

export default function AirportMetaLearningPage(props:any) {
    let Newarr:any[] = [];

    // const { Column } = Table;

    const projectType = localStorage.getItem('projectType');
    const curId = window.location.href.split('/')[6];
    let token : string | null = localStorage.getItem("token") 
    let projectId : string | null = localStorage.getItem("projectId")

    const setting :any = localStorage.getItem("settings")
    const count = JSON.parse(setting).cameraCount

    const images : Image [] = [];
    const cnt : CNT [] = [];

    const [ImgList, setImgList] = useState<any[]>(images);
    const [finalCNT, setFinalCNT] = useState<any[]>(cnt);
    const [result, setResult] = useState<boolean>(false)
    const [arr, setArr] = useState<any>([])
    const [URLarr, setURLarr] = useState<any>([])
    const [fileArr,setFileArr] = useState<any>([])

    const [page, setPage] = React.useState(0);
    const handlePage = (event:any) => {
      const nowPageInt = parseInt(event.target.outerText);
      console.log(event.target)
      
      if(event.target.outerText) {
        setPage(nowPageInt);
      }
      else {
        if(event.target.ariaLabel.includes("prev")) {
          setPage(page-1);
        }
        if(event.target.ariaLabel.includes("next")) {
          setPage(page+1);
        }
  
      }
      
    }
    

    const StyledTableCell = styled(TableCell)(({ theme }) => ({
        [`&.${tableCellClasses.head}`]: {
          fontSize: '1rem',
          fontWeight: 600,
          backgroundColor: '#F5F5F5',
          color: '#3A4557',
          width: '42.8125rem',
          height: '2.5rem',
          border: '1px solid #DDD',
          borderTop: '0.15rem solid #3A4557',
          padding : 0
        },
        [`&.${tableCellClasses.body}`]: {
          color: '#3A4557',
          textAlign: 'center',
          fontSize: '0.8125rem',
          width: '42.8125rem',
          height: '3rem',
          border: '1px solid #DDD',
          padding : 0,
        },
      }));

    for(let i = 1; i < count+1; i++){
        images.push({ 
            num:i, 
            // folderName:"C0"+ i, 
            folderName: "C"+String(i).padStart(2,"0"), 
            imageURL: [],
            cnt:0,
            })
            
        cnt.push({
            camera_number :  i-1, 
            cnt : 0})
    }

    const [userid, setUserId] = useState<number>(-1);
    const [companyId, setcompanyId] = useState<any>(localStorage.getItem("companyId"));
    
    useEffect(() => {
        let getIdCompany = async () => {
          if (token !== null) {
            console.log("프로젝트ID" + projectId);
            const response = await axios({
              method: "get",
              url: `${API_URL}/account/auth/check/${token}`,
            })
              .then(async (res) => {
                if (res.data.check === true) {
                  setUserId(res.data.data.id)
                  setcompanyId(res.data.data.companyId)
                  // localStorage.set("project_id", id);
                  console.log(
                    `아이디는 다음과 같음 : ${res.data.data.id} / 회사는 다음과 같음 : ${res.data.data.companyId}`
                  );
                  return {
                    email: res.data.data.email,
                    name: res.data.data.name,
                    phone: res.data.data.phone,
                    avatar: res.data.data.avatar,
                    role: res.data.data.role,
                    id: res.data.data.id,
                    companyId: res.data.data.companyId,
                  };
                } else {
                  console.log("토큰 만료");
                  localStorage.removeItem("token");
                  alert("토큰이 만료었습니다 다시 로그인 해주세요");
                  window.location.replace("/Main");
                }
              })
              .catch((err) => {
                console.log(err);
              });
          }
        };
        getIdCompany();
      }, []);

      useEffect(() => {

        axios({
            method: 'get',
            url: API_URL + `/project/view/${projectId}`,
            headers: { "accept": `application/json`, "access-token": `${token}` },
        }).then((res) => {
            if (res.data.check === true) {
                const settings: any = JSON.parse(res.data.data.settings)
                // setType(res.data.data.projectType)
            } else {
                console.log("실패")
            }
        })
        for(let i = 0; i < count; i++){
            Newarr[i] = {cam: 'C'+String(i+1).padStart(2,'0'), filename: []};
            axios({
                method: 'get',
                url: API_URL+'/File/Files',
                headers: { "accept": `application/json`, "access-token": `${token}` },
                params: {
                    path: `/project/${projectId}/stage1/${images[i].folderName}`
                }
            }).then((res)=>{
                if (res.data.check === true) {
                    console.log("성공")
                    // setImgList123(images);
                    setResult(true);

                    for(let j=0; j<res.data.data.files.length; j++){
                        images[i].imageURL.push(`${IMAGE_URL}/image?path=/project/${projectId}/stage1/${images[i].folderName}/${res.data.data.files[j]}&width=360`)
                        
                    }

                } else {
                    console.log("실패")
                }
            }).catch((err) => {
                console.log(err);
            });
        }
        setArr(Newarr)
    }, [])

    const rendering = (i:number) => {
        const result: any[] = [];     

        ImgList[i].imageURL.map((imageURL: any) => {
            let index1 = ""
            if (projectType === "Tunnel") {
                index1 = imageURL.indexOf(".png")
            } else if(projectType==="Airport") {
                index1=imageURL.indexOf(".JPG")
            }
            const index_c=imageURL.indexOf('C')
            const ImageUrlCutWidth = imageURL.substring(index_c+4, index1+4)
            result.push(<img src={imageURL} id={String(i)} alt={imageURL} key={imageURL} className={URLarr.includes(ImageUrlCutWidth) ? styles.JtagImageBorder : styles.JtagImage} onClick={onClickImage} />)
        })
        
        if(result.length < 1){
            setResult(false);
        }

        return result;
    }

    let copyArrImgList = [...ImgList];
    let copyArrCNT = [...finalCNT];
    let copyArr = [...URLarr];
    let copyfilename = [...arr]
    let filename = [...fileArr]

    const onClickImage = (e: any) => {
        const id = e.target.id
        filename = [...copyfilename[id].filename]
        const str = e.target.src
        
        let index1 = ""
        if (projectType === "Tunnel") {
            index1 = str.indexOf(".png")
        } else if(projectType==="Airport") {
            index1=str.indexOf(".JPG")
        }
        
        const index2 = str.indexOf("C")
        if (Number(id) === cnt[e.target.id].camera_number) {
            let name = str.substring(index2 + 4, index1 + 4)
            if (copyArr.includes(name) === false) {
                //border
                copyArr.push(name)
                //선택 개수 계산
                copyArrCNT[id] = { ...copyArrCNT[id], cnt: copyArrCNT[id].cnt + 1 }
                copyArrImgList[id] = { ...copyArrImgList[id], cnt: copyArrCNT[id].cnt, }
                //filename
                filename.push(name)
                setFileArr(filename)
                
            } else {//선택이미지 중복일때
                //  border없앰
                const i = copyArr.indexOf(name)
                copyArr.splice(i, 1)
                // 선택 개수 계산
                copyArrCNT[id] = { ...copyArrCNT[id], cnt: copyArrCNT[id].cnt -1 }
                copyArrImgList[id] = { ...copyArrImgList[id], cnt: copyArrCNT[id].cnt, }
                //filename 
                const j = filename.indexOf(name)
                filename.splice(j, 1)
            }
        }
        setFinalCNT(copyArrCNT)
        setImgList(copyArrImgList)
        setURLarr(copyArr)
        
        copyfilename[id] = {...copyfilename[id], filename: filename}
        setArr(copyfilename)
    }

    let job_id = 0;
    let metaLearning :any;
    const confirm = () => {
        axios({
            method: "post",
            url: API_URL + '/scheduler/job/query',
            headers: { "accept": `application/json`, "access-token": `${token}`, "Content-Type" : `application/json` },
                data: {
                    "job_id": job_id ,
                    "company_id": companyId
                }
            }).then((res) => {
                console.log(res)
                if (res.data.check === true) {
                    console.log("성공",res.data.data.status)
                    if (res.data.data.status === "done") {
                        alert("Meta Learning 분류가 끝났습니다.")
                        clearInterval(metaLearning)
                        window.location.href='../CrackDetector_Measure/MetaLearningResult'
                    }else if(res.data.data.status === "wait") {
                        // alert("작업 대기 중입니다.")
                    } else if(res.data.data.status === "error"){
                        // alert("해당 파일이 없습니다.")
                    }
                } else {
                    console.log("실패")
                }
            })
        
    }
    const onClick = () => {
        // console.log(URLarr)
        console.log(arr)
        arr.map((ob: any, i: number) => {
            console.log(ob.filename)
            axios({
                method: "post",
                url: API_URL +`/scheduler/job/start/${companyId}/${userid}`,
                headers: {
                  "accept": `application/json`,
                  "access-token": `${token}`,
                  "Content-Type": `application/json`
                },
                    data: {
                    project_id: projectId,
                    task_name: "copy_folder",
                    interactive: false,
                    tasks : [{
                        input_folder : `stage1/`+ob.cam,
                        file_names : ob.filename,
                        output_folder : "stageMeta/original"
                    }]
                }
            }).then((res)=>{
                if (res.data.check === true) {
                    console.log("복사 성공")
                    
                    axios({
                        method: "post",
                        url: API_URL +`/scheduler/job/start/${companyId}/${userid}`,
                        headers: {
                          "accept": `application/json`,
                          "access-token": `${token}`,
                          "Content-Type": `application/json`
                        },
                                    data: {
                            project_id: projectId,
                            task_name: "airport_meta_learning",
                            interactive: false,
                            tasks: [{
                                input_folder : "stageMeta/original",
                                output_folder : "stageMeta/result"
                            }]
                        }
                    }).then((res2) => {
                        console.log(res2)
                        job_id = res2.data.data.job_id
                        metaLearning = setInterval(confirm, 10000)
                    }).catch((err) => {
                        console.log(err);
                    });
                }
            }).catch((err)=>{
                console.log(err)
            })
            
        })
    }

    const StyledTableRow = styled(TableRow)(({ theme }) => ({
        // '&:nth-of-type(odd)': {
        //   backgroundColor: theme.palette.action.hover,
        // },
        // hide last border
        '&:last-child td': {
        },

      }));

      const StyledTab = styled(Tab)({
        "&.Mui-selected": {
          color: "#0F6FB7",
          fontWeight : 700,
          background: 'rgba(15, 111, 183, 0.10)',
          borderRadius: '0.25rem',
          marginBottom : '0.25rem',
        }
      });

    return (
        <Layout>
            <div style={{ paddingTop : '2.01rem', paddingLeft : '2rem', color: '#999', fontSize: '0.75rem', fontFamily: 'inter-pretendard', fontStyle: 'normal', fontWeight: 400, lineHeight: '100%'}}>검출 & 측정 &gt; 메타 러닝</div>
            <div style={{ paddingTop : '0.3rem', paddingLeft : '2rem', color: '#3A4557', fontSize: '1.5rem', fontFamily: 'inter-pretendard', fontStyle: 'normal', fontWeight: 700, lineHeight: '100%'}}>메타 러닝</div>
            
            <div style={{ display : 'flex'}}>
                <div>
                    <Box sx={{ width: '25rem', backgroundColor: 'white', borderRadius: '0.5rem', boxShadow: '2px 2px 20px 0px rgba(58, 69, 87, 0.05)', padding : '2rem', marginLeft : '2rem', marginTop: '1rem'}}>
                            <div style={{ display: 'flex', alignItems : 'center', justifyContent : 'space-between'}}>
                            <div style={{ fontWeight : 700, borderLeft: '0.1875rem solid #0F6FB7', fontSize:'1rem', height:'1.2rem',paddingLeft: '0.62rem'}}>이미지 파일 리스트 </div>
                            </div>
                            <div>
                                <TableContainer  sx={{ width: '23rem', marginLeft: '1rem', marginTop: '1rem' }}>
                                    <Table sx={{ backgroundColor: '#fffff' }} aria-label="customized table">
                                        <TableHead>
                                        <TableRow>
                                            <StyledTableCell align="center" style={{width:'2rem', fontFamily:'inter-pretendard', fontSize: '1rem'}}>번호</StyledTableCell>
                                            <StyledTableCell align="center" style={{width:'2rem', fontFamily:'inter-pretendard', fontSize: '1rem'}}>이름</StyledTableCell>
                                            <StyledTableCell align="center" style={{width:'2rem', fontFamily:'inter-pretendard', fontSize: '1rem'}}>개수</StyledTableCell>
                                        </TableRow>
                                        </TableHead>
                                        
                                        <TableBody>
                                        {rows.map((row, i) => (
                                            <StyledTableRow 
                                                key={row.num}
                                                style={{ height: '2.5rem'}}
                                            >
                                            {/* <StyledTableCell component="th" scope="row" align="center">
                                                {row.num}
                                            </StyledTableCell> */}
                                            <StyledTableCell align="center" style={{width:'2rem', height: '2.5rem', fontFamily:'inter-pretendard', fontSize: '0.8rem'}}>{row.num}</StyledTableCell>
                                            <StyledTableCell align="center" style={{width:'2rem', height: '2.5rem', fontFamily:'inter-pretendard', fontSize: '0.8rem'}}>{row.name}</StyledTableCell>
                                            <StyledTableCell align="center" style={{width:'2rem', height: '2.5rem', fontFamily:'inter-pretendard', fontSize: '0.8rem'}}>{row.cnt}</StyledTableCell>
                                            </StyledTableRow> 
                                        ))}
                                        </TableBody>
                                    </Table>
                                    <TableFooter>
                                        <TableRow style={{ width: '23rem', display : 'flex', justifyContent: 'center', marginTop:'4rem'}}>
                                        <Pagination count={rows.length > 10 && rows.length % 10 === 0 ? Math.floor(rows.length/10) : Math.floor(rows.length/10)+1} defaultPage={1} onChange={(e) => handlePage(e)} color="primary" 
                                            sx={{
                                            '& svg': {
                                                pointerEvents: 'none'
                                            }
                                            }}
                                        />
                                        </TableRow>
                                        </TableFooter>
                                </TableContainer>
                                
                            </div>
                    </Box> 
                </div>
                </div>
            <div style={{ marginTop: '1rem', height : '50rem'}}>
                <Box sx={{ width: '69rem', height: '47rem', flexShrink: 0, borderRadius: '0.5rem', background: '#FFF', boxShadow: '2px 2px 20px 0px rgba(58, 69, 87, 0.05)', marginLeft : '2rem', marginBottom: '1rem'}}>

                </Box>
            </div>
        </Layout>
    );
};
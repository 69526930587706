import React, { useState, useEffect, useCallback } from 'react';
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";
import { Layout } from '../../allcomponents';
import { useNavigate } from 'react-router-dom';
import { User } from '../../alltypes';
import { ReturnValues } from '../../allmodels';
import { apiHelper } from '../../allutils';

function UserConfirmPage(props:any) {
    const columns = [
        { key: 'id', name: 'UID', show:false, isKey:true },
        { key: 'email', name: 'ID', show:true, isKey:false },
        { key: 'name', name: 'Name', show:true, isKey:false },
        { key: 'companyId', name: 'Company', show:true, isKey:false },
        { key: 'phone', name: 'Contact', show:true, isKey:false },
        { key: 'role', name: 'Grade', show:true, isKey:false }
    ];

    const [isBind, setIsBind] = useState(false);
    const [list, setList] = useState<User[]>();

    const navigate = useNavigate();

    const DataBind = () => {
      if (!isBind) {
        apiHelper.Get("/account/member/list/confirm/0", {}, (rst: ReturnValues<User[]>) => {
          if (rst.check && rst.data !== null && rst.data !== undefined) {
              setList(rst.data);
              console.log(rst);
              setIsBind(true);
          }
      });
      } 
    };

    useEffect(function() {
      DataBind();
  }, [isBind]);
    
    const onRowClick = (row:any) => {
      console.log("click");
      console.log(row.key);
      navigate(`/user/list/confirm/${row.key}`);
    };

    const [confirmList, setConfirmList]:any = useState([]);

    useEffect(function() {
      console.log(confirmList);
  }, [confirmList]);

    // isConfirm === true 일 때 api 요청 보내고 요청 보낸 후 setIsConfirm(false) 해주기
    const [isConfirm, setIsConfirm] = useState(false); 

    useEffect(function() {
      ListConfirm();
  }, [isConfirm]);

  const ListConfirm = () => {
    const confirmArr:any = [];
    let contentData2 : JSX.Element = (
      <p>전체 승인이 완료되었습니다.</p>
    )

    let contentData3 : JSX.Element = (
      <p>전체 승인 요청이 <span>거부</span> 되었습니다.</p>
    )

    if(isConfirm) {
      confirmList.map((item:any, index:number) => {
        apiHelper.Get(`/account/member/confirm/${item}?confirm=1`, {}, (rst: ReturnValues<User>) => {
          if (rst.check) {
               //승인에 성공하면 
               console.log(rst);
               console.log(rst.data);
               confirmArr.push(item);
               if(confirmList.length === index+1) {
                let data : JSX.Element = (
                  <p>{confirmArr.toString()}의 승인이 완료되었습니다.</p>
                )
                
                navigate("/user/list/confirm");
                // window.history.go(0)
               }

          } else {
            console.log(rst.message);
            if(confirmList.length === 0) {
              
     
            }
            else {
              let data : JSX.Element = (
                <p>{confirmArr.toString()}의 승인이 완료되었습니다.</p>
              )
            
            }
            
          }
      });

      })
      
  }

  }

    const onRegistrationClick = () => {
      // reload 안되면 window.history.go(0); 사용하기
      // span tag로 감싸면 red, bold 적용
      let contentData : JSX.Element = (
        <p>선택된 사용자를 <span>모두 승인</span> 처리하시겠습니까?</p>
      )
        
    }

    return (
        <Layout>
            <div className="user-confirm-list">
                {/* 사용자 승인 목록 화면 */}
                <div className="data-grid">
                  <div className="row">
                    <div className="col-12">
                        <div className="card">
                          <div className="card-body">
                          <div className="registration" onClick={onRegistrationClick}>선택 승인</div>
                              {
                                  (isBind) ? 
                                  <div className="spinner-border text-primary" style={{ margin : "0 auto" }} role="status"></div>
                                  :
                                  <div className="spinner-border text-primary" style={{ margin : "0 auto" }} role="status"></div>
                              }
                          </div>
                        </div>
                    </div>
                  </div>
                </div>
            </div>
        </Layout>
    );
};

export default UserConfirmPage;
import React, { useState, useEffect } from 'react';
import { Layout } from '../../../allcomponents';
import axios from "axios";
import { API_URL } from '../../../Store/Global';
import Box from '@mui/material/Box';
import DashboardPreprocess from './DashboardPreprocess';
import DashboardPanorama from './DashboardPanorama';
import DashboardMeasure from './DashboardMeasure';
import DashboardEstimator from './DashboardEstimator';
import DashboardXAI from './DashboardXAI';

export default function TunnelDashboardPage(props:any) {
    const projectType = localStorage.getItem('projectType');
    let token : string | null = localStorage.getItem("token") 
    let projectId = localStorage.getItem("projectId")

    const [title, setTitle] = useState<any>();

    useEffect(()=> {
        console.log(projectId)
        axios({
            method: 'get',
            url: API_URL + `/project/view/${projectId}`,
            headers: { "accept": `application/json`, "access-token": `${token}` },
        }).then((res) => {
            setTitle(res.data.data.title)
        })
    }, [])

    return (
        <Layout>
            <div style={{ height: '100vh', overflow: 'auto'}}>
            <div style={{ paddingTop : '2.01rem', paddingLeft : '2rem', color: '#999', fontSize: '0.75rem', fontFamily: 'inter-pretendard', fontStyle: 'normal', fontWeight: 400, lineHeight: '100%'}}>프로젝트 명 : {title}</div>
            <div style={{ paddingTop : '0.3rem', paddingLeft : '2rem', color: '#3A4557', fontSize: '1.5rem', fontFamily: 'inter-pretendard', fontStyle: 'normal', fontWeight: 700, lineHeight: '100%'}}>작업 현황</div>
                <Box sx={{ width: '80%', backgroundColor: 'white', borderRadius: '1rem', padding : '2rem', marginLeft : '2rem', marginTop: '2rem'}}>
                <div style={{ fontWeight : 700, borderLeft: '0.1875rem solid #0F6FB7', paddingLeft: '0.62rem', fontSize:'1rem', height:'1.2rem', fontFamily : 'inter-pretendard'}}>전처리</div>
                    <DashboardPreprocess/>
                </Box>
                <Box sx={{ width: '80%', backgroundColor: 'white', borderRadius: '1rem', padding : '2rem', marginLeft : '2rem', marginTop: '2rem'}}>
                <div style={{ fontWeight : 700, borderLeft: '0.1875rem solid #0F6FB7', paddingLeft: '0.62rem', fontSize:'1rem', height:'1.2rem', fontFamily : 'inter-pretendard'}}>파노라마</div>
                    <DashboardPanorama/>
                </Box>
                <Box sx={{ width: '80%', backgroundColor: 'white', borderRadius: '1rem', padding : '2rem', marginLeft : '2rem', marginTop: '2rem'}}>
                <div style={{ fontWeight : 700, borderLeft: '0.1875rem solid #0F6FB7', paddingLeft: '0.62rem', fontSize:'1rem', height:'1.2rem', fontFamily : 'inter-pretendard'}}>검출 & 측정</div>
                    <DashboardMeasure/>
                </Box>
                <Box sx={{ width: '80%', backgroundColor: 'white', borderRadius: '1rem', padding : '2rem', marginLeft : '2rem', marginTop: '2rem'}}>
                <div style={{ fontWeight : 700, borderLeft: '0.1875rem solid #0F6FB7', paddingLeft: '0.62rem', fontSize:'1rem', height:'1.2rem', fontFamily : 'inter-pretendard'}}>외관조사망도 & 상태평가보고서</div>
                    <DashboardEstimator/>
                </Box>
                <Box sx={{ width: '80%', backgroundColor: 'white', borderRadius: '1rem', padding : '2rem', marginLeft : '2rem', marginTop: '2rem', marginBottom:'6rem'}}>
                <div style={{ fontWeight : 700, borderLeft: '0.1875rem solid #0F6FB7', paddingLeft: '0.62rem', fontSize:'1rem', height:'1.2rem', fontFamily : 'inter-pretendard'}}>XAI</div>
                    <DashboardXAI/>
                </Box>
            </div>
        </Layout>
    );
};


import React, { useState, useEffect } from 'react';
import { Layout } from '../../../../allcomponents';
import styles from '../../../../styles/Preprocess.module.css'
import axios from "axios";
import { API_URL, IMAGE_URL } from '../../../../Store/Global';
import { Select } from 'antd';
import { Box, Button } from '@mui/material';

const { Option } = Select;

interface Image {
    camerafolderName : string;
    spanfolderName : string;
    imageURL : Array<string>;
}

export default function BridgePreProcessFolderDivPage(props:any) {
    const projectType = localStorage.getItem('projectType');
    const curId = window.location.href.split('/')[6];
    let token : string | null = localStorage.getItem("token") 
    let projectId : string | null = localStorage.getItem("projectId")

    const [userid, setUserId] = useState<number>(-1);
    const [companyId, setcompanyId] = useState<any>(localStorage.getItem("companyId"));

    useEffect(() => {
        let getIdCompany = async () => {
            if(token !== null){ 
                console.log("여기 들어옴?")
                console.log("프로젝트ID"+projectId)
                const response = await axios({
                method : "get",
                url : `${API_URL}/account/auth/check/${token}`,                
                }).then(async(res)=>{
                if(res.data.check === true){
                    setUserId(res.data.data.id)
                    setcompanyId(res.data.data.companyId)
                    // localStorage.set("projectId", id);
                    console.log(`아이디는 다음과 같음 : ${res.data.data.id} / 회사는 다음과 같음 : ${res.data.data.companyId}`)
                    return {email : res.data.data.email, name : res.data.data.name, phone : res.data.data.phone, avatar : res.data.data.avatar, role : res.data.data.role, id : res.data.data.id, companyId : res.data.data.companyId}
                }else{
                    console.log("토큰 만료")
                    localStorage.removeItem('token')
                    alert("토큰이 만료었습니다 다시 로그인 해주세요")
                    window.location.replace("/Main")
                }
                }).catch((err)=>{
                console.log(err)
                })
            }
        }
        getIdCompany()
    },[])

    const [isSelect, setIsSelect] = useState<boolean>(false)

    const [typeArr, setType]=useState([])

    const images : Image [] = [];
    const [ImgList, setImgList] = useState<any[]>(images);
    
    const [BridgeType, setBridgeType] = useState<string>("")
    const [selectOptionGirderSpan, setselectOptionGirderSpan] = useState("") //G1
    const [selectOptionGirder, setSelectOptionGirder] = useState("") //G1
    const [GirderCount, setGirderCount] = useState<number>(0)
    const [SlabCount, setSlabCount] = useState<number>(0)
    const [PierCount, setPierCount] = useState<number>(0)
    const [SpanCount, setSpanCount] = useState<number>(0)
    const [SpanNumList, setSpanNumList] = useState<any>([])
    const [PierNumList, setPierNumList] = useState<any>([])

    const [girderOption, setgirderOption] = useState<boolean>(false);
    const [girderSideOption, setgirderSideOption] = useState<boolean>(false);
    const [SlabOption, setSlabOption] = useState<boolean>(false);
    const [pierOption, setpierOption] = useState<boolean>(false);

    const [girderSpanOptionList, setgirderSpanOptionList] = useState()
    const [girderOptionList, setgirderOptionList] = useState()

    const [resultImageList, setresultImageList] = useState<boolean>(false)

    useEffect(()=>{
        let path: any

        axios({
            method: 'get',
            url: API_URL + `/project/view/${projectId}`,
            headers : {"accept" : `application/json`, "access-token" : `${token}`}, 
        }).then((res) => {
            if (res.data.check === true) {
                const settings: any = JSON.parse(res.data.data.settings)
                    setType(settings.bridge_type)
                    setGirderCount(settings.girder_count)
                    setSlabCount(settings.slab_count)
                    setPierCount(settings.pier_count)
                    setSpanCount(settings.span_count)
                    setSpanNumList(settings.span_number_list)
                    setPierNumList(settings.pier_number_list)

                    //거더 선택 시
                    if(BridgeType === "Girder"){
                                for(let k = 0; k < settings.girder_camera_count; k++){
                                    for(let l = 0; l < settings.span_length / settings.span_class_length; l++){
                                        images.push({
                                            camerafolderName:"C" + String(k+1).padStart(2,"0"),
                                            spanfolderName : "S" + String(selectOptionGirderSpan.substring(2,3) === "0" ? String(selectOptionGirderSpan.substring(3,4)).padStart(2,"0") : selectOptionGirderSpan.substring(2,4)) + String(l+1).padStart(2,"0"),
                                            imageURL : []
                                        })
                                    }
                                }

                                for(let k = 0; k < settings.girder_camera_count; k++){
                                    for(let l = 0; l < settings.span_length / settings.span_class_length; l++){
                                        path = `/project/${projectId}/stage2/${BridgeType}/${selectOptionGirderSpan}/${BridgeType}_${selectOptionGirder}/C${String(k+1).padStart(2,"0")}/S` + `${String(selectOptionGirderSpan.substring(2,3) === "0" ? String(selectOptionGirderSpan.substring(3,4)).padStart(2,"0") : selectOptionGirderSpan.substring(2,4)) + String(l+1).padStart(2,"0")}`
                                        // console.log("path : ", path)                                            
                                    axios({
                                        method: 'get',
                                        url: API_URL + `/File/Files`,
                                        headers: { "accept" : `application/json`, "access-token": `${token}` },
                                        params: { path : path }
                                    }).then((res) => { 
                                        if (res.data.check === true) {
                                            for(let j = 0;  j< res.data.data.files.length; j++){
                                                // console.log(`${IMAGE_URL}/image?path=/project/${projectId}/stage2/${BridgeType}/${selectOptionGirderSpan}/${selectOptionGirder}/C${String(k+1).padStart(2,"0")}/S` + `${String(selectOptionGirderSpan.substring(2,3) === "0" ? String(selectOptionGirderSpan.substring(3,4)).padStart(2,"0") : selectOptionGirderSpan.substring(2,4)) + String(l+1).padStart(2,"0")}/${res.data.data.files[j]}&width=360`)
                                                images[6*(k)+l].imageURL.push(`${IMAGE_URL}/image?path=/project/${projectId}/stage2/${BridgeType}/${selectOptionGirderSpan}/${BridgeType}_${selectOptionGirder}/C${String(k+1).padStart(2,"0")}/S` + `${String(selectOptionGirderSpan.substring(2,3) === "0" ? String(selectOptionGirderSpan.substring(3,4)).padStart(2,"0") : selectOptionGirderSpan.substring(2,4)) + String(l+1).padStart(2,"0")}/${res.data.data.files[j]}&width=360`)
                                                // images[6*(k)+l].imageURL.push(`${IMAGE_URL}/image?path=/project/${projectId}/stage2/${BridgeType}/${selectOptionGirderSpan}/${selectOptionGirder}/C${String(k+1).padStart(2,"0")}/S` + `${String(selectOptionGirderSpan.substring(2,3) === "0" ? selectOptionGirderSpan.substring(3,4) : selectOptionGirderSpan.substring(2,4)) + String(selectOptionGirderSpan.substring(2,3) === "0" ? String(l+1).padStart(2,"0"): String(l+1).padStart(1,"0"))}/${res.data.data.files[j]}&width=360`)
                                            }
                                            setresultImageList(true);
                                        }
                                    })
                                    }
                                }
                    setImgList(images);
                    }   

                    //거더 측면 선택 시
                    if(BridgeType === "GirderSide"){
                        for(let i = 0; i < settings.span_length / settings.span_class_length; i++){
                            images.push({
                                camerafolderName : "",
                                spanfolderName : "S" + String(selectOptionGirderSpan.substring(2,3) === "0" ? String(selectOptionGirderSpan.substring(3,4)).padStart(2,"0") : selectOptionGirderSpan.substring(2,4)) + String(i+1).padStart(2,"0"),
                                imageURL: []
                            })
                        }

                        for(let i = 0; i < settings.span_length / settings.span_class_length; i++){
                            path = `/project/${projectId}/stage2/${BridgeType}/${selectOptionGirderSpan}/S${String(selectOptionGirderSpan.substring(2,3) === "0" ? String(selectOptionGirderSpan.substring(3,4)).padStart(2,"0") : selectOptionGirderSpan.substring(2,4)) + String(i+1).padStart(2,"0")}`
                            axios({
                                method: 'get',
                                url: API_URL + `/File/Files`,
                                headers: { "accept" : `application/json`, "access-token": `${token}` },
                                params: {
                                    path : path
                                }
                            }).then((res) => {
                                if(res.data.check === true){
                                    // console.log(res.data.data.files)
                                    for(let j = 0; j < res.data.data.files.length; j++){
                                        images[i].imageURL.push(`${IMAGE_URL}/image?path=/project/${projectId}/stage2/${BridgeType}/${selectOptionGirderSpan}/S${String(selectOptionGirderSpan.substring(2,3) === "0" ? String(selectOptionGirderSpan.substring(3,4)).padStart(2,"0") : selectOptionGirderSpan.substring(2,4)) + String(i+1).padStart(2,"0")}/${res.data.data.files[j]}&width=360`)
                                    }
                                    setresultImageList(true);
                                }
                            })
                        }
                        setImgList(images);
                    }
                    
                    
                    //교각 선택 시
                    if(BridgeType === "Pier"){
                            for(let j = 0; j < settings.pier_film_count; j++){
                                images.push({
                                    camerafolderName : selectOptionGirder,
                                    spanfolderName :"S" + String(j+1).padStart(3,"0"),
                                    imageURL : []
                                    })
                            }

                            for(let i = 0; i < settings.pier_film_count; i++){
                                path = `/project/${projectId}/stage2/${BridgeType}/${selectOptionGirder}/S${String(i+1).padStart(3,"0")}`
                                axios({
                                method: 'get',
                                url: API_URL + `/File/Files`,
                                headers: { "accept" : `application/json`, "access-token": `${token}` },
                                params: { 
                                    path :  path
                                }
                                }).then((res) => { 
                                    if (res.data.check === true) {
                                        for(let k = 0;  k< res.data.data.files.length; k++){
                                            images[i].imageURL.push(`${IMAGE_URL}/image?path=/project/${projectId}/stage2/${BridgeType}/${selectOptionGirder}/S${String(i+1).padStart(3,"0")}/${res.data.data.files[k]}&width=360`)
                                        }
                                        setresultImageList(true);
                                    }
                                })  
                            }
                        setImgList(images);
                    } 

                    //슬라브 선택 시
                    if(BridgeType === "Slab"){
                        for(let i = 0; i < settings.slab_count; i++){
                            for(let j = 0; j<settings.span_length / settings.span_class_length; j++){
                                images.push({
                                    camerafolderName : selectOptionGirder,
                                    spanfolderName : "S"+String(selectOptionGirderSpan.substring(2,3) === "0" ? String(selectOptionGirderSpan.substring(3,4)).padStart(2,"0") : selectOptionGirderSpan.substring(2,4)) + String(j+1).padStart(2,"0"),
                                    imageURL : []
                                })
                            }
                        }

                        for(let i =0; i <settings.slab_count; i++){
                            for(let j = 0; j <settings.span_length / settings.span_class_length; j++){
                                path = `/project/${projectId}/stage2/${BridgeType}/${selectOptionGirderSpan}/${selectOptionGirder}/S` + `${String(selectOptionGirderSpan.substring(2,3) === "0" ? String(selectOptionGirderSpan.substring(3,4)).padStart(2,"0") : selectOptionGirderSpan.substring(2,4)) + String(j+1).padStart(2,"0")}`
                                // console.log(`/project/${projectId}/stage2/${BridgeType}/${selectOptionGirderSpan}/${selectOptionGirder}/S` + `${String(selectOptionGirderSpan.substring(2,3) === "0" ? String(selectOptionGirderSpan.substring(3,4)).padStart(2,"0") : selectOptionGirderSpan.substring(2,4)) + String(j+1).padStart(2,"0")}`)
                                axios({
                                    method: 'get',
                                    url: API_URL + `/File/Files`,
                                    headers: { "accept" : `application/json`, "access-token": `${token}` },
                                    params: { path : path }
                                }).then((res) => { 
                                    if (res.data.check === true) {
                                        for(let k = 0;  k< res.data.data.files.length; k++){
                                            images[6*(i)+j].imageURL.push(`${IMAGE_URL}/image?path=/project/${projectId}/stage2/${BridgeType}/${selectOptionGirderSpan}/${selectOptionGirder}/S` + `${String(selectOptionGirderSpan.substring(2,3) === "0" ? String(selectOptionGirderSpan.substring(3,4)).padStart(2,"0") : selectOptionGirderSpan.substring(2,4)) + String(j+1).padStart(2,"0")}/${res.data.data.files[k]}&width=360`)
                                        }
                                        setresultImageList(true);
                                    }
                                })
                            }
                        }

                    setImgList(images);   
                    
                    }
            }
        }).catch((err) => {
            console.log(err);
        });

    }, [BridgeType, selectOptionGirder, selectOptionGirderSpan])


    const rendering = (j:number) => {
        const resultImage:any[] = [];        
        ImgList[j].imageURL.map((imageURL:any)=>{
            resultImage.push(<img src={imageURL} id={String(j)} alt={imageURL} key={imageURL} className={BridgeType === "Girder" || BridgeType === "Pier" ? styles.GirderImage:styles.DamImage}/>)
        }) 
        if(resultImage.length < 1){
            setresultImageList(false);
        }
        return resultImage;
    }

    const option_render=()=>{
        const arr:any[]=[];
        typeArr?.map((type:any)=>{
            let name=''
            if(type==='Girder'){
                name='거더 하면'
            }else if(type==='GirderSide'){
                name='거더 측면'
            }else if (type === 'Slab') {
                name='슬라브 하면'
            } else if (type === 'Pier') {
                name ='교각'
            } else if(type==='Abutment') {
                name = '교대'
            }
            arr.push(<Option value={type}> {name}</Option>)
        })
        return arr;
    }

    const onChangeType = (e:any) => {
        // console.log(e)
        setgirderOption(false)
        setgirderSideOption(false)
        setSlabOption(false)
        setpierOption(false)

            if (e === 'Girder') {
                setBridgeType("Girder")
                setgirderOption(true)
                let girderSpanOption : any=[]
                let girderoption :any=[]
                for(let i = 1; i < SpanCount + 1; i++){
                    girderSpanOption.push(<Option value={'S' + String(SpanNumList[i-1].num).padStart(3,"0")}> Span {String(SpanNumList[i-1].num)} </Option>)
                }
                for (let i = 1; i< GirderCount+ 1; i++){
                    girderoption.push(<Option value={'Girder' + String(i).padStart(2,"0")}> Girder {i}</Option>)
                }
                setgirderSpanOptionList(girderSpanOption)
                setgirderOptionList(girderoption)
            } else if (e === 'GirderSide'){
                setBridgeType("GirderSide")
                setgirderSideOption(true)
                let girderSideSpanOption : any = []
                for(let i = 1; i < SpanCount + 1; i++){
                    girderSideSpanOption.push(<Option value={'S' + String(SpanNumList[i-1].num).padStart(3,"0")}> Span {String(SpanNumList[i-1].num)} </Option>)
                }
                setgirderSpanOptionList(girderSideSpanOption)
            } else if (e === 'Slab') {
                setBridgeType("Slab")
                setSlabOption(true)
                let slabSpanOption : any = []
                let slaboption : any =[]
                for(let i = 1; i < SpanCount + 1; i++){
                    slabSpanOption.push(<Option value={'S' + String(SpanNumList[i-1].num).padStart(3,"0")}> Span {String(SpanNumList[i-1].num)} </Option>)
                }
                for(let i = 1; i < SlabCount + 1; i++){
                    slaboption.push(<Option value = {'Slab' + String(i).padStart(2,"0")}> Slab {i}</Option>)
                }
                setgirderSpanOptionList(slabSpanOption)
                setgirderOptionList(slaboption)
            } else if (e === 'Slab_Side'){
                setBridgeType('Slab_Side')
            } else if (e === 'Pier') {
                setBridgeType("Pier")
                setpierOption(true)
                let pieroption : any =[]
                // console.log(PierCount)
                for(let i = 1; i < PierCount + 1; i++){
                    pieroption.push(<Option value={'Pier' + String(PierNumList[i-1].num).padStart(2,"0")}> Pier {String(PierNumList[i-1].num)} </Option>)
                }
                setgirderOptionList(pieroption)
            } else if (e === 'Abutment') {
                setBridgeType("Abutment")
            }
    }

    const onChange1 = (e: string) => {
        if(BridgeType === 'Girder'){
            const n = e.length
            setSelectOptionGirder("G" + String(e.substring(n - 2, n)).padStart(2,"0"))
            setIsSelect(true)
        }else if(BridgeType === 'GirderSide'){
            
        }else if(BridgeType === 'Slab'){
            const n = e.length
            setSelectOptionGirder("Slab"+String(e.substring(n - 2, n)).padStart(2,"0"))
            setIsSelect(true)
        }else if(BridgeType === 'Slab_Side'){
        }else if(BridgeType === 'Pier'){
            const n = e.length
            setSelectOptionGirder("P"+String(e.substring(n - 2, n)).padStart(2,"0"))
            setIsSelect(true)
        }else if(BridgeType === 'Abutment'){
            
        }
    }

    const onChange2 = (e: string) => {
        setselectOptionGirderSpan(e)
        if(BridgeType === 'GirderSide' || BridgeType === 'Pier') {
            setIsSelect(true)
        }
    }

    const selectModel = () => {

        return <div style={{ display:'flex'}}>
                {/* 거더, 거더 측면, 슬라브, 교각 선택 */}
                <Select placeholder="선택해주세요" style={{ width : '8rem'}} onChange={onChangeType}>
                    {option_render()}    
                </Select>
                {/* 교량 스팬 선택 */}
                { girderOption === true || SlabOption === true || girderSideOption === true ? 
                    <Select placeholder="선택해주세요" style={{ width : '8rem', marginLeft: '1rem'}} onChange={onChange2} >
                        {girderSpanOptionList}
                    </Select>
                    : <></>
                }
                {/* 거더 번호, 슬라브 번호, 교각 스팬 번호 선택 */}
                { girderOption === true || pierOption === true  || SlabOption === true ?
                    <Select placeholder="선택해주세요" style={{ width : '8rem', marginLeft: '1rem'}} onChange={onChange1}>
                        {girderOptionList}
                    </Select>
                : <></>
                }
            </div>;
    }

    return (
        <Layout>
            <div style={{ paddingTop : '2.01rem', paddingLeft : '2rem', color: '#999', fontSize: '0.75rem', fontFamily: 'inter-pretendard', fontStyle: 'normal', fontWeight: 400, lineHeight: '100%'}}>전처리 &gt; 폴더 구분</div>
            <div style={{ paddingTop : '0.3rem', paddingLeft : '2rem', color: '#3A4557', fontSize: '1.5rem', fontFamily: 'inter-pretendard', fontStyle: 'normal', fontWeight: 700, lineHeight: '100%'}}>폴더 구분</div>
            <div style={{ marginTop: '1rem', marginLeft: '2rem'}}>
                <div style={{ marginBottom: '1rem', display : 'flex'}}>
                    <div>
                    <Box sx={{
                        display: 'block',
                        width: '8rem',
                        height: '2rem',
                        borderRadius: '0.5rem',
                        background: '#FFF',
                        boxShadow: '2px 2px 20px 0px rgba(58, 69, 87, 0.05)',
                        padding : '1rem'
                    }}>
                        {selectModel()}
                    </Box>
                    </div>
                </div>
                <div>
                    {
                        isSelect && resultImageList? 
                        <div style={{ height: '43rem', overflowY:'scroll'}}>
                        {
                            ImgList?.map((row:any, i:any)=>(
                                <Box sx={BridgeType === 'Girder'||  BridgeType === 'Pier' ? { width: '99.6875rem', height: '21rem', flexShrink: 0, borderRadius: '0.5rem', background: '#FFF', boxShadow: '2px 2px 20px 0px rgba(58, 69, 87, 0.05)', marginBottom: '1rem'} : { width: '99.6875rem', height: '12.5rem', flexShrink: 0, borderRadius: '0.5rem', background: '#FFF', boxShadow: '2px 2px 20px 0px rgba(58, 69, 87, 0.05)',marginBottom: '1rem'}}>
                                <div style={{padding : '2rem'}}>
                                    {/* 네임라인 */}
                                    <div style={{ display : 'flex'}}>
                                        <div style={{ backgroundColor : '#0F6FB7', borderRadius : '50%', color : '#fff', width : '1.25rem', height : '1.25rem', textAlign : 'center', fontSize : '0.75rem', fontWeight:400, lineHeight:'175%'}}>{row.camerafolderName}</div>
                                        <div style={{ marginLeft : '0.5rem', fontWeight : 600}}>{row.spanfolderName}</div>
                                    </div>
                                    {/* 이미지라인 */}
                                    <div style={BridgeType === 'Girder'||  BridgeType === 'Pier' ? { width : '95.65rem', height : '15rem', marginTop : '1.25rem', whiteSpace : 'nowrap', overflow: 'auto'}:{ width : '95.65rem', height : '6.5rem', marginTop : '1.25rem', whiteSpace : 'nowrap', overflow: 'auto'}}>
                                        { rendering(i) }
                                    </div>
                                </div>
                            </Box>
                            ))
                        }
                        </div>
                        
                        
                        
                        : <Box sx={{
                            width: '11.5rem',
                            height: '1.5rem',
                            borderRadius: '0.5rem',
                            background: '#FFF',
                            boxShadow: '2px 2px 20px 0px rgba(58, 69, 87, 0.05)',
                            padding : '0.75rem'
                        }}>
                            <div>구역을 먼저 선택해주세요.</div>
                        </Box>
                    }
                    
                </div>
            </div>
        </Layout>
    );
};
import { ReturnValue,ReturnValues } from "../allmodels";
import { apiHelper,util,formHelper } from "../allutils";
import React, { useState, useEffect } from 'react';
import config from "../config";
import { truncate } from "fs/promises";
import axios from "axios";
import { API_URL, IMAGE_URL } from '../Store/Global';

//로그인 페이지 (시작 페이지)
const LoginPage = () => {
    const [isLogin, setIsLogin] = useState<boolean>(true);
    const [isJoin, setIsJoin] = useState<boolean>(false);

    const [state, setState] = useState({
        "email" : '',
        "password" : '', 
        "name" : '', 
        "phone" : ''
    })

    const handleChange = (e: any) => {
        console.log(e)
        setState({
            ...state,
            [e.target.name]:  e.target.value
        })
  }
  
    // 로그인 버튼
    const BtnLogin = () => {
        formHelper("frm", function(jsonData:any) {
            if (util.IsNullOrEmpty(jsonData.email)) {
                alert("아이디를 입력하세요.");
            } else if (util.IsNullOrEmpty(jsonData.password)) {
                alert("비밀번호를 입력하세요.");
            } else {
                apiHelper.Post("/Account/member/Login", jsonData, (rst:ReturnValue) => {
                    console.log(rst.check)
                    if (rst.check) {
                        //로그인에 성공하면 
                        axios({
                            method: 'get',
                            url: API_URL+'/account/member/list',
                            headers: { "accept": `application/json`, "access-token": `${rst.value}` },
                        }).then((res)=>{
                            res.data.data.map((row:any)=>{
                                if(row.email === jsonData.email) {
                                    axios({
                                        method: 'get',
                                        url: API_URL+`/account/member/view/${row.id}`,
                                        headers: { "accept": `application/json`, "access-token": `${rst.value}`, "Content-Type": `application/json` },
                                    }).then((res2)=>{
                                        if(res2.data.data.isConfirm) {
                                            console.log(config.key)
                                            util.setCookie(config.key, rst.value); //쿠키에 저장하고
                                            localStorage.setItem('token', rst.value); //storage에 저장
                                            localStorage.setItem('code', String(rst.code));
                                            alert(`${res2.data.data.email} 님 안녕하세요 !`);
                                            util.Move( '/main'); //main 페이지로 이동합니다.
                                        }
                                        else {
                                            alert("승인 대기 상태입니다. 본사에 문의하세요.");
                                        }
                                    }).catch((err)=>{
                                        alert("아이디 또는 비밀번호를 잘못 입력했습니다. 입력하신 내용을 다시 확인해주세요.");
                                    })
                                }
                            })
                        })
                    } else {
                        alert(rst.message);
                    }
                });
            }
        });
    };

    // 회원가입 버튼
    const BtnJoin = () => {
        // if 예외처리가 되어서 모든 값이 제대로 입력되면
        formHelper("frm2", function(jsonData:any) {
            if (util.IsNullOrEmpty(jsonData.email)) {
                alert("아이디를 입력하세요.");
            } else if (util.IsNullOrEmpty(jsonData.password)) {
                alert("비밀번호를 입력하세요.");
            } else if (util.IsNullOrEmpty(jsonData.passwordCheck)) {
                alert("비밀번호 확인을 입력하세요.");
            } else if (util.IsNullOrEmpty(jsonData.name)) {
                alert("이름을 입력하세요.");
            } else if (util.IsNullOrEmpty(jsonData.phone)) {
                alert("연락처를 입력하세요.");
            }
            else {
                if(jsonData.password2 === jsonData.password2Check) {
                    console.log(state)
                    axios({
                        method: 'post',
                        url: API_URL + '/account/member/regist',
                        headers: { 
                          "accept": `application/json`,
                          "Content-Type": `application/json`
                        },
                          data: { 
                            "email": state.email,
                            "password": state.password,
                            "name": state.name,
                            "phone": state.phone
                           },
                        // data : {state}
                          
                      }).then((res) => {
                        console.log(res)
                        if(res.data.check) {
                            //회원가입에 성공하면 
                            alert("회원가입이 완료되었습니다. 계정이 승인될 때 까지 기다려주세요. 승인은 평균 3~5 영업일 소요됩니다.")
                            window.location.reload()
                        }
                        else {
                            alert("다시 시도해주세요. : " + res.data.message)
                        }
                      }).catch((err) => {
                        console.log(err)
                      })

                    // apiHelper.Post('/account/member/regist', jsonData, (rst:ReturnValue) => {
                    //     console.log(rst.check)
                    //     if (rst.check) {
                    //         //회원가입에 성공하면 
                    //         alert("회원가입이 완료되었습니다. 계정이 승인될 때 까지 기다려주세요.")
                    //     } else {
                    //         alert(rst.message);
                    //     }
                    // });
                } else {
                    alert("비밀번호와 비밀번호 확인값이 일치하지 않습니다.")
                }
                
            }
        });
    };

    return(
        <div style={{ background: 'url("/images/background/login_background.png")', backgroundSize:'cover', height: '100vh'}}>
        <div className="login-page-wrapper" >
            <div className="page-content">
                <div className="container-fluid">
                    <div className="row">
                    <div className="col-3"></div>
                    <div className="col-6">
                    <div className="card shadow-lg">
                    <div className="card-body">
                        <div className="px-3">
                            <div style={{ textAlign:"center" }}>
                                {/* <img src="#" height="55" alt="logo" className="auth-logo" /> */}
                            </div>
                            <div style={{ display: 'flex', width:'15rem', justifyContent:'space-between', marginLeft:'5.5rem'}}>
                                <h4 style={isLogin ? { color : 'white', fontSize:'1.5rem', paddingBottom:'1rem', cursor:'pointer', borderBottom:'3px solid #35AFE6'}:{ color : 'white', fontSize:'1.5rem', paddingBottom:'2rem', cursor:'pointer'}} onClick={()=>{setIsLogin(true); setIsJoin(false)}}>로그인</h4>
                                <h4 style={isJoin ? { color : 'white', fontSize:'1.5rem', paddingBottom:'1rem', cursor:'pointer', borderBottom:'3px solid #35AFE6'}:{ color : 'white', fontSize:'1.5rem', paddingBottom:'2rem', cursor:'pointer'}} onClick={()=>{setIsLogin(false); setIsJoin(true)}}>회원가입</h4>
                            </div>
                            {
                                isLogin ?
                                <form className="form-horizontal auth-form my-4" name="frm" id="frm" method="post" style={{ borderRadius:'1rem', backdropFilter:'blur(8px)', padding : '2rem'}}>
                                <div className="form-group">
                                    <div style={{ color :'white', fontFamily:'inter-pretendard', fontSize:'1.25rem', fontWeight: 700, paddingBottom:'0.5rem'}}>로그인</div>
                                    <div style={{ color :'white', fontFamily:'inter-pretendard', fontSize:'0.9rem', fontWeight: 300, paddingBottom:'1rem'}}>딥인스펙터에 오신 걸 환영합니다!</div>                                  
                                </div>
                                <div className="form-group">
                                    <label htmlFor="managerid" style={{ color : 'white'}}>아이디</label>
                                    <div className="input-group mb-3">
                                        <span className="auth-form-icon">
                                            <i className="dripicons-user"></i> 
                                        </span>                                                                                                              
                                        <input type="email" className="form-control" id="email" name="email" placeholder="아이디를 입력해주세요" defaultValue={''} style={{ border: '1px solid #35AFE6', background:'none', borderRadius:'8px', color :'white'}} />
                                    </div>                                    
                                </div>
                                <div className="form-group">
                                    <label htmlFor="password" style={{ color : 'white'}}>비밀번호</label>                                            
                                    <div className="input-group mb-3"> 
                                        <span className="auth-form-icon">
                                            <i className="dripicons-lock"></i> 
                                        </span>                                                       
                                        <input type="password" className="form-control" id="password" name="password" placeholder="비밀번호를 입력해주세요" defaultValue={''} style={{ border: '1px solid #35AFE6', background:'none', borderRadius:'8px', color :'white'}} />
                                    </div>                               
                                </div>

                                <div className="form-group mb-0 row">
                                    <div className="col-12 mt-2">
                                        <button className="btn btn-gradient-primary btn-round btn-block waves-effect waves-light" type="button" onClick={BtnLogin}>로그인 <i className="fas fa-sign-in-alt ml-1"></i></button>
                                    </div>
                                </div>
                            </form> :
                                <form className="form-horizontal auth-form my-4" name="frm2" id="frm2" method="post" style={{ borderRadius:'1rem', backdropFilter:'blur(8px)', padding : '2rem'}}>
                                <div className="form-group2">
                                    <label htmlFor="managerid" style={{ color : 'white'}}>아이디</label>
                                    <div className="input-group mb-3">
                                        <span className="auth-form-icon">
                                            <i className="dripicons-user"></i> 
                                        </span>                                                                                                              
                                        <input type="email" className="form-control" id="email" name="email" placeholder="이메일을 입력해주세요" defaultValue={''} onChange={handleChange} style={{ border: '1px solid #35AFE6', background:'none', borderRadius:'8px', height:'1.75rem', color :'white'}} />
                                    </div>                                    
                                </div>
                                <div className="form-group2">
                                    <label htmlFor="password" style={{ color : 'white'}}>비밀번호</label>                                            
                                    <div className="input-group mb-3"> 
                                        <span className="auth-form-icon">
                                            <i className="dripicons-lock"></i> 
                                        </span>                                                       
                                        <input type="password" className="form-control" id="password" name="password" placeholder="비밀번호를 입력해주세요" defaultValue={''} onChange={handleChange} style={{ border: '1px solid #35AFE6', background:'none', borderRadius:'8px', height:'1.75rem', color :'white'}} />
                                    </div>                               
                                </div>
                                <div className="form-group2">
                                    <label htmlFor="password" style={{ color : 'white'}}>비밀번호 확인</label>                                            
                                    <div className="input-group mb-3"> 
                                        <span className="auth-form-icon">
                                            <i className="dripicons-lock"></i> 
                                        </span>                                                       
                                        <input type="password" className="form-control" id="passwordCheck" name="passwordCheck" placeholder="비밀번호를 한번 더 입력해주세요" defaultValue={''} style={{ border: '1px solid #35AFE6', background:'none', borderRadius:'8px', height:'1.75rem', color :'white'}} />
                                    </div>                               
                                </div>
                                <div className="form-group2">
                                    <label htmlFor="password" style={{ color : 'white'}}>이름</label>                                            
                                    <div className="input-group mb-3"> 
                                        <span className="auth-form-icon">
                                            <i className="dripicons-lock"></i> 
                                        </span>                                                       
                                        <input type="text" className="form-control" id="name" name="name" placeholder="이름을 입력해주세요" defaultValue={''} onChange={handleChange} style={{ border: '1px solid #35AFE6', background:'none', borderRadius:'8px', height:'1.75rem', color :'white'}} />
                                    </div>                               
                                </div>
                                <div className="form-group2">
                                    <label htmlFor="password" style={{ color : 'white'}}>연락처</label>                                            
                                    <div className="input-group mb-3"> 
                                        <span className="auth-form-icon">
                                            <i className="dripicons-lock"></i> 
                                        </span>                                                       
                                        <input type="text" className="form-control" id="phone" name="phone" placeholder="연락처를 입력해주세요" defaultValue={''} onChange={handleChange} style={{ border: '1px solid #35AFE6', background:'none', borderRadius:'8px', height:'1.75rem', color :'white'}} />
                                    </div>                               
                                </div>
                                {/* <div className="form-group2">
                                    <label htmlFor="password" style={{ color : 'white'}}>사업자 번호</label>                                            
                                    <div className="input-group mb-3"> 
                                        <span className="auth-form-icon">
                                            <i className="dripicons-lock"></i> 
                                        </span>                                                       
                                        <input type="text" className="form-control" id="groupCode" name="groupCode" placeholder="소속되어있는 회사의 사업자 번호를 입력해주세요" defaultValue={''} style={{ border: '1px solid #35AFE6', background:'none', borderRadius:'8px', height:'1.75rem', color :'white'}} />
                                    </div>                               
                                </div> */}
                                <div>
                                <div className="form-group mb-0 row">
                                    <div className="col-12 mt-2" style={{ paddingTop:'1rem'}}>
                                        <button className="btn btn-gradient-primary btn-round btn-block waves-effect waves-light" type="button" onClick={BtnJoin}>회원가입 <i className="fas fa-sign-in-alt ml-1"></i></button>
                                    </div>
                                </div>
                                <div className="m-3 text-center text-muted"  style={{ fontSize:'1rem', paddingTop:'1rem'}}>
                                <p>기업 회원가입이 필요하신가요?  <a href="/join/company" target="_blank">여기를 클릭해주세요</a></p>
                                </div>
                                </div>
                            </form>
                            
                            }
                            
                        </div>
                    </div>
                    </div>
                    </div>
                    </div>
                </div>
            </div>
        </div>
        </div>
    );
};

export default LoginPage;
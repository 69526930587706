import React, { useState, useEffect } from 'react';
import { Layout } from '../../allcomponents';
import { useNavigate } from 'react-router-dom';
import { User } from '../../alltypes';
import { ReturnValues } from '../../allmodels';
import { apiHelper } from '../../allutils';

function UserListPage(props:any) {
    const columns = [
        { key: 'id', name: 'UID', show:false, isKey:true },
        { key: 'email', name: 'ID', show:true, isKey:false },
        { key: 'name', name: 'Name', show:true, isKey:false },
        { key: 'companyId', name: 'Company', show:true, isKey:false },
        { key: 'phone', name: 'Contact', show:true, isKey:false },
        { key: 'role', name: 'Grade', show:true, isKey:false },
        { key: 'isEnabled', name: 'Status', show:true, isKey:false, on:"승인", off:"미승인" }
    ];

    const [isBind, setIsBind] = useState(false);
    const [list, setList] = useState<User[]>();
    const navigate = useNavigate();

    const DataBind = () => {
      if (!isBind) {
        apiHelper.Get("/account/member/list/confirm/1", {}, (rst: ReturnValues<User[]>) => {
          if (rst.check && rst.data !== null && rst.data !== undefined) {
              setList(rst.data);
              console.log(rst);
              setIsBind(true);
          }
      });
      }
    };

    useEffect(function() {
        DataBind();
    }, [isBind]);

    
    const onRowClick = (row:any) => {
      console.log("click");
      console.log(row.key);
      navigate(`/user/detail/${row.key}`);
    };

    return (
        <Layout>
            <div className="user-list">
                {/* 사용자 목록 화면 */}
                <div className="data-grid">
                  <div className="row">
                    <div className="col-12">
                        <div className="card">
                          <div className="card-body">
                              {
                                  (isBind) ? 
                                  <div className="spinner-border text-primary" style={{ margin : "0 auto" }} role="status"></div>
                                  :
                                  <div className="spinner-border text-primary" style={{ margin : "0 auto" }} role="status"></div>
                              }
                          </div>
                        </div>
                    </div>
                  </div>
                </div>
            </div>
        </Layout>
    );
};

export default UserListPage;
import React, { useState, useEffect } from 'react';
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";
import { Layout } from '../../allcomponents';
import { User } from '../../alltypes';
import { useNavigate } from 'react-router-dom';
import { ReturnValues } from '../../allmodels';
import { apiHelper } from '../../allutils';



function UserConfirmDetailPage(props:any) {
  const userid = document.location.href.split("user/list/confirm/")[1];

  const navigate = useNavigate();

  const [isBind, setIsBind] = useState(false);
  const [data, setData] = useState<User>();

  const DataBind = () => {
    if (!isBind) {
      apiHelper.Get(`/account/member/view/${userid}`, {}, (rst: ReturnValues<User>) => {
        if (rst.check && rst.data !== null && rst.data !== undefined) {
            setData(rst.data);
            console.log(rst);
            setIsBind(true);
        }
    });
    }
  };

  useEffect(function() {
      DataBind();
  }, [isBind]);

  // isConfirm === true 일 때 api 요청 보내고 요청 보낸 후 setIsConfirm(false) 해주기`
  const [isConfirm, setIsConfirm] = useState(false); 
  const [isEnabled, setIsEnabled] = useState(false); 

  useEffect(function() {
    if(isConfirm) {
      confirmTrue();
    }

}, [isConfirm]);

useEffect(function() {
  if(isEnabled) {
    confirmFalse();
  }

}, [isEnabled]);

  const onFalseBtnClick = () => {
    // reload 안되면 window.history.go(0); 사용하기
    // span tag로 감싸면 red, bold 적용
    let contentData : JSX.Element = (
      <p>해당 대기자의 사용을 <span>승인 거절</span>하시겠습니까?</p>
    )

    

  }

  const onTrueBtnClick = () => {
    // reload 안되면 window.history.go(0); 사용하기
    // span tag로 감싸면 red, bold 적용
    let contentData : JSX.Element = (
      <p>해당 대기자의 사용을 <span>승인</span>하시겠습니까?</p>
    )
      
  };

  const confirmTrue = () => {
    let contentData : JSX.Element = (
      <p>승인이 완료되었습니다.</p>
    )
    let contentData2 : JSX.Element = (
      <p>승인 요청이 <span>거부</span> 되었습니다.</p>
    )
    if(isConfirm) {
      apiHelper.Get(`/account/member/confirm/${userid}?confirm=1`, {}, (rst: ReturnValues<User>) => {
        if (rst.check) {
             //승인에 성공하면 
             console.log(rst);
             console.log(rst.data);
             
           
            navigate("/user/list/confirm");
            // window.history.go(-1);
        } else {
          console.log(rst.message);
          
        }
    });
    }
  }

  const confirmFalse = () => {
    let contentData : JSX.Element = (
      <p>승인 거절이 완료되었습니다.</p>
    )
    let contentData2 : JSX.Element = (
      <p>승인 거절 요청이 <span>거부</span> 되었습니다.</p>
    )
    if(isEnabled) {
      apiHelper.Get(`/account/member/enabled/${userid}?isenabled=0`, {}, (rst: ReturnValues<User>) => {
        if (rst.check) {
             //승인 거절에 성공하면 
             console.log(rst);
             console.log(rst.data);
             
            
            navigate("/user/list/confirm");
            // window.history.go(-1);
        } else {
          console.log(rst.message);
          
        }
    });
    }
  }

    return (
        <Layout tabOff={false}>
            <div className="user-confirm-detail">
                {/* 사용자 승인 상세 조회 화면 */}
                <div className="data-grid">
                  <div className="row">
                    <div className="col-12">
                        <div className="card">
                          <div className="card-body">
                              <div className = "detail-title">
                                사용자 정보
                              </div>
                              <div className="back-btn">
                                <a href="javascript:history.go(-1)">
                                    <span className="material-symbols-outlined">
                                        arrow_back
                                    </span>
                                    <span className="back-btn-text">
                                        이전으로
                                    </span>
                                </a>
                              </div>
                              <div className = "user-detail-view">
                                <div className="profile-title">
                                  <div className="account">
                                    <span className="material-symbols-outlined">
                                      account_circle
                                    </span>
                                    <div className="account-text">
                                      {data?.email}
                                    </div>
                                  </div>
                                  <div className="approval">
                                      <span className="approval-name">
                                        Approval Date
                                      </span>
                                      <span className="approval-data">
                                        {data?.createdAt.split("T")[0]}
                                      </span>
                                  </div>
                                </div>
                                <div className="section-line"/>
                                <div className="profile-data">
                                  <div className="name-data">
                                    Name <span>{data?.name}</span>
                                  </div>
                                  <div className="company-data">
                                    Company <span>{data?.companyId ? data?.companyId:'없음'}</span>
                                  </div>
                                  <div className="phone-data">
                                    Phone Number <span>{data?.phone}</span>
                                  </div>
                                  <div className="grade-data">
                                    Grade <span>{data?.role}</span>
                                  </div>
                                </div>
                              </div>
                              <div className="used-data-box">
                                  
                              </div>
                              <div className="btn-skin btn-skin-location">
                                <div className="delete-btn" onClick={onFalseBtnClick}>
                                    승인 거절
                                </div>
                                <div className="update-btn" onClick={onTrueBtnClick}>
                                    사용 승인
                                </div>
                              </div>
                          </div>
                        </div>
                    </div>
                  </div>
                </div>
            </div>
        </Layout>
    );
};

export default UserConfirmDetailPage;
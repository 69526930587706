import React, { useState, useEffect } from 'react';
import axios from "axios";
import { Layout } from '../../../../allcomponents';
import { useNavigate } from 'react-router-dom';
import { API_URL, IMAGE_URL } from '../../../../Store/Global';
import { Box, Button, Table, TableBody, TableCell, tableCellClasses, TableContainer, TableHead, TableRow, TableFooter, Pagination, styled, Tabs, Tab, Typography } from '@mui/material';

interface ImgContents {
  no : number
  name : any
  sort : any
}

interface OriImage {
  no : any
  original_image : string
}

interface ResultImage {
  no : any
  result_image : string
}

  interface TabPanelProps {
    children?: React.ReactNode;
    index: number;
    value: number;
  }
  
  function TabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props;
  
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box sx={{ p: 3 }}>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    );
  }
  
  function a11yProps(index: number) {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
    };
  }

  function createData(
    num: string,
    name: string,
  ) {
    return { num, name };
  }
  
export default function TunnelCrackDetectorPage(props:any) {
    const projectType = localStorage.getItem('projectType');
    const curId = window.location.href.split('/')[6];
    let token : string | null = localStorage.getItem("token")
    let projectId: string | null = localStorage.getItem("projectId");

    const navigate = useNavigate();
    const [page, setPage] = React.useState(1);
    const [data, setData] = useState<any[]>([]);
    const [spanNumber, setSpanNumber] = useState();

    useEffect(() => {
      setData(ImgList.slice((page-1)*10, page*10));
    }, [page]);
    const handlePage = (event:any) => {
      const nowPageInt = parseInt(event.target.outerText);
      console.log(event.target)
      
      if(event.target.outerText) {
        setPage(nowPageInt);
      }
      else {
        if(event.target.ariaLabel.includes("prev")) {
          setPage(page-1);
        }
        if(event.target.ariaLabel.includes("next")) {
          setPage(page+1);
        }
  
      }
      
    }

    const ImgContents : ImgContents[] = [];  
    const OriImage : OriImage[] = [];  
    const ResultImage : ResultImage[] = [];  

    const [spanCount, setspanCount] = useState<number>(0)
    const [CameraCount, setCameraCount] = useState<number>(0)

    const [num1, setNum1] = useState<number>(0);
    const [num2, setNum2] = useState<number>(0);
    const [ImgList, setImgList] = useState<any | undefined>(ImgContents)

    const [ImgListOri, setImgListOri] = useState<any[]>(OriImage);
    const [ImgListCrack, setImgListCrack] = useState<any[]>(ResultImage);

    const [resultOri, setResultOri] = useState<boolean>(false)
    const [resultCrack, setResultCrack] = useState<boolean>(false)

    const [isOpen, setOpen] = useState(false);

    const [value, setValue] = React.useState(0);

    const handleChange = (event: React.SyntheticEvent, newValue: number) => {
        setValue(newValue);
    };

    const [userid, setUserId] = useState<number>(-1);
    const [companyid, setCompanyId] = useState<number>(-1);
  
    useEffect(() => {
        let getIdCompany = async () => {
          if (token !== null) {
            console.log("프로젝트ID" + projectId);
            const response = await axios({
              method: "get",
              url: `${API_URL}/account/auth/check/${token}`,
            })
              .then(async (res) => {
                if (res.data.check === true) {
                  setUserId(res.data.data.id)
                  setCompanyId(res.data.data.companyId)
                  // localStorage.set("project_id", id);
                  console.log(
                    `아이디는 다음과 같음 : ${res.data.data.id} / 회사는 다음과 같음 : ${res.data.data.companyId}`
                  );
                  return {
                    email: res.data.data.email,
                    name: res.data.data.name,
                    phone: res.data.data.phone,
                    avatar: res.data.data.avatar,
                    role: res.data.data.role,
                    id: res.data.data.id,
                    companyId: res.data.data.companyId,
                  };
                } else {
                  console.log("토큰 만료");
                  localStorage.removeItem("token");
                  alert("토큰이 만료었습니다 다시 로그인 해주세요");
                  window.location.replace("/Main");
                }
              })
              .catch((err) => {
                console.log(err);
              });
          }
        };
        getIdCompany();
      }, []);

useEffect(() => {
        const response = axios({
            method: 'get',
            url: API_URL + `/project/view/${projectId}`,
            headers : {"accept" : `application/json`, "access-token" : `${token}`}, 
        }).then((res) => {
                const settings: any = JSON.parse(res.data.data.settings)
                if (res.data.check === true) {
                    console.log(settings)
                    setspanCount(settings.spanCount);
                    setCameraCount(settings.cameraCount);

                    axios({
                        method: 'get',
                        url: API_URL+'/File/Files',
                        headers: { "accept": `application/json`, "access-token": `${token}` },
                        params: {
                            path: `/project/${projectId}/stage6_crack`
                        }
                    }).then((res)=>{
                        if (res.data.check === true) {
                            for(let i = 0; i<res.data.data.files.length; i++){
                                const indexFront = res.data.data.files[i].indexOf("S") 
                                const indexBack = res.data.data.files[i].indexOf(".png") 
                                const SpanNum = res.data.data.files[i].substring(indexFront, indexBack-6)
                                setSpanNumber(SpanNum.substring(3))
                                // console.log(SpanNum)
                                ResultImage.push({
                                    result_image : `${IMAGE_URL}/image?path=/project/${projectId}/stage6_crack/${res.data.data.files[i]}&width=1440`,
                                    no : SpanNum
                                })
                                console.log(ResultImage[i].result_image)
                                ImgContents.push({
                                                no : i + 1,
                                                name : <a style={{color:"black"}}>{res.data.data.files[i]}</a>,
                                                sort : SpanNum
                                            })
                            }
            
                            if(ResultImage.length > 0){
                                setResultCrack(true);
                                // console.log(ResultImage)
                            }else{
                                alert("결과가 나올 때까지 기다려주세요.")
                            }
            
                        } else {
                            console.log("실패")
                            alert('균열 검출을 실행해주세요.')
                        }
            
                            ImgContents.sort((obj1, obj2) => {
                                if (obj1.sort > obj2.sort) {
                                    return 1;
                                }
                                if (obj1.sort < obj2.sort) {
                                    return -1;
                                }
                                return 0;
                                })
            
                        let copyArrImgList = [...ImgList];
            
                        for(let i = 0; i < ImgList.length; i++){
                            copyArrImgList[i] = { ...copyArrImgList[i], no : i};
                        }
            
            
                        setImgList(copyArrImgList)
                        setData(copyArrImgList.slice((page-1)*10, page*10));
                        // console.log(ImgList, "ImgList")
            
                        ResultImage.sort((obj1, obj2) => {
                            if (obj1.no > obj2.no) {
                                return 1;
                            }
                            if (obj1.no < obj2.no) {
                                return -1;
                            }
                            return 0;
                            })
            
                        setImgListCrack(ResultImage);
            
                    }).catch((err) => {
                        console.log(err);
                    });
                    
                    
                    for(let i = 1; i <Number(settings.spanCount) + 1; i++){
                        axios({
                            method: 'get',
                            url: API_URL+'/File/Files',
                            headers: { "accept": `application/json`, "access-token": `${token}` },
                            params: {
                                // path: `/project/${project_id}/stage4/S00${i}`
                                path: `/project/${projectId}/stage4/S${String(i).padStart(3,"0")}`
                            }
                        }).then((res)=>{
                            if (res.data.check === true) {
                                for(let j = 0; j < res.data.data.files.length; j++){
                                    OriImage.push({
                                        // original_image : `${IMAGE_URL}/image?path=/project/${project_id}/stage4/S00${i}/${res.data.data.files[j]}&width=1440`,
                                        original_image : `${IMAGE_URL}/image?path=/project/${projectId}/stage4/S${String(i).padStart(3,"0")}/${res.data.data.files[j]}&width=1440`,
                                        no : `${i}`
                                    })
                                }
                                
                            } else {
                                console.log("실패")
                            }
            
                            if(OriImage.length === settings.cameraCount * settings.spanCount){
                                setResultOri(true);
                            }
            
                            OriImage.sort((obj1, obj2) => {
                                if (obj1.no > obj2.no) {
                                    return 1;
                                }
                                if (obj1.no < obj2.no) {
                                    return -1;
                                }
                                return 0;
                            })
            
                            setImgListOri(OriImage)
                            
                        }).catch((err) => {
                            console.log(err);
                        });
                    }
                } else {
                    console.log("실패");
                }
            }).catch((err) => {
                console.log(err);
            });

            // console.log(spanCount)
            // console.log(CameraCount)
        
    }, [])

    let job_id = 0;
    let resultCouple :any;

    //커플 이미지 다운로드
    const downloadCouple = () => {


      // console.log(ImgListOri[num].original_image)
      // console.log(ImgListCrack[num].result_image)

      alert("30초 정도 기다리시면 다운로드 됩니다.")

      const indexFrontOri = ImgListOri[num2].original_image.indexOf("/stage")
      const indexBackOri= ImgListOri[num2].original_image.indexOf("&width")   
      const ImageUrlCutWidthOri = ImgListOri[num2].original_image.substring(indexFrontOri+1, indexBackOri)

      // console.log(ImageUrlCutWidthOri)

      const indexFrontName = ImgListOri[num2].original_image.indexOf("/stage")
      const indexBackName= ImgListOri[num2].original_image.indexOf("&width")   
      const ImageUrlCutWidthName = ImgListOri[num2].original_image.substring(indexFrontName+13, indexBackName)

      // console.log(ImageUrlCutWidthName)

      const indexFrontCrack = ImgListCrack[num2].result_image.indexOf("/stage")
      const indexBackCrack= ImgListCrack[num2].result_image.indexOf("&width")   
      const ImageUrlCutWidthCrack = ImgListCrack[num2].result_image.substring(indexFrontCrack+1, indexBackCrack)

      // console.log(ImageUrlCutWidthCrack)

      axios({
          method: "post",
          url: API_URL +`/scheduler/job/start/${companyid}/${userid}`,
          headers: {
            "accept": `application/json`,
            "access-token": `${token}`,
            "Content-Type": `application/json`
          },
                data: {
              project_id: projectId,
              task_name: "image_join",
              interactive: false,
              tasks: [{
                  image1_path: ImageUrlCutWidthOri,
                  image2_path: ImageUrlCutWidthCrack,
                  align: 0, //수평 //수직으로 하려면 0
                  output_folder: 'stage6_Couple',
                  output_name: ImageUrlCutWidthName
              }]
              }
      }).then((res) => {
          console.log(res.data.check)
          if (res.data.check === true) {
              job_id = res.data.data.job_id
                      /////30초마다 alert로 알려줌////////////
                      resultCouple = setInterval(confirm, 10000)
          } else {
              console.log("실패")
          }
      })
  }

  async function confirm(){
        
    axios({
        method: "post",
        url: API_URL + '/scheduler/job/query',
        headers: { "accept": `application/json`, "access-token": `${token}`, "Content-Type" : `application/json` },
            data: {
                "job_id": job_id ,
                "company_id": companyid
            }
              }).then(async (res) => {
            console.log(res)
            if (res.data.check === true) {
                if (res.data.data.status === "done") {
                    console.log("성공")

                    setTimeout(()=>
                        download()
                    ,10000)

                clearInterval(resultCouple)
                } else if (res.data.data.status === "progress") {

                } else if(res.data.data.status === "error"){
                    alert("해당 파일이 없습니다.")
                }
            } else {
                console.log("실패")
            }
        })
    
}

async function download(){

  const indexFrontName = ImgListOri[num2].original_image.indexOf("/stage")
  const indexBackName= ImgListOri[num2].original_image.indexOf("&width")   
  const ImageUrlCutWidthName = ImgListOri[num2].original_image.substring(indexFrontName+13, indexBackName)
                          
  const link = document.createElement('a');
  let src = `${IMAGE_URL}/image?path=/project/${projectId}/stage6_Couple/${ImageUrlCutWidthName}`;
  const imageBlob = await (await fetch(src)).blob();
  src = URL.createObjectURL(imageBlob);
  link.href = src;
  link.download = ImageUrlCutWidthName
  link.click();
}
    const openWindow = () => {
        window.open('/ViewerCrack', "균열 결과 수정", "width=1650px, height=600px, left=300, top=150");
    }

    const rendering = () => {
      const result = [];
      resultCrack === true ? localStorage.setItem('edit_crack_image', ImgListCrack[num2].result_image) : localStorage.setItem('edit_crack_image', "")
      
      for (let i = 0; i < spanCount; i++) {

          result.push(
          <TabPanel value={value} index={i}>
              <div style={{ marginTop : '0.5rem'}} >
                  <div style={{ display: 'flex', justifyContent : 'space-between'}}>
                    <span style={{ fontWeight : 600, borderLeft: '0.1875rem solid #0F6FB7', paddingLeft: '0.62rem', height:'1.5rem'}}>{resultOri === true ? ImgListOri[num2].original_image.includes('png') ? ImgListOri[num2].original_image.substring(ImgListOri[num2].original_image.indexOf("/S") + 6, ImgListOri[num2].original_image.indexOf(".png") + 4) : ImgListOri[num2].original_image.substring(ImgListOri[num2].original_image.indexOf("/S") + 6, ImgListOri[num2].original_image.indexOf(".jpg") + 4) : ""}</span>
                    <div>
                      <Button sx={{ marginRight : '0.5rem', fontWeight : 600, fontFamily: 'inter-pretendard' }} onClick={downloadCouple}>커플 이미지 다운로드</Button> 
                      <Button sx={{ marginRight : '0.5rem', fontWeight : 600, fontFamily: 'inter-pretendard' }} onClick={openWindow}>균열 결과 수정</Button>
                    </div>
                  </div>
                  {/* <Button className={styles.nextBtn}>{t.upload}</Button> */}
                  {/* <Button className={styles.nextBtn}>{t.save}</Button> */}
                  <div>
                    <div>
                        <img src={resultOri === true ? ImgListOri[num2].original_image : ""} alt="" style={{width: '60rem', height: '15rem', marginTop : '1.25rem', marginRight: '3rem', flexShrink: 0, cursor: 'pointer', borderRadius: '0.625rem'}} />
                    </div>
                    <div>
                        <img src={resultCrack === true ? ImgListCrack[num2].result_image : ""} alt="" style={{width: '60rem', height: '15rem', marginTop : '1.25rem', marginRight: '2rem', flexShrink: 0, cursor: 'pointer', borderRadius: '0.625rem'}} />
                    </div>
                  </div>
              </div>
          </TabPanel>
          );
      }
      return result;
    }

    const StyledTableCell = styled(TableCell)(({ theme }) => ({
        [`&.${tableCellClasses.head}`]: {
          fontSize: '1rem',
          fontWeight: 600,
          backgroundColor: '#F5F5F5',
          color: '#3A4557',
          width: '42.8125rem',
          height: '2.5rem',
          border: '1px solid #DDD',
          borderTop: '0.15rem solid #3A4557',
          padding : 0
        },
        [`&.${tableCellClasses.body}`]: {
          color: '#3A4557',
          textAlign: 'center',
          fontSize: '0.8125rem',
          width: '42.8125rem',
          height: '3rem',
          border: '1px solid #DDD',
          padding : 0,
        },
      }));
      
    const StyledTableRow = styled(TableRow)(({ theme }) => ({
        // '&:nth-of-type(odd)': {
        //   backgroundColor: theme.palette.action.hover,
        // },
        // hide last border
        '&:last-child td': {
        },

      }));

      const StyledTab = styled(Tab)({
        "&.Mui-selected": {
          color: "#0F6FB7",
          fontWeight : 700,
          background: 'rgba(15, 111, 183, 0.10)',
          borderRadius: '0.25rem',
          marginBottom : '0.25rem',
        }
      });

      const onClickPanor = (i:any) => {
        console.log((page-1)*10+i)
        setNum2((page-1)*10+i);
    };

    const onClickTab = (i:any) => {
      console.log(i)
      setPage(1);
      setNum1(i);
      console.log(CameraCount*i)
      setNum2(CameraCount*i);
      // setPanoramaa(true);
      console.log("tab", i , "panor", num2)
      console.log(`${IMAGE_URL}/image?path=/project/${projectId}/stage4/S00${i+1}/${ImgList[num2]}&width=1240`)
  };

    const tabsPrint = () => {
      const spanResult:any[] = [];

      for (let i = 0; i < spanCount; i++) {
        spanResult.push((<StyledTab onClick={() => onClickTab(i)}
          label={`Span ${spanNumber}`} {...a11yProps(i)} />))
      }
      
      return spanResult;
    }

    
    return (
        <Layout>
            <div style={{ paddingTop : '2.01rem', paddingLeft : '2rem', color: '#999', fontSize: '0.75rem', fontFamily: 'inter-pretendard', fontStyle: 'normal', fontWeight: 400, lineHeight: '100%'}}>균열 검출 & 측정 &gt; 균열 검출</div>
            <div style={{ paddingTop : '0.3rem', paddingLeft : '2rem', color: '#3A4557', fontSize: '1.5rem', fontFamily: 'inter-pretendard', fontStyle: 'normal', fontWeight: 700, lineHeight: '100%'}}>균열 검출</div>
            <div style={{ display : 'flex'}}>
                <div>
                
                <Box sx={{ width: '25rem', backgroundColor: 'white', borderRadius: '0.5rem', boxShadow: '2px 2px 20px 0px rgba(58, 69, 87, 0.05)', padding : '2rem', marginLeft : '2rem', marginTop: '1rem'}}>
                    <div style={{ display: 'flex', alignItems : 'center', justifyContent : 'space-between'}}>
                    <div style={{ fontWeight : 700, borderLeft: '0.1875rem solid #0F6FB7', fontSize:'1rem', height:'1.2rem',paddingLeft: '0.62rem'}}>이미지 파일 리스트 </div>
                    </div>
                    <div>
                        <TableContainer  sx={{ width: '23rem', marginLeft: '1rem', marginTop: '1rem' }}>
                            <Table sx={{ backgroundColor: '#fffff' }} aria-label="customized table">
                                <TableHead>
                                <TableRow>
                                    <StyledTableCell align="center" style={{width:'3rem', fontFamily:'inter-pretendard', fontSize: '1rem'}}>번호</StyledTableCell>
                                    <StyledTableCell align="center" style={{width:'5rem', fontFamily:'inter-pretendard', fontSize: '1rem'}}>이름</StyledTableCell>
                                </TableRow>
                                </TableHead>
                                
                                <TableBody>
                                {data.map((row:any, i:any) => (
                                    <StyledTableRow 
                                        key={row.num}
                                        style={{ height: '2.5rem'}}
                                        onClick={() => onClickPanor(i)}
                                    >
                                    {/* <StyledTableCell component="th" scope="row" align="center">
                                        {row.num}
                                    </StyledTableCell> */}
                                    <StyledTableCell align="center" style={{width:'3em', height: '2.5rem', fontFamily:'inter-pretendard', fontSize: '0.8rem'}}>{row.sort}</StyledTableCell>
                                    <StyledTableCell align="center" style={{width:'5rem', height: '2.5rem', fontFamily:'inter-pretendard', fontSize: '0.8rem', cursor:'pointer'}}>{row.name}</StyledTableCell>
                                    </StyledTableRow> 
                                ))}
                                </TableBody>
                            </Table>
                            <TableFooter>
                                <TableRow style={{ width: '23rem', display : 'flex', justifyContent: 'center', marginTop:'4rem'}}>
                                <Pagination count={ImgList.length > 10 && ImgList.length % 10 === 0 ? Math.floor(ImgList.length/10) : Math.floor(ImgList.length/10)+1} defaultPage={1} onChange={(e) => handlePage(e)} color="primary" 
                                    sx={{
                                      '& svg': {
                                        pointerEvents: 'none'
                                      }
                                    }}
                                  />
                                </TableRow>
                                </TableFooter>
                        </TableContainer>
                        
                    </div>
                </Box> 
                </div>
                <div style={{ marginTop: '1rem', height : '50rem'}}>
                <Box sx={{ width: '69rem', height: '47rem', flexShrink: 0, borderRadius: '0.5rem', background: '#FFF', boxShadow: '2px 2px 20px 0px rgba(58, 69, 87, 0.05)', marginLeft : '2rem', marginBottom: '1rem'}}>
                            <div style={{padding : '3rem'}}>
                                {/* 탭들어갈자리 */}
                                <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>

                                <Tabs value={value} onChange={handleChange} aria-label="basic tabs example" variant="scrollable" style={{ height: '2rem'}}>
                                        {tabsPrint()}
                                </Tabs>
                                </Box>
                                {rendering()}
                                </div>
                            </Box>
                </div>
            </div>
        </Layout>
    );
};

